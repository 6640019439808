import {ElasticCommonSchema} from 'helpers/log/ECS/ElasticCommonSchema';

const FAKE_DOMAIN = 'https://www.joom.com';

export function ecsUrl(url: string | URL): ElasticCommonSchema['url'] {
  let urlInstance;
  let hasDomain = true;

  if (typeof url === 'string') {
    try {
      urlInstance = new URL(url);
    } catch {
      hasDomain = false;
      urlInstance = new URL(url, FAKE_DOMAIN);
    }
  } else {
    urlInstance = url;
  }

  return {
    ...(hasDomain && {
      full: urlInstance.toString(),
      domain: urlInstance.hostname,
    }),
    path: urlInstance.pathname,
    query: urlInstance.searchParams.toString(),
  };
}

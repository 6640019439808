import {loadCartCount, discardCart} from 'store/modules/cartCount';
import {loadCouponCards} from 'store/modules/couponCards';
import {loadPointsAccount} from 'store/modules/points';
import {discardParcels} from 'store/modules/parcels';
import {DISCARD_USER_RELEATED_DATA, DISCARD_PAYMENT_RELEATED_DATA} from 'helpers/discardable';

export function discardPaymentRelatedData() {
  return {
    type: DISCARD_PAYMENT_RELEATED_DATA,
    result: (client, dispatch) => {
      dispatch(discardCart(true));
      dispatch(loadCartCount());
      dispatch(loadCouponCards());
      dispatch(loadPointsAccount());
      dispatch(discardParcels());
      return null;
    },
  };
}

export function discardUserRelatedData(user) {
  return {
    type: DISCARD_USER_RELEATED_DATA,
    result: (client, dispatch) => {
      if (user && user.anonymous) {
        dispatch(discardCart(true));
      }
      return null;
    },
  };
}

import {
  Color,
  HexColor,
  HslColor,
  isHexArgbColor,
  isHexRgbColor,
  isNamedColor,
  RgbaColor,
  RgbColor,
} from 'types/Color';
import {Gradient, LinearMulticolorGradient} from 'types/Gradient';

export function removeHexHash<Color extends HexColor | undefined>(color: Color): Color {
  if (color && color.startsWith('#')) {
    return color.slice(1) as Color;
  }
  return color;
}

export function rgbaToRgb([r, g, b]: RgbaColor): RgbColor {
  return [r, g, b];
}

export function rgbToRgba([r, g, b]: RgbColor): RgbaColor {
  return [r, g, b, 1];
}

function getHexColorItem(hex: string, offset: number): number {
  return parseInt(hex.slice(offset, offset + 2), 16);
}

export function hexToRgba(color: HexColor): RgbaColor {
  if (isHexRgbColor(color)) {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    return rgbToRgba(hexToRgb(color));
  }

  const hex = removeHexHash(color);
  const a = getHexColorItem(hex, 0) / 255;
  const r = getHexColorItem(hex, 2);
  const g = getHexColorItem(hex, 4);
  const b = getHexColorItem(hex, 6);
  return [r, g, b, a];
}

export function hexToRgb(color: HexColor): RgbColor {
  if (isHexArgbColor(color)) {
    return rgbaToRgb(hexToRgba(color));
  }

  const hex = removeHexHash(color);
  const r = getHexColorItem(hex, 0);
  const g = getHexColorItem(hex, 2);
  const b = getHexColorItem(hex, 4);
  return [r, g, b];
}

/**
 * Converts an RGB color value to HSL. Conversion formula
 * adapted from http://en.wikipedia.org/wiki/HSL_color_space.
 * Assumes r, g, and b are contained in the set [0, 255] and
 * returns h, s, and l in the set [0, 1].
 *
 * @param   {number}  red       The red color value
 * @param   {number}  green     The green color value
 * @param   {number}  blue      The blue color value
 * @return  {Array}             The HSL representation
 */
export function rgbToHsl(red: number, green: number, blue: number): HslColor {
  const r = red / 255;
  const g = green / 255;
  const b = blue / 255;
  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);
  let h = (max + min) / 2;
  let s = h;
  const l = h;

  if (max === min) {
    // achromatic
    h = 0;
    s = 0;
  } else {
    const d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
      default:
        /* impossible way */
        break;
    }
    h /= 6;
  }

  return [h, s, l];
}

function hue2rgb(p: number, q: number, term: number): number {
  let t = term;
  if (t < 0) t += 1;
  if (t > 1) t -= 1;
  if (t < 1 / 6) return p + (q - p) * 6 * t;
  if (t < 1 / 2) return q;
  if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
  return p;
}

/**
 * Converts an HSL color value to RGB. Conversion formula
 * adapted from http://en.wikipedia.org/wiki/HSL_color_space.
 * Assumes h, s, and l are contained in the set [0, 1] and
 * returns r, g, and b in the set [0, 255].
 *
 * @param   {number}  h       The hue
 * @param   {number}  s       The saturation
 * @param   {number}  l       The lightness
 * @return  {Array}           The RGB representation
 */
export function hslToRgb(h: number, s: number, l: number): RgbColor {
  let r;
  let g;
  let b;

  if (s === 0) {
    // achromatic
    r = l;
    g = l;
    b = l;
  } else {
    const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p = 2 * l - q;
    r = hue2rgb(p, q, h + 1 / 3);
    g = hue2rgb(p, q, h);
    b = hue2rgb(p, q, h - 1 / 3);
  }

  return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
}

export function colorToHexColor(color: Color): HexColor {
  if (typeof color === 'object') {
    const hex = color.rgb || color.argb;
    if (!hex) {
      throw new Error('Color does not contain hex color');
    }

    return hex;
  }

  return color;
}

export function colorToHspBrightness(color: HexColor | RgbColor): number {
  const [r, g, b] = typeof color === 'string' ? hexToRgb(color) : color;
  return Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));
}

export function isColorLight(color: Color | RgbColor, threshold = 200): boolean {
  return colorToHspBrightness(isNamedColor(color) ? colorToHexColor(color) : color) > threshold;
}

export function simpleGradientToRgbColor(gradient: Gradient): RgbColor {
  const c1 = colorToHexColor(gradient.colorStart);
  const c2 = colorToHexColor(gradient.colorEnd);
  let rgb: RgbColor;

  if (c1 === c2) {
    rgb = hexToRgb(c1);
  } else {
    const rgb1 = hexToRgb(c1);
    const rgb2 = hexToRgb(c2);

    rgb = [0, 0, 0];
    for (let step = 0; step < 3; step += 1) {
      rgb[step] = Math.round(((rgb1[step] as number) + (rgb2[step] as number)) / 2);
    }
  }

  return rgb;
}

export function linearMulticolorGradientToRgbColor({colors}: LinearMulticolorGradient): RgbColor {
  const rgb: RgbColor = [0, 0, 0];

  colors.forEach(({position, color}, index) => {
    const prevStep = colors[index - 1];
    const nextStep = colors[index + 1];
    const stepRgb = hexToRgb(colorToHexColor(color));
    const positionStart = prevStep ? (position - prevStep.position) / 2 + prevStep.position : 0;
    const positionEnd = nextStep ? (nextStep.position - position) / 2 + position : 1;
    const percentage = positionEnd - positionStart;

    for (let register = 0; register < 3; register += 1) {
      rgb[register]! += (stepRgb[register] as number) * percentage;
    }
  });

  return rgb;
}

export function gradientToRgbColor(gradient: Gradient | LinearMulticolorGradient): RgbColor {
  if ('colorStart' in gradient) {
    return simpleGradientToRgbColor(gradient);
  }

  return linearMulticolorGradientToRgbColor(gradient);
}

const RGB_REGEXP = /^rgb\(\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*\)$/;

export function rgbStringToRgb(color: string): RgbColor | undefined {
  const matches = color.trim().match(RGB_REGEXP) as [string, r: string, g: string, b: string];

  if (matches) {
    const [, rString, gString, bString] = matches;
    const r = parseInt(rString, 10) || 0;
    const g = parseInt(gString, 10) || 0;
    const b = parseInt(bString, 10) || 0;

    return [r, g, b];
  }

  return undefined;
}

/**
 * @see https://stackoverflow.com/questions/596216/formula-to-determine-perceived-brightness-of-rgb-color
 * @returns {number} Number between 0 and 255.
 */
export function rgbToBrightness([r, g, b]: RgbColor): number {
  return 0.2126 * r + 0.7152 * g + 0.0722 * b;
}

import {ecsError} from 'helpers/log/ECS/ecsError';
import {ECSLog, ECSLogger} from 'helpers/log/ECS/types';
import {getPrefixiedKey, isStorageAvailable, Storage} from 'utils/storage';

import {IKeyValueStorage} from './types';

export function isLocalStorageAvailable(): boolean {
  return __CLIENT__ && isStorageAvailable(window.localStorage);
}

export function isSessionStorageAvailable(): boolean {
  return __CLIENT__ && isStorageAvailable(window.sessionStorage);
}

export class WindowStorage<T> implements IKeyValueStorage<T> {
  private storage: Storage;

  private logger: ECSLogger;

  constructor(storage: Storage, log: ECSLog) {
    this.storage = storage;
    this.logger = log.getLogger('ApiClient/KeyValueStorage/WindowStorage');
  }

  setItem(key: string, value: T): void {
    try {
      this.storage.setItem(getPrefixiedKey(key), JSON.stringify(value));
    } catch (ex) {
      this.logger.error({error: ecsError(ex)});
    }
  }

  removeItem(key: string): void {
    try {
      this.storage.removeItem(getPrefixiedKey(key));
    } catch (ex) {
      this.logger.error({error: ecsError(ex)});
    }
  }

  getItem(key: string): T | undefined {
    try {
      const string = this.storage.getItem(getPrefixiedKey(key));
      if (string) {
        const value = JSON.parse(string);
        return value;
      }
    } catch (ex) {
      this.logger.error({error: ecsError(ex)});
    }
    return undefined;
  }
}

import {ScopeConfig} from 'helpers/ApiClient/Scope/ScopeConfig';

const DEFAULT_PUBLIC_PATH = '/dist/';

export function getPublicPath(scope: ScopeConfig): string {
  if (__DEVELOPMENT__) {
    return DEFAULT_PUBLIC_PATH;
  }

  if (__CLIENT__) {
    return __webpack_public_path__;
  }

  const envPublicPath = scope.isJmt ? process.env.WEB_STATIC_URL_JMT : process.env.WEB_STATIC_URL;

  return envPublicPath || DEFAULT_PUBLIC_PATH;
}

export function setWebpackPublicPath(path: string): void {
  if (__CLIENT__) {
    __webpack_public_path__ = path;
  }
}

export function createPublicPath(url: string, scope: ScopeConfig): string {
  if (__DEVELOPMENT__) {
    return url;
  }

  const publicPath = getPublicPath(scope);
  if (!publicPath || url.startsWith(publicPath) || url.match(/^https?:\/\//)) {
    // do not prefix already prefixied urls
    return url;
  }

  const resultUrl = url.startsWith(DEFAULT_PUBLIC_PATH)
    ? url.replace(DEFAULT_PUBLIC_PATH, '')
    : url;

  return `${publicPath}${resultUrl}`;
}

export function createWorkerWithLocalPath(creator: () => Worker): Worker {
  const publicPath = __webpack_public_path__;
  // worker script must be from same domain
  __webpack_public_path__ = DEFAULT_PUBLIC_PATH;

  const worker = creator();

  __webpack_public_path__ = publicPath;

  return worker;
}

if (__SERVER__ && typeof __webpack_public_path__ !== 'undefined') {
  __webpack_public_path__ = DEFAULT_PUBLIC_PATH;
}

import {LegalEntity, Scope} from './enums';
import {DomainScopeConfig} from './types';

const baseCoolbeConfig = {
  scope: Scope.COOLBE,
  legalEntity: LegalEntity.JOOM,
  scopeHeader: 'coolBeCom',

  companyInfo: {
    companyName: 'Coolbe',
    supportEmail: 'support@coolbe.com',
    copyrightEmail: 'ipr@coolbe.com',
  },

  integrations: {
    oauthEnvPrefix: 'COOLBE_',
    fb: {
      appId: '',
    },
    firebase: {
      hostname: 'coolbe.page.link',
      config: {
        apiKey: 'AIzaSyBQozKWVvvDVS_ynmOYZKldpq9_STJVhgY',
        authDomain: 'coolbe-2024.firebaseapp.com',
        projectId: 'coolbe-2024',
        storageBucket: 'coolbe-2024.appspot.com',
        messagingSenderId: '752649646211',
        appId: '1:752649646211:web:1f8d67c0d03084e18febb6',
        measurementId: 'G-0JGJFG4EHS',
      },
      vapidKey:
        'BAasm2iaXIxFR8k-4JUt1n3spDhQPhqTyWJ99sCL5A00vNvwX4A1z2nnS37Z3w_5BR8DFBZTLpswZFubfxth7wU',
    },
    googleMapsKey: 'AIzaSyCa8t2qpR250dvEmnNBRuBWgphSX9zl5bQ',
    gtmId: 'GTM-M67GHJC6',
  },
} satisfies Partial<DomainScopeConfig>;

export const domainScopeCoolbe: DomainScopeConfig = {
  ...baseCoolbeConfig,

  id: 'coolbe',

  prefixScopes: [
    {
      scope: Scope.CBTREND,
      scopeHeader: 'coolBeTrending',
      pathPrefix: 'trending',
      disableMobileApps: true,
      hasOppositeEntity: false,
      companyInfo: {
        companyName: 'Coolbe',
      },
    },
  ],

  defaultLanguage: 'en',
  defaultCurrency: 'EUR',
  hostname: 'www.coolbe.com',

  static: {
    folder: 'coolbe/com',
    swFileName: 'swCoolbe',
  },
};

export const domainScopeCoolbeDe: DomainScopeConfig = {
  ...baseCoolbeConfig,

  id: 'coolbe-de',
  deviceCustomDomain: 'coolbe.de',

  defaultLanguage: 'de',
  defaultCurrency: 'EUR',
  hostname: 'www.coolbe.de',

  static: {
    folder: 'coolbe/de',
    swFileName: 'swCoolbe',
  },
};

export const domainScopeCoolbeFr: DomainScopeConfig = {
  ...baseCoolbeConfig,

  id: 'coolbe-fr',
  deviceCustomDomain: 'coolbe.fr',

  defaultLanguage: 'fr',
  defaultCurrency: 'EUR',
  hostname: 'www.coolbe.fr',

  static: {
    folder: 'coolbe/fr',
    swFileName: 'swCoolbe',
  },
};

export const domainScopeCoolbeUk: DomainScopeConfig = {
  ...baseCoolbeConfig,

  id: 'coolbe-uk',
  deviceCustomDomain: 'coolbe.co.uk',

  defaultLanguage: 'en',
  defaultCurrency: 'GBP',
  hostname: 'www.coolbe.co.uk',

  static: {
    folder: 'coolbe/uk',
    swFileName: 'swCoolbe',
  },
};

export const domainScopeCoolbeSe: DomainScopeConfig = {
  ...baseCoolbeConfig,

  id: 'coolbe-se',
  deviceCustomDomain: 'coolbe.se',

  defaultLanguage: 'sv',
  defaultCurrency: 'SEK',
  hostname: 'www.coolbe.se',

  static: {
    folder: 'coolbe/se',
    swFileName: 'swCoolbe',
  },
};

export const domainScopeCoolbeAt: DomainScopeConfig = {
  ...baseCoolbeConfig,

  id: 'coolbe-at',
  deviceCustomDomain: 'coolbe.at',

  defaultLanguage: 'de',
  defaultCurrency: 'EUR',
  hostname: 'www.coolbe.at',

  static: {
    folder: 'coolbe/at',
    swFileName: 'swCoolbe',
  },
};

export const domainScopeCoolbePl: DomainScopeConfig = {
  ...baseCoolbeConfig,

  id: 'coolbe-pl',
  deviceCustomDomain: 'coolbe.pl',

  defaultLanguage: 'pl',
  defaultCurrency: 'PLN',
  hostname: 'www.coolbe.pl',

  static: {
    folder: 'coolbe/pl',
    swFileName: 'swCoolbe',
  },
};

export const domainScopeCoolbePt: DomainScopeConfig = {
  ...baseCoolbeConfig,

  id: 'coolbe-pt',
  deviceCustomDomain: 'coolbe.pt',

  defaultLanguage: 'pt',
  defaultCurrency: 'EUR',
  hostname: 'www.coolbe.pt',

  static: {
    folder: 'coolbe/pt',
    swFileName: 'swCoolbe',
  },
};

export const domainScopeCoolbeNl: DomainScopeConfig = {
  ...baseCoolbeConfig,

  id: 'coolbe-nl',
  deviceCustomDomain: 'coolbe.co.nl',

  defaultLanguage: 'nl',
  defaultCurrency: 'EUR',
  hostname: 'www.coolbe.co.nl',

  static: {
    folder: 'coolbe/nl',
    swFileName: 'swCoolbe',
  },
};

export const domainScopeCoolbeIt: DomainScopeConfig = {
  ...baseCoolbeConfig,

  id: 'coolbe-it',
  deviceCustomDomain: 'coolbe.co.it',

  defaultLanguage: 'it',
  defaultCurrency: 'EUR',
  hostname: 'www.coolbe.co.it',

  static: {
    folder: 'coolbe/it',
    swFileName: 'swCoolbe',
  },
};

export const domainScopeCoolbeIl: DomainScopeConfig = {
  ...baseCoolbeConfig,

  id: 'coolbe-il',
  deviceCustomDomain: 'coolbe.co.il',

  defaultLanguage: 'he',
  defaultCurrency: 'ILS',
  hostname: 'www.coolbe.co.il',

  static: {
    folder: 'coolbe/il',
    swFileName: 'swCoolbe',
  },
};

export const domainScopeCoolbeCh: DomainScopeConfig = {
  ...baseCoolbeConfig,

  id: 'coolbe-ch',
  deviceCustomDomain: 'coolbe.ch',

  defaultLanguage: 'de',
  defaultCurrency: 'CHF',
  hostname: 'www.coolbe.ch',

  static: {
    folder: 'coolbe/ch',
    swFileName: 'swCoolbe',
  },
};

export const domainScopeCoolbeCz: DomainScopeConfig = {
  ...baseCoolbeConfig,

  id: 'coolbe-cz',
  deviceCustomDomain: 'coolbe.cz',

  defaultLanguage: 'cs',
  defaultCurrency: 'CZK',
  hostname: 'www.coolbe.cz',

  static: {
    folder: 'coolbe/cz',
    swFileName: 'swCoolbe',
  },
};

export const domainScopeCoolbeSk: DomainScopeConfig = {
  ...baseCoolbeConfig,

  id: 'coolbe-sk',
  deviceCustomDomain: 'coolbe.sk',

  defaultLanguage: 'sk',
  defaultCurrency: 'EUR',
  hostname: 'www.coolbe.sk',

  static: {
    folder: 'coolbe/sk',
    swFileName: 'swCoolbe',
  },
};

export const domainScopeCoolbeHu: DomainScopeConfig = {
  ...baseCoolbeConfig,

  id: 'coolbe-hu',
  deviceCustomDomain: 'coolbe.hu',

  defaultLanguage: 'hu',
  defaultCurrency: 'HUF',
  hostname: 'www.coolbe.hu',

  static: {
    folder: 'coolbe/hu',
    swFileName: 'swCoolbe',
  },
};

export const domainScopeCoolbeRo: DomainScopeConfig = {
  ...baseCoolbeConfig,

  id: 'coolbe-ro',
  deviceCustomDomain: 'coolbe.ro',

  defaultLanguage: 'ro',
  defaultCurrency: 'RON',
  hostname: 'www.coolbe.ro',

  static: {
    folder: 'coolbe/ro',
    swFileName: 'swCoolbe',
  },
};

export const domainScopeCoolbeFi: DomainScopeConfig = {
  ...baseCoolbeConfig,

  id: 'coolbe-fi',
  deviceCustomDomain: 'coolbe.fi',

  defaultLanguage: 'en',
  defaultCurrency: 'EUR',
  hostname: 'www.coolbe.fi',

  static: {
    folder: 'coolbe/fi',
    swFileName: 'swCoolbe',
  },
};

export const domainScopeCoolbeGr: DomainScopeConfig = {
  ...baseCoolbeConfig,

  id: 'coolbe-gr',
  deviceCustomDomain: 'coolbe.gr',

  defaultLanguage: 'el',
  defaultCurrency: 'EUR',
  hostname: 'www.coolbe.gr',

  static: {
    folder: 'coolbe/gr',
    swFileName: 'swCoolbe',
  },
};

export const domainScopeCoolbeIe: DomainScopeConfig = {
  ...baseCoolbeConfig,

  id: 'coolbe-ie',
  deviceCustomDomain: 'coolbe.ie',

  defaultLanguage: 'en',
  defaultCurrency: 'EUR',
  hostname: 'www.coolbe.ie',

  static: {
    folder: 'coolbe/ie',
    swFileName: 'swCoolbe',
  },
};

export const domainScopeCoolbeEs: DomainScopeConfig = {
  ...baseCoolbeConfig,

  id: 'coolbe-es',
  deviceCustomDomain: 'coolbe.es',

  defaultLanguage: 'es',
  defaultCurrency: 'EUR',
  hostname: 'www.coolbe.es',

  static: {
    folder: 'coolbe/es',
    swFileName: 'swCoolbe',
  },
};

export const domainScopeCoolbeTr: DomainScopeConfig = {
  ...baseCoolbeConfig,

  id: 'coolbe-tr',
  deviceCustomDomain: 'coolbe.com.tr',

  defaultLanguage: 'tr',
  defaultCurrency: 'TRY',
  hostname: 'www.coolbe.com.tr',

  static: {
    folder: 'coolbe/tr',
    swFileName: 'swCoolbe',
  },
};

import {ElasticCommonSchema} from 'helpers/log/ECS/ElasticCommonSchema';
import {Expandable} from 'types/utility';
import {TypedObject} from 'utils/object/typed';

function createEcsError(error: unknown): ElasticCommonSchema['error'] {
  if (!error) {
    return undefined;
  }

  if (typeof error === 'object') {
    // error class instance
    if (error.constructor) {
      // hidden field with original error for internal usage
      const ecs = Object.create({__originalError: error});
      TypedObject.getOwnPropertyNames(error).forEach((key) => {
        ecs[key] = error[key];
      });
      return ecs;
    }

    // plain object error
    return Object(error);
  }

  // primitive type error
  return {
    message: String(error),
  };
}

export function ecsError(error: unknown): ElasticCommonSchema['error'] {
  let ecsError = createEcsError(error);

  if (ecsError?.stack) {
    ecsError.stack_trace = String(ecsError.stack);
    delete ecsError.stack;
  }

  // remove unnecessary req from superagent response
  if (ecsError?.response && (ecsError.response as Expandable).req) {
    ecsError = {...ecsError, response: {...ecsError.response, req: undefined}};
  }

  return ecsError;
}

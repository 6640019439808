import {TraceRequestHandler} from 'helpers/ApiClient/Transport/traceRequest/types';
import {withProm} from 'helpers/prometheus';
import {memoizeLastShallowEqual} from 'utils/memoize';

// need to memoize to prevent adding metric before reset in development
const histogram = memoizeLastShallowEqual(() =>
  withProm((client) => {
    const metric = new client.Histogram({
      name: 'transport_request_duration_seconds',
      help: 'Duration of transport requests to other services (like backend-client)',
      labelNames: ['prefix', 'method', 'statusCode'],
      buckets: [0.03, 0.1, 0.3, 1, 2, 3, 4, 5, 7.5, 10],
    });
    client.register.registerMetric(metric);
    return metric;
  }),
);

export const traceRequestPrometheus: TraceRequestHandler = ({method, prefix}) => {
  const instance = histogram();
  if (!instance) {
    return undefined;
  }

  const end = instance.startTimer({
    prefix,
    method: method.toUpperCase(),
  });

  return (res) => {
    end({statusCode: res.status});
  };
};

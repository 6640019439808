import {CHUNK_SIZE, ContentListItemView} from 'helpers/contentList';
import {ContentListBaseItem, contentListBaseItem} from 'types/ContentList/ContentListBaseItem';
import {SDLNodeWithExtras} from 'types/ServerDrivenLayout';

type Layout = {
  layout: SDLNodeWithExtras & {
    appearance?: {
      // layout size is 1 / `columnsCount`
      columnsCount: number;
    };
  };
};

export type ContentListLayout = ContentListBaseItem<Layout>;

export function contentListLayout(id: string, layout: Layout): ContentListLayout {
  return contentListBaseItem(id, layout);
}

export function isContentListLayout(item: ContentListBaseItem): item is ContentListLayout {
  return Boolean((item as ContentListLayout).content.layout);
}

export function getContentListLayoutView(item: ContentListLayout): ContentListItemView {
  switch (item.content.layout.appearance?.columnsCount) {
    case CHUNK_SIZE:
      return ContentListItemView.CELL;
    case CHUNK_SIZE / 2:
      return ContentListItemView.DOUBLE_CELL;
    case 1:
    case undefined:
      return ContentListItemView.ROW;
    default:
      return ContentListItemView.FREEFORM;
  }
}

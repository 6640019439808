export enum Scope {
  GLOBAL = 'global',
  GEEK = 'geek',
  COOLBE = 'coolbe',
  // vigoshop
  CBTREND = 'coolbeTrending',
}

export enum LegalEntity {
  JOOM = 'joom',
  JMT = 'jmt',
}

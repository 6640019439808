import {CartBadge} from 'types/CartBadge';
import {Color} from 'types/Color';
import {Gradient} from 'types/Gradient';
import {ImageBundle} from 'types/Image';

import {ColoredText} from './ColoredText';

export type Timer = {
  remainingMs: number;
};

export type GenericBadge = {
  generic: {
    title: string;
    image?: ImageBundle;
    color?: string;
    background?: Gradient;
  };
};

// Default value: 'horizontal'
export type DiscountAlignment = 'horizontal' | 'diagonal' | undefined;

export type Discount<Alignment = DiscountAlignment> = {
  title: string;
  timer?: Timer;
  background?: Gradient;
  icon?: ImageBundle;
  timerColor?: Color;
  titleColor?: Color;
  // key can be ommited on server side,
  // we use it like `| undefined` for easier typing.
  // Default value: 'horizontal'
  alignment?: Alignment;
};

export type DiscountBadge<Alignment = DiscountAlignment> = {
  discount: Discount<Alignment>;
};

export type OutOfStockBadge = {
  outOfStock: {
    title: string;
  };
};

export type BlockedProductBadge = {
  blockedProductBadge: {
    title: string;
  };
};

export type PointsCashbackBadge = {
  pointsCashback: {
    title: string;
    timer?: Timer;
  };
};

export function isDiscountBadge(badge?: ProductBadge): badge is DiscountBadge {
  return Boolean(badge && 'discount' in badge);
}

export function isAlignedDiscountBadge<T extends DiscountAlignment>(
  alignment: Exclude<T, undefined>,
  badge?: ProductBadge,
): badge is DiscountBadge<T> {
  if (!isDiscountBadge(badge)) {
    return false;
  }

  // 'horizontal' is dafault
  const badgeAlignment = badge.discount.alignment || 'horizontal';
  return badgeAlignment === alignment;
}

export function isOutOfStockBadge(badge?: ProductBadge): badge is OutOfStockBadge {
  return Boolean(badge && 'outOfStock' in badge);
}

export function isGenericBadge(badge?: ProductBadge): badge is GenericBadge {
  return Boolean(badge && 'generic' in badge);
}

export function isPointsCashbackBadge(
  badge?: ProductBadge | CartBadge,
): badge is PointsCashbackBadge {
  return Boolean(badge && 'pointsCashback' in badge);
}

export type ProductBadge = GenericBadge | DiscountBadge | OutOfStockBadge | PointsCashbackBadge;

export type DiagonalGalleryOverlay = {
  diagonal: {
    title: ColoredText;
    background: Gradient;
  };
};

export type GalleryOverlay = DiagonalGalleryOverlay;

export const isDiagonalGalleryOverlay = (
  overlay?: GalleryOverlay,
): overlay is DiagonalGalleryOverlay => {
  return !!overlay && 'diagonal' in overlay;
};

import {useSsrOrHydration} from 'hooks/useSsrOrHydration';
import React, {ReactNode, SyntheticEvent} from 'react';
import ReactDOM from 'react-dom';

const stopPropagationHandler = (evt: SyntheticEvent<HTMLDivElement>): void => evt.stopPropagation();

// TODO(4u@): That's soooo dirty way to prevent React.Portal synthetic bubbling.
const stopPropagationHandlers = {
  onBlur: stopPropagationHandler,
  onChange: stopPropagationHandler,
  onClick: stopPropagationHandler,
  onContextMenu: stopPropagationHandler,
  onDoubleClick: stopPropagationHandler,
  onDrag: stopPropagationHandler,
  onDragEnd: stopPropagationHandler,
  onDragEnter: stopPropagationHandler,
  onDragExit: stopPropagationHandler,
  onDragLeave: stopPropagationHandler,
  onDragOver: stopPropagationHandler,
  onDragStart: stopPropagationHandler,
  onDrop: stopPropagationHandler,
  onFocus: stopPropagationHandler,
  onInput: stopPropagationHandler,
  onInvalid: stopPropagationHandler,
  onMouseDown: stopPropagationHandler,
  onMouseEnter: stopPropagationHandler,
  onMouseLeave: stopPropagationHandler,
  onMouseMove: stopPropagationHandler,
  onMouseOver: stopPropagationHandler,
  onMouseOut: stopPropagationHandler,
  onMouseUp: stopPropagationHandler,
  onKeyDown: stopPropagationHandler,
  onKeyPress: stopPropagationHandler,
  onKeyUp: stopPropagationHandler,
  onSubmit: stopPropagationHandler,
};

type Props = {
  children: ReactNode;
  stopPropagation?: boolean | Array<keyof typeof stopPropagationHandlers>;
};

export const Portal: React.FC<Props> = ({children = null, stopPropagation = false}: Props) => {
  const isSsrOrHydration = useSsrOrHydration();
  if (isSsrOrHydration) {
    return null;
  }

  let additionalProps: Partial<
    React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
  > = {};
  if (Array.isArray(stopPropagation)) {
    stopPropagation.forEach((eventName) => {
      additionalProps[eventName] = stopPropagationHandlers[eventName];
    });
  } else if (stopPropagation) {
    additionalProps = stopPropagationHandlers;
  }

  return ReactDOM.createPortal(<div {...additionalProps}>{children}</div>, document.body);
};

type Response<T = unknown> = {
  body: {payload: T};
};

type PayloadEnhanced = {
  language: string;
  currency: string;
  error?: unknown;
};

type ResponseEnhanced = Response & Partial<PayloadEnhanced>;

export function extractResponsePayload<T>({body: {payload}}: Response<T>): T & {} {
  return (payload || {}) as T & {};
}

export function extractResponsePayloadEnhanced<
  R extends ResponseEnhanced,
  P = R['body']['payload'],
>(
  response: R,
  getPayload?: (response: R) => P,
): P & (R extends PayloadEnhanced ? PayloadEnhanced : Partial<PayloadEnhanced>) {
  const getter = getPayload || extractResponsePayload;
  const payload = getter(response) as unknown as P &
    (R extends PayloadEnhanced ? PayloadEnhanced : Partial<PayloadEnhanced>);
  if (!payload.language) {
    payload.language = response.language;
  }
  if (!payload.currency) {
    payload.currency = response.currency;
  }
  if (response.error) {
    payload.error = response.error;
  }
  return payload;
}

export function createAsyncActionNames(
  name: string,
): [request: string, success: string, fail: string] {
  return [name, `${name}_SUCCESS`, `${name}_FAIL`];
}

export function actionNameCreator(prefix: string): (name: string) => string {
  return (name) => `${prefix}/${name}`;
}

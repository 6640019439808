import {createRequestAction} from 'store/utils/requestActions';
import {CouponCard} from 'types/CouponCard';
import {createAction} from 'typesafe-actions';

export type LoadAllResponse = {
  items: CouponCard[];
};

export type LoadOneResponse = {
  item: CouponCard;
};

export const loadAll = createRequestAction(
  '@couponCards/LOAD_COUPON_CARDS_REQUEST',
  '@couponCards/LOAD_COUPON_CARDS_SUCCESS',
  '@couponCards/LOAD_COUPON_CARDS_FAILURE',
)<unknown, LoadAllResponse>();

export const loadOne = createRequestAction(
  '@couponCards/LOAD_COUPON_CARD_REQUEST',
  '@couponCards/LOAD_COUPON_CARD_SUCCESS',
  '@couponCards/LOAD_COUPON_CARD_FAILURE',
)<unknown, LoadOneResponse>();

// client action
export const setViewedCouponCard = createAction('@couponCards/SET_VIEWED')<string>();

// client action
export const syncViewedCouponCards = createAction('@couponCards/SYNC_VIEWED')();

import config, {DomainConfigId, DomainScopeConfig, PrefixScopeConfig, Scope} from 'config';
import type {ScopeConfig} from 'helpers/ApiClient/Scope/ScopeConfig';
import * as process from 'process';
import {memoizeLastShallowEqual} from 'utils/memoize';
import {TypedObject} from 'utils/object/typed';
import {getUrlPath} from 'utils/url';

export function isScope(scope: string | undefined): scope is Scope {
  return Boolean(scope && Object.values(Scope).includes(scope as Scope));
}

export const getDevDomainByScopeMap = memoizeLastShallowEqual(
  (): Record<string, string> | undefined => {
    if (config.releaseStage === 'prod') {
      return undefined;
    }

    if (__SERVER__) {
      try {
        return process.env.DOMAIN_BY_SCOPE_MAP
          ? JSON.parse(process.env.DOMAIN_BY_SCOPE_MAP)
          : undefined;
      } catch {
        return undefined;
      }
    }

    // eslint-disable-next-line no-underscore-dangle
    return (window as {__DOMAIN_BY_SCOPE_MAP__?: Record<string, string>}).__DOMAIN_BY_SCOPE_MAP__;
  },
);

export function getDomainScopeConfigByHostname(hostname: string): DomainScopeConfig {
  const devDomainByScopeMap = getDevDomainByScopeMap();
  if (devDomainByScopeMap) {
    for (const [domainConfigId, itemHostname] of Object.entries(devDomainByScopeMap)) {
      if (
        itemHostname === hostname &&
        Object.prototype.hasOwnProperty.call(config.scopes, domainConfigId)
      ) {
        return config.scopes[domainConfigId as DomainConfigId];
      }
    }
  }

  for (const scopeConfig of Object.values(config.scopes)) {
    if (scopeConfig.hostname === hostname || scopeConfig.hostname === `www.${hostname}`) {
      return scopeConfig;
    }
  }

  return config.scopes.joom;
}

export function getPrefixScopeConfigByUrl(
  url: string,
  domainScopeConfig: DomainScopeConfig | null,
): PrefixScopeConfig | undefined {
  const [prefix] = getUrlPath(url)?.split('/').filter(Boolean) || [];

  const availableDomainScopes = domainScopeConfig
    ? [domainScopeConfig]
    : Object.values(config.scopes);

  for (const scopeConfig of availableDomainScopes) {
    for (const prefixScopeConfig of scopeConfig.prefixScopes || []) {
      if (prefix === prefixScopeConfig.pathPrefix) {
        return prefixScopeConfig;
      }
    }
  }

  return undefined;
}

export function getByScope<Result>(
  scope: ScopeConfig,
  variants: Partial<Record<Scope, Result>> & {[Scope.GLOBAL]: Result},
): Result {
  return variants[scope.topScope] || variants[Scope.GLOBAL];
}

/**
 * Converts:
 * `/geek` -> `/`
 * `/geek/a/b` -> `/a/b`
 * `/a/b` -> `/a/b`
 */
export function stripPrefixScope(url: string, domainScopeConfig: DomainScopeConfig | null): string {
  const prefixScopeConfig = getPrefixScopeConfigByUrl(url, domainScopeConfig);
  const result = prefixScopeConfig ? url.replace(`/${prefixScopeConfig.pathPrefix}`, '') : url;
  return result || '/';
}

export const allScopes = TypedObject.values(Scope);

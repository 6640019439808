import {captureException} from '@sentry/core';
import type {ApiClient} from 'helpers/ApiClient';

const NON_FATAL_DELAY = 50;

let timer: ReturnType<typeof setTimeout> | undefined;

export function reportNonFatalError(client: ApiClient): void {
  /**
   * Send a little bit later, becuse in this place
   * we don't know is it fatal error or not. Function
   * `reportCrash` will discard timer if necessary.
   */
  timer = setTimeout(() => client.analytics.sendEvent({type: 'vitalsNonFatal'}), NON_FATAL_DELAY);
}

export function reportCrash(client: ApiClient, error: unknown): void {
  if (timer) {
    clearTimeout(timer);
  }
  client.analytics.sendEvent({type: 'vitalsCrash'}, {immediately: true});
  captureException(error, {tags: {crash: true}});
}

import {enhanceProductLites} from 'helpers/productLite';
import {contentListProduct} from 'types/ContentList/ContentListProduct';
import {ProductsList, ProductsListRaw} from 'types/ContentList/ContentListProductsList';
import {ProductLitesOptions} from 'types/ProductLite';

export function enhanceProductsList(
  productsList: ProductsListRaw,
  options: ProductLitesOptions,
): ProductsList {
  const items = enhanceProductLites(
    productsList.items.map(({product}) => product),
    options,
  ).map(contentListProduct);

  const list = productsList as unknown as ProductsList;
  list.items = items;
  return list;
}

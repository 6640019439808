import {ContentListBaseItem, contentListBaseItem} from './ContentListBaseItem';
import type {Row} from './Row';

export type RowList = {
  rowList: {
    items: Row[];
    header?: {
      title: string;
      action?: string;
      url?: string;
    };
  };
};

export type ContentListRowList = ContentListBaseItem<RowList>;

export function contentListRowList(id: string, rowList: RowList): ContentListRowList {
  return contentListBaseItem(id, rowList);
}

export function isContentListRowList(item: ContentListBaseItem): item is ContentListRowList {
  return Boolean((item as ContentListRowList).content.rowList);
}

import {routeAsync} from 'helpers/asyncConnect';

import {DefaultParams, PoorRoute} from './types';

type Params = DefaultParams & {
  orderGroupId: string;
};

const NAME = 'vat';

export const VatRoute: PoorRoute<typeof NAME, Params> = {
  name: NAME,
  path: '/vat/:orderGroupId',
  async: routeAsync(
    /* #__LOADABLE__ */ () => import('containers/pages/VatPage'),
    (module) => module.VatPage,
  ),
};

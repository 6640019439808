import {ecsError} from 'helpers/log/ECS/ecsError';
import {getUrl} from 'routes';
import {getFirstOrderParcelId, getParcel, isParcelLoaded, loadParcel} from 'store/modules/parcel';
import {Parcel} from 'types/Parcel';

import {AsyncFunctionOptions, DefaultParams} from '../types';

type Params = DefaultParams & {
  parcelId: string;
  action?: string;
};

const ORDER_ID_PREFIX = 'o-';

async function tryGetParcel({
  client,
  match,
  store,
}: AsyncFunctionOptions<Params>): Promise<Parcel | undefined> {
  const {parcelId} = match.params;
  const {getState, dispatch} = store;
  const state = getState();

  if (isParcelLoaded(state, parcelId)) {
    return getParcel(state, parcelId);
  }

  const logger = client.device.log.getLogger('routes/parcelOpen');

  try {
    const result = await dispatch(loadParcel(parcelId));

    return result.parcel;
  } catch (error) {
    logger.error({
      error: ecsError(error),
      message: 'Failed to load parcel',
    });

    return undefined;
  }
}

export async function parcelOpen(
  options: AsyncFunctionOptions<Params>,
): Promise<string | undefined> {
  const {parcelId, action, lang, scope} = options.match.params;

  if (!parcelId.startsWith(ORDER_ID_PREFIX)) {
    return undefined;
  }

  const parcel = await tryGetParcel(options);
  const firstOrderParcelId = parcel && getFirstOrderParcelId(parcel);

  if (!firstOrderParcelId || parcelId === firstOrderParcelId) {
    return undefined;
  }

  return getUrl('order', {
    lang,
    scope,
    parcelId: firstOrderParcelId,
    action,
  });
}

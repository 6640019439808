import config, {DomainConfigId} from 'config';
import {CookieType} from 'types/CookiesSettings';
import {TypedObject} from 'utils/object/typed';

import {CookieValue} from '../Device/CookieValue';

const COOKIE_NAME = 'domainscope';

/**
 * Domain scope for dev and stage envs.
 */
export const DevDomainScope: CookieValue<DomainConfigId | undefined> = {
  type: CookieType.FUNCTIONAL,
  key: COOKIE_NAME,
  decode: (str) =>
    TypedObject.keys(config.scopes).includes(str as DomainConfigId)
      ? (str as DomainConfigId)
      : undefined,
  encode: (value) => value || '',
};

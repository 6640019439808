import {routeAsync} from 'helpers/asyncConnect';

import {anonymous} from './decorators';
import {DefaultParams, PoorRoute} from './types';

type Params = DefaultParams & {
  productCollectionId: string;
};

const NAME = 'productCollection';

export const ProductCollectionRoute: PoorRoute<typeof NAME, Params> = {
  name: NAME,
  path: '/favorites/collections/:productCollectionId',
  async: routeAsync(
    /* #__LOADABLE__ */ () => import('containers/pages/ProductCollectionPage'),
    (module) => module.ProductCollectionPage,
  ),
  decorator: anonymous,
  strict: true,
  scrollBehaviour: 'scroll-restoration',
};

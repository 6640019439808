import {routeAsync} from 'helpers/asyncConnect';

import {DefaultParams, PoorRoute} from './types';

const NAME = 'rewardWheel';

export const RewardWheelRoute: PoorRoute<typeof NAME, DefaultParams> = {
  name: NAME,
  path: '/rewardWheel',
  async: routeAsync(
    /* #__LOADABLE__ */ () => import('containers/pages/RewardWheelPage'),
    (module) => module.RewardWheelPage,
  ),
};

const vendors = ['ms', 'moz', 'webkit', 'o'];

function polyfill() {
  let lastTime = 0;
  let {cancelAnimationFrame} = global;
  let {requestAnimationFrame} = global;

  vendors.every((prefix) => {
    requestAnimationFrame = requestAnimationFrame || global[`${prefix}RequestAnimationFrame`];
    cancelAnimationFrame =
      cancelAnimationFrame ||
      global[`${prefix}CancelAnimationFrame`] ||
      global[`${prefix}CancelRequestAnimationFrame`];
    return requestAnimationFrame && cancelAnimationFrame;
  });

  if (!requestAnimationFrame) {
    requestAnimationFrame = (callback) => {
      const currTime = Date.now();
      const timeToCall = Math.max(0, 16 - (currTime - lastTime));
      const id = setTimeout(() => callback(currTime + timeToCall), timeToCall);
      lastTime = currTime + timeToCall;
      return id;
    };
  }

  if (!cancelAnimationFrame) {
    cancelAnimationFrame = (id) => clearTimeout(id);
  }

  return {cancelAnimationFrame, requestAnimationFrame};
}

const funcs = polyfill();

export const {cancelAnimationFrame, requestAnimationFrame} = funcs;

import config from 'config';
import {identity} from 'utils/function';
import {memoizeLastShallowEqual} from 'utils/memoize';
import {getUrlPath} from 'utils/url';

export const LANGUAGE_REGEXP = /^[a-z]{2}(-[a-z]{2})?$/;

const languagesDict: Record<string, boolean> = {};
config.languages.forEach(({code}) => {
  languagesDict[code] = true;
});

export function getAcceptableLanguageByList(
  list: string[],
  appLangs: string[],
  defaultLang: string,
): string {
  let result = '';
  list.some((locale) => {
    if (appLangs.indexOf(locale) !== -1) {
      result = locale;
      return true;
    }

    const parts = locale.split('-') as [string, ...string[]];
    if (appLangs.indexOf(parts[0]) !== -1) {
      [result] = parts;
    }
    return result;
  });

  return result || defaultLang;
}

export const getUserLocalesByHeader = memoizeLastShallowEqual((header: string = ''): string[] =>
  header
    .split(',')
    .map((language: string) => language.split(';')[0]!.trim().toLowerCase())
    .filter((language: string) => language !== '*'),
);

export const getUserLocalesByNavigator = memoizeLastShallowEqual((navigator?: Navigator) => {
  // ts does not know about userLanguage property
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const anyNavigator = (navigator || {}) as any;
  const list: string[] = Array.isArray(anyNavigator.languages)
    ? anyNavigator.languages
    : [anyNavigator.userLanguage || '', anyNavigator.language || ''];
  return list.filter(identity).map((language) => language.trim().toLowerCase());
});

export function detectLanguageByAcceptLanguageHeader(
  header: string | undefined,
  appLangs: string[],
  defaultLang: string,
): string {
  const headerLangs = getUserLocalesByHeader(header);
  return getAcceptableLanguageByList(headerLangs, appLangs, defaultLang);
}

export function detectLanguageByNavigator(
  navigator: Navigator,
  appLangs: string[],
  defaultLang: string,
): string {
  const navigatorLangs = getUserLocalesByNavigator(navigator);
  return getAcceptableLanguageByList(navigatorLangs, appLangs, defaultLang);
}

export function isLanguageExist(lang: string): boolean {
  return !!languagesDict[lang];
}

export function detectLanguageByPath(path: string): string | null {
  const [maybeScope, maybeLanguage] = getUrlPath(path)?.split('/').filter(Boolean) || [];

  if (maybeScope && isLanguageExist(maybeScope)) {
    return maybeScope;
  }
  if (maybeLanguage && isLanguageExist(maybeLanguage)) {
    return maybeLanguage;
  }

  return null;
}

/**
 * Converts:
 * `/en` -> `/`
 * `/en/a/b` -> `/a/b`
 * `/a/b` -> `/a/b`
 */
export function stripLanguage(path: string): string {
  const lang = detectLanguageByPath(path);
  const result = lang ? path.replace(`/${lang}`, '') : path;
  return !result ? '/' : result;
}

export function linksAreSameExceptLanguage(path1: string, path2: string): boolean {
  return stripLanguage(path1) === stripLanguage(path2);
}

/**
 * Converts: /:lang(en|ru|...|ro) -> /:lang
 */
export function stripLanguageUnionFromPath(path: string | undefined): string {
  if (!path) return '';

  return path.replace(/\/:lang\([^)]+\)/, '/:lang');
}

import {RequestIdMeta} from 'helpers/log/Logger';

import {Event} from '../Event';
import {Level} from '../Level';
import {Appender} from './Appender';

const {FATAL, ERROR, WARN, INFO, TRACE} = Level;

function log(type: 'error' | 'warn' | 'info' | 'trace' | 'log', event: Event<RequestIdMeta>): void {
  const {
    meta: {requestId = '-'},
    name,
    args,
    timestamp,
    level,
  } = event;
  const consoleArgs = args.map((item) => {
    if (__SERVER__ && item instanceof Error) {
      return {
        message: item.message,
        name: item.name,
        stack: item.stack,
      };
    }

    return item;
  });

  const timeString = new Date(timestamp).toISOString();
  const signature = `${level.name} [${name}][${timeString}][#${requestId}]`;

  if (global.console[type]) {
    global.console[type](signature, ...consoleArgs);
  } else {
    global.console.log(signature, ...consoleArgs);
  }
}

export class ConsoleAppender extends Appender<RequestIdMeta> {
  processInternal(data: Event<RequestIdMeta>): void {
    if (!global.console) {
      return;
    }

    switch (data.level.value) {
      case FATAL.value:
      case ERROR.value:
        log('error', data);
        break;

      case WARN.value:
        log('warn', data);
        break;

      case INFO.value:
        log('info', data);
        break;

      case TRACE.value:
        log('trace', data);
        break;

      default:
        log('log', data);
        break;
    }
  }
}

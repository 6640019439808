import EventEmitter from 'component-emitter';
import {Error, isApiError} from 'types/Error';
import {
  ApiHcaptchaRequiredError,
  HcaptchaError,
  hcaptchaError,
  HcaptchaErrorType,
  HcaptchaRequiredError,
} from 'types/HcaptchaError';

import {BaseApiProtectionTransport} from './ApiTransport';

type VerifyTokenBody = {
  clientToken: string;
  ekey?: string;
};

type VerifyErrorBody = {
  error: HcaptchaError;
};

export type VerifyHcaptchaBody = VerifyTokenBody | VerifyErrorBody;

export type VerifyHcaptchaOptions = {
  disable?: boolean;
};

const API_HCAPTCHA_ERROR_TYPE = 'bot.hcaptcha_required';

// eslint-disable-next-line no-underscore-dangle
const INITIAL_HCAPTCHA_REQUIRED_ERROR = __CLIENT__ ? window.__hcaptchaRequiredError : undefined;

export function isApiHcaptchaRequiredError(
  error: Error | null | undefined,
): error is ApiHcaptchaRequiredError {
  return (
    isApiError(error) && error.type === API_HCAPTCHA_ERROR_TYPE && typeof error.payload === 'object'
  );
}

/** Processes and solves API hcaptcha errors */
export class HcaptchaProtectionTransport extends BaseApiProtectionTransport {
  private requiredError?: HcaptchaRequiredError = INITIAL_HCAPTCHA_REQUIRED_ERROR;

  private retryRequest?: () => Promise<unknown>;

  get hcaptchaRequiredError(): Readonly<HcaptchaRequiredError> | undefined {
    return this.requiredError;
  }

  get hcaptchaRetryRequest(): (() => Promise<unknown>) | undefined {
    return this.retryRequest;
  }

  events = new EventEmitter();

  verify = (body: VerifyHcaptchaBody): Promise<void> =>
    this.post('/hcaptcha/verify', {body}).then(() => undefined);

  async verifyProtection<T>(
    handler: () => Promise<T>,
    options?: VerifyHcaptchaOptions,
  ): Promise<T> {
    if (options?.disable) {
      return handler();
    }

    try {
      return await handler();
    } catch (exception) {
      const {error} = Object(exception);
      if (!this.hcaptchaRequiredError && isApiHcaptchaRequiredError(error)) {
        try {
          const {
            requestId,
            payload: {siteKey, scriptUrl},
          } = error;

          if (siteKey && scriptUrl) {
            this.requiredError = {siteKey, scriptUrl, requestId};
            this.retryRequest = handler;
            this.events.emit('hcaptchaRequiredError', this.requiredError);
          } else if (!siteKey) {
            this.verify({
              error: hcaptchaError(HcaptchaErrorType.PAYLOAD, 'missing "siteKey" in payload'),
            });
          } else if (!scriptUrl) {
            this.verify({
              error: hcaptchaError(HcaptchaErrorType.PAYLOAD, 'missing "scriptUrl" in payload'),
            });
          }
        } catch (error) {
          this.verify({
            error: hcaptchaError(
              HcaptchaErrorType.INTERNAL,
              Object(error).message || 'unexpected error',
            ),
          });
        }
      }
      throw exception;
    }
  }
}

// @ts-check

const IMAGE_FORMATS = /** @type {const} */ ({
  png: 'png',
  svg: 'svg',
});

const FILE_EXTENSIONS = /** @type {const} */ ({
  componentSvg: '.jsx.svg',
  svg: '.svg',
  png: '.png',
});

const PREFIXES = /** @type {const} */ ({
  stub: 'stub-', // these components and should be ignored completely
  mono: 'mono-', // these are monochromatic icons
  multi: 'multi-', // these are polychromatic icons
  illustration: 'illustration-', // these are polychromatic icons
  unknown: 'unknown-',
});

const TYPE_CONFIGURATIONS = /** @type {const} */ ({
  [PREFIXES.mono]: {
    format: IMAGE_FORMATS.svg,
    extension: FILE_EXTENSIONS.componentSvg,
    dirName: 'mono',
    baseColorsToReplace: ['#000', '#2d2f43', '#000000'],
    optimize: true,
    originalWidth: undefined,
    aspectRatio: undefined,
  },
  [PREFIXES.multi]: {
    format: IMAGE_FORMATS.svg,
    extension: FILE_EXTENSIONS.componentSvg,
    dirName: 'multi',
    optimize: true,
    originalWidth: undefined,
    aspectRatio: undefined,
  },
  [PREFIXES.illustration]: {
    format: IMAGE_FORMATS.png,
    extension: FILE_EXTENSIONS.png,
    originalWidth: 240,
    dirName: 'illustration',
    aspectRatio: 2 / 3,
    optimize: true,
  },
  [PREFIXES.stub]: {
    skip: true,
  },
  [PREFIXES.unknown]: {
    skip: true,
  },
});

const FORMAT_SPECIFIC_CONFIGURATIONS = /** @type {const} */ ({
  [IMAGE_FORMATS.png]: {
    sizes: [1, 2, 3, 4],
  },
  [IMAGE_FORMATS.svg]: {
    sizes: [1],
    editable: true,
  },
});

module.exports = {
  IMAGE_FORMATS,
  TYPE_CONFIGURATIONS,
  FILE_EXTENSIONS,
  FORMAT_SPECIFIC_CONFIGURATIONS,
  PREFIXES,
};

import {ContentListItem, ContentListItemType} from 'types/ContentList';
import {isContentListAlertNothingFound} from 'types/ContentList/ContentListAlertNothingFound';
import {isContentListAwaitingPaymentOrderGroup} from 'types/ContentList/ContentListAwaitingPaymentOrderGroup';
import {
  extractBannersList,
  isContentListBannersList,
  isContentListBannersListFreeForm,
} from 'types/ContentList/ContentListBannersList';
import {isContentListCountdownRowV2} from 'types/ContentList/ContentListCountdownRowV2';
import {isContentListCoupon} from 'types/ContentList/ContentListCoupon';
import {isContentListGroup} from 'types/ContentList/ContentListGroup';
import {isContentListHtmlBlock} from 'types/ContentList/ContentListHtmlBlock';
import {getContentListLayoutView, isContentListLayout} from 'types/ContentList/ContentListLayout';
import {isContentListParcel} from 'types/ContentList/ContentListParcel';
import {isContentListPaymentRequisites} from 'types/ContentList/ContentListPaymentRequisites';
import {isContentListProduct} from 'types/ContentList/ContentListProduct';
import {isContentListProductAttributes} from 'types/ContentList/ContentListProductAttributes';
import {isContentListProductBrand} from 'types/ContentList/ContentListProductBrand';
import {isContentListProductCardGroup} from 'types/ContentList/ContentListProductCardGroup';
import {isContentListProductCollectionBanner} from 'types/ContentList/ContentListProductCollectionBanner';
import {isContentListProductFromCollections} from 'types/ContentList/ContentListProductFromCollection';
import {isContentListProductListPurchaseItems} from 'types/ContentList/ContentListProductListPurchaseItems';
import {isContentListProductPresaleDiscount} from 'types/ContentList/ContentListProductPresaleDiscount';
import {isContentListProductReviews} from 'types/ContentList/ContentListProductReviews';
import {isContentListProductsList} from 'types/ContentList/ContentListProductsList';
import {isContentListProductStore} from 'types/ContentList/ContentListProductStore';
import {isContentListPromotionsCarousel} from 'types/ContentList/ContentListPromotionsCarousel';
import {isContentListPurchaseStats} from 'types/ContentList/ContentListPurchaseStats';
import {isContentListRowList} from 'types/ContentList/ContentListRowList';
import {isContentListSearchFilters} from 'types/ContentList/ContentListSearchFilters';
import {isContentListSectionHeader} from 'types/ContentList/ContentListSectionHeader';
import {isContentListSideBannerGroup} from 'types/ContentList/ContentListSideBannerGroup';
import {isContentListSlimBanner} from 'types/ContentList/ContentListSlimBanner';
import {isContentListTermsHeader} from 'types/ContentList/ContentListTermsHeader';
import {isContentListTermsItem} from 'types/ContentList/ContentListTermsItem';
import {isContentListTermsList} from 'types/ContentList/ContentListTermsList';
import {isContentListVariantParameters} from 'types/ContentList/ContentListVariantParameters';
import {isContentListPowerShop} from 'types/ContentList/PowerShop';
import {isContentListRow} from 'types/ContentList/Row';
import {isContentListScreenHeader} from 'types/ContentList/ScreenHeader';
import {isContentListSearchStoreList} from 'types/ContentList/SearchStoreList';

export type ContentListAppearance = {
  productColumns: number;
};

export const CHUNK_SIZE = 12;
export const DEFAULT_APPEARANCE: ContentListAppearance = {
  productColumns: CHUNK_SIZE,
};

export enum ContentListItemView {
  CELL = 'cell',
  DOUBLE_CELL = 'doubleCell',
  ROW = 'row',
  INLINE_ROW = 'inlineRow',
  FREEFORM = 'freeform',
}

export function getContentListItemType(item: ContentListItem): ContentListItemType | null {
  if (isContentListGroup(item)) {
    return ContentListItemType.GROUP;
  }
  if (isContentListProduct(item)) {
    return ContentListItemType.PRODUCT;
  }
  if (isContentListProductFromCollections(item)) {
    return ContentListItemType.PRODUCT_FROM_COLLECTIONS;
  }
  if (isContentListProductCollectionBanner(item)) {
    return ContentListItemType.PRODUCT_COLLECTION_BANNER;
  }
  if (isContentListSearchFilters(item)) {
    return ContentListItemType.SEARCH_FILTERS;
  }
  if (isContentListPromotionsCarousel(item)) {
    return ContentListItemType.PROMOTIONS_CAROUSEL;
  }
  if (isContentListBannersList(item)) {
    return ContentListItemType.BANNERS_LIST;
  }
  if (isContentListProductCardGroup(item)) {
    return ContentListItemType.PRODUCT_CARD_GROUP;
  }
  if (isContentListSideBannerGroup(item)) {
    return ContentListItemType.SIDE_BANNER_GROUP;
  }
  if (isContentListSlimBanner(item)) {
    return ContentListItemType.SLIM_BANNER;
  }
  if (isContentListRow(item)) {
    return ContentListItemType.ROW;
  }
  if (isContentListTermsHeader(item)) {
    return ContentListItemType.TERMS_HEADER;
  }
  if (isContentListTermsItem(item)) {
    return ContentListItemType.TERMS_ITEM;
  }
  if (isContentListTermsList(item)) {
    return ContentListItemType.TERMS_LIST;
  }
  if (isContentListPowerShop(item)) {
    return ContentListItemType.POWER_SHOP;
  }
  if (isContentListSectionHeader(item)) {
    return ContentListItemType.SECTION_HEADER;
  }
  if (isContentListAlertNothingFound(item)) {
    return ContentListItemType.ALERT_NOTHING_FOUND;
  }
  if (isContentListSearchStoreList(item)) {
    return ContentListItemType.SEARCH_STORE_LIST;
  }
  if (isContentListCoupon(item)) {
    return ContentListItemType.COUPON;
  }
  if (isContentListVariantParameters(item)) {
    return ContentListItemType.VARIANT_PARAMETERS;
  }
  if (isContentListProductReviews(item)) {
    return ContentListItemType.PRODUCT_REVIEWS;
  }
  if (isContentListProductsList(item)) {
    return ContentListItemType.PRODUCTS_LIST;
  }
  if (isContentListProductStore(item)) {
    return ContentListItemType.PRODUCT_STORE;
  }
  if (isContentListProductBrand(item)) {
    return ContentListItemType.PRODUCT_BRAND;
  }
  if (isContentListProductPresaleDiscount(item)) {
    return ContentListItemType.PRODUCT_PRESALE_DISCOUNT;
  }
  if (isContentListRowList(item)) {
    return ContentListItemType.ROW_LIST;
  }
  if (isContentListProductAttributes(item)) {
    return ContentListItemType.PRODUCT_ATTRIBUTES;
  }
  if (isContentListPurchaseStats(item)) {
    return ContentListItemType.PURCHASE_STATS;
  }
  if (isContentListScreenHeader(item)) {
    return ContentListItemType.SCREEN_HEADER;
  }
  if (isContentListHtmlBlock(item)) {
    return ContentListItemType.HTML_BLOCK;
  }
  if (isContentListCountdownRowV2(item)) {
    return ContentListItemType.COUNTDOWN_ROW_V2;
  }
  if (isContentListParcel(item)) {
    return ContentListItemType.PARCEL;
  }
  if (isContentListPaymentRequisites(item)) {
    return ContentListItemType.PAYMENT_REQUISITES;
  }
  if (isContentListAwaitingPaymentOrderGroup(item)) {
    return ContentListItemType.AWAITING_PAYMENT_ORDER_GROUP;
  }
  if (isContentListProductListPurchaseItems(item)) {
    return ContentListItemType.PRODUCT_LIST_PURCHASE_ITEMS;
  }
  if (isContentListLayout(item)) {
    return ContentListItemType.LAYOUT;
  }

  return null;
}

export function getContentListItemView(item: ContentListItem): ContentListItemView {
  if (isContentListGroup(item)) {
    return ContentListItemView.FREEFORM;
  }

  if (
    isContentListProduct(item) ||
    isContentListProductFromCollections(item) ||
    isContentListProductCollectionBanner(item)
  ) {
    return ContentListItemView.CELL;
  }

  if (
    isContentListBannersList(item) &&
    isContentListBannersListFreeForm(extractBannersList(item))
  ) {
    return ContentListItemView.FREEFORM;
  }

  if (isContentListPowerShop(item)) {
    return ContentListItemView.DOUBLE_CELL;
  }

  if (isContentListSectionHeader(item)) {
    return ContentListItemView.INLINE_ROW;
  }

  if (isContentListCountdownRowV2(item)) {
    return ContentListItemView.INLINE_ROW;
  }

  if (isContentListCoupon(item)) {
    return ContentListItemView.FREEFORM;
  }

  if (isContentListLayout(item)) {
    return getContentListLayoutView(item);
  }

  return ContentListItemView.ROW;
}

export function isContentListEmpty(items?: ContentListItem[]): boolean {
  return !(items?.length && items.some((item) => !isContentListSearchFilters(item)));
}

export function isContentListWithoutProducts(items?: ContentListItem[]): boolean {
  return (
    !items ||
    !items.length ||
    !items.some((item) => isContentListProduct(item) || isContentListProductFromCollections(item))
  );
}

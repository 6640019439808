import {Cookies} from '../Cookies';
import {CookieManager, CookieManagerOptions} from '../Cookies/CookieManager';
import {CookieNames} from './cookieValues';

function createCookie<N extends CookieNames>(
  name: N,
  cookies: Cookies,
  options?: CookieManagerOptions,
): CookieManager<N> {
  return new CookieManager(name, cookies, options);
}

export {createCookie};

import {PresaleDiscount} from 'types/PresalePromo/Presale/Discount';

import {ContentListBaseItem, contentListBaseItem} from './ContentListBaseItem';

export type ProductPresaleDiscount = {
  productPresaleDiscount: PresaleDiscount;
};

export type ContentListProductPresaleDiscount = ContentListBaseItem<ProductPresaleDiscount>;

export function contentListProductPresaleDiscount(
  id: string,
  productPresaleDiscount: ProductPresaleDiscount,
): ContentListProductPresaleDiscount {
  return contentListBaseItem(id, productPresaleDiscount);
}

export function isContentListProductPresaleDiscount(
  item: ContentListBaseItem,
): item is ContentListProductPresaleDiscount {
  return Boolean((item as ContentListProductPresaleDiscount).content.productPresaleDiscount);
}

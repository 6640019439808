import EventEmitter from 'component-emitter';
import appConfig, {DomainConfigId, LanguageConfig} from 'config';
import type express from 'express';
import {
  DeephemerizeReason,
  DeephemerizeReasonType,
  getDeephemerizeReasonPayload,
} from 'helpers/ApiClient/Device/deephemerizeReason';
import {getDeviceVersion} from 'helpers/ApiClient/Device/getVersion';
import tokenDegradation from 'helpers/ApiClient/Device/tokenDegradation';
import {ScopeConfig} from 'helpers/ApiClient/Scope/ScopeConfig';
import {FaqTransport} from 'helpers/ApiClient/Transport/FaqTransport';
import {PaymentTransport} from 'helpers/ApiClient/Transport/PaymentTransport';
import {ProofOfWorkController} from 'helpers/ApiClient/Transport/ProofOfWork/ProofOfWorkController';
import {WebTransport} from 'helpers/ApiClient/Transport/WebTransport';
import {filterLangConfig, getLangCodes, LanguageConfigFilter} from 'helpers/language';
import {globalLog} from 'helpers/log';
import {ecsError} from 'helpers/log/ECS/ecsError';
import {ECSLog, ECSLogger} from 'helpers/log/ECS/types';
import {badAccessToken} from 'helpers/mobileAppApi';
import {MemoUAParser} from 'helpers/userAgent';
import {getUserTypeByRenderingType, Type, UserType} from 'helpers/UserType';
import isEqual from 'lodash/isEqual';
import {ClientBackendResponse} from 'types/ClientBackendResponse';
import {
  CookiesSettings,
  CookiesSettingsResponse,
  defaultCookiesSettings,
  fullyAcceptedCookiesSettings,
} from 'types/CookiesSettings';
import {DeviceVars} from 'types/deviceVars';
import {EmptyObject} from 'types/EmptyObject';
import {RenderingConfiguration, RenderingType} from 'types/Rendering';
import {User} from 'types/User';
import {AUTHORIZATION_HEADER, createAuthorizationHeader} from 'utils/authorizationHeader';
import {getAuthTokensFromHeaders} from 'utils/authTokens';
import {guidWithoutDashes} from 'utils/guid';
import {TypedObject} from 'utils/object/typed';
import {hashCode} from 'utils/string';
import {createUrl, getQueryData, getUrlPath, serializeQueryData} from 'utils/url';

import {Cookies} from '../Cookies';
import {loadRenderingConfig} from '../serverRendering';
import {ApiNoAuthTransport} from '../Transport/ApiNoAuthTransport';
import {
  ApiTransport,
  enableClientBackendProxy,
  SecureApiTransport,
} from '../Transport/ApiTransport';
import {HcaptchaProtectionTransport} from '../Transport/HcaptchaProtectionTransport';
import {PureTransport} from '../Transport/PureTransport';
import {createApiError, createUnallowedResponse} from '../Transport/Response';
import {wrap} from './asyncWrapper';
import {Auth, AuthResult} from './Auth';
import type {CookiesRegistry} from './cookiesRegistry';
import {getGsAttrs} from './gsAttrs';
import {getForcedLanguageByRequest, getUserLanguage, getUserLocales} from './language';
import {loadEndpointsConfig} from './loadEndpointsConfig';
import {Preferences} from './preferences';

const FREEZED_TOKEN = '-';

const getUserHash = (user: User) => hashCode((user && user.id) || '').toString(32);

type DeviceConfig = {
  configId: string;
  devicevars: DeviceVars;
  preferences: Preferences;
  copyrightEntity: CopyrightEntity;
  detectedCountry: string;
  isJmtMigrationNeeded?: boolean;
  canUseLolLanguage?: boolean;
  features?: Array<{id: string; params: Record<string, unknown>}>;
};

export type CopyrightEntity = 'ip' | 'joom' | 'jmt';

export type DeviceEndpoints = {
  api: string;
  serverApi?: string;
  faq: string;
  payment: string;
  payhub: string;
  secureApi: string;
};

export type DeviceTransportEndpoints = {
  api: () => string;
  serverApi: () => string;
  faq: () => string;
  payment: () => string;
  payhub: () => string;
  secureApi: () => string;
};

declare global {
  interface Window {
    __endpoint: DeviceEndpoints;
    __STORYBOOK_CLIENT_API__?: unknown;
  }
}

export type EndpointsConfig = Array<{
  babyloneClientApi: string;
  babyloneClientFaqApi: string;
  babyloneClientFaqRestApi: string;
  babyloneClientFiles: string;
  baseApi: string;
  baseApiSecure: string;
  baseGrpcApi: string;
  basePayment: string;
  baseWeb: string;
  id: string;
  title: string;
  version: string;
}>;

type DevicePrivateState = {
  userAgent: MemoUAParser;
  inited?: boolean;
  hydrated?: boolean;
  hasImportedTokens?: boolean;
  setupEndpointsOverrides?: boolean;
  // can be empty string, always empty for browser
  refreshToken: string;
  trackingToken: string;
  req?: express.Request;
  res?: express.Response;
  recaptchaSent?: boolean;
  // can be empty string
  transportRegionOverride: string;
  transportCurrencyOverride: string;
  // can be empty string
  language: string;
  isWebView: boolean;
};

// this state can be fully hydrated from server
type DevicePublicState = {
  // can be empty string
  accessToken: string;
  // can be empty string
  accessTokenHash: string;
  deviceVersion: ReturnType<typeof getDeviceVersion>;
  config?: DeviceConfig;
  user?: User;
  // can be empty string
  userHash: string;
  freezedToken?: boolean;
  userCookiesSettings: CookiesSettings;
  clientRequestId: string | undefined;
  endpointsConfig?: EndpointsConfig;
  renderingConfig?: RenderingConfiguration;
  // google shopping attributes
  gsAttrs?: string;
};

type TokensPayload = {
  accessToken?: string;
  refreshToken?: string;
  trackingToken?: string;
};

export class Device extends EventEmitter {
  private private: DevicePrivateState;

  private logger: ECSLogger;

  public proofOfWorkController = new ProofOfWorkController();

  public state: DevicePublicState;

  public auth: Auth;

  public scope: ScopeConfig;

  public log: ECSLog;

  public endpoints: DeviceEndpoints = __CLIENT__
    ? // eslint-disable-next-line no-underscore-dangle
      window.__endpoint
    : {
        api: process.env.CLIENT_API || '',
        serverApi: process.env.SERVER_API || '',
        faq: process.env.FAQ_API || '',
        secureApi: process.env.SECURE_API || '',
        payment: process.env.PAYMENT_API || '',
        payhub: process.env.PAYHUB_API || '',
      };

  // transports are created before device can load forced endpoints, must use functions to get actual endpoints
  public readonly transportEndpoints: DeviceTransportEndpoints = {
    api: () => this.endpoints.api,
    serverApi: () => this.endpoints.serverApi || '',
    faq: () => this.endpoints.faq,
    secureApi: () => this.endpoints.secureApi,
    payment: () => this.endpoints.payment,
    payhub: () => this.endpoints.payhub,
  };

  public readonly transports = {
    tokens: new ApiNoAuthTransport(this, this.req, this.res),
    api: new ApiTransport(this, this.req, this.res),
    secureApi: new SecureApiTransport(this, this.req, this.res),
    paymentApi: new PaymentTransport(this, this.req, this.res),
    pureApi: new PureTransport(this, this.req, this.res),
    faqApi: new FaqTransport(this, this.req, this.res),
    webApi: new WebTransport(this),
    hcaptcha: new HcaptchaProtectionTransport(this, this.req, this.res),
  };

  constructor(
    private cookiesRegistry: CookiesRegistry,
    private cookies: Cookies,
    private readonly req?: express.Request,
    private readonly res?: express.Response,
  ) {
    super();

    this.log = req?.log || globalLog;
    this.auth = new Auth(this, this.transports.api);
    this.auth.on('userchange', this.handleAuth);
    this.logger = this.log.getLogger('Device');
    this.scope = req?.scope || ScopeConfig.configure(req, cookies);

    this.private = {
      userAgent: new MemoUAParser().setUA(req?.get('User-Agent') || navigator.userAgent || ''),
      req,
      res,
      refreshToken: this.cookiesRegistry.refreshToken.restore(),
      trackingToken: this.cookiesRegistry.trackingToken.restore(),
      transportRegionOverride: '',
      transportCurrencyOverride: '',
      language: '',
      isWebView: false,
    };

    this.state = {
      accessToken: this.cookiesRegistry.accessToken.restore(),
      accessTokenHash: this.cookiesRegistry.accessTokenHash.restore(),
      deviceVersion: getDeviceVersion(this.private.userAgent),
      userHash: this.cookiesRegistry.userHash.restore(),
      freezedToken: this.private.refreshToken === FREEZED_TOKEN,
      userCookiesSettings: defaultCookiesSettings,
      clientRequestId: __SERVER__ ? guidWithoutDashes() : undefined,
    };
  }

  getTransportRegionOverride(): string {
    return this.private.transportRegionOverride;
  }

  getTransportCurrencyOverride(): string {
    return this.private.transportCurrencyOverride;
  }

  async setupTransportOverrides(): Promise<void> {
    const {req} = this.private;
    const url = req?.originalUrl || window.location.href;
    const query = getQueryData(url);
    if (typeof query.country === 'string' && query.country.length === 2) {
      this.private.transportRegionOverride = query.country;
    }
    if (typeof query.currency === 'string' && query.currency.length === 3) {
      this.private.transportCurrencyOverride = query.currency.toUpperCase();
    }
  }

  async getEndpointsConfig(): Promise<EndpointsConfig> {
    if (!this.state.endpointsConfig) {
      try {
        const endpointsConfig = await loadEndpointsConfig();
        this.state.endpointsConfig = endpointsConfig;
      } catch (error) {
        this.state.endpointsConfig = [];
        this.logger.error({error: ecsError(error)});
      }
    }

    return this.state.endpointsConfig;
  }

  getEndpointsConfigPreset(): string {
    return this.cookiesRegistry.endpointsPreset.restore();
  }

  setEndpointsConfigPreset(endpointsPreset: string): void {
    if (__SERVER__) {
      this.cookiesRegistry.endpointsPreset.store(endpointsPreset);
    } else {
      const url = new URL(window.location.href);
      url.searchParams.set('endpointsPreset', endpointsPreset);
      window.location.replace(url);
    }
  }

  getForcedRenderingType(): RenderingType | undefined {
    const renderingType = this.cookiesRegistry.forcedRenderingType.restore();

    if (Object.values(RenderingType).includes(renderingType as RenderingType)) {
      return renderingType as RenderingType;
    }

    return undefined;
  }

  setForcedRenderingType(renderingType: RenderingType | undefined | null): void {
    this.cookiesRegistry.forcedRenderingType.store(renderingType || '');
  }

  createEndpointsOrScopeOverrideRedirect(): string | undefined {
    if (__CLIENT__ || appConfig.releaseStage === 'prod') {
      return undefined;
    }

    const url = this.private.req?.originalUrl || '';
    const {endpointsPreset, domainConfigId, ...restQuery} = getQueryData(url);
    const hasEndpointsPreset = typeof endpointsPreset === 'string';
    const hasDomainConfigId = typeof domainConfigId === 'string';
    if (hasEndpointsPreset || hasDomainConfigId) {
      // save values to has ability to change domain config id and endpoint preset simultaneously
      const endpointsPresetValue = hasEndpointsPreset
        ? endpointsPreset
        : this.cookiesRegistry.endpointsPreset.restore();
      const domainConfigIdValue = hasDomainConfigId
        ? domainConfigId
        : this.cookiesRegistry.devDomainScope.restore();

      TypedObject.keys(this.cookiesRegistry).forEach((name) => {
        this.cookiesRegistry[name].remove();
      });

      this.setEndpointsConfigPreset(endpointsPresetValue);
      this.cookiesRegistry.devDomainScope.store(domainConfigIdValue as DomainConfigId);

      const search = serializeQueryData(restQuery);
      return `${getUrlPath(url)}${search && '?'}${search}`;
    }

    return undefined;
  }

  async setupEndpointsOverrides(): Promise<void> {
    if (this.private.setupEndpointsOverrides || __CLIENT__ || appConfig.releaseStage === 'prod') {
      return;
    }
    this.private.setupEndpointsOverrides = true;

    const endpointsPreset = this.getEndpointsConfigPreset();
    if (!endpointsPreset) {
      return;
    }

    const endpointsConfig = await this.getEndpointsConfig();
    const endpointsConfigItem = endpointsConfig.find((endpoint) => endpoint.id === endpointsPreset);

    if (!endpointsConfigItem) {
      this.setEndpointsConfigPreset('');
      return;
    }

    this.endpoints = {
      api: `${endpointsConfigItem.baseApi}/${endpointsConfigItem.version}`,
      serverApi: `${endpointsConfigItem.baseApi}/${endpointsConfigItem.version}`,
      faq: endpointsConfigItem.babyloneClientFaqRestApi,
      secureApi: `${endpointsConfigItem.baseApiSecure}/${endpointsConfigItem.version}`,
      payment: `${endpointsConfigItem.basePayment}/${endpointsConfigItem.version}`,
      payhub: `${endpointsConfigItem.basePayment}/v1`,
    };
  }

  getDeviceId(): string {
    return this.state.user?.deviceId || '';
  }

  getDeviceHash(): string {
    return hashCode(this.getDeviceId()).toString(32);
  }

  hydrate(state: DevicePublicState, skipConfigEmit?: boolean): void {
    if (state.user) {
      this.updateUser(state.user);
    }

    if (state.config) {
      this.updateConfig(state.config, skipConfigEmit);
    }

    if (state.userCookiesSettings) {
      this.updateUserCookiesSettings(state.userCookiesSettings);
    }

    if (state.renderingConfig && this.state.renderingConfig) {
      // nodejs always set BROWSER option
      state.renderingConfig = {...this.state.renderingConfig, id: state.renderingConfig.id};
    }

    this.state = state;
    this.private.hydrated = true;
  }

  getHydrationData(): DevicePublicState {
    return this.state;
  }

  setupGsAttrs(): void {
    const gsAttrs = getGsAttrs(this.private.req);
    if (gsAttrs) {
      this.state.gsAttrs = gsAttrs;

      // clear forced region to let gsAttrs works correctly
      this.cookiesRegistry.forcedRegion.remove();
    }
  }

  async init(): Promise<DevicePublicState> {
    this.private.res?.startTime('acdi', 'ApiClient Device Initialization');
    await this.setupTransportOverrides();
    await this.setupEndpointsOverrides();
    this.setupGsAttrs();
    const isTimezoneChanged = this.checkAndUpdateTimezoneCookie();

    if (__SERVER__) {
      await tokenDegradation(this, () => Promise.resolve());

      // data can be load when device created
      // or when check jmt migration redirect
      if (!this.state.config) {
        await this.configure();
      }
      if (!this.state.user) {
        await Promise.all([this.loadUser(), this.loadUserCookiesSettings()]);
      }
    } else if (!this.private.hydrated) {
      const data = await this.transports.tokens.post<ClientBackendResponse<DevicePublicState>>(
        createUrl('/tokens/init', {scope: this.scope.prefixScope}),
        {retry: true},
      );
      this.hydrate(data.body.payload);
    } else if (isTimezoneChanged) {
      // configure timezone in background
      this.configure().catch((error) => {
        this.logger.error({error: ecsError(error)});
      });
    }

    this.private.res?.endTime('acdi');

    this.private.inited = true;
    return this.state;
  }

  checkAndUpdateTimezoneCookie(): boolean {
    if (__CLIENT__) {
      const tzOffset = new Date().getTimezoneOffset();
      const tzName = Intl.DateTimeFormat().resolvedOptions().timeZone;

      // cookieTimezoneName stores client timezone in IANA format
      const cookieTimezoneName = this.cookiesRegistry.timezoneName.restore();
      // we need to store both name and offset to keep track of DST changes
      const cookieTimezone = this.cookiesRegistry.timezone.restore();

      // timezone name is stored to use it with IntlProvider
      if (tzName !== cookieTimezoneName) {
        this.cookiesRegistry.timezoneName.store(tzName);
      }

      // offset may change when clock shifts due to DST or due to timezone change so we need to signal reconfiguration
      if (cookieTimezone !== tzOffset) {
        this.cookiesRegistry.timezone.store(tzOffset);
        return true;
      }
    }

    return false;
  }

  getUserType(): UserType | undefined {
    const {renderingConfig} = this.state;
    return renderingConfig ? getUserTypeByRenderingType(renderingConfig.option) : undefined;
  }

  isUserTypeVerified(): boolean {
    return __SERVER__
      ? Boolean(this.getUserType()?.verified || this.private.hasImportedTokens)
      : true;
  }

  isBot(): boolean {
    return __SERVER__ ? Boolean(this.getUserType()?.type === Type.BOT) : false;
  }

  isAuthorized(): boolean {
    return !this.state.user?.anonymous;
  }

  isEphemeral(): boolean {
    return Boolean(this.state.user?.ephemeral);
  }

  setCurrency(currency: string): Promise<DevicePublicState> {
    // drop currency override after currency changing
    this.private.transportCurrencyOverride = '';
    return this.configure({
      currency,
    });
  }

  setLanguage(language: string): Promise<DevicePublicState> {
    return this.configure({
      language,
    });
  }

  setForcedRegion(forcedRegion: string): Promise<DevicePublicState> {
    this.cookiesRegistry.forcedRegion.store(forcedRegion);
    // need to pass empty object to force device upgrade
    return this.configure({});
  }

  setPushToken(pushToken: string): Promise<DevicePublicState> {
    return this.configure({
      pushToken,
    });
  }

  getLanguage(): string {
    const language = (
      this.private.language ||
      this.state.config?.preferences.language ||
      this.cookiesRegistry.language.restore() ||
      getUserLanguage(this.private.req, this.scope, this.getAvailableLanguagesConfig())
    )?.toLowerCase();

    if (!this.getAvailableLanguages().includes(language)) {
      if (this.scope.notJmt && language === 'ru') {
        return 'ru-ua';
      }

      return this.scope.defaultLanguage;
    }

    return language.toLowerCase();
  }

  getAvailableLanguagesConfig(filter?: LanguageConfigFilter): LanguageConfig[] {
    return filterLangConfig({
      legalEntity: this.scope.legalEntity,
      scope: this.scope.topScope,
      contextL10n: this.getCanUseLolLanguage() ? undefined : false,
      ...filter,
    });
  }

  getAvailableLanguages(filter?: LanguageConfigFilter): string[] {
    return getLangCodes({
      legalEntity: this.scope.legalEntity,
      scope: this.scope.topScope,
      contextL10n: this.getCanUseLolLanguage() ? undefined : false,
      ...filter,
    });
  }

  getLanguageConfig(): LanguageConfig {
    const lang = this.getLanguage();
    const langConfig = this.getAvailableLanguagesConfig().find(({code}) => code === lang);
    // since `getLanguage()` has `this.getAvailableLanguages().includes(language)` check,
    // we can be sure that language config exists
    if (!langConfig) {
      throw new Error(`Language config for current language "${lang}" doesn't exists`);
    }
    return langConfig;
  }

  getDir(language = this.getLanguage()): 'ltr' | 'rtl' {
    if (appConfig.releaseStage !== 'prod') {
      // eslint-disable-next-line dot-notation
      if (__CLIENT__ && window['__STORYBOOK_CLIENT_API__']) {
        // use global dir inside storybook
        return window.document.dir as 'ltr' | 'rtl';
      }
      if (this.cookiesRegistry.forcedRtl.restore()) {
        return 'rtl';
      }
    }
    return getLangCodes({rtl: true}).includes(language) ? 'rtl' : 'ltr';
  }

  getCurrency(): string {
    return this.state.config?.preferences.currency || this.scope.defaultCurrency;
  }

  getRegion(): string {
    return this.state.config?.preferences.region || '';
  }

  getDetectedCountry(): string {
    return this.state.config?.detectedCountry || '';
  }

  getCopyrightEntity(): CopyrightEntity | undefined {
    return this.state.config?.copyrightEntity;
  }

  getCanUseLolLanguage(): boolean {
    return this.state.config?.canUseLolLanguage || false;
  }

  getIsJmtMigrationNeeded(): boolean {
    return this.scope.isJmt && Boolean(this.state.config?.isJmtMigrationNeeded);
  }

  isAppWebView(): boolean {
    return __SERVER__ ? this.cookiesRegistry.webView.restore() : this.private.isWebView;
  }

  enableWebView(): void {
    if (__SERVER__) {
      this.cookiesRegistry.webView.store(true);
    } else {
      this.private.isWebView = true;
    }
  }

  forceLanguage(language?: string): void {
    if (!language) {
      return;
    }

    const langConfig = this.getAvailableLanguagesConfig().find(({code, locale}) =>
      [code, locale].includes(language.toLowerCase()),
    );

    if (langConfig) {
      this.private.language = langConfig.code;
    }
  }

  autoForceLanguage(): void {
    const language = getForcedLanguageByRequest(this.private.req);
    this.forceLanguage(language);
  }

  isAccessTokenOutdated(): boolean {
    const {accessTokenHash} = this.state;
    const cookieAccessTokenHash = this.cookiesRegistry.accessTokenHash.restore();
    return Boolean(
      cookieAccessTokenHash && accessTokenHash && cookieAccessTokenHash !== accessTokenHash,
    );
  }

  getAccessToken(): string {
    return this.state.accessToken;
  }

  setAccessToken(value: string): void {
    if (this.getAccessToken() !== value) {
      this.state.accessToken = value;
      this.cookiesRegistry.accessToken.store(value);

      const hash = hashCode(value).toString(32);

      this.state.accessTokenHash = hash;
      this.cookiesRegistry.accessTokenHash.store(hash);
    }
  }

  getRefreshToken(): string {
    return this.private.refreshToken;
  }

  setRefreshToken(value: string): void {
    if (this.getRefreshToken() !== value) {
      this.private.refreshToken = value;
      this.state.freezedToken = value === FREEZED_TOKEN;
      this.cookiesRegistry.refreshToken.store(value);
    }
  }

  getTrackingToken(): string {
    return this.private.trackingToken;
  }

  setTrackingToken(value: string): void {
    if (this.getTrackingToken() !== value) {
      this.private.trackingToken = value;
      this.cookiesRegistry.trackingToken.store(value);
    }
  }

  getClientRequestId(): string | undefined {
    return this.state.clientRequestId;
  }

  isUserChanged(): boolean {
    return this.state.userHash !== this.cookiesRegistry.userHash.restore();
  }

  getConfig(): DeviceConfig | undefined {
    return this.state.config;
  }

  getPreferences(): Preferences | undefined {
    return this.state.config?.preferences;
  }

  isAdult(): boolean {
    return Boolean(this.state.user?.isAdult);
  }

  getUser(): User | undefined {
    return this.state.user;
  }

  getUserId(): string | undefined {
    return this.getUser()?.id;
  }

  private updateTokens({accessToken, refreshToken, trackingToken}: TokensPayload): void {
    if (__CLIENT__) {
      return;
    }

    if (accessToken) {
      this.setAccessToken(accessToken);
    }

    if (refreshToken) {
      this.setRefreshToken(refreshToken);
    }

    if (trackingToken) {
      this.setTrackingToken(trackingToken);
    }
  }

  updateTokensWithHeaders(headers: Record<string, string>): void {
    this.updateTokens(getAuthTokensFromHeaders(headers));
  }

  private updateUser(user: User): void {
    const currentUser = this.state.user;
    const userChanged =
      user.id !== currentUser?.id ||
      // email signup doesn't change user id
      user.anonymous !== currentUser?.anonymous;
    const deviceIdChanged = user.deviceId !== currentUser?.deviceId;

    this.state.user = user;
    this.state.userHash = getUserHash(user);
    this.cookiesRegistry.userHash.store(this.state.userHash);

    if (this.private.inited) {
      if (deviceIdChanged) {
        this.emit('deviceIdChange', user.deviceId);
      }

      if (userChanged) {
        this.emit('userChange', user);
      } else {
        this.emit('userUpdate', user);
      }
    }
  }

  private updateUserCookiesSettings(cookiesSettings: CookiesSettings): void {
    const changed = !isEqual(this.state.userCookiesSettings, cookiesSettings);

    this.state.userCookiesSettings = cookiesSettings;

    this.cookies.setPermissions(cookiesSettings);

    if (this.private.inited && changed) {
      this.emit('cookiesSettingsChange', cookiesSettings);
    }
  }

  async reconfigure(): Promise<DevicePublicState> {
    if (!this.state.freezedToken) {
      await this.configure();
    }
    return this.state;
  }

  create = wrap(async (skipUserLoading?: boolean): Promise<DevicePublicState> => {
    if (this.state.freezedToken) {
      badAccessToken();
      return Promise.reject(createUnallowedResponse('Device.create'));
    }

    if (__SERVER__) {
      let data;

      try {
        data = await this.transports.tokens.post<ClientBackendResponse<TokensPayload>>(
          '/device/create',
          {
            body: {
              ephemeral: true,
              // additional info for coolbe WEB-8423
              ...(this.isBot() && {
                version: {legalEntity: this.scope.isJmt ? 'jmt' : undefined},
                preferences: {gsAttrs: this.state.gsAttrs},
              }),
            },
          },
        );
      } catch (error) {
        this.logger.error({error: ecsError(error)});
        return Promise.reject(error);
      }

      this.updateTokens(data?.body?.payload || {});
      await this.configure();

      if (!skipUserLoading) {
        await Promise.all([this.loadUser(), this.loadUserCookiesSettings()]);
      }
    } else {
      const data = await this.transports.tokens.post<ClientBackendResponse<DevicePublicState>>(
        createUrl('/tokens/init', {scope: this.scope.prefixScope}),
        {retry: true},
      );
      this.hydrate(data.body.payload);
    }

    if (this.private.inited) {
      this.emit('create');
    }

    return this.state;
  });

  refreshToken = wrap(async (): Promise<DevicePublicState> => {
    if (this.state.freezedToken) {
      badAccessToken();
      return Promise.reject(createUnallowedResponse('Device.refreshToken'));
    }

    if (__SERVER__) {
      const accessToken = this.getAccessToken();
      const refreshToken = this.getRefreshToken();
      const trackingToken = this.getTrackingToken();

      if (!accessToken || !refreshToken) {
        return Promise.reject(createApiError('Device.refreshToken', 401));
      }

      const data = await this.transports.tokens.post<ClientBackendResponse<TokensPayload>>(
        '/device/token/refresh',
        {
          headers: {
            [AUTHORIZATION_HEADER]: createAuthorizationHeader(accessToken),
          },
          body: trackingToken ? {refreshToken, trackingToken} : {refreshToken},
        },
      );
      this.updateTokens(data.body?.payload || {});
    } else {
      const data = await this.transports.tokens.post<ClientBackendResponse<DevicePublicState>>(
        createUrl('/tokens/refresh', {scope: this.scope.prefixScope}),
      );
      this.hydrate(data.body.payload);
    }

    return this.state;
  });

  upgradeEphemeral = wrap(
    async (reason?: DeephemerizeReason, skipConfigEmit?: boolean): Promise<DevicePublicState> => {
      // Do not allow upgrades for bots.
      if (this.isBot()) {
        this.logger.warn('You are trying to upgrade device for crawlers. Do not do that!');
        throw new Error('Can not upgrade this device.');
      }

      if (!this.state.user?.ephemeral) {
        return this.state;
      }

      if (__SERVER__) {
        const data = await this.transports.tokens.post<ClientBackendResponse<TokensPayload>>(
          '/device/upgradeEphemeral',
          {
            headers: {
              [AUTHORIZATION_HEADER]: createAuthorizationHeader(this.getAccessToken()),
            },
            body: {
              deephemerizeReason: getDeephemerizeReasonPayload(reason),
              preferences: this.state.config?.preferences,
              version: this.state.deviceVersion,
            },
          },
        );
        this.updateTokens(data.body?.payload || {});

        await Promise.all([this.loadUser(), this.loadUserCookiesSettings()]);
      } else {
        const data = await this.transports.tokens.post<ClientBackendResponse<DevicePublicState>>(
          createUrl('/tokens/upgrade', {scope: this.scope.prefixScope}),
          {
            body: {
              reason,
            },
          },
        );
        this.hydrate(data.body.payload, skipConfigEmit);
      }

      return this.state;
    },
  );

  private setLanguageCookie(language: string) {
    const redirectLanguage = this.cookiesRegistry.language.restore();

    if (language && language !== redirectLanguage) {
      this.cookiesRegistry.language.store(language.toLowerCase());
    }
  }

  private updateConfig(
    deviceConfig: DeviceConfig,
    skipConfigEmit?: boolean,
    prevConfigToEmit?: DeviceConfig,
  ) {
    const prevConfig = prevConfigToEmit || this.state.config;
    this.state.config = deviceConfig;
    enableClientBackendProxy(this.getDeviceVar('proxyClientBackend'));
    this.setLanguageCookie(deviceConfig.preferences.language);

    if (this.private.inited && !skipConfigEmit) {
      this.emit('configChange', deviceConfig, prevConfig);
    }
  }

  overrideDeviceLanguage(): boolean {
    // WEB-8705 don't override device language for web view
    return Boolean(!this.isAppWebView() || this.private.language);
  }

  configure = async (preferencesUpdate?: Partial<Preferences>): Promise<DevicePublicState> => {
    const prevConfig = this.state.config;
    if (preferencesUpdate && this.state.user?.ephemeral) {
      await this.upgradeEphemeral(
        {
          type: DeephemerizeReasonType.UPDATE_PREFERENCES,
          list: Object.keys(preferencesUpdate),
        },
        true,
      );
    }

    const body = {
      ...this.state.deviceVersion,
      supportedDevicevars: appConfig.supportedDevicevars,
      preferences: {
        ...this.state.config?.preferences,
        locales: getUserLocales(this.private.req),
        ...preferencesUpdate,
      },
      legalEntity: this.scope.isJmt ? 'jmt' : undefined,
      customDomain: this.scope.deviceCustomDomain,
    };

    const forcedRegion = this.cookiesRegistry.forcedRegion.restore();
    if (forcedRegion) {
      // T&C content depends on region value so need to force this one as well
      body.preferences.region = forcedRegion;
      body.preferences.forcedRegion = forcedRegion;
    }

    const {gsAttrs} = this.state;
    if (gsAttrs) {
      body.preferences.gsAttrs = gsAttrs;
    }

    const tzOffset = this.cookiesRegistry.timezone.restore();

    if (typeof tzOffset === 'number') {
      // server needs timeZone in seconds and with opposite sign, e.g. for UTC+3 it will be 10800
      body.preferences.timeZone = tzOffset * 60 * -1;
    }

    const query = this.overrideDeviceLanguage() ? undefined : {language: undefined};

    const data = await this.transports.api.post<ClientBackendResponse<DeviceConfig>>(
      '/device/configure',
      {retry: true, body, query},
    );

    this.updateConfig(data.body.payload, false, prevConfig);

    return this.state;
  };

  getRenderingConfig(): RenderingConfiguration | undefined {
    return this.state.renderingConfig;
  }

  setRenderingConfig(renderingConfig: RenderingConfiguration): void {
    this.state.renderingConfig = renderingConfig;
    this.log.addMeta({renderingId: renderingConfig?.id});
  }

  getCookieRenderingId(): string | undefined {
    return this.cookiesRegistry.renderingId.restore();
  }

  setCookieRenderingId(id: string): void {
    this.cookiesRegistry.renderingId.store(id);
  }

  loadRenderingConfig(req: express.Request): RenderingConfiguration {
    if (!this.state.renderingConfig) {
      this.setRenderingConfig(loadRenderingConfig(this, req));
    }
    return this.state.renderingConfig!;
  }

  loadUser = async (): Promise<User> => {
    const data = await this.transports.api.get<ClientBackendResponse<User>>('/users/self');
    const user = data.body.payload;
    this.updateUser(user);
    return user;
  };

  loadUserCookiesSettings = async (configPromise?: Promise<unknown>): Promise<CookiesSettings> => {
    if (this.isAppWebView()) {
      this.updateUserCookiesSettings(defaultCookiesSettings);
      return defaultCookiesSettings;
    }

    // with configPromise deviceVars is undefined
    if (!configPromise && !this.getDeviceVars().disableCookiesUntilUserSetsPermission) {
      this.updateUserCookiesSettings(fullyAcceptedCookiesSettings);
      return fullyAcceptedCookiesSettings;
    }

    const data =
      await this.transports.api.post<ClientBackendResponse<CookiesSettingsResponse>>(
        '/users/cookies/get',
      );
    let cookiesSettings = data.body.payload.cookies;

    await configPromise;

    if (!this.getDeviceVars().disableCookiesUntilUserSetsPermission) {
      cookiesSettings = fullyAcceptedCookiesSettings;
    }

    this.updateUserCookiesSettings(cookiesSettings);
    return cookiesSettings;
  };

  private handleAuth = async (result: AuthResult): Promise<AuthResult> => {
    if (result.type === 'confirmed') {
      this.updateUser(result.payload.user);
      await this.reconfigure();
    }
    return result;
  };

  getDeviceVars(): DeviceVars | EmptyObject {
    return this.state.config?.devicevars || {};
  }

  getDeviceVar<T extends keyof DeviceVars>(name: T): DeviceVars[T] | undefined {
    return this.getDeviceVars()[name];
  }

  getDeviceVersion(): ReturnType<typeof getDeviceVersion> {
    return this.state.deviceVersion;
  }

  getUserAgent(): MemoUAParser {
    return this.private.userAgent;
  }

  getCookiesSettings(): CookiesSettings {
    return this.state.userCookiesSettings;
  }

  async reportAdulthood(): Promise<boolean> {
    const data =
      await this.transports.api.post<ClientBackendResponse<User>>('/users/self/adulthood');
    const user = data.body.payload;
    this.updateUser(user);
    return Boolean(user.isAdult);
  }
}

import {CancelBehavior} from 'types/CancelBehavior';
import {ImageBundle} from 'types/Image';
import {Button, Text} from 'types/Layout';

import {Timer} from './common';
import {ContentListBaseItem, contentListBaseItem} from './ContentListBaseItem';
import {ProductListPurchaseItemsItem} from './ContentListProductListPurchaseItems';

export type AwaitingPaymentOrderGroup = {
  actionButton: Button;
  cancelBehavior?: CancelBehavior;
  cancelButton?: Button;
  icon?: ImageBundle;
  orderGroupId: string;
  products?: ProductListPurchaseItemsItem[];
  subtitle?: Text;
  timer?: Timer;
  title?: Text;
};

export type ContentListAwaitingPaymentOrderGroup = ContentListBaseItem<{
  awaitingPaymentOrderGroup: AwaitingPaymentOrderGroup;
}>;

export function contentListAwaitingPaymentOrderGroup(
  id: string,
  awaitingPaymentOrderGroup: AwaitingPaymentOrderGroup,
): ContentListAwaitingPaymentOrderGroup {
  return contentListBaseItem(id, {awaitingPaymentOrderGroup});
}

export function isContentListAwaitingPaymentOrderGroup(
  item: ContentListBaseItem,
): item is ContentListAwaitingPaymentOrderGroup {
  return Boolean((item as ContentListAwaitingPaymentOrderGroup).content.awaitingPaymentOrderGroup);
}

import {SimpleState} from 'store/utils/reducerCreators/simple/index';
import {Selector, SelectorFactory, SelectorMeta} from 'store/utils/selectors';
import {Error} from 'types/Error';
import {trueFunction} from 'utils/function';

export type SimpleStateRequestMatcher<T, TArgs extends unknown[]> = T extends SimpleState<
  infer U,
  infer V
>
  ? RequestMatcher<U, V, TArgs>
  : never;

export type RequestMatcher<TRequest, TData, TArgs extends unknown[]> = (
  request: TRequest,
  meta: SelectorMeta<SimpleState<TRequest, TData>>,
  ...args: TArgs
) => boolean;

export function isStateLoading<TRequest, TData, TArgs extends unknown[]>(
  meta: SelectorMeta<SimpleState<TRequest, TData>>,
  requestMatcher: RequestMatcher<TRequest, TData, TArgs> = trueFunction,
  ...args: TArgs
): boolean {
  if (meta.state.request !== null && requestMatcher(meta.state.request, meta, ...args)) {
    return meta.state.loading;
  }
  return false;
}

export function isStateLoaded<TRequest, TData, TArgs extends unknown[]>(
  meta: SelectorMeta<SimpleState<TRequest, TData>>,
  requestMatcher: RequestMatcher<TRequest, TData, TArgs> = trueFunction,
  ...args: TArgs
): boolean {
  if (meta.state.request !== null && requestMatcher(meta.state.request, meta, ...args)) {
    return meta.state.loaded;
  }
  return false;
}

export function getStateData<TRequest, TData, TArgs extends unknown[]>(
  meta: SelectorMeta<SimpleState<TRequest, TData>>,
  requestMatcher: RequestMatcher<TRequest, TData, TArgs> = trueFunction,
  ...args: TArgs
): TData | null {
  if (isStateLoaded(meta, requestMatcher, ...args)) {
    return meta.state.data;
  }
  return null;
}

export function getStateError<TRequest, TData, TArgs extends unknown[]>(
  meta: SelectorMeta<SimpleState<TRequest, TData>>,
  requestMatcher: RequestMatcher<TRequest, TData, TArgs> = trueFunction,
  ...args: TArgs
): Error | null {
  if (meta.state.request !== null && requestMatcher(meta.state.request, meta, ...args)) {
    return meta.state.error;
  }
  return null;
}

type Selectors<TRequest, TData, TArgs extends unknown[]> = {
  isLoading: Selector<boolean, TArgs>;
  isLoaded: Selector<boolean, TArgs>;
  getError: Selector<Error | null, TArgs>;
  getData: Selector<TData | null, TArgs>;
  getRequest: Selector<TRequest | null>;
};

export function getSelectors<TArgs extends unknown[], TRequest, TData>(
  selector: SelectorFactory<SimpleState<TRequest, TData>>,
  requestMatcher: RequestMatcher<TRequest, TData, TArgs> = trueFunction,
): Selectors<TRequest, TData, TArgs> {
  return {
    isLoading: selector((meta, ...args: TArgs) => isStateLoading(meta, requestMatcher, ...args)),
    isLoaded: selector((meta, ...args: TArgs) => isStateLoaded(meta, requestMatcher, ...args)),
    getError: selector((meta, ...args: TArgs) => getStateError(meta, requestMatcher, ...args)),
    getData: selector((meta, ...args: TArgs) => getStateData(meta, requestMatcher, ...args)),
    getRequest: selector((meta) => meta.state.request),
  };
}

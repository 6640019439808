import {getServerTimestamp} from 'helpers/serverTime';
import {ContextItem, ContextItemRaw} from 'types/Context';
import {createStringCounter} from 'utils/counter';

type CurrencyMixin = {
  currency: string;
};

type WithCurrency<TObject> = TObject & CurrencyMixin;

type LanguageMixin = {
  language: string;
};

type WithLanguage<TObject> = TObject & LanguageMixin;

type LoadedTimeMixin = {
  loadedTimeMs: number;
};

type WithLoadedTime<TObject> = TObject & LoadedTimeMixin;

type PageDependedIdMixin = {
  pdid: string;
};

type WithPageDependedId<TObject> = TObject & PageDependedIdMixin;

type ContextMixin = {
  contextId: string;
  context: ContextItem;
};

type WithContext<TObject> = TObject & ContextMixin;

const enhanceObject = <
  TObject extends Record<string, unknown>,
  TExtra extends Record<string, unknown>,
>(
  obj: TObject,
  extra: TExtra,
): TObject & TExtra => {
  const result = obj as Record<string, unknown>;

  Object.keys(extra).forEach((key) => {
    result[key] = extra[key];
  });

  return obj as TObject & TExtra;
};

const enhanceCurrency = <TObject extends Record<string, unknown>>(
  obj: TObject,
  currency: string,
): WithCurrency<TObject> => enhanceObject<TObject, {currency: string}>(obj, {currency});

const enhanceLanguage = <TObject extends Record<string, unknown>>(
  obj: TObject,
  language: string,
): WithLanguage<TObject> => enhanceObject<TObject, {language: string}>(obj, {language});

const enhanceLoadedTime = <TObject extends Record<string, unknown>>(
  obj: TObject,
  loadedTimeMs: number = getServerTimestamp(),
): WithLoadedTime<TObject> => enhanceObject<TObject, {loadedTimeMs: number}>(obj, {loadedTimeMs});

const enhancePageDependedId = <TObject extends Record<string, unknown>>(
  obj: TObject,
  pageId: string,
  id: string,
): WithPageDependedId<TObject> =>
  enhanceObject<TObject, {pdid: string}>(obj, {pdid: `${pageId}:${id}`});

const CONTEXT_SEED = Math.floor((1 + Math.random()) * 0x10000).toString(36);
const getContextCounter = createStringCounter();

const enhanceContext = <TObject extends Record<string, unknown>>(
  obj: TObject,
  type: string,
  value: ContextItemRaw,
): WithContext<TObject> =>
  enhanceObject<TObject, {context: ContextItem; contextId: string}>(obj, {
    context: {type, value: [value]},
    contextId: `${CONTEXT_SEED}.${getContextCounter()}`,
  });

export {
  enhanceContext,
  enhanceCurrency,
  enhanceLanguage,
  enhanceLoadedTime,
  enhancePageDependedId,
  ContextMixin,
  WithContext,
  CurrencyMixin,
  WithCurrency,
  LanguageMixin,
  WithLanguage,
  LoadedTimeMixin,
  WithLoadedTime,
  PageDependedIdMixin,
  WithPageDependedId,
};

import {SocialPostRoute} from './SocialPostRoute';
import {DefaultParams, PoorRoute} from './types';

type Params = DefaultParams & {
  postId: string;
  commentId?: string;
};

const NAME = 'socialPostCommentFeedback';

export const SocialPostCommentFeedbackRoute: PoorRoute<typeof NAME, Params> = {
  ...SocialPostRoute,
  path: '/social/posts/:postId/comments/:commentId/:feedback(feedback)',
  name: NAME,
};

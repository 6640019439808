import {createResponse, createApiError} from 'helpers/ApiClient/Transport/Response';
import {PREFIX_SCOPE_GET_PARAM} from 'helpers/ApiClient/Scope/ScopeConfig';
import {AuthProvider} from 'types/AuthProvider';
import {createUrl} from 'utils/url';

const CALLBACK = '__AUTH_CALLBACK__';
const CLOSE_CHECKER_DELAY = 2000;

let popup = null;
let closeTimer = 0;

function cancel() {
  if (global[CALLBACK]) {
    global[CALLBACK]();
  }
}

function closeChecker() {
  clearTimeout(closeTimer);
  if (popup && !popup.closed) {
    closeTimer = setTimeout(closeChecker, CLOSE_CHECKER_DELAY);
  } else {
    cancel();
  }
}

function typeToPath(type) {
  if (type === AuthProvider.FACEBOOK) {
    return '/auth/fb';
  }

  return `/auth/${type}`;
}

export default function auth(type, client, params) {
  if (popup) {
    if (popup.close) {
      popup.close();
    }
    cancel();
  }

  return new Promise((resolve, reject) => {
    global[CALLBACK] = (res) => {
      global[CALLBACK] = null;
      clearTimeout(closeTimer);

      if (!res) {
        return reject(createApiError('auth.cancel', 400));
      }

      // IE does not respect objects, which created in child window
      let response = null;
      if (res) {
        try {
          response = JSON.parse(res);
        } catch (ex) {
          return reject(ex);
        }
      }

      const result = createResponse(response, response.error, response.meta);
      result.requestId = 'authcallback';
      return result.error ? reject(result) : resolve(result);
    };

    const requestInfo = client.device.getDeviceVar('requestExtraUserInfoOnSignInWeb') || {};

    const height = type === AuthProvider.APPLE ? 680 : 500;
    const width = type === AuthProvider.APPLE ? 800 : 600;

    popup = global.open(
      createUrl(typeToPath(type), {
        ...requestInfo,
        ...params,
        [PREFIX_SCOPE_GET_PARAM]: client.scope.prefixScope,
      }),
      'auth',
      `height=${height},width=${width},location=0,menubar=0,toolbar=0,personalbar=0`,
    );
    closeTimer = setTimeout(closeChecker, CLOSE_CHECKER_DELAY);
  });
}

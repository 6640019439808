import {routeAsync} from 'helpers/asyncConnect';

import {anonymous} from './decorators';
import {DefaultParams, PoorRoute} from './types';

const NAME = 'about';

const aboutJoomRouteAsync = routeAsync(
  /* #__LOADABLE__ */ () => import('containers/pages/About'),
  (module) => module.AboutPage,
);

const aboutJmtRouteAsync = routeAsync(
  /* #__LOADABLE__ */ () => import('containers/pages/AboutJmt'),
  (module) => module.AboutPage,
);

export const AboutRoute: PoorRoute<typeof NAME, DefaultParams> = {
  name: NAME,
  path: '/about',
  async: (options) => {
    if (options.client.scope.isJmt) {
      return aboutJmtRouteAsync(options);
    }
    return aboutJoomRouteAsync(options);
  },
  decorator: anonymous,
};

import {useRef} from 'react';

export function useConst<S>(constState: S | (() => S)): S {
  const ref = useRef<{value: S}>();

  if (!ref.current) {
    const value = typeof constState === 'function' ? (constState as () => S)() : constState;
    ref.current = {value};
  }

  return ref.current.value;
}

/**
 * Java hashCode with stringify
 * NB! Do not use as key for big data sets.
 * ┌───────┬──────────────┐
 * │ Count │ Probability  │
 * │       │ of collision │
 * ├───────┼──────────────┤
 * │  9292 │           1% │
 * │ 30085 │          10% │
 * │ 77163 │          50% │
 * └───────┴──────────────┘
 * @type {string} str
 * @return {number}
 */
export function hashCode(str) {
  let hash = 0;
  if (str.length === 0) {
    return hash;
  }

  for (let index = 0, len = str.length; index < len; index += 1) {
    const chr = str.charCodeAt(index);
    // eslint-disable-next-line no-bitwise
    hash = (hash << 5) - hash + chr;
    // eslint-disable-next-line no-bitwise
    hash |= 0; // Convert to 32bit integer
  }

  return hash;
}

export function repeat(string, times) {
  return new Array(times + 1).join(string);
}

export function leftPad(value, width = 2, symbol = '0') {
  const string = value ? `${value}` : '';
  return string.length >= width ? string : repeat(symbol, width - string.length) + string;
}

export function rightPad(value, width = 2, symbol = ' ') {
  const string = value ? `${value}` : '';
  return string.length >= width ? string : string + repeat(symbol, width - string.length);
}

export function capitalizeFirstLetter(string) {
  return string ? string.charAt(0).toUpperCase() + string.slice(1) : string;
}

export function lowercaseFirstLetter(string) {
  if (!string) {
    return string;
  }

  const str = String(string);
  const first = str.charAt(0);
  const second = str.charAt(1);

  // maybe acronym at start
  if (first && second && first.toUpperCase() === first && second.toUpperCase() === second) {
    return str;
  }

  return str.charAt(0).toLowerCase() + str.slice(1);
}

const REGEX_QUOTES = /"/g;
const REGEX_HEXES = /%[\dA-F]{2}/g;
const REGEX_WHITESPACE = /\s+/g;

function svgDataUriBody(string) {
  return encodeURIComponent(string).replace(REGEX_HEXES, (match) => {
    switch (
      match // Browsers tolerate these characters, and they're frequent
    ) {
      case '%20':
        return ' ';
      case '%3D':
        return '=';
      case '%3A':
        return ':';
      case '%2F':
        return '/';
      default:
        return match;
    }
  });
}

export function svgToDataUri(svg) {
  let string = svg;
  if (string.charCodeAt(0) === 0xfeff) {
    string = string.slice(1);
  }
  const body = svgDataUriBody(string.replace(REGEX_WHITESPACE, ' ').replace(REGEX_QUOTES, "'"));
  return `data:image/svg+xml,${body}`;
}

export function limitCountValue(value, limit = null) {
  return limit && value > limit ? `${limit}+` : `${value}`;
}

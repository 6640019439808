import {useEffectOnce} from 'hooks/useEffectOnce';
import {useHistoryBlock} from 'hooks/useHistoryBlock';
import React, {createContext, PropsWithChildren, ReactElement, useContext, useRef} from 'react';

export type AppHistoryInfoItem = {
  scrollPosition: number;
};

export type AppHistoryInfo = {
  items: AppHistoryInfoItem[];
  popItem?: AppHistoryInfoItem;
};

const AppHistoryInfoContext = createContext<AppHistoryInfo | undefined>(undefined);

export function useAppHistoryInfo(): AppHistoryInfo {
  const appHistoryInfo = useContext(AppHistoryInfoContext);

  if (!appHistoryInfo) {
    throw new Error('Usage of useAppHistoryInfo outside AppHistoryInfoProvider');
  }

  return appHistoryInfo;
}

export function AppHistoryInfoProvider({children}: PropsWithChildren): ReactElement {
  const historyBlock = useHistoryBlock();
  const appHistoryInfo = useRef<AppHistoryInfo>({items: []}).current;

  useEffectOnce(() => {
    const unblock = historyBlock((location, action) => {
      const nextItem: AppHistoryInfoItem = {
        scrollPosition: window.pageYOffset,
      };

      appHistoryInfo.popItem = undefined;
      // eslint-disable-next-line default-case
      switch (action) {
        case 'POP': {
          appHistoryInfo.popItem = appHistoryInfo.items.pop();
          break;
        }
        case 'PUSH': {
          appHistoryInfo.items.push(nextItem);
          break;
        }
      }
    });

    return () => {
      unblock();
    };
  });

  return (
    <AppHistoryInfoContext.Provider value={appHistoryInfo}>
      {children}
    </AppHistoryInfoContext.Provider>
  );
}

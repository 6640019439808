import {
  isSolutionResponseAction,
  solveRequestAction,
} from 'helpers/ApiClient/Transport/ProofOfWork/worker/actions';
import {createWorkerWithLocalPath} from 'helpers/publicPath';
import {createDetachedPromise} from 'utils/promise';

import {ProofOfWorkSolution, ProofOfWorkSolveRequest} from '../types';

type JobState = {
  resolve: (solution: ProofOfWorkSolution) => void;
  reject: (error: unknown) => void;
};

export class ProofOfWorkWorkerBus {
  private readonly solutionTimeoutMs = 10_000;

  private worker: Worker | undefined;

  private jobs = new Map<number, JobState>();

  private incrementId = 0;

  private generateId() {
    this.incrementId += 1;
    return this.incrementId;
  }

  private handleMessage = (event: MessageEvent) => {
    if (isSolutionResponseAction(event.data) && this.jobs.has(event.data.id)) {
      const state = this.jobs.get(event.data.id);
      this.jobs.delete(event.data.id);

      state?.resolve(event.data.solution);
    }
  };

  private handleError = (event: ErrorEvent) => {
    this.terminateWorker(new Error(event.message));
  };

  private getWorker(): Worker {
    if (!this.worker) {
      this.worker = createWorkerWithLocalPath(
        () =>
          new Worker(new URL('./proofOfWorkWorker.ts', import.meta.url), {
            credentials: 'same-origin',
            name: 'ProofOfWorkSolver',
          }),
      );

      this.worker.addEventListener('message', this.handleMessage);
      this.worker.addEventListener('error', this.handleError);
    }

    return this.worker;
  }

  hasJobs(): boolean {
    return this.jobs.size > 0;
  }

  hasWorker(): boolean {
    return Boolean(this.worker);
  }

  terminateWorker(error?: unknown): void {
    if (this.worker) {
      this.worker.terminate();
      this.worker = undefined;

      this.jobs.forEach(({reject}) => {
        reject(error || new Error('Worker terminated'));
      });

      this.jobs.clear();
    }
  }

  solve(request: ProofOfWorkSolveRequest): Promise<ProofOfWorkSolution> {
    const id = this.generateId();

    const {promise, resolve, reject} = createDetachedPromise<ProofOfWorkSolution>();
    this.jobs.set(id, {resolve, reject});

    this.getWorker().postMessage(solveRequestAction(id, request));

    setTimeout(() => {
      this.jobs.delete(id);
      reject(new Error('Proof of work solution timeout exceeded'));
    }, this.solutionTimeoutMs);

    return promise;
  }
}

import {globalLog} from 'helpers/log';

function callApi(name: string, ...args: unknown[]): unknown {
  if (__CLIENT__) {
    if (__DEVELOPMENT__) {
      globalLog.getLogger('mobileAppApi').log('call', name, args);
    }
    if ('APP_API' in window && APP_API[name]) {
      return APP_API[name]?.(...args);
    }
    globalLog.getLogger('mobileAppApi').warn('undefined action', name);
  }
  return undefined;
}

function action(name: string) {
  return (...args: unknown[]): unknown => callApi(name, ...args);
}

export const close = action('close');
export const consent = action('consent');
export const badAccessToken = action('badAccessToken');
export const consentAgree = action('consentAgree');
export const consentDisagree = action('consentDisagree');
export const userPreferencesChanged = action('userPreferencesChanged');
export const feedbackSent = action('feedbackSent');

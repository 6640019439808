import {ContentListBaseItem} from './ContentListBaseItem';

export type HtmlBlockItem = {
  html: string;
};

export type ContentListHtmlBlock = ContentListBaseItem<{htmlBlock: HtmlBlockItem}>;

export function isContentListHtmlBlock(item: ContentListBaseItem): item is ContentListHtmlBlock {
  return Boolean((item as ContentListHtmlBlock).content.htmlBlock);
}

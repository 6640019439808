import type {Request, Response} from 'express';
import {Device} from 'helpers/ApiClient/Device';
import {TransportHeaders, TransportMeta} from 'types/Transport';

import {GoTransport} from './GoTransport';

export class PayhubTransport extends GoTransport {
  constructor(device: Device, req: Request | undefined, res: Response | undefined) {
    super(device.transportEndpoints.payhub, device, req, res);
  }

  override buildHeaders(headers: TransportHeaders, meta: TransportMeta): TransportHeaders {
    const result = super.buildHeaders(headers, meta);
    delete result['X-OSType'];
    return result;
  }
}

import {isNonEphemeral} from 'store/modules/auth/isNonEphemeral';
import {
  enhanceStorageViewedCoupon,
  syncStorageViewedCoupons,
} from 'store/modules/couponCards/storage';
import {enhanceLoadedTime} from 'store/utils/enhancers';
import {requestActionCreator} from 'store/utils/requestActions';
import {CouponCard} from 'types/CouponCard';
import {FuncAction} from 'typesafe-actions';

import {
  loadAll,
  loadOne,
  LoadOneResponse,
  setViewedCouponCard,
  syncViewedCouponCards,
} from './actions';

type LoadAllResponseBody = {
  payload: {
    items: CouponCard[];
  };
};

type LoadOneResponseBody = {
  payload: CouponCard;
};

export const loadCouponCards = (): FuncAction =>
  requestActionCreator(loadAll, {}, ({getState}, client) =>
    isNonEphemeral(getState())
      ? client.api.get<LoadAllResponseBody>('/couponCards').then(({body: {payload}}) => ({
          items: syncStorageViewedCoupons(payload.items.map((item) => enhanceLoadedTime(item))),
        }))
      : Promise.resolve({
          items: [],
        }),
  );

export const loadCouponCard = (id: string): FuncAction<Promise<LoadOneResponse>> =>
  requestActionCreator(loadOne, {}, (state, client) =>
    client.api.get<LoadOneResponseBody>(`/couponCards/${id}`).then(({body: {payload}}) => ({
      item: enhanceStorageViewedCoupon(enhanceLoadedTime(payload)),
    })),
  );

export {setViewedCouponCard, syncViewedCouponCards};

import {createRequestAction} from 'store/utils/requestActions';
import {createAction} from 'typesafe-actions';

export const setCartCount = createAction('@cartCount/SET_COUNT')<number>();

export const discardCartCount = createAction('@cartCount/DISCARD')();

export type LoadCartCountResponse = {count: number};

export const loadCartCount = createRequestAction(
  '@cartCount/LOAD_REQUEST',
  '@cartCount/LOAD_SUCCESS',
  '@cartCount/LOAD_FAILURE',
)<undefined, LoadCartCountResponse>();

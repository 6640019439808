export type ContentListBaseItem<
  TContent extends Record<string, unknown> = Record<string, unknown>,
  TID extends string = string,
> = {
  id: TID;
  content: TContent;
  pdid?: string;
  groupId?: string;
};

export function contentListBaseItem<TContent extends Record<string, unknown>, TID extends string>(
  id: TID,
  content: TContent,
  pdid?: string,
  groupId?: string,
): ContentListBaseItem<TContent, TID> {
  return {
    content,
    id,
    pdid,
    groupId,
  };
}

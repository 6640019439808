import {routeAsync} from 'helpers/asyncConnect';

import {anonymous} from './decorators';
import {DefaultParams, PoorRoute} from './types';

const NAME = 'appViewTerms';

export const AppViewTermsRoute: PoorRoute<typeof NAME, DefaultParams> = {
  name: NAME,
  path: '/app-view/legal/terms',
  async: routeAsync(
    /* #__LOADABLE__ */ () => import('containers/pages/AppView/Terms'),
    (module) => module.TermsPage,
  ),
  decorator: anonymous,
};

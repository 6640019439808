import {ProductReviewFilter} from 'types/ProductReviews';
import {SocialPost} from 'types/SocialPost';

import {ContentListBaseItem, contentListBaseItem} from './ContentListBaseItem';

type ProductReviews = {
  productReviews: {
    reviews: SocialPost[];
    count?: {
      value: number;
      text?: string;
    };
    filters: ProductReviewFilter[];
    showMoreButton?: boolean;
    showReviewsOverview?: boolean;
  };
};

export type ContentListProductReviews = ContentListBaseItem<ProductReviews>;

export function contentListProductReviews(
  id: string,
  productReviews: ProductReviews,
): ContentListProductReviews {
  return contentListBaseItem(id, productReviews);
}

export function isContentListProductReviews(
  item: ContentListBaseItem,
): item is ContentListProductReviews {
  return Boolean((item as ContentListProductReviews).content.productReviews);
}

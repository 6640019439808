import {Analytics} from 'helpers/ApiClient/Analytics';
import {Device} from 'helpers/ApiClient/Device';
import invert from 'lodash/invert';
import {AuthProvider} from 'types/AuthProvider';
import {SocialAuth} from 'types/deviceVars';
import {User} from 'types/User';
import {Expandable} from 'types/utility';

export const CredentialProvider = {
  [AuthProvider.GOOGLE]: 'https://accounts.google.com',
  [AuthProvider.FACEBOOK]: 'https://www.facebook.com',
  [AuthProvider.APPLE]: 'https://apple.com',
  [AuthProvider.VK]: 'https://vk.com',
  [AuthProvider.OK]: 'https://ok.ru',
};

export const CredentialProviderToAuthProvider = invert(CredentialProvider);

function enhanceUserData(
  credentialData: PasswordCredentialData | FederatedCredentialInit,
  user: User,
): void {
  if (user.fullName) {
    credentialData.name = user.fullName;
  }

  if (user.avatar?.images[0]?.url) {
    credentialData.iconURL = user.avatar?.images[0].url;
  }
}

export function isPasswordCredential(
  credential?: Credential | null,
): credential is PasswordCredential {
  return Boolean(credential && credential.type === 'password');
}

export function isFederatedCredential(
  credential?: Credential | null,
): credential is FederatedCredential {
  return Boolean(credential && credential.type === 'federated');
}

export function isCredentialsAvailable(device: Device): boolean {
  if (__CLIENT__) {
    return (
      typeof window !== 'undefined' &&
      Boolean(window.PasswordCredential) &&
      !device.isAppWebView() &&
      device.getUserAgent().getBrowser().name === 'Chrome' &&
      device.getDeviceVar('webSmartLockEnabled') === true
    );
  }

  return false;
}

function storeCredentials(
  credential: Credential | null,
  type: string,
  analytics?: Analytics,
): Promise<Credential | null> {
  if (credential) {
    const promise = navigator.credentials.store(credential);

    if (analytics) {
      promise
        .then((data: unknown) => {
          analytics.sendEvent({
            type: 'smartlockSave',
            payload: {
              result: data ? 'success' : 'cancelled',
              type,
            },
          });

          return data;
        })
        .catch((error) => {
          analytics.sendEvent({
            type: 'smartlockSave',
            payload: {
              result: 'error',
              type,
            },
          });

          return Promise.reject(error);
        });
    }
  }

  return Promise.resolve(null);
}

type EmailSigninData = {
  email: string;
  password: string;
};

export function storePasswordCredentials(
  data: EmailSigninData,
  user?: User,
  analytics?: Analytics,
): Promise<Credential | null> {
  if (__CLIENT__) {
    const passwordData: PasswordCredentialData = {
      id: data.email,
      password: data.password,
    };

    if (user) {
      enhanceUserData(passwordData, user);
    }

    return navigator.credentials
      .create({password: passwordData})
      .then((credential) => storeCredentials(credential, 'password', analytics));
  }

  return Promise.resolve(null);
}

export function storeFederatedCredentials(
  type: string,
  user?: User,
  analytics?: Analytics,
): Promise<Credential | null> {
  if (__CLIENT__) {
    const provider = (CredentialProvider as Expandable<typeof CredentialProvider>)[type];
    if (!user?.email || !provider) {
      return Promise.resolve(null);
    }

    const federatedData: FederatedCredentialInit = {
      id: user.email,
      provider,
    };

    enhanceUserData(federatedData, user);

    return navigator.credentials
      .create({federated: federatedData})
      .then((credential) => storeCredentials(credential, provider, analytics));
  }

  return Promise.resolve(null);
}

export function getCredentials(socialAuth?: SocialAuth): Promise<Credential | null> {
  if (__CLIENT__) {
    return navigator.credentials.get({
      password: true,
      federated: {
        providers: Object.entries(CredentialProvider)
          .filter(
            ([name]) => !socialAuth || (socialAuth as Expandable<typeof socialAuth>)[name] === 'on',
          )
          .map(([, value]) => value),
      },
      mediation: 'required',
    });
  }

  return Promise.resolve(null);
}

export function preventSilentAccess(): Promise<void> {
  if (__CLIENT__) {
    return navigator.credentials.preventSilentAccess().catch(() => undefined);
  }

  return Promise.resolve();
}

import {routeAsync} from 'helpers/asyncConnect';
import {searchRouteAsyncWrapper} from 'routes/async/searchRouteAsyncWrapper';
import {RouteParams, SEARCH_POSTFIX} from 'utils/search/pattern';

import {anonymous} from './decorators';
import {DefaultParams, PoorRoute} from './types';

type Params = DefaultParams &
  RouteParams & {
    trendingLinkSlug: string;
  };

const NAME = 'trend';

export const TrendRoute: PoorRoute<typeof NAME, Params> = {
  name: NAME,
  path: `/best/:trendingLinkSlug${SEARCH_POSTFIX}`,
  async: searchRouteAsyncWrapper(
    routeAsync(
      /* #__LOADABLE__ */ () => import('containers/pages/TrendPage'),
      (module) => module.TrendPage,
    ),
  ),
  decorator: anonymous,
};

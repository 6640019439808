import {ecsError} from 'helpers/log/ECS/ecsError';
import {generatePath} from 'react-router-dom';
import {AsyncFunction, DefaultParams} from 'routes/types';
import {loadCategoryRedirect} from 'store/modules/categoryRedirect';
import {
  getCategoryRedirect,
  getCategoryRedirectRequest,
  isCategoryRedirectLoaded,
} from 'store/modules/categoryRedirect/selectors';
import {isApiError} from 'types/Error';
import {ExtendedErrorObject, unwrapErrorObject} from 'utils/error/unwrapErrorObject';
import {getParamsData, RouteParams} from 'utils/search/pattern';

type Params = DefaultParams & RouteParams;

export function searchRouteAsyncWrapper<T extends Params>(
  asyncFn: AsyncFunction<T>,
): AsyncFunction<T> {
  return async function searchRouteAsync(options) {
    const {match, location, store, client} = options;
    const asyncResultPromise = asyncFn(options);

    const {categoryId} = getParamsData(match.params);
    if (categoryId) {
      if (
        !isCategoryRedirectLoaded(store.getState()) ||
        getCategoryRedirectRequest(store.getState())?.categoryId !== categoryId
      ) {
        try {
          await store.dispatch(loadCategoryRedirect({categoryId}));
        } catch (ex) {
          const error = unwrapErrorObject(ex as ExtendedErrorObject);

          if (!isApiError(error) || error.status !== 404) {
            const logger = client.device.log.getLogger('SearchRouteAsyncWrapper');
            logger.error({error: ecsError(error)});
          }
        }
      }

      const toCategoryId = getCategoryRedirect(store.getState())?.toCategoryId;

      if (toCategoryId && toCategoryId !== categoryId) {
        const redirectUrl =
          generatePath(match.path, {
            ...match.params,
            scope: match.params.scope || undefined,
            categoryId: `c.${toCategoryId}`,
          }) + location.search;

        return {
          redirect: {link: redirectUrl},
        };
      }
    }

    return asyncResultPromise;
  };
}

import {ImageBundle} from 'types/Image';
import {Text} from 'types/Layout';

import {ContentListBaseItem} from './ContentListBaseItem';

export type TermsHeader = {
  title: Text;
  icon?: ImageBundle;
  subtitle?: Text;
};

export type ContentListTermsHeader = ContentListBaseItem<{termsHeader: TermsHeader}>;

export function isContentListTermsHeader(
  item: ContentListBaseItem,
): item is ContentListTermsHeader {
  return Boolean((item as ContentListTermsHeader).content.termsHeader);
}

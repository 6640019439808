import {IKeyValueStorage} from './types';

export class MapStorage<T> implements IKeyValueStorage<T> {
  private map: Map<string, T>;

  constructor() {
    this.map = new Map();
  }

  setItem(key: string, value: T): void {
    this.map.set(key, value);
  }

  removeItem(key: string): void {
    this.map.delete(key);
  }

  getItem(key: string): T | undefined {
    return this.map.get(key);
  }
}

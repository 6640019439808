import {useAnalytics} from 'hooks/useAnalytics';
import {useApiClient} from 'hooks/useApiClient';
import {useCurrency} from 'hooks/useCurrency';
import {useLanguage} from 'hooks/useLanguage';
import {useLocale} from 'hooks/useLocale';
import {usePopupManager} from 'hooks/usePopupManager';
import {useScope} from 'hooks/useScope';
import {useUserAdulthood} from 'hooks/useUserAdulthood';
import {useAppHistoryInfo} from 'providers/AppHistoryInfo';
import {useOpenToast} from 'providers/Toast';
import {useContext} from 'react';
import {useIntl} from 'react-intl';
import {ReactReduxContext} from 'react-redux';

// no return type for autotyping in this place
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useContextProps = () => ({
  analytics: useAnalytics(),
  client: useApiClient(),
  intl: useIntl(),
  currency: useCurrency(),
  scope: useScope(),
  language: useLanguage(),
  locale: useLocale(),
  store: useContext(ReactReduxContext).store,
  popupManager: usePopupManager(),
  openToast: useOpenToast(),
  userAdulthood: useUserAdulthood(),
  appHistoryInfo: useAppHistoryInfo(),
});

export type ContextProps = ReturnType<typeof useContextProps>;

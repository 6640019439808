import {routeAsync} from 'helpers/asyncConnect';

import {anonymous} from './decorators';
import {DefaultParams, PoorRoute} from './types';

const NAME = 'appViewTosSettings';

export const AppViewTosSettingsRoute: PoorRoute<typeof NAME, DefaultParams> = {
  name: NAME,
  path: '/app-view/tos-settings',
  async: routeAsync(
    /* #__LOADABLE__ */ () => import('containers/pages/AppView/TosSettings'),
    (module) => module.TosSettingsPage,
  ),
  decorator: anonymous,
};

import {ErrorEvent, EventHint} from '@sentry/types';
import {isApiError} from 'types/Error';

const stringifyIfNeeded = (value: unknown) => {
  if (typeof value === 'string') return value;

  try {
    return JSON.stringify(value);
  } catch {
    return 'Stringify error';
  }
};

export function transformApiErrorEvent(event: ErrorEvent, hint: EventHint): ErrorEvent {
  const [errorValue] = event.exception?.values || [];
  const error =
    (hint.originalException as {error?: unknown} | undefined)?.error || hint.originalException;

  if (errorValue && isApiError(error)) {
    const message = stringifyIfNeeded(error.message || error.userMessage);
    const type = 'ApiError';

    // create error for correct naming
    delete hint.syntheticException;
    hint.originalException = new Error(message);
    (hint.originalException as Error).name = type;

    errorValue.type = type;
    errorValue.value = message;

    event.tags = event.tags || {};
    event.tags.apiErrorStatus = error.status;
    event.tags.apiErrorType = error.type;

    event.fingerprint = [error.status?.toString() || '', 'api-error'];
  }

  return event;
}

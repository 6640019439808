export function wrap<A extends unknown[], R extends Promise<unknown>>(
  fn: (...args: A) => R,
): (...args: A) => R {
  let promise: R | undefined;
  return (...args: A): R => {
    if (!promise) {
      promise = fn(...args).finally(() => {
        promise = undefined;
      }) as R;
    }
    return promise;
  };
}

import PropTypes from 'prop-types';

export const DeliveryConfirmationModeType = {
  NONE: 'none',
  YES_NO: 'yesNo',
  YES: 'yes',
};

export const DeliveryConfirmationMode = PropTypes.oneOf(
  Object.values(DeliveryConfirmationModeType),
);

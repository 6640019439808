import {Request, Response} from 'express';
import {TransportHeaders, TransportQuery} from 'types/Transport';

import {Device} from '../Device';
import {Transport} from './index';

export class PureTransport extends Transport {
  public override device!: Device;

  constructor(device: Device, req: Request | undefined, res: Response | undefined) {
    super('', device, req, res);
  }

  override buildQuery(query: TransportQuery): TransportQuery {
    const copy = {...query};

    delete copy._;
    return copy;
  }

  override buildHeaders(headers: TransportHeaders | null | undefined): TransportHeaders {
    return headers ?? {};
  }
}

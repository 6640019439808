import {ApiError} from 'types/Error';

export enum HcaptchaErrorType {
  PAYLOAD = 'payload',
  ENVIRONMENT = 'environment',
  HCAPTCHA = 'hcaptcha',
  INTERNAL = 'internal',
}

export type HcaptchaError = {
  type: HcaptchaErrorType;
  message?: string;
};

export type ApiHcaptchaRequiredError = ApiError & {
  payload: {
    siteKey?: string;
    scriptUrl?: string;
  };
};

export type HcaptchaRequiredError = {
  siteKey: string;
  scriptUrl: string;
  requestId?: string;
};

export function hcaptchaError(type: HcaptchaErrorType, message?: string): HcaptchaError {
  return {type, message};
}

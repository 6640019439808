import {routeAsync} from 'helpers/asyncConnect';

import {loggedIn} from './decorators';
import {DefaultParams, PoorRoute} from './types';

type Params = DefaultParams & {
  orderGroupId: string;
  status: string;
};

const NAME = 'customsDuty';

export const CustomsDutyRoute: PoorRoute<typeof NAME, Params> = {
  name: NAME,
  path: '/duty/:orderGroupId/:status',
  async: routeAsync(
    /* #__LOADABLE__ */ () => import('containers/pages/CustomsDutyPage'),
    (module) => module.CustomsDutyPage,
  ),
  decorator: loggedIn,
};

import PropTypes from 'prop-types';
import Image from 'shapes/Image';

// eslint-disable-next-line import/no-default-export
export default PropTypes.shape({
  images: PropTypes.arrayOf(Image).isRequired,
  accessibilityLabel: PropTypes.string,
  showOverlay: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  tint: PropTypes.any,
  name: PropTypes.string,
});

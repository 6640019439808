import {ContentListScreenHeader as ContentListScreenHeaderType} from 'types/ContentListScreen';

import {ContentListBaseItem} from './ContentListBaseItem';

export type ScreenHeader = ContentListScreenHeaderType;

export type ContentListScreenHeader = ContentListBaseItem<{
  screenHeader: ScreenHeader;
}>;

export function isContentListScreenHeader(
  item: ContentListBaseItem,
): item is ContentListScreenHeader {
  return Boolean((item as ContentListScreenHeader).content.screenHeader);
}

import {type MonochromeIconNames} from 'components/UIKit/Icon/iconNames';

enum IconColor {
  PRIMARY = 'var(--color-primary100)',
  ACCENT = 'var(--color-accent100)',
  ON_ACCENT_HIGHT = 'var(--color-onAccentHigh)',
}

export const ImageBundleNameToIconName: Record<string, [MonochromeIconNames, IconColor]> = {
  'backup-filled': ['backup-filled-24', IconColor.PRIMARY],
  'card-filled': ['card-filled-24', IconColor.PRIMARY],
  'delivery-filled': ['delivery-filled-24', IconColor.PRIMARY],
  'document-filled': ['document-filled-24', IconColor.PRIMARY],
  'heart-filled': ['heart-filled-24', IconColor.PRIMARY],
  'help-filled': ['help-filled-24', IconColor.PRIMARY],
  'joom-round': ['joom-round-24', IconColor.PRIMARY],
  'lock-filled': ['lock-filled-24', IconColor.PRIMARY],
  'message-filled': ['message-filled-24', IconColor.PRIMARY],
  'notification-filled': ['notification-filled-24', IconColor.PRIMARY],
  'passport-filled': ['passport-filled-24', IconColor.PRIMARY],
  'percent-badge-filled': ['percent-badge-filled-24', IconColor.PRIMARY],
  'pin-filled': ['pin-filled-24', IconColor.PRIMARY],
  'star-filled': ['star-filled-24', IconColor.ACCENT],
  'translation-filled': ['translation-filled-24', IconColor.PRIMARY],
  'warning-filled': ['warning-filled-24', IconColor.PRIMARY],
  coupon: ['coupon-filled-24', IconColor.PRIMARY],
  info: ['info-filled-24', IconColor.PRIMARY],
  list: ['list-linear-24', IconColor.PRIMARY],
  logout: ['logout-linear-24', IconColor.PRIMARY],
  profilePageFavorites: ['heart-filled-24', IconColor.PRIMARY],
  profilePageOrders: ['document-filled-24', IconColor.PRIMARY],
  profilePagePoints: ['joom-24', IconColor.PRIMARY],
  profilePageSupport: ['help-filled-24', IconColor.PRIMARY],
  share: ['share-filled-24', IconColor.PRIMARY],
  shipping_plane: ['plane-filled-24', IconColor.ACCENT],
  shipping_truck: ['delivery-filled-24', IconColor.ACCENT],
  social: ['bar-feed-filled-24', IconColor.PRIMARY],
  socialPostCallToActionCart: ['bar-cart-linear-24', IconColor.ON_ACCENT_HIGHT],
};

import {TraceRequestHandler} from 'helpers/ApiClient/Transport/traceRequest/types';
import {hashCode} from 'utils/string';
import {getUrlPath} from 'utils/url';

export const traceRequestServerTiming: TraceRequestHandler = ({res, requestData, method}) => {
  const enabled = __SERVER__ && process.env.APP_ENV !== 'prod' && res;
  if (!enabled) {
    return undefined;
  }

  let description = `- ${method.toUpperCase()} ${getUrlPath(requestData.path)}`;
  if (requestData.meta.retryNumber) {
    description += ` retry: ${requestData.meta.retryNumber}`;
  }

  const key = `r-${hashCode(description).toString(36)}`;

  res.startTime(key, description);

  return () => {
    res.endTime(key);
  };
};

import {ecsError} from 'helpers/log/ECS/ecsError';

const RESET_REQUIRED_ERROR_TYPES = ['auth.reset_required'];

function ifAccessDenied(response, handler) {
  if (response.status === 401) {
    return handler();
  }
  throw response;
}

function ifAccessDeniedAndNoResetRequired(response, handler) {
  if (response.status === 401 && !RESET_REQUIRED_ERROR_TYPES.includes(response.error?.type)) {
    return handler();
  }
  throw response;
}

export default function tokenDegradation(device, handler, createPolicy = 'retry') {
  const logger = device.log.getLogger('Device/tokenDegradation');
  let currentAccessToken = device.getAccessToken();

  if (currentAccessToken) {
    return (
      device.isAccessTokenOutdated()
        ? // eslint-disable-next-line prefer-promise-reject-errors
          Promise.reject({status: 401})
        : Promise.resolve(null).then(handler)
    ) // has refresh token, but no access token
      .catch((response) =>
        ifAccessDeniedAndNoResetRequired(response, async () => {
          logger.log('Access token expired');
          if (global.document && global.document.execCommand) {
            // drop http authentication for ie>=6
            // https://en.wikipedia.org/wiki/Basic_access_authentication
            global.document.execCommand('ClearAuthenticationCache', 'false');
          }

          // access token might have already been updated
          if (currentAccessToken === device.getAccessToken()) {
            await device.refreshToken();
          }

          currentAccessToken = device.getAccessToken();

          if (!currentAccessToken) {
            throw response;
          }

          return handler();
        }),
      )
      .catch((response) =>
        ifAccessDenied(response, async () => {
          logger.warn('Refresh token expired');

          // access token might have already been updated
          if (currentAccessToken === device.getAccessToken()) {
            await device.create();
          }

          if (createPolicy === 'error') {
            throw response;
          }
          if (createPolicy === 'silent') {
            return undefined;
          }
          if (!device.getAccessToken()) {
            throw response;
          }

          return handler();
        }),
      );
  }

  const err = new Error('No access token');
  logger.warn({error: ecsError(err)});

  return device.create().then(() => {
    if (createPolicy === 'error') {
      return Promise.reject(err);
    }
    if (createPolicy === 'silent') {
      return undefined;
    }
    if (!device.getAccessToken()) {
      return Promise.reject(err);
    }

    return handler();
  });
}

import {discardableByUser} from 'helpers/discardable';
import {enhanceCurrency, enhanceLanguage} from 'store/utils/enhancers';

const LOAD = 'points/about/LOAD';
const LOAD_SUCCESS = 'points/about/LOAD_SUCCESS';
const LOAD_FAIL = 'points/about/LOAD_FAIL';

const initialState = {
  data: null,
  error: null,
  loaded: false,
  loading: false,
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        error: null,
        loaded: false,
        loading: true,
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        data: action.result,
        loaded: true,
        loading: false,
      };
    case LOAD_FAIL:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
}

export default discardableByUser(reducer);

export function isPointsAboutLoading(globalState) {
  return globalState.points.about.loading;
}

export function isPointsAboutLoaded(globalState) {
  const {loaded} = globalState.points.about;
  return loaded;
}

export function getPointsAbout(globalState) {
  return isPointsAboutLoaded(globalState) ? globalState.points.about.data : null;
}

export function getPointsAboutError(globalState) {
  return globalState.points.about.error || null;
}

export function loadPointsAbout() {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: (client) =>
      client.api
        .get('/points/about')
        .then(({currency, language, body: {payload}}) =>
          enhanceCurrency(enhanceLanguage(payload, language), currency),
        ),
  };
}

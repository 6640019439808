import {MutableRefObject, Ref} from 'react';

export function mergeRefs<T>(...refs: Array<Ref<T> | undefined>) {
  return (instance: T | null): void => {
    refs.forEach((ref) => {
      if (typeof ref === 'function') {
        ref(instance);
      } else if (ref && 'current' in ref) {
        (ref as MutableRefObject<T | null>).current = instance;
      }
    });
  };
}

import {addProofOfWorkSolutionHeader} from 'helpers/ApiClient/Transport/headers';
import {ProofOfWorkSolution} from 'helpers/ApiClient/Transport/ProofOfWork/types';
import {TransportOptions} from 'types/Transport';

export function optionsWithPowSolution(
  options: TransportOptions,
  powSolution: ProofOfWorkSolution | undefined,
): TransportOptions {
  if (powSolution) {
    const headers = options.headers || {};
    addProofOfWorkSolutionHeader(headers, powSolution);

    return {...options, headers};
  }

  return options;
}

import config, {LanguageConfig} from 'config';
import {memoizeLastShallowEqualEachArg} from 'utils/memoize';

export type LanguageConfigFilter = Partial<
  Pick<LanguageConfig, 'legalEntity' | 'scope' | 'rtl' | 'contextL10n'>
>;
export const filterLangConfig = memoizeLastShallowEqualEachArg(
  (filter: LanguageConfigFilter = {}): LanguageConfig[] =>
    config.languages.filter(
      (item) =>
        (!filter.legalEntity || !item.legalEntity || filter.legalEntity === item.legalEntity) &&
        (!filter.scope || !item.scope || filter.scope === item.scope) &&
        (filter.rtl === undefined || filter.rtl === Boolean(item.rtl)) &&
        (filter.contextL10n === undefined || filter.contextL10n === Boolean(item.contextL10n)),
    ),
);
export const getLangCodes = memoizeLastShallowEqualEachArg(
  (filter?: LanguageConfigFilter): string[] => filterLangConfig(filter).map(({code}) => code),
);

import {ContentListBaseItem, contentListBaseItem} from './ContentListBaseItem';

type VariantParameters = {
  hasDetailedSizeTables?: boolean;
};

export type ContentListVariantParameters = ContentListBaseItem<{
  variantParameters: VariantParameters;
}>;

export function contentListVariantParameters(
  id: string,
  variantParameters: VariantParameters,
): ContentListVariantParameters {
  return contentListBaseItem(id, {variantParameters});
}

export function isContentListVariantParameters(
  item: ContentListBaseItem,
): item is ContentListVariantParameters {
  return Boolean((item as ContentListVariantParameters).content.variantParameters);
}

import {TypedObject} from 'utils/object/typed';

export function shallowDiffers(left: unknown = null, right: unknown = null): boolean {
  if (!left || !right) {
    return left !== right;
  }

  if (left === right) {
    return false;
  }

  if (TypedObject.keys(left).some((index) => !(index in (right as {})))) {
    return true;
  }

  return TypedObject.keys(right).some((index) => left[index] !== right[index]);
}

const ARRAY_STRING = '[object Array]';
const OBJECT_STRING = '[object Object]';

export function isShallowDifferableObject(
  value: unknown,
): value is Record<string, unknown> | unknown[] | null {
  if (value === null) {
    return true;
  }
  const str = Object.prototype.toString.call(value);
  return str === ARRAY_STRING || str === OBJECT_STRING;
}

import {Log} from 'helpers/log';
import {ecsError} from 'helpers/log/ECS/ecsError';
import {Unsubscribe} from 'redux';
import {RootState} from 'store/rootReducer';
import {Expandable} from 'types/utility';
import {Store} from 'typesafe-actions';
import {TypedObject} from 'utils/object/typed';

type Operation<T> = (store: Store) => Promise<T>;

type StoreWithTransactions = Store & {
  originalStore: Store;
  transaction: <T>(operation: Operation<T>) => Promise<T>;
};

function createTransactionsResolver(
  onFreeze: () => void,
  onUnfreeze: () => void,
): <T>(transaction: Promise<T>) => Promise<T> {
  let resolving = 0;
  function handleResolve() {
    resolving -= 1;
    if (resolving === 0) {
      onUnfreeze();
    }
  }

  return <T>(promise: Promise<T>): Promise<T> => {
    if (!resolving) {
      onFreeze();
    }

    resolving += 1;
    return promise.finally(handleResolve).then((data) => data);
  };
}

export function isStoreWithTransactions(store: Store): store is StoreWithTransactions {
  return 'originalStore' in store;
}

export function addTransactions(store: Store, log: Log): StoreWithTransactions {
  let freezedState: RootState | undefined;
  let callAfterFreeze: Array<() => void> = [];
  const logger = log.getLogger('utils/redux');

  const addTransaction = createTransactionsResolver(
    () => {
      freezedState = store.getState();
    },
    () => {
      logger.debug('Resolve transaction freezing...');
      freezedState = undefined;
      try {
        callAfterFreeze.forEach((listener) => listener());
      } catch (ex) {
        logger.error({error: ecsError(ex)});
      }
      callAfterFreeze = [];
    },
  );

  return {
    ...store,
    originalStore: store,
    getState(): RootState {
      if (freezedState) {
        return freezedState;
      }
      return store.getState();
    },
    subscribe(listener: () => void): Unsubscribe {
      return store.subscribe(() => {
        if (!freezedState) {
          listener();
          return;
        }

        logger.debug('Ignore store update while transaction is going.');
        if (!callAfterFreeze.includes(listener)) {
          callAfterFreeze.push(listener);
        }
      });
    },
    transaction<T>(operation: Operation<T>): Promise<T> {
      return addTransaction(operation(store));
    },
  };
}

type StoreWithInitialState = Store & {
  initialState: RootState;
};

export function createStoreWithInitialState(store: Store): StoreWithInitialState {
  return {
    ...store,
    initialState: store.getState(),
  };
}

export function isStoreWithInitialState(store: Store): store is StoreWithInitialState {
  return 'initialState' in store;
}

export function getStoreInitialState(store: Store): RootState | undefined {
  return isStoreWithInitialState(store) ? store.initialState : undefined;
}

export function getCleanSsrStoreState(store: Store): RootState {
  const initialState = getStoreInitialState(store);
  const nextState: RootState = {...store.getState()};

  if (initialState) {
    TypedObject.keys(nextState).forEach((key) => {
      if (initialState[key] === nextState[key]) {
        delete nextState[key];
      }
    });
  }

  // this data is received from client when store created
  delete (nextState as Expandable<typeof nextState>).preferences;
  delete (nextState as Expandable<typeof nextState>).auth;

  return nextState;
}

import {BannersList, BannersListDesign} from './BannersList';
import {ContentListBaseItem, contentListBaseItem} from './ContentListBaseItem';

export type ContentListBannersList = ContentListBaseItem<{
  bannersList: BannersList;
}>;

export function contentListBannersList(
  id: string,
  bannersList: BannersList,
): ContentListBannersList {
  return contentListBaseItem(id, {bannersList});
}

export function isContentListBannersList(
  item: ContentListBaseItem,
): item is ContentListBannersList {
  return Boolean((item as ContentListBannersList).content.bannersList);
}

export function extractBannersList(item: ContentListBannersList): BannersList {
  return item.content.bannersList;
}

export function isContentListBannersListFreeForm(item: BannersList): boolean {
  return item.design === BannersListDesign.CARD_GRID;
}

export function makeOneItemBannersList(bannersList: BannersList): BannersList {
  return {
    ...bannersList,
    items: bannersList.items.slice(0, 1),
  };
}

import {ApiClient} from 'helpers/ApiClient';
import {ScopeConfig} from 'helpers/ApiClient/Scope/ScopeConfig';
import {allScopes} from 'helpers/ApiClient/Scope/utils';
import {getLangCodes} from 'helpers/language';
import {History, parsePath} from 'history';
import {generatePath, match as ReactRouterMatch, matchPath, useLocation} from 'react-router-dom';
import {AboutRoute} from 'routes/AboutRoute';
import {AppViewFaqArticleRoute} from 'routes/AppViewFaqArticleRoute';
import {AppViewIprRoute} from 'routes/AppViewIprRoute';
import {AppViewLegalRoute} from 'routes/AppViewLegalRoute';
import {AppViewPrivacyRoute} from 'routes/AppViewPrivacyRoute';
import {AppViewTermsRoute} from 'routes/AppViewTermsRoute';
import {AppViewTosSettingsRoute} from 'routes/AppViewTosSettingsRoute';
import {AppViewUserDataRoute} from 'routes/AppViewUserDataRoute';
import {AuthRoute} from 'routes/AuthRoute';
import {BestsPaginationItemRoute} from 'routes/BestsPaginationItemRoute';
import {BestsPaginationRoute} from 'routes/BestsPaginationRoute';
import {BestsRoute} from 'routes/BestsRoute';
import {BloggerTermsRoute} from 'routes/BloggerTermsRoute';
import {CartRoute} from 'routes/CartRoute';
import {CatalogRoute} from 'routes/CatalogRoute';
import {CategoryPaginationItemRoute} from 'routes/CategoryPaginationItemRoute';
import {CategoryPaginationRoute} from 'routes/CategoryPaginationRoute';
import {CheckoutSessionRoute} from 'routes/CheckoutSessionRoute';
import {CheckoutSuccessRoute} from 'routes/CheckoutSuccessRoute';
import {CollectionsDiscoveryRoute} from 'routes/CollectionsDiscoveryRoute';
import {CustomsDutyRoute} from 'routes/CustomsDutyRoute';
import {EntranceRoute} from 'routes/EntranceRoute';
import {FaqArticleRoute} from 'routes/FaqArticleRoute';
import {FaqRoute} from 'routes/FaqRoute';
import {FavoriteCollectionsRoute} from 'routes/FavoriteCollectionsRoute';
import {FavoriteLikedCollectionsRoute} from 'routes/FavoriteLikedCollectionsRoute';
import {FavoriteShopsRoute} from 'routes/FavoriteShopsRoute';
import {FavoritesRoute} from 'routes/FavoritesRoute';
import {IprProtectionRoute} from 'routes/IprProtectionRoute';
import {MainRoute} from 'routes/MainRoute';
import {NotFoundRoute} from 'routes/NotFoundRoute';
import {OrderRoute} from 'routes/OrderRoute';
import {OrdersRoute} from 'routes/OrdersRoute';
import {ParcelRoute} from 'routes/ParcelRoute';
import {ParcelsRoute} from 'routes/ParcelsRoute';
import {PointsRoute} from 'routes/PointsRoute';
import {PremiumInfoRoute} from 'routes/PremiumInfoRoute';
import {PremiumSeoListRoute} from 'routes/PremiumSeoListRoute';
import {PremiumSeoRoute} from 'routes/PremiumSeoRoute';
import {PrivacyRoute} from 'routes/PrivacyRoute';
import {ProductCollectionRoute} from 'routes/ProductCollectionsRoute';
import {ProductFeedbackRoute} from 'routes/ProductFeedbackRoute';
import {ProductFromProductGroupRoute} from 'routes/ProductFromProductGroupRoute';
import {ProductReviewFeedbackRoute} from 'routes/ProductReviewFeedbackRoute';
import {ProductRoute} from 'routes/ProductRoute';
import {ProfileSecurityRoute} from 'routes/ProfileSecurityRoute';
import {PromotionRoute} from 'routes/PromotionRoute';
import {QuizRoute} from 'routes/QuizRoute';
import {RewardWheelRoute} from 'routes/RewardWheelRoute';
import {SearchRoute} from 'routes/SearchRoute';
import {SharePopupRoute} from 'routes/SharePopup';
import {SocialFeedRoute} from 'routes/SocialFeedRoute';
import {SocialPostCommentFeedbackRoute} from 'routes/SocialPostCommentFeedbackRoute';
import {SocialPostFeedbackRoute} from 'routes/SocialPostFeedbackRoute';
import {SocialPostRoute} from 'routes/SocialPostRoute';
import {SocialUserRoute} from 'routes/SocialUserRoute';
import {StoreFeedbackRoute} from 'routes/StoreFeedbackRoute';
import {StoreRoute} from 'routes/StoreRoute';
import {TermsRoute} from 'routes/TermsRoute';
import {TrendRoute} from 'routes/TrendRoute';
import {AsyncFunction, DefaultParams, PoorRoute, Route, RouteParams} from 'routes/types';
import {VatRoute} from 'routes/VatRoute';
import {Store} from 'typesafe-actions';
import {createNetworkError} from 'utils/error/createNetworkError';
import {memoize} from 'utils/memoize';
import {createUrl, getUrlPath, QueryMap, trimTrailingSlash} from 'utils/url';

import {AppViewRefundRoute} from './AppViewRefundRoute';
import {AppViewRefundSuccessRoute} from './AppViewRefundSuccessRoute';
import {anonymous, routeAsyncLanguageRedirect, unauthorizedLolRedirect} from './decorators';
import {InviteRoute} from './InviteRoute';
import {LegalDocRoute} from './LegalDocRoute';
import {RefundRoute} from './RefundRoute';
import {RefundSuccessRoute} from './RefundSuccessRoute';
import {VatByParcelRoute as OrderVatRoute} from './VatByParcelRoute';

const LIST = [
  AboutRoute,
  AppViewFaqArticleRoute,
  AppViewIprRoute,
  AppViewLegalRoute,
  AppViewPrivacyRoute,
  AppViewRefundRoute,
  AppViewRefundSuccessRoute,
  AppViewTermsRoute,
  AppViewTosSettingsRoute,
  AppViewUserDataRoute,
  AuthRoute,
  BestsRoute,
  BestsPaginationRoute,
  BestsPaginationItemRoute,
  BloggerTermsRoute,
  CartRoute,
  CatalogRoute,
  CategoryPaginationItemRoute,
  CategoryPaginationRoute,
  CheckoutSessionRoute,
  CheckoutSuccessRoute,
  CollectionsDiscoveryRoute,
  CustomsDutyRoute,
  EntranceRoute,
  FaqArticleRoute,
  FaqRoute,
  FavoriteShopsRoute,
  FavoriteLikedCollectionsRoute,
  ProductCollectionRoute,
  FavoriteCollectionsRoute,
  FavoritesRoute,
  IprProtectionRoute,
  InviteRoute,
  LegalDocRoute,
  MainRoute,
  OrderVatRoute,
  OrderRoute,
  OrdersRoute,
  ParcelsRoute,
  ParcelRoute,
  PointsRoute,
  PremiumSeoRoute,
  PremiumSeoListRoute,
  PremiumInfoRoute,
  PrivacyRoute,
  ProductFeedbackRoute,
  ProductFromProductGroupRoute,
  ProductReviewFeedbackRoute,
  ProductRoute,
  ProfileSecurityRoute,
  PromotionRoute,
  RefundRoute,
  RefundSuccessRoute,
  RewardWheelRoute,
  QuizRoute,
  SharePopupRoute,
  SearchRoute,
  SocialFeedRoute,
  SocialPostCommentFeedbackRoute,
  SocialPostFeedbackRoute,
  SocialPostRoute,
  SocialUserRoute,
  StoreFeedbackRoute,
  StoreRoute,
  TermsRoute,
  TrendRoute,
  VatRoute,
  NotFoundRoute,
].filter((route): route is Exclude<typeof route, false | undefined> => Boolean(route));

const getLangPrefix = memoize(
  (scope: ScopeConfig) =>
    `/:lang(${getLangCodes({legalEntity: scope.legalEntity, scope: scope.topScope}).join('|')})`,
  ([scope]) => [scope.legalEntity, scope.topScope].join('-'),
);
const getInContextL10nLangPrefix = memoize(
  (scope: ScopeConfig) =>
    `/:lang(${getLangCodes({
      contextL10n: true,
      legalEntity: scope.legalEntity,
      scope: scope.topScope,
    }).join('|')})`,
  ([scope]) => [scope.legalEntity, scope.topScope].join('-'),
);

function getScopePrefix(
  scope: ScopeConfig,
  route: RoutesUnion,
): {
  hasNonPrefixedScope: boolean;
  scopePrefix: string;
} {
  const routeScopes = route.scope || allScopes;
  if (!routeScopes?.length) {
    throw new Error(
      `Route "${route.name}" has no scopes to render. Please provide scope property for given route`,
    );
  }

  const prefixes = routeScopes
    .map(
      (prefixScope) =>
        scope.availablePrefixScopes?.find((prefixConfig) => prefixConfig.scope === prefixScope)
          ?.pathPrefix,
    )
    .filter(Boolean);
  const hasNonPrefixedScope = routeScopes.includes(scope.domainScope);

  let scopePrefix = '';

  if (prefixes.length) {
    const prefix = `/:scope(${prefixes.join('|')})`;
    scopePrefix = hasNonPrefixedScope ? `${prefix}?` : prefix;
  }

  return {hasNonPrefixedScope, scopePrefix};
}

const getRoutes = memoize(
  (scope: ScopeConfig) => {
    const routes: Array<Required<PoorRoute>> = [];

    LIST.forEach((route) => {
      const {
        exact = true,
        sensitive = true,
        strict = true,
        decorator = anonymous,
        scrollBehaviour = 'scroll-to-top',
      } = route;

      const {hasNonPrefixedScope, scopePrefix} = getScopePrefix(scope, route);

      // route unavailable for current scope config
      if (!hasNonPrefixedScope && !scopePrefix) {
        return;
      }

      routes.push({
        ...route,
        scope: route.scope || allScopes,
        name: `lol/${route.name}`,
        async: route.async || null,
        exact,
        sensitive,
        strict,
        decorator: unauthorizedLolRedirect,
        scrollBehaviour,
        path: trimTrailingSlash(`${scopePrefix}${getInContextL10nLangPrefix(scope)}${route.path}`),
      } as Route<string, RouteParams<typeof route>>);

      routes.push({
        ...route,
        scope: route.scope || allScopes,
        async: route.async || null,
        exact,
        sensitive,
        strict,
        decorator,
        scrollBehaviour,
        path: trimTrailingSlash(`${scopePrefix}${getLangPrefix(scope)}${route.path}`),
      } as Route<string, RouteParams<typeof route>>);

      routes.push({
        ...route,
        name: `lang-redirect/${route.name}`,
        scope: route.scope || allScopes,
        path: trimTrailingSlash(`${scopePrefix}${route.path}`),
        async: routeAsyncLanguageRedirect,
        exact,
        sensitive,
        strict,
        component: null,
        scrollBehaviour,
        decorator: anonymous,
      });
    });

    return routes;
  },
  ([scope]) => [scope.domainConfigId, scope.prefixScope].join('-'),
);

function convert<T extends DefaultParams>(
  client: ApiClient,
  route: Required<PoorRoute<string, T>>,
): Route<string, T> {
  return {
    ...route,
    render: route.decorator<T>(client, route),
  };
}

export const create = (client: ApiClient): Array<Route> => {
  return getRoutes(client.scope).map((route) => convert(client, route));
};

export const matchRoute = <T extends DefaultParams>(
  scope: ScopeConfig,
  url: string,
):
  | {route: Route<string, T>; result: ReactRouterMatch<T>}
  | {route: undefined; result: undefined} => {
  let foundRoute: Route<string, T> | undefined;
  let foundResult: ReactRouterMatch<T> | undefined;
  const urlPath = getUrlPath(url) || '';
  getRoutes(scope).some((route) => {
    const {strict, exact, sensitive, path} = route;
    const result = matchPath<T>(urlPath, {
      strict,
      exact,
      sensitive,
      path,
    });
    if (result) {
      foundRoute = route as Route<string, T>;
      foundResult = result;
      return true;
    }
    return false;
  });

  if (foundRoute && foundResult) {
    return {route: foundRoute, result: foundResult};
  }

  return {route: undefined, result: undefined};
};

export async function loadRoutesInitialData({
  store,
  delayedStore = store,
  client,
  history,
  url,
  skipDataLoading,
  inTransaction,
  splitTasks,
  executeDelayed,
}: {
  store: Store;
  delayedStore?: Store;
  client: ApiClient;
  history?: History;
  url: string;
  skipDataLoading?: boolean;
  inTransaction?: boolean;
  splitTasks?: boolean;
  executeDelayed: boolean;
}): Promise<{
  route: Route;
  props: Awaited<ReturnType<AsyncFunction>> | Record<string, never>;
}> {
  const availablePrefixScopes = client.device.getDeviceVar('webScopes') || [];

  if (client.scope.prefixScope && !availablePrefixScopes.includes(client.scope.prefixScope)) {
    throw createNetworkError('scope/notfound', 404, 'Not Found');
  }

  const {route, result} = matchRoute(client.scope, url);
  if (route && result) {
    const location = parsePath(url);

    const props = route.async
      ? await route.async({
          client,
          history,
          store,
          delayedStore,
          match: result,
          route,
          location,
          initial: true,
          skipDataLoading,
          inTransaction,
          splitTasks,
          executeDelayed,
        })
      : {};
    return {route, props};
  }

  throw createNetworkError('notfound', 404, 'Not Found');
}

export type RoutesUnion = (typeof LIST)[number];
export type RouteNamesUnion = RoutesUnion['name'];

export type PickRouteByName<N extends RouteNamesUnion> = Extract<RoutesUnion, {name: N}>;
export type PickParamsByRouteName<N extends RouteNamesUnion> = RouteParams<PickRouteByName<N>>;

const routesMap = LIST.reduce(
  (map, route) => {
    map[route.name] = route;
    map[`lang-redirect/${route.name}`] = route;
    map[`lol/${route.name}`] = route;
    return map;
  },
  {} as Record<`${'' | 'lang-redirect/' | 'lol/'}${RouteNamesUnion}`, RoutesUnion>,
);

export function getBareUrl<N extends RouteNamesUnion>(
  name: N,
  params: Omit<PickParamsByRouteName<N>, 'lang' | 'scope'>,
  query?: QueryMap,
): string {
  const route = routesMap[name];

  if (!route) {
    throw new Error('Route does not exist');
  }

  return createUrl(generatePath(route.path, params as PickParamsByRouteName<N>), query);
}

export function getUrl<N extends RouteNamesUnion>(
  name: N,
  params: Omit<PickParamsByRouteName<N>, 'scope'> & {scope: ScopeConfig | string | undefined},
  query?: QueryMap,
  location?: ReturnType<typeof useLocation>,
): string {
  const route = routesMap[name];
  const search = location?.search || '';

  if (!route) {
    throw new Error(`Route "${name}" does not exist`);
  }

  const scopePrefix = params.scope instanceof ScopeConfig ? params.scope.pathPrefix : params.scope;
  const prefix = [scopePrefix, params.lang].filter(Boolean).join('/');

  let pathWithParams = `${prefix ? `/${prefix}` : ''}${getBareUrl(
    name,
    // getBareUrl don't use scope, but has type conflicts
    params as Omit<PickParamsByRouteName<N>, 'lang' | 'scope'>,
  )}`;

  if (pathWithParams.length > 1 && pathWithParams.endsWith('/')) {
    pathWithParams = pathWithParams.slice(0, -1);
  }

  return createUrl(`${pathWithParams}${search}`, query);
}

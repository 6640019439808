import {ApiClient} from 'helpers/ApiClient';
import {ecsError} from 'helpers/log/ECS/ecsError';
import {History, Location} from 'history';
import {RenderingType} from 'types/Rendering';
import {hashCode} from 'utils/string';
import {createUrl, getQueryData, getUrlHostname} from 'utils/url';
import {getUtmsByUrl} from 'utils/url/tracking';

const isExternalNavigate = (client: ApiClient) => {
  const referrer = client.getReferrer();
  return !referrer || getUrlHostname(referrer) !== getUrlHostname(client.getOrigin());
};

const needToActivateLinkContext = (
  client: ApiClient,
  restQuery: Record<string, string | string[]>,
) => {
  if (!isExternalNavigate(client)) {
    return false;
  }

  const renderingConfig = client.device.getRenderingConfig();
  switch (renderingConfig?.option) {
    case RenderingType.BROWSER:
      // for client-side rendering need to activate only from browser
      return __CLIENT__;
    case RenderingType.USER:
      // for server-side rendering need to activate only from nodejs
      return __SERVER__;
    case RenderingType.CRAWLER:
      // if utm_ticket is passed to crawler (WEB-3603)
      return Boolean(restQuery.utm_ticket) && __SERVER__;
    default:
      // skip activation for bots and special rendering cases
      return false;
  }
};

const buildLinkContext = (client: ApiClient) => {
  const url = `${client.getOrigin()}${client.getOriginalUrl()}`;
  return {
    type: 'link',
    value: [{params: getUtmsByUrl(url), url}],
  };
};

export function getContextRedirectLink({location}: {location: Location}): string | undefined {
  const {context, contextSeed, ...restQuery} = getQueryData(location.search);

  if (context && contextSeed) {
    return createUrl(location.pathname, restQuery);
  }

  return undefined;
}

type Options = {
  client: ApiClient;
  location: Location;
  history?: History;
  initial: boolean;
};

export function getContextActivationInfo({
  client,
  history,
  location: serverLocation,
  initial,
}: Options): {
  payload?: Record<string, unknown>;
  redirectLink?: string;
} {
  const location = history?.location || serverLocation;

  const state = location.state as Record<string, unknown> | undefined;
  if (state && 'contextActivationPayload' in state) {
    return {payload: state.contextActivationPayload as Record<string, unknown>};
  }

  const {context, contextSeed, ...restQuery} = getQueryData(location.search);
  const deviceId = client.device.getDeviceId();

  if (typeof context === 'string' && contextSeed === hashCode(deviceId).toString(32)) {
    return {
      payload: {contexts: [JSON.parse(context)]},
      redirectLink: createUrl(location.pathname, restQuery),
    };
  }

  if (initial && needToActivateLinkContext(client, restQuery)) {
    return {
      payload: {contexts: [buildLinkContext(client)]},
    };
  }

  return {};
}

export async function contextActivate(options: Options): Promise<string> {
  const logger = options.client.device.log.getLogger('routes/contextActivate');
  const {payload, redirectLink} = getContextActivationInfo(options);

  if (payload) {
    try {
      await options.client.api.post('/context/activate', {body: payload});
    } catch (ex) {
      logger.error({
        error: ecsError(ex),
        message: 'Can not activate context',
      });
    }
  }

  return redirectLink || '';
}

import {isApiError} from 'types/Error';

import {ExtendedErrorObject, unwrapErrorObject} from './unwrapErrorObject';

const LOGIX_PREFIX = 'logic.';

export function getErrorType(error: ExtendedErrorObject): string {
  if (error) {
    const errorObject = unwrapErrorObject(error);
    if (isApiError(errorObject)) {
      const {type = '', status} = errorObject;

      if (status === 429) {
        return 'api.too_many_requests';
      }

      if (type.indexOf(LOGIX_PREFIX) === 0) {
        return type.substr(LOGIX_PREFIX.length);
      }
      return type;
    }
  }
  return '';
}

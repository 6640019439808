import {
  CredentialProviderToAuthProvider,
  getCredentials,
  isCredentialsAvailable,
  isFederatedCredential,
  isPasswordCredential,
  preventSilentAccess,
  storeFederatedCredentials,
  storePasswordCredentials,
} from 'helpers/credentials';
import {discardUserRelatedData} from 'store/modules/discard';
import {AuthProvider} from 'types/AuthProvider';
import auth from 'utils/auth';
import {createApiError} from 'helpers/ApiClient/Transport/Response';
import {getUser} from './getUser';

const SET_USER = 'auth/SET_USER';
const UPDATE_USER = 'auth/UPDATE_USER';

const LOAD = 'auth/LOAD';
export const LOAD_SUCCESS = 'auth/LOAD_SUCCESS';
const LOAD_FAIL = 'auth/LOAD_FAIL';

const RECOVER = 'auth/RECOVER';
const RECOVER_SUCCESS = 'auth/RECOVER_SUCCESS';
const RECOVER_FAIL = 'auth/RECOVER_FAIL';

const EMAIL_CONFIRMATION = 'auth/EMAIL_CONFIRMATION';
const EMAIL_CONFIRMATION_SUCCESS = 'auth/EMAIL_CONFIRMATION_SUCCESS';
const EMAIL_CONFIRMATION_FAIL = 'auth/EMAIL_CONFIRMATION_FAIL';

const NEW_PASSWORD = 'auth/NEW_PASSWORD';
const NEW_PASSWORD_SUCCESS = 'auth/NEW_PASSWORD_SUCCESS';
const NEW_PASSWORD_FAIL = 'auth/NEW_PASSWORD_FAIL';

const SIGNIN = 'auth/SIGNIN';
const SIGNIN_SUCCESS = 'auth/SIGNIN_SUCCESS';
const SIGNIN_FAIL = 'auth/SIGNIN_FAIL';
const SMARTLOCK_SIGNIN = 'auth/SMARTLOCK_SIGNIN';

const SIGNUP = 'auth/SIGNUP';
const SIGNUP_SUCCESS = 'auth/SIGNUP_SUCCESS';
const SIGNUP_FAIL = 'auth/SIGNUP_FAIL';

const SIGNOUT = 'auth/SIGNOUT';
export const SIGNOUT_SUCCESS = 'auth/SIGNOUT_SUCCESS';
const SIGNOUT_FAIL = 'auth/SIGNOUT_FAIL';

const EMAIL_CONFIRMATION_SEND = 'auth/EMAIL_CONFIRMATION_SEND';
const EMAIL_CONFIRMATION_SEND_SUCCESS = 'auth/EMAIL_CONFIRMATION_SEND_SUCCESS';
const EMAIL_CONFIRMATION_SEND_FAIL = 'auth/EMAIL_CONFIRMATION_SEND_FAIL';

const initialState = {
  user: null,
  error: null,
  loading: false,
  signinError: null,
  signinLoading: false,
  signoutError: null,
  signoutLoading: false,
  emailConfirmationSent: 0,
  emailConfirmationLoading: false,
  emailConfirmationError: null,
  emailConfirmed: false,
  emailConfirmedLoading: false,
  emailConfirmedError: null,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case LOAD_FAIL:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case LOAD_SUCCESS: {
      return {
        ...state,
        error: null,
        loading: false,
      };
    }
    case SET_USER:
    case UPDATE_USER:
      return {
        ...state,
        user: action.user,
      };
    case SIGNOUT:
      return {
        ...state,
        signoutError: null,
        signoutLoading: true,
      };
    case SIGNOUT_FAIL:
      return {
        ...state,
        signoutError: action.error,
        signoutLoading: false,
      };
    case SIGNOUT_SUCCESS:
      return {
        ...state,
        signoutLoading: false,
      };
    case SIGNIN:
      return {
        ...state,
        signinError: null,
        signinLoading: true,
      };
    case SIGNIN_FAIL:
      return {
        ...state,
        signinError: action.error,
        signinLoading: false,
      };
    case SIGNIN_SUCCESS:
      return {
        ...state,
        signinLoading: false,
      };
    case EMAIL_CONFIRMATION_SEND:
      return {
        ...state,
        emailConfirmationLoading: true,
        emailConfirmationError: null,
      };
    case EMAIL_CONFIRMATION_SEND_FAIL:
      return {
        ...state,
        emailConfirmationLoading: false,
        emailConfirmationError: action.error,
      };
    case EMAIL_CONFIRMATION_SEND_SUCCESS:
      return {
        ...state,
        emailConfirmationLoading: false,
        emailConfirmationSent: state.emailConfirmationSent + 1,
      };
    case EMAIL_CONFIRMATION:
      return {
        ...state,
        emailConfirmedLoading: true,
        emailConfirmedError: null,
      };
    case EMAIL_CONFIRMATION_FAIL:
      return {
        ...state,
        emailConfirmedLoading: false,
        emailConfirmedError: action.error,
      };
    case EMAIL_CONFIRMATION_SUCCESS:
      return {
        ...state,
        emailConfirmedLoading: false,
        emailConfirmed: true,
      };
    default:
      return state;
  }
}

export {getUser} from './getUser';

export {isNonEphemeral} from './isNonEphemeral';

export function isUnauthorized(globalState) {
  const user = getUser(globalState);
  return !user || user.anonymous;
}

export function isUserLoaded(globalState) {
  const {user} = globalState.auth;
  return !!user;
}

export function getAuthError(globalState) {
  return globalState.auth.error;
}

export function isSignining(globalState) {
  return globalState.auth.signinLoading;
}

export function getSigninError(globalState) {
  return globalState.auth.signinError;
}

export function getEmailConfirmationSent(globalState) {
  return globalState.auth.emailConfirmationSent;
}

export function getEmailConfirmationLoading(globalState) {
  return globalState.auth.emailConfirmationLoading;
}

export function getEmailConfirmationError(globalState) {
  return globalState.auth.emailConfirmationError;
}

export function getEmailConfirmed(globalState) {
  return globalState.auth.emailConfirmed;
}

export function getEmailConfirmedLoading(globalState) {
  return globalState.auth.emailConfirmedLoading;
}

export function getEmailConfirmedError(globalState) {
  return globalState.auth.emailConfirmedError;
}

export function setUser(user) {
  return {
    type: SET_USER,
    user,
  };
}

export function changeUser(user) {
  return {
    type: UPDATE_USER,
    user,
    result: (client, dispatch) => {
      dispatch(discardUserRelatedData(user));
      return Promise.resolve(null);
    },
  };
}

export function updateUser(user) {
  return {
    type: UPDATE_USER,
    user,
  };
}

export function loadUser() {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: (client) => client.device.loadUser(),
  };
}

function handleSignin(client, response) {
  if (response.body && response.body.device) {
    client.device.hydrate(response.body.device);
  }
  const user = client.device.getUser();

  if (response.body?.popup) {
    return {
      ...user,
      popup: response.body.popup,
    };
  }

  return user;
}

// when user decline signin it may have some delay, so several signins can occur simultaneously
let renderingIdActiveCount = 0;
async function withAuthCookies(device, migrationMethod, callback) {
  if (renderingIdActiveCount === 0) {
    device.setCookieRenderingId(device.getRenderingConfig()?.id);
    if (migrationMethod) {
      device.cookiesRegistry.authMigrationMethod.store(migrationMethod);
    } else {
      device.cookiesRegistry.authMigrationMethod.remove();
    }
  } else if ((migrationMethod || '') !== device.cookiesRegistry.authMigrationMethod.restore()) {
    throw createApiError('auth.cancel', 400);
  }

  renderingIdActiveCount += 1;

  try {
    return await callback();
  } finally {
    renderingIdActiveCount -= 1;
    if (renderingIdActiveCount === 0) {
      device.setCookieRenderingId('');
      device.cookiesRegistry.authMigrationMethod.remove();
    }
  }
}

export function socialSignin(type, params, skipStoreCredentials = false, migrationMethod) {
  return {
    types: [SIGNIN, SIGNIN_SUCCESS, SIGNIN_FAIL],
    promise: async (client) => {
      client.riskified.go('sign_in/init');
      try {
        const response = await withAuthCookies(client.device, migrationMethod, () =>
          auth(type, client, params),
        );
        const user = handleSignin(client, response);

        if (!skipStoreCredentials && user && isCredentialsAvailable(client.device)) {
          try {
            await storeFederatedCredentials(type, user);
          } catch (ex) {
            // do nothing
          }
        }

        client.riskified.go('sign_in/success');
        return user;
      } catch (error) {
        handleSignin(client, error);
        if (error.type === 'auth.cancel') {
          client.riskified.go('sign_in/cancel');
        } else {
          client.riskified.go('sign_in/fail');
        }
        throw error;
      }
    },
    consentPromise: (client) => {
      return withAuthCookies(client.device, migrationMethod, () =>
        client.webApi.post('/auth/continue').then((response) => handleSignin(client, response)),
      );
    },
  };
}

export function emailSignin(body, skipStoreCredentials = false) {
  return {
    types: [SIGNIN, SIGNIN_SUCCESS, SIGNIN_FAIL],
    promise: async (client) => {
      client.riskified.go('sign_in/init');
      try {
        const data = await client.device.auth.emailSignIn(body);
        if (!skipStoreCredentials && isCredentialsAvailable(client.device)) {
          try {
            await storePasswordCredentials(body, client.device.getUser(), client.analytics);
          } catch (ex) {
            // do nothing
          }
        }
        client.riskified.go('sign_in/success');
        return data;
      } catch (error) {
        client.riskified.go('sign_in/fail');
        throw error;
      }
    },
  };
}

const SMARTLOCK_OPEN_INDICATION_TIME = 500;

export function smartLockSignin(source) {
  return ({dispatch}, client) => {
    dispatch({type: SMARTLOCK_SIGNIN});

    if (isCredentialsAvailable(client.device)) {
      const startTime = Date.now();
      let smartlockOpenEventSent = false;
      const sendSmartlockOpenEvent = () => {
        if (!smartlockOpenEventSent) {
          smartlockOpenEventSent = true;
          client.analytics.sendEvent({
            type: 'smartlockOpen',
            payload: {
              source,
            },
          });
        }
      };
      const openTimeoutId = window.setTimeout(
        sendSmartlockOpenEvent,
        SMARTLOCK_OPEN_INDICATION_TIME,
      );

      return getCredentials(client.device.getDeviceVar('socialAuth'))
        .catch((error) => {
          window.clearTimeout(openTimeoutId);

          client.analytics.sendEvent({
            type: 'smartlockRequest',
            payload: {
              result: 'error',
              source,
            },
          });
        })
        .then((credential) => {
          // we use time to determine whether the dialog was shown
          const sinceOpenMs = Date.now() - startTime;
          window.clearTimeout(openTimeoutId);
          if (sinceOpenMs > SMARTLOCK_OPEN_INDICATION_TIME) {
            if (credential === null) {
              client.analytics.sendEvent({
                type: 'smartlockRequest',
                payload: {
                  resolvedByDialog: true,
                  result: 'cancelled',
                  source,
                },
              });
            }

            sendSmartlockOpenEvent();

            client.analytics.sendEvent({
              type: 'smartlockClose',
              payload: {
                sinceOpenMs,
                source,
              },
            });
          }

          if (isPasswordCredential(credential)) {
            client.analytics.sendEvent({
              type: 'smartlockRequest',
              payload: {
                resolvedByDialog: true,
                result: 'success',
                source,
                type: 'password',
              },
            });

            return dispatch(
              emailSignin(
                {
                  email: credential.id,
                  password: credential.password,
                },
                true,
              ),
            ).then(() => true);
          }

          if (isFederatedCredential(credential)) {
            const type = CredentialProviderToAuthProvider[credential.provider];

            client.analytics.sendEvent({
              type: 'smartlockRequest',
              payload: {
                resolvedByDialog: true,
                result: 'success',
                source,
                type: credential.provider,
              },
            });

            if (type) {
              let params;

              if (type === AuthProvider.GOOGLE) {
                params = {email: credential.id};
              }

              return dispatch(socialSignin(type, params, true)).then(() => true);
            }
          }

          return Promise.resolve(false);
        });
    }

    return Promise.resolve(false);
  };
}

export function signup(body) {
  return {
    types: [SIGNUP, SIGNUP_SUCCESS, SIGNUP_FAIL],
    promise: async (client) => {
      client.riskified.go('sign_up/init');
      try {
        const data = await client.device.auth.emailSignUp(body);
        if (data.type === 'confirmed' && isCredentialsAvailable(client.device)) {
          try {
            await storePasswordCredentials(body, client.device.getUser(), client.analytics);
          } catch (ex) {
            // do nothing
          }
        }
        client.riskified.go('sign_up/success');
        return data;
      } catch (error) {
        client.riskified.go('sign_up/fail');
        throw error;
      }
    },
  };
}

export function signupOnCheckout(body) {
  return {
    types: [SIGNUP, SIGNUP_SUCCESS, SIGNUP_FAIL],
    promise: (client) => client.device.auth.emailCheckoutSignUp(body),
  };
}

export function recoverPassword(body) {
  return {
    types: [RECOVER, RECOVER_SUCCESS, RECOVER_FAIL],
    promise: (client) => client.api.post('/auth/email/recoverPassword', {body}),
  };
}

export function confirmEmail(body) {
  return {
    types: [EMAIL_CONFIRMATION, EMAIL_CONFIRMATION_SUCCESS, EMAIL_CONFIRMATION_FAIL],
    promise: (client) => client.api.post('/auth/email/confirm', {body}),
  };
}

export function setPasswordByToken(body) {
  return {
    types: [NEW_PASSWORD, NEW_PASSWORD_SUCCESS, NEW_PASSWORD_FAIL],
    promise: (client) => client.device.auth.setNewPassword(body),
  };
}

export function signout() {
  return {
    types: [SIGNOUT, SIGNOUT_SUCCESS, SIGNOUT_FAIL],
    promise: async (client) => {
      client.riskified.go('sign_out/init');
      try {
        const data = await client.device.auth.signout();
        if (isCredentialsAvailable(client.device)) {
          preventSilentAccess();
        }
        client.riskified.go('sign_out/success');
        return data;
      } catch (error) {
        client.riskified.go('sign_out/fail');
        throw error;
      }
    },
  };
}

export function emailConfirmationSend(body) {
  return {
    types: [EMAIL_CONFIRMATION_SEND, EMAIL_CONFIRMATION_SEND_SUCCESS, EMAIL_CONFIRMATION_SEND_FAIL],
    promise: (client) => client.api.post('/auth/email/sendConfirmationEmail', body),
  };
}

type TypedObjectKeys = <const Obj extends object>(object: Obj) => Exclude<keyof Obj, symbol>[];

type TypedObjectValues = <const Obj extends object>(obj: Obj) => Obj[Exclude<keyof Obj, symbol>][];

type NeverToArray<T> = T extends never[] | undefined[] ? [] : T;

type TypedEntries = <const Obj extends object>(
  obj: Obj,
) => NeverToArray<
  Array<Exclude<{[K in keyof Obj]: [K, Obj[K]]}[Extract<keyof Obj, string | number>], undefined>>
>;

export type ArrayElement<A> = A extends readonly (infer T)[] ? T : never;

type TypedFromEntries = <const Arr extends Array<[PropertyKey, unknown]>>(
  arr: Arr,
) => Arr extends [infer Key extends PropertyKey, infer Value][]
  ? {
      [K in Key]: Extract<ArrayElement<Arr>, [K, unknown]>[1] extends never
        ? Value
        : Extract<ArrayElement<Arr>, [K, unknown]>[1];
    }
  : {[key in string]: unknown};

export type TypedObjectType = {
  keys: TypedObjectKeys;
  getOwnPropertyNames: TypedObjectKeys;
  values: TypedObjectValues;
  entries: TypedEntries;
  fromEntries: TypedFromEntries;
};

export const TypedObject = Object as TypedObjectType;

import {ProductLite, ProductLiteRaw} from '../ProductLite';
import {ContentListBaseItem, contentListBaseItem} from './ContentListBaseItem';

export type ContentListProductRaw = ContentListBaseItem<{product: ProductLiteRaw}>;
export type ContentListProduct = ContentListBaseItem<{product: ProductLite}>;

export function contentListProduct(product: ProductLite): ContentListProduct {
  return contentListBaseItem(product.id, {product}, product.pdid || '');
}

export function isContentListProduct(item: ContentListBaseItem): item is ContentListProduct {
  return Boolean((item as ContentListProduct).content.product);
}

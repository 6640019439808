import {routeAsync} from 'helpers/asyncConnect';

import {hasCheckoutAccess} from './decorators';
import {DefaultParams, PoorRoute} from './types';

type Params = DefaultParams & {
  parcelId: string;
  action?: string;
};

const NAME = 'parcel';

export const ParcelRoute: PoorRoute<typeof NAME, Params> = {
  name: NAME,
  path: '/parcels/:parcelId/:action?',
  async: routeAsync(
    /* #__LOADABLE__ */ () => import('containers/pages/ParcelPage'),
    (module) => module.ParcelPage,
  ),
  decorator: hasCheckoutAccess,
};

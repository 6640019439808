import {ProductLite} from '../ProductLite';
import {ContentListBaseItem, contentListBaseItem} from './ContentListBaseItem';

type ProductLiteFromCollections = {
  productLite: ProductLite;
  collectionNames: string[];
};

export type ContentListProductFromCollections = ContentListBaseItem<{
  productLiteFromCollections: ProductLiteFromCollections;
}>;

export function contentListProductFromCollections(
  id: string,
  productLiteFromCollections: ProductLiteFromCollections,
): ContentListProductFromCollections {
  return contentListBaseItem(
    id,
    {productLiteFromCollections},
    productLiteFromCollections.productLite.pdid || '',
  );
}

export function isContentListProductFromCollections(
  item: ContentListBaseItem,
): item is ContentListProductFromCollections {
  return Boolean((item as ContentListProductFromCollections).content.productLiteFromCollections);
}

import {Parcel, ParcelRaw} from 'types/Parcel';

import {ContentListBaseItem} from './ContentListBaseItem';

export type ContentListParcelRaw = ContentListBaseItem<{parcel: ParcelRaw}>;
export type ContentListParcel = ContentListBaseItem<{parcel: Parcel}>;

export function isContentListParcel(item: ContentListBaseItem): item is ContentListParcel {
  return Boolean((item as ContentListParcel).content.parcel);
}

import {CookieType} from 'types/CookiesSettings';

import {CookieValue} from '../Device/CookieValue';
import {stringDecoder, stringEncoder} from './helpers';

const COOKIE_NAME = 'timezoneName';

// stores timezone in IANA format (`Europe/Moscow`)
export const TimezoneNameCookie: CookieValue<string | undefined> = {
  type: CookieType.FUNCTIONAL,
  key: COOKIE_NAME,
  decode: stringDecoder,
  encode: stringEncoder,
};

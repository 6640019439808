import {routeAsync} from 'helpers/asyncConnect';

import {loggedIn} from './decorators';
import {DefaultParams, PoorRoute} from './types';

const NAME = 'premium-info';

export const PremiumInfoRoute: PoorRoute<typeof NAME, DefaultParams> = {
  name: NAME,
  path: '/premium-info',
  async: routeAsync(
    /* #__LOADABLE__ */ () => import('containers/pages/PremiumInfoPage'),
    (module) => module.PremiumInfoPage,
  ),
  decorator: loggedIn,
};

import {routeAsync} from 'helpers/asyncConnect';

import {hasCheckoutAccess} from './decorators';
import {DefaultParams, PoorRoute} from './types';

const NAME = 'parcels';

export const ParcelsRoute: PoorRoute<typeof NAME, DefaultParams> = {
  name: NAME,
  path: '/parcels',
  async: routeAsync(
    /* #__LOADABLE__ */ () => import('containers/pages/ParcelsPage'),
    (module) => module.ParcelsPage,
  ),
  decorator: hasCheckoutAccess,
  scrollBehaviour: 'scroll-restoration',
};

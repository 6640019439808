import EventEmitter from 'component-emitter';
import {Request} from 'express';

import {EventArgs, LoggerEvent} from './Event';
import {Level, Verbosity} from './Level';

function generateRandomId(): string {
  const epsilon = Number.EPSILON || 2 ** -52;

  return (epsilon + Math.random()).toString(36).substring(2, 12);
}

export function addRequestIdToRequest(
  req: Request,
): asserts req is Request & {traceId: string; requestId: string} {
  if (!req.requestId) {
    req.traceId = (req.headers['x-amzn-trace-id'] as string) || '';
    req.requestId = (req.headers['request-id'] as string) || generateRandomId();
  }
}

const {FATAL, ERROR, WARN, INFO, DEBUG, TRACE, ALL} = Level;

export const EventType = {
  LOG: 'log' as const,
};

export type RequestIdMeta = {
  requestId?: string;
  req?: Request;
  userId?: string;
  deviceId?: string;
  renderingId?: string;
};

export class Logger<TArgs extends EventArgs = EventArgs> extends EventEmitter<{
  [EventType.LOG]: [LoggerEvent<TArgs>];
}> {
  readonly name: string;

  protected level: Verbosity;

  constructor(name: string, level: Verbosity = ALL) {
    super();
    this.name = name;
    this.level = level;
  }

  setLevel(level: Verbosity): void {
    this.level = level;
  }

  getLevel(): Verbosity {
    return this.level;
  }

  protected logInternal(level: Verbosity, args: TArgs = [] as unknown as TArgs): void {
    if (this.level.value <= level.value) {
      this.emit(EventType.LOG, {
        args,
        level,
        name: this.name,
        timestamp: Date.now(),
      });
    }
  }

  protected createEventHandler(level: Verbosity) {
    return (...args: TArgs): void => this.logInternal(level, args);
  }

  fatal = this.createEventHandler(FATAL);

  error = this.createEventHandler(ERROR);

  warn = this.createEventHandler(WARN);

  info = this.createEventHandler(INFO);

  log = this.createEventHandler(DEBUG);

  debug = this.createEventHandler(DEBUG);

  trace = this.createEventHandler(TRACE);
}

import {
  TypedUseSelectorHook,
  useDispatch as useDispatchRedux,
  useSelector as useSelectorRedux,
  useStore as useStoreRedux,
} from 'react-redux';
import type {RootState} from 'store/rootReducer';
import {Dispatch, Store} from 'typesafe-actions';

export const useStore = useStoreRedux as () => Store;

export const useDispatch = useDispatchRedux as () => Dispatch;

export const useSelector = useSelectorRedux as TypedUseSelectorHook<RootState>;

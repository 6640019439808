import {PurchaseStats as PurchaseStatsType} from 'types/Product';

import {ContentListBaseItem, contentListBaseItem} from './ContentListBaseItem';

type PurchaseStats = {
  productPurchaseStats: PurchaseStatsType;
};

export type ContentListPurchaseStats = ContentListBaseItem<PurchaseStats>;

export function contentListPurchaseStats(
  id: string,
  purchaseStats: PurchaseStats,
): ContentListPurchaseStats {
  return contentListBaseItem(id, purchaseStats);
}

export function isContentListPurchaseStats(
  item: ContentListBaseItem,
): item is ContentListPurchaseStats {
  return Boolean((item as ContentListPurchaseStats).content.productPurchaseStats);
}

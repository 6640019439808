import {CHUNK_SIZE, ContentListItemView, getContentListItemView} from 'helpers/contentList';
import {getServerTimestamp} from 'helpers/serverTime';
import {enhanceProductsList} from 'store/enhancer/productsList';
import {
  enhanceContext,
  enhanceCurrency,
  enhanceLanguage,
  enhanceLoadedTime,
  enhancePageDependedId,
} from 'store/utils/enhancers';
import {
  ContentList,
  ContentListItem,
  ContentListItemRaw,
  ContentListWithCount,
} from 'types/ContentList';
import {Timer} from 'types/ContentList/common';
import {
  extractBannersList,
  isContentListBannersList,
} from 'types/ContentList/ContentListBannersList';
import {ContentListProduct, isContentListProduct} from 'types/ContentList/ContentListProduct';
import {isContentListProductCardGroup} from 'types/ContentList/ContentListProductCardGroup';
import {isContentListProductFromCollections} from 'types/ContentList/ContentListProductFromCollection';
import {
  ContentListProductsList,
  ContentListProductsListRaw,
  isContentListProductsList,
  isContentListProductsListRaw,
} from 'types/ContentList/ContentListProductsList';
import {isContentListSideBannerGroup} from 'types/ContentList/ContentListSideBannerGroup';
import {extractSlimBanner, isContentListSlimBanner} from 'types/ContentList/ContentListSlimBanner';
import {SideBannerGroupProductItem} from 'types/ContentList/SideBannerGroup';
import {ContextItemRaw, ContextList} from 'types/Context';
import {ProductLite, ProductLiteRaw} from 'types/ProductLite';
import {removeDuplicates} from 'utils/array';
import {memoizeLastShallowEqual} from 'utils/memoize';
import {getValueByPath} from 'utils/object';

function enhanceContentListProduct(
  product: ProductLite,
  language: string,
  currency: string,
  context: ContextItemRaw | undefined,
  contextType: string | undefined,
  handler?: (product: ProductLite) => void,
) {
  if (handler) {
    handler(product);
  }

  const productWithContext =
    context && contextType ? enhanceContext(product, contextType, context) : product;

  return enhanceLoadedTime(
    enhanceCurrency(enhanceLanguage(productWithContext, language), currency),
  );
}

export function wrapProductsInContentList(products: ProductLiteRaw[]): ContentListProduct[] {
  return products?.map((product) => ({
    id: product.id,
    content: {product: product as ProductLite},
  }));
}

export function enhanceContentListProductsList(
  listRaw: ContentListProductsListRaw,
  language: string,
  currency: string,
): ContentListProductsList {
  const list = listRaw as unknown as ContentListProductsList;
  list.content.productsList = enhanceProductsList(listRaw.content.productsList, {
    contexts: list.content.productsList.contexts,
    language,
    currency,
  });

  return list;
}

function enhanceProductContextInContentListBlockItem(
  blockItem: {contexts?: ContextList[]; items: SideBannerGroupProductItem[]},
  language: string,
  currency: string,
  productHandler?: (product: ProductLite) => void,
): void {
  const {contexts, items} = blockItem;
  const contextType = getValueByPath(contexts, 0, 'type');
  const contextList = getValueByPath(contexts, 0, 'value');
  let productCounter = 0;

  items.forEach(({product}) => {
    if (product) {
      enhanceContentListProduct(
        product,
        language,
        currency,
        contextList && contextList[productCounter],
        contextType,
        productHandler,
      );
    }

    productCounter += 1;
  });
}

function enhanceTimerExpitationTime(timer: Timer | undefined): Timer | undefined {
  if (timer) {
    timer.expirationTimeMs = timer.timeRemainingMs + getServerTimestamp();
  }

  return timer;
}

/*
  Rearranges content list items
  so that the amount of products between ROW items is divisible by CHUNK_SIZE
*/
function arrangeContentListItems(items: ContentListItem[]): ContentListWithCount {
  let result: ContentListItem[] = [];
  let pool: ContentListItem[] = [];
  // do we have at least 1 chunk filled with CELL items
  let chunkFilled = false;
  items.forEach((item) => {
    const view = getContentListItemView(item);
    if (view === ContentListItemView.ROW) {
      result.push(item);
    } else {
      pool.push(item);
    }
    if (pool.length === CHUNK_SIZE) {
      result = result.concat(pool);
      pool = [];
      chunkFilled = true;
    }
  });
  const arrangedCount = result.length;
  if (pool.length) {
    result = result.concat(pool);
  }
  return {
    items: result,
    // If don't have a full chunk then show all the items, see WEB-1606
    shownCount: chunkFilled ? arrangedCount : result.length,
    incompleteChunkLength: chunkFilled ? 0 : pool.length,
  };
}

export function appendContentListItems(
  itemsData: (ContentList & Partial<ContentListWithCount>) | null | undefined,
  itemsToAdd: ContentListItem[],
  shouldAppend: boolean,
  isLastPage: boolean,
): ContentListWithCount {
  const {items = [], shownCount = 0, incompleteChunkLength = 0} = itemsData || {};
  const sliceIndex = shownCount - incompleteChunkLength;
  const head = shouldAppend ? items.slice(0, sliceIndex) : [];
  const tail = shouldAppend ? items.slice(sliceIndex) : [];
  const {
    items: newItems,
    shownCount: newShownCount,
    incompleteChunkLength: newIncompleteChunkLength,
  } = arrangeContentListItems(tail.concat(itemsToAdd));
  const allItems = head.concat(newItems);
  return {
    items: allItems,
    shownCount: isLastPage ? allItems.length : head.length + newShownCount,
    incompleteChunkLength: newIncompleteChunkLength,
  };
}

export function enhanceContentListItems(
  items: Array<ContentListItemRaw> | null | undefined,
  language: string,
  currency: string,
  pageToken?: string | null,
  contexts?: ContextList[],
  productHandler?: (product: ProductLite) => void,
): ContentListItem[] {
  if (!items) {
    return [];
  }
  const contextType = getValueByPath(contexts, 0, 'type');
  const contextList = getValueByPath(contexts, 0, 'value');
  let productCounter = 0;

  items.map((item) => {
    enhancePageDependedId(item, pageToken || '', item.id);
    enhanceLoadedTime(item);

    if (isContentListProduct(item)) {
      enhanceContentListProduct(
        item.content.product,
        language,
        currency,
        contextList && contextList[productCounter],
        contextType,
        productHandler,
      );
      productCounter += 1;
    } else if (isContentListProductFromCollections(item)) {
      enhanceContentListProduct(
        item.content.productLiteFromCollections.productLite,
        language,
        currency,
        contextList && contextList[productCounter],
        contextType,
        productHandler,
      );
      productCounter += 1;
    } else if (isContentListProductCardGroup(item)) {
      enhanceProductContextInContentListBlockItem(
        item.content.productCardGroup,
        language,
        currency,
        productHandler,
      );
    } else if (isContentListSideBannerGroup(item)) {
      const {sideBannerGroup} = item.content;
      enhanceTimerExpitationTime(sideBannerGroup.banner.timer);
      enhanceProductContextInContentListBlockItem(
        sideBannerGroup,
        language,
        currency,
        productHandler,
      );
    } else if (isContentListBannersList(item)) {
      const bannersList = extractBannersList(item);
      bannersList.items.forEach((banner) => {
        enhanceTimerExpitationTime(banner.timer);
      });
    } else if (isContentListSlimBanner(item)) {
      const slimBanner = extractSlimBanner(item);
      enhanceTimerExpitationTime(slimBanner.timer);
    } else if (isContentListProductsListRaw(item)) {
      enhanceContentListProductsList(item, language, currency);
    }

    return item;
  });

  // Backend can not filter duplicates, so do it on frontend
  return removeDuplicates(items, items, ({id}) => id) as unknown as ContentListItem[];
}

export function handleContentListProducts(
  items: ContentListItem[] | null | undefined,
  handler: (product: ProductLite) => void,
): void {
  items?.forEach((item) => {
    if (isContentListProduct(item)) {
      handler(item.content.product);
    } else if (isContentListProductFromCollections(item)) {
      handler(item.content.productLiteFromCollections.productLite);
    } else if (isContentListProductCardGroup(item)) {
      item.content.productCardGroup.items?.forEach(({product}) => handler(product));
    } else if (isContentListSideBannerGroup(item)) {
      item.content.sideBannerGroup.items?.forEach(({product}) => handler(product));
    } else if (isContentListProductsList(item)) {
      handleContentListProducts(item.content.productsList.items, handler);
    }
  });
}

export const getItemsToShow = memoizeLastShallowEqual(
  (itemsData: ContentListWithCount | null): ContentListItem[] | null => {
    const items = itemsData && itemsData.items;
    return items ? items.slice(0, itemsData.shownCount) : null;
  },
);

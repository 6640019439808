import {DialogModal} from 'components/DialogModal';
import {TransitionAppearance} from 'components/UIKit/Appearance/TransitionAppearance';
import {useMergedRef} from 'hooks/useMergedRef';
import React, {
  AriaAttributes,
  ReactElement,
  ReactNode,
  SyntheticEvent,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import {CSSTransition} from 'react-transition-group';

import {appearance} from './appearance/default';
import {ModalOverlayContext, ModalOverlayContextProvider} from './context';
import styles from './index.scss';

type PropsType = {
  children: ReactNode;
  appearance?: TransitionAppearance;
  onClose?: () => unknown;
  closeDisabled?: boolean;
} & AriaAttributes;

export const ModalOverlay = React.forwardRef(function ModalOverlay(
  {
    children,
    onClose,
    closeDisabled = false,
    appearance: {className, transitionProps} = appearance,
  }: PropsType,
  outerRef: React.Ref<HTMLElement>,
): ReactElement {
  const innerRef = useRef<HTMLElement>(null);
  const mergedRef = useMergedRef(outerRef, innerRef);
  const clickTargetRef = useRef<EventTarget>();

  const handleOverlayMouseDown = useCallback((evt: SyntheticEvent) => {
    clickTargetRef.current = evt.target;
  }, []);
  const handleOverlayMouseUp = useCallback(
    (evt: SyntheticEvent): void => {
      if (
        !closeDisabled &&
        clickTargetRef.current === evt.target &&
        clickTargetRef.current === innerRef.current
      ) {
        onClose?.();
      }

      clickTargetRef.current = undefined;
    },
    [onClose, closeDisabled],
  );

  useEffect(() => {
    if (!onClose) {
      return undefined;
    }

    const handleKeydown = (evt: KeyboardEvent): void => {
      // Detect a keyboard user from a escape key press
      if (evt.keyCode === 27 && !closeDisabled) {
        onClose();
      }
    };

    window.addEventListener('keydown', handleKeydown);
    return () => window.removeEventListener('keydown', handleKeydown);
  }, [onClose, closeDisabled]);

  return (
    <CSSTransition {...transitionProps} nodeRef={innerRef}>
      <ModalOverlayContextProvider>
        <ModalOverlayContext.Consumer>
          {({ariaAttributes}) => (
            <DialogModal
              ref={mergedRef}
              className={`${styles.modalOverlay} ${className}`}
              onMouseDown={handleOverlayMouseDown}
              onMouseUp={handleOverlayMouseUp}
              {...ariaAttributes}
            >
              {children}
            </DialogModal>
          )}
        </ModalOverlayContext.Consumer>
      </ModalOverlayContextProvider>
    </CSSTransition>
  );
});

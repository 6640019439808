import {Request, Response} from 'express';
import {TransportQuery} from 'types/Transport';

import {Device} from '../Device';
import {Transport} from './index';
import {Meta as ResponseMeta} from './Response';

type Meta = Omit<ResponseMeta, 'language'> & {
  language: string;
};

/** Transport for FAQ articles and structure from babylone */
export class FaqTransport extends Transport {
  public override device!: Device;

  constructor(device: Device, req: Request | undefined, res: Response | undefined) {
    super(device.transportEndpoints.faq, device, req, res);
  }

  override buildQuery(query: TransportQuery, meta: Meta): TransportQuery {
    return {
      lang: meta.language,
      platform: 'Web',
      ...query,
    };
  }
}

import {ContentListBaseItem, contentListBaseItem} from './ContentListBaseItem';

export type ContentListSearchFilters = ContentListBaseItem<Record<never, never>, 'searchFilters'>;

export function contentListPromotionsCarousel(): ContentListSearchFilters {
  return contentListBaseItem('searchFilters', {});
}

export function isContentListSearchFilters(
  item: ContentListBaseItem,
): item is ContentListSearchFilters {
  return (item as ContentListSearchFilters).id === 'searchFilters';
}

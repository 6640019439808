import {ecsError} from 'helpers/log/ECS/ecsError';
import {ECSLogger} from 'helpers/log/ECS/types';
import {DeviceVars} from 'types/deviceVars';
import {loadAsyncScript} from 'utils/dom';

import {Device} from './Device';

const STORE_DOMAIN = 'joom.com';

type RiskifiedSDK = {
  go: (url: string) => void;
  setSid: (url: string) => void;
};

export class Riskified {
  private queue: string[];

  private device: Device;

  private lastSentId: string;

  private logger: ECSLogger;

  constructor(device: Device) {
    this.device = device;
    this.lastSentId = '';
    this.queue = [];
    this.logger = device.log.getLogger('Riskified');
  }

  private getConfig(): DeviceVars['riskifiedClient'] {
    return this.device.getDeviceVar('riskifiedClient');
  }

  private isEnabled(): boolean {
    return Boolean(this.getConfig()?.enabled);
  }

  private isAllowedLog(url: string): boolean {
    if (!this.isEnabled()) {
      return false;
    }

    const allowedLogs = this.getConfig()?.allowedLogs || [];
    return allowedLogs.some((allowed) => url.indexOf(allowed) === 0);
  }

  private getClient(): RiskifiedSDK | undefined {
    if (__CLIENT__ && 'RISKX' in window) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return (window as any).RISKX as RiskifiedSDK;
    }
    return undefined;
  }

  private sendQueue(): void {
    const {queue} = this;
    this.queue = [];
    queue.map((url) => this.go(url));
  }

  getId(): string {
    return this.device.getDeviceId() || '';
  }

  go(url: string): void {
    if (__CLIENT__) {
      if (!this.isAllowedLog(url)) {
        this.logger.info('not allowed', url);
        return;
      }

      try {
        const client = this.getClient();
        if (client) {
          this.sendQueue();

          const id = this.getId();
          if (id && this.lastSentId !== id) {
            this.lastSentId = id;
            client.setSid(id);
          }
          client.go(url);
          this.logger.log('riskified', url);
        } else {
          this.queue.push(url);
        }
      } catch (ex) {
        this.logger.error({error: ecsError(ex)});
      }
    }
  }

  async init(): Promise<void> {
    if (__CLIENT__ && this.isEnabled()) {
      this.lastSentId = this.getId();
      this.go('app/init');
      try {
        const url = `https://beacon.riskified.com?shop=${STORE_DOMAIN}&sid=${this.lastSentId}`;
        await loadAsyncScript(url);
        this.sendQueue();
      } catch (ex) {
        this.logger.error({error: ecsError(ex)});
      }
    }
  }
}

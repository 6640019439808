import {routeAsync} from 'helpers/asyncConnect';
import {searchRouteAsyncWrapper} from 'routes/async/searchRouteAsyncWrapper';
import {RouteParams, SEARCH_POSTFIX} from 'utils/search/pattern';

import {anonymous} from './decorators';
import {DefaultParams, PoorRoute} from './types';

type Params = DefaultParams & RouteParams;

const NAME = 'search';

export const SearchRoute: PoorRoute<typeof NAME, Params> = {
  name: NAME,
  path: `/search${SEARCH_POSTFIX}`,
  async: searchRouteAsyncWrapper(
    routeAsync(
      /* #__LOADABLE__ */ () => import('containers/pages/SearchPage'),
      (module) => module.SearchPage,
    ),
  ),
  decorator: anonymous,
  scrollBehaviour: 'scroll-restoration',
};

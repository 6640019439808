import {routeAsync} from 'helpers/asyncConnect';

import {anonymous} from './decorators';
import {DefaultParams, PoorRoute} from './types';

type Params = DefaultParams & {
  type?: string;
  token?: string;
};

const NAME = 'auth';

export const AuthRoute: PoorRoute<typeof NAME, Params> = {
  name: NAME,
  path: '/entrance/:type?/:token?',
  async: routeAsync(
    /* #__LOADABLE__ */ () => import('containers/pages/AuthPage'),
    (module) => module.AuthPage,
  ),
  decorator: anonymous,
};

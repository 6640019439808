import {memoizeLastShallowEqual} from 'utils/memoize';

export const PageVisibility = {
  // the document is completely hidden from view
  HIDDEN: 'hidden',
  // the document is at least partially visible on at least one display device
  VISIBLE: 'visible',
  // the document is loaded off-screen and not visible [opt]v
  PRERENDER: 'prerender',
  // if the document is to be unloaded, then this value will be returned [opt]
  UNLOADED: 'unloaded',
};

const PREFIXES = ['webkit', 'moz', 'ms', 'o'];
const DEFAULT_VISIBILITY = PageVisibility.VISIBLE;

const getPrefix = memoizeLastShallowEqual(() => {
  if ('hidden' in document) {
    return '';
  }

  const prefix = PREFIXES.find((item) => `${item}Hidden` in document);
  return prefix || null;
});

const getHiddenProp = memoizeLastShallowEqual(() => {
  const prefix = getPrefix();
  if (prefix === null) {
    return null;
  }
  return prefix ? `${prefix}Hidden` : 'hidden';
});

export const getVisibilityChangeEventName = memoizeLastShallowEqual(
  () => `${getPrefix() || ''}visibilitychange`,
);

export const isPageVisibilitySupported = memoizeLastShallowEqual(
  () => __CLIENT__ && !!getHiddenProp(),
);

export function getPageVisibility(): string {
  if (!isPageVisibilitySupported()) {
    return DEFAULT_VISIBILITY;
  }
  return document.visibilityState || DEFAULT_VISIBILITY;
}

export function isPageVisible(): boolean {
  return getPageVisibility() === PageVisibility.VISIBLE;
}

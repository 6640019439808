import {CookieType} from 'types/CookiesSettings';

import {CookieValue} from '../Device/CookieValue';
import {falseDecoder, falseEncoder} from './helpers';

const COOKIE_NAME = 'forcedrtl';

export const ForcedRtlCookie: CookieValue<boolean> = {
  type: CookieType.FUNCTIONAL,
  key: COOKIE_NAME,
  decode: falseDecoder,
  encode: falseEncoder,
};

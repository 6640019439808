import config from 'config';
import {Request, Response} from 'express';
import {Device} from 'helpers/ApiClient/Device';
import {DeephemerizeReasonType} from 'helpers/ApiClient/Device/deephemerizeReason';
import {
  TransportHeaders,
  TransportMeta,
  TransportMethod,
  TransportOptions,
  TransportQuery,
} from 'types/Transport';
import {TypedObject} from 'utils/object/typed';

import tokenDegradation from '../../Device/tokenDegradation';
import {
  addAuthorizationHeader,
  addExpAppHeader,
  addHeadersFromRequest,
  addOsTypeHeader,
  addRenderingIdHeader,
  addVersionHeader,
} from '../headers';
import {Transport, TransportPrefix} from '../index';
import {ApiResponse, createUnallowedResponse} from '../Response';
import {getUserType, isEndpointAllowed, needUpgradeUserType} from './acl';

/** Base transport for requests with user auth, ACL and common headers */
export abstract class GoTransport extends Transport {
  constructor(
    prefix: TransportPrefix,
    protected override readonly device: Device,
    req: Request | undefined,
    res: Response | undefined,
  ) {
    super(prefix, device, req, res);
  }

  isUserTypeVerified(): boolean {
    return this.device.isUserTypeVerified();
  }

  override buildHeaders(headers: TransportHeaders, meta: TransportMeta): TransportHeaders {
    const result = super.buildHeaders(headers, meta) || {};
    addHeadersFromRequest(result, this.req);
    addOsTypeHeader(result, this.device.getDeviceVersion()?.osType);
    addAuthorizationHeader(result, this.device.getAccessToken());
    addRenderingIdHeader(result, this.device.getRenderingConfig());
    addExpAppHeader(result, this.device.scope);
    addVersionHeader(result, config.version);

    return result;
  }

  override buildQuery(query: TransportQuery, meta: TransportMeta): TransportQuery {
    const params = {
      language: meta.locale || meta.language,
      currency: meta.overrideCurrency,
    };

    const result = {...params, ...query};
    TypedObject.keys(result).forEach((key) => {
      if (result[key] === undefined) {
        delete result[key];
      }
    });

    return result;
  }

  override request<TBody>(
    method: TransportMethod,
    path: string,
    options: TransportOptions = {},
  ): Promise<ApiResponse<TBody>> {
    const handler = (): Promise<ApiResponse<TBody>> => {
      const userType = getUserType(this.device);
      if (
        needUpgradeUserType(userType, path, this.device.getDeviceVar('webAllowAnonymousCheckout'))
      ) {
        this.logger.info('Upgrade ephemeral user');
        return this.device
          .upgradeEphemeral({type: DeephemerizeReasonType.RESTRICTED_ENDPOINT, endpoint: path})
          .then(() => this.request(method, path, options));
      }

      if (
        isEndpointAllowed(
          userType,
          path,
          this.isUserTypeVerified(),
          this.device.getDeviceVar('webAllowAnonymousCheckout'),
        )
      ) {
        return super.request(method, path, {
          ...options,
          withCredentials: true,
        });
      }

      this.logger.log(`Endpoint is not allowed: ${method.toUpperCase()} ${path}`);
      return Promise.reject(createUnallowedResponse(`${method} ${path}`, this.buildMeta(options)));
    };

    if (options?.deviceCreateDegradationPolicy === 'disabled') {
      return handler();
    }

    return tokenDegradation(this.device, handler, options?.deviceCreateDegradationPolicy);
  }
}

import {Scope} from 'config';
import {routeAsync} from 'helpers/asyncConnect';
import {getUrl} from 'routes';

import {loggedIn} from './decorators';
import {DefaultParams, PoorRoute} from './types';

type Params = DefaultParams;

const NAME = 'invite';

export const InviteRoute: PoorRoute<typeof NAME, Params> = {
  name: NAME,
  path: '/invite',
  scope: [Scope.GLOBAL],
  async: async (options) => {
    if (options.client.device.getDeviceVar('referralUserProgramConfig')?.enabled) {
      return routeAsync(
        /* #__LOADABLE__ */ () => import('containers/pages/InvitePage'),
        (module) => module.InvitePage,
      )(options);
    }

    return {
      redirect: {
        link: getUrl('notfound', {
          lang: options.client.device.getLanguage(),
          scope: options.client.scope,
        }),
      },
    };
  },
  decorator: loggedIn,
};

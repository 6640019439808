import {ScopeConfig} from 'helpers/ApiClient/Scope/ScopeConfig';
import {UserAgent} from 'types/UserAgent';
import {IBrowser, ICPU, IDevice, IEngine, IOS, IResult, UAParser} from 'ua-parser-js';
import {assert} from 'utils/asserts';
import {memoizeLastShallowEqual} from 'utils/memoize';

export enum OS {
  ANDROID = 'Android',
  IOS = 'iOS',
  WINDOWS = 'Windows',
  MACOS = 'Mac OS',
}

export enum Engine {
  WEBKIT = 'WebKit',
}

export enum Browser {
  EDGE = 'Edge',
  CHROME = 'Chrome',
  CHROMIUM = 'Chromium',
  YANDEX = 'Yandex',
}

export enum DeviceType {
  CONSOLE = 'console',
  MOBILE = 'mobile',
  TABLET = 'tablet',
  SMARTTV = 'smarttv',
  WEARABLE = 'wearable',
  EMBEDDED = 'embedded',
}

const parser = new UAParser();
// eslint-disable-next-line import/no-default-export
export default parser;

// UAParser sets methods in constructor, so use implementation instead of extending
export class MemoUAParser implements UAParser {
  private cache: Partial<IResult> = {};

  private parser = new UAParser({
    // MacOS ua string: Mozilla/5.0 (darwin) AppleWebKit/537.36 (KHTML, like Gecko) jsdom/20.0.3
    // https://github.com/faisalman/ua-parser-js/issues/627
    os: [[/(jsdom)\/([\d.]+)/i], [UAParser.OS.NAME, UAParser.OS.VERSION]],
  });

  getUA(): string {
    return this.parser.getUA();
  }

  setUA(ua: string): this {
    this.cache = {};
    this.parser.setUA(ua);
    return this;
  }

  getBrowser(): IBrowser {
    if (!this.cache.browser) this.cache.browser = this.parser.getBrowser();
    return this.cache.browser;
  }

  getOS(): IOS {
    if (!this.cache.os) this.cache.os = this.parser.getOS();
    return this.cache.os;
  }

  getEngine(): IEngine {
    if (!this.cache.engine) this.cache.engine = this.parser.getEngine();
    return this.cache.engine;
  }

  getDevice(): IDevice {
    if (!this.cache.device) this.cache.device = this.parser.getDevice();
    return this.cache.device;
  }

  getCPU(): ICPU {
    if (!this.cache.cpu) this.cache.cpu = this.parser.getCPU();
    return this.cache.cpu;
  }

  getResult(): IResult {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;

    return {
      get ua() {
        return self.getUA();
      },
      get browser() {
        return self.getBrowser();
      },
      get os() {
        return self.getOS();
      },
      get engine() {
        return self.getEngine();
      },
      get device() {
        return self.getDevice();
      },
      get cpu() {
        return self.getCPU();
      },
    };
  }
}

const memoizeUserAgent = memoizeLastShallowEqual(
  (userAgent: string): UserAgent => parser.setUA(userAgent).getResult(),
);

export const parseUserAgent = (userAgent: string): UserAgent => memoizeUserAgent(userAgent || '');

export function isMobileOrTabletDevice(userAgent: UserAgent): boolean {
  assert(userAgent, 'You have to pass userAgent to `isMobileOrTabletDevice`');
  const {type} = userAgent.device;
  return type === DeviceType.MOBILE || type === DeviceType.TABLET;
}

export function hasMobileAppForGivenOS(os: string | undefined, scope: ScopeConfig): boolean {
  return (
    Boolean(os === OS.ANDROID && scope.mobileApps?.android) ||
    Boolean(os === OS.IOS && scope.mobileApps?.ios)
  );
}

export function hasMobileAppForDevice(userAgent: UserAgent, scope: ScopeConfig): boolean {
  assert(userAgent, 'You have to pass userAgent to `hasMobileAppForDevice`');
  return hasMobileAppForGivenOS(userAgent.os.name, scope);
}

export function isWebkit(userAgent: UserAgent): boolean {
  return userAgent.engine.name === Engine.WEBKIT;
}

import EventEmitter from 'component-emitter';
import {getPassiveEventOptions} from 'utils/dom';

/**
 * List of `widnow` events that indicates user activity.
 */
const EVENTS_ARRAY = [
  'keypress',
  'keydown',
  'click',
  'contextmenu',
  'dblclick',
  'mousemove',
  'scroll',
  'touchmove',
  'touchstart',
];

export enum State {
  /**
   * User has no activity for significant time. The timeout
   * after activity that indicates idle mode can be overriden
   * by options.
   */
  IDLE = 'idle',
  /**
   * User had recent activity (DOM events from `EVENTS_ARRAY`).
   */
  ACTIVE = 'active',
}

export type IdleOptions = Partial<{
  /**
   * Timeout in millieseconds after last user activity
   * which indicates that user in idle mode.
   */
  idleTimeout: number;
}>;

const DEFAULT_OPTIONS: Required<IdleOptions> = {
  idleTimeout: 15 * 1000,
};

/**
 * Simple event emitter that notifies you when user is active on the page.
 * NB! It does not support frames / iframes.
 */
export class Idle extends EventEmitter<{change: [State]; activity: []}> {
  /**
   * Current state of user activity.
   */
  public state: State = State.IDLE;

  /**
   * By default is equal DEFAULT_OPTIONS,
   * but can be partially overrided via constructor.
   */
  private options = DEFAULT_OPTIONS;

  /**
   * Last user activity timestamp.
   */
  private lastActivity = 0;

  /**
   * Timer that registers `IDLE` state.
   */
  private timer: ReturnType<typeof setTimeout> | undefined = undefined;

  constructor(options?: IdleOptions) {
    super();

    if (options) {
      this.setOptions(options);
    }

    if (typeof window !== 'undefined') {
      const eventOptions = getPassiveEventOptions();
      EVENTS_ARRAY.forEach((event) =>
        window.addEventListener(event, this.handleActivity, eventOptions),
      );
    }
  }

  setOptions(options: IdleOptions): void {
    this.options = {
      ...this.options,
      ...options,
    };

    if (this.timer && 'idleTimeout' in options) {
      // new check interval was received when ticker
      // is working, so just have to restart it.
      this.setTimerEnabled(true);
    }
  }

  private setTimerEnabled(enable: boolean): void {
    if (this.timer) {
      // always clear previous one, because we are able
      // to create the new one with proper delay.
      clearTimeout(this.timer);
      this.timer = undefined;
    }

    if (enable) {
      this.timer = setTimeout(
        this.handleIdle,
        Math.max(0, this.lastActivity + this.options.idleTimeout - Date.now()),
      );
    }
  }

  private setState(state: State): void {
    if (state === this.state) {
      return;
    }

    this.state = state;
    this.emit('change', state);
  }

  private handleIdle = (): void => {
    this.setState(State.IDLE);
  };

  private handleActivity = (): void => {
    this.lastActivity = Date.now();
    this.emit('activity');

    this.setState(State.ACTIVE);
    this.setTimerEnabled(true);
  };

  destroy(): void {
    this.setTimerEnabled(false);

    if (typeof window !== 'undefined') {
      EVENTS_ARRAY.forEach((event) => window.removeEventListener(event, this.handleActivity));
    }
  }
}

import {ContentListBaseItem, contentListBaseItem} from './ContentListBaseItem';

const ALERT_NOTHING_FOUND_ID = 'alert:nothingFound';

export type AlertNothingFound = {
  alert: {
    text: string;
    description: string;
  };
};

export type ContentListAlertNothingFound = ContentListBaseItem<AlertNothingFound>;

export function contentListAlertNothingFound(
  id: string,
  content: AlertNothingFound,
): ContentListAlertNothingFound {
  return contentListBaseItem(id, content);
}

export function isContentListAlertNothingFound(
  item: ContentListBaseItem,
): item is ContentListAlertNothingFound {
  return (item as ContentListAlertNothingFound).id === ALERT_NOTHING_FOUND_ID;
}

export function extractAlertNothingFound(item: ContentListAlertNothingFound): AlertNothingFound {
  return item.content;
}

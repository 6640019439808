import {routeAsync} from 'helpers/asyncConnect';
import {getContextActivationInfo} from 'routes/async/contextActivate';

import {anonymous} from './decorators';
import {AsyncFunction, DefaultParams, PoorRoute} from './types';

type Params = DefaultParams & {
  productId: string;
};

const productAsync: AsyncFunction<Params> = routeAsync(
  /* #__LOADABLE__ */ () => import('containers/pages/Product'),
  (module) => module.ProductPage,
);

const NAME = 'product';

export const ProductRoute: PoorRoute<typeof NAME, Params> = {
  name: NAME,
  path: '/products/:productId',
  async: async (options) => {
    if (__CLIENT__) {
      const {payload, redirectLink} = getContextActivationInfo(options);
      if (redirectLink) {
        return {
          redirect: {
            link: redirectLink,
            historyState: {contextActivationPayload: payload},
          },
        };
      }
    }

    return productAsync(options);
  },
  decorator: anonymous,
  scrollBehaviour: 'scroll-restoration',
};

const START_DIFF = 0;

let diff = START_DIFF;

const setServerTimeDifference = (milliseconds: number): void => {
  if (__CLIENT__) {
    diff = milliseconds;
  }
};

const getServerTimestamp = (date?: number | Date | null): number =>
  (Number(date) || Date.now()) - diff;

const getClientTimestamp = (serverDate: number | Date | null = null): number => {
  return (Number(serverDate) || Date.now()) + diff;
};

const getRemainingTime = (untilTimeMs: number): number => {
  return Math.max(0, untilTimeMs - getServerTimestamp());
};

export {setServerTimeDifference, getServerTimestamp, getClientTimestamp, getRemainingTime};

import {Scope} from 'config';
import {routeAsync} from 'helpers/asyncConnect';
import {createPath} from 'history';

import {anonymous} from './decorators';
import {type AsyncFunction, DefaultParams, PoorRoute} from './types';

type Params = DefaultParams & {
  userId: string;
  collections?: 'collections';
};

const socialUserAsync: AsyncFunction<Params> = routeAsync(
  /* #__LOADABLE__ */ () => import('containers/pages/SocialUserPage'),
  (module) => module.SocialUserPage,
);

const NAME = 'socialUser';

export const SocialUserRoute: PoorRoute<typeof NAME, Params> = {
  name: NAME,
  path: '/social/users/:userId/:collections(collections)?',
  async: async (options) => {
    const {match, location, client} = options;

    // only joom global scope has collections, so just redirect it
    if (client.device.scope.topScope !== Scope.GLOBAL && match.params.collections) {
      const to = {...location, pathname: location.pathname.replace('/collections', '')};

      return {
        redirect: {link: createPath(to)},
      };
    }

    return socialUserAsync(options);
  },
  decorator: anonymous,
  scrollBehaviour: 'ignore-pathname',
};

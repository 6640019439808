import React from 'react';

const prefix = '__GL__';
function cn(name: string): string {
  return `${prefix}${name}`;
}

/* eslint-disable react/no-danger */

export function GlobalLoader(): React.ReactElement {
  return (
    <>
      <style
        dangerouslySetInnerHTML={{
          __html: `
          @keyframes ${cn('rotate')} {
            from {
              transform: rotate(0deg);
            }
            to {
              transform: rotate(360deg);
            }
          }

          .${cn('parent')} {
            align-items: center;
            bottom: 0;
            display: flex;
            font-size: 15px;
            justify-content: center;
            left: 0;
            position: absolute;
            right: 0;
            top: 0;
          }

          .${cn('loader')} {
            animation: ${cn('rotate')} 0.8s linear infinite;
            block-size: var(--loader-size, 16px);
            inline-size: var(--loader-size, 16px);
            border-color: transparent;
            border-block-start-color: var(--color-primary, rgba(45, 47, 67, 1));
            border-radius: 100%;
            border-style: solid;
            border-width: calc(var(--loader-size, 18px) * 0.15);
          }
        `,
        }}
      />
      <div className={cn('parent')}>
        <div className={cn('loader')} />
      </div>
    </>
  );
}

/* eslint-enable react/no-danger */

import {CookieType} from 'types/CookiesSettings';

import {CookieValue} from '../Device/CookieValue';
import {stringDecoder, stringEncoder} from './helpers';

export const LANGUAGE_COOKIE_NAME = 'redirectLanguage';

export const LanguageCookie: CookieValue<string> = {
  type: CookieType.FUNCTIONAL,
  key: LANGUAGE_COOKIE_NAME,
  decode: stringDecoder,
  encode: stringEncoder,
};

import {Device} from 'helpers/ApiClient/Device';
import {loadFirebase} from 'helpers/ApiClient/firebase';
import {ecsError} from 'helpers/log/ECS/ecsError';
import {ECSLogger} from 'helpers/log/ECS/types';
import {isMobileOrTabletDevice} from 'helpers/userAgent';
import type {Metric} from 'web-vitals';

import {Analytics} from './Analytics';

type FirebasePerformance = import('@firebase/performance-types').FirebasePerformance;

type TraceRecord = {
  name: string;
  startTime: number;
  duration: number;
  options?: {
    metrics?: {[key: string]: number};
    attributes?: {[key: string]: string};
  };
};

function canTracePerformance(): boolean {
  // firebase uses browser's fetch, but some old browsers, for example iOS 10,
  // have no fetch on board. We just do not send traces in this case
  return Boolean(__CLIENT__ && window.fetch);
}

export class Performance {
  perf?: FirebasePerformance;

  landingUrl: string;

  routeName: string | undefined;

  perfTraceQueue?: TraceRecord[];

  logger: ECSLogger;

  constructor(
    private analytics: Analytics,
    private device: Device,
  ) {
    this.perf = undefined;
    this.landingUrl = '';
    this.perfTraceQueue = canTracePerformance() ? undefined : [];
    this.logger = device.log.getLogger('Performance');
  }

  traceRecord(record: TraceRecord): void {
    if (__SERVER__) {
      this.logger.warn(`Can not trace "${record.name}" on server`);
      return;
    }

    if (this.perf) {
      const {name, startTime, duration, options} = record;
      this.perf.trace(name).record(startTime, duration, options);
    } else if (this.perfTraceQueue) {
      this.perfTraceQueue.push(record);
    }
  }

  sendVital = (metric: Metric): void => {
    this.analytics.sendEvent(
      {
        type: 'webVitals',
        payload: {
          webVitalsViewType: this.routeName || 'unknown',
          webVitalsLandingUrl: this.landingUrl,
          webVitalsType: metric.name,
          webVitalsValue: metric.value,
        },
      },
      {
        beacon: true,
        immediately: true,
      },
    );
  };

  private collectINP(webVitals: typeof import('web-vitals')): void {
    if (__SERVER__) {
      return;
    }

    // webVitals run onINP callback every 'visibilitychange' event
    // but INP usually measures by full page life, and analytics can't group these events
    // so use 'beforeunload' event to send last metric
    // it doesn't always fired, but quality is better then quantity
    let lastMetric: Metric | undefined;

    webVitals.onINP(
      (metric) => {
        // in theory, there can be several INP on page life, check it by id
        if (lastMetric && lastMetric.id !== metric.id) {
          this.sendVital(lastMetric);
        }

        lastMetric = metric;
      },
      {
        reportAllChanges: true,
        durationThreshold: 0,
      },
    );

    window.addEventListener('pagehide', () => {
      if (lastMetric) {
        this.sendVital(lastMetric);
      }
    });
  }

  collectWebVitals(routeName: string | undefined): void {
    if (__CLIENT__) {
      this.routeName = routeName;

      import('web-vitals')
        .then((webVitals) => {
          webVitals.onCLS(this.sendVital);
          webVitals.onFCP(this.sendVital);
          webVitals.onFID(this.sendVital);
          webVitals.onLCP(this.sendVital);
          webVitals.onTTFB(this.sendVital);

          this.collectINP(webVitals);
        })
        .catch((error) => this.logger.error({error: ecsError(error)}));
    }
  }

  private async collectINPAttribution(): Promise<void> {
    try {
      const isMobile = isMobileOrTabletDevice(this.device.getUserAgent().getResult());
      const webVitalsAttribution = await import('web-vitals/attribution');

      webVitalsAttribution.onINP(
        (metric) => {
          if (metric.rating !== 'good') {
            const target = metric.attribution.eventTarget || 'unknown';
            // remove hash from class names
            const normalizedTarget = target.replace(/___[\w_-]{5}/gi, '');

            // convert app time to global time
            const appStartTime = metric.attribution.eventEntry?.startTime ?? performance.now();
            const startTime = Date.now() - (performance.now() - appStartTime);

            this.traceRecord({
              name: 'slowINP',
              startTime,
              duration: metric.value,
              options: {
                attributes: {
                  platform: isMobile ? 'mobile' : 'desktop',
                  loadState: metric.attribution.loadState || 'unknown',
                  target,
                  normalizedTarget,
                  pathname: window.location.pathname,
                },
              },
            });
          }
        },
        {
          reportAllChanges: true,
        },
      );
    } catch (error) {
      this.logger.error({error: ecsError(error)});
    }
  }

  async init(): Promise<void> {
    if (__CLIENT__) {
      this.landingUrl = document.location.href;

      if (canTracePerformance()) {
        try {
          const firebase = await loadFirebase(this.device.scope);

          this.perf = firebase?.performance();
        } catch (error) {
          this.logger.error(`Could not load firebase module: ${error}`);
        }

        if (this.perf && this.perfTraceQueue) {
          this.perfTraceQueue.forEach((record) => this.traceRecord(record));
          this.perfTraceQueue = undefined;
        }

        // run on init, when device vars already available
        if (this.device.getDeviceVar('webVitalsINPAttribution')) {
          this.collectINPAttribution();
        }
      }
    }
  }
}

import {routeAsync} from 'helpers/asyncConnect';

import {anonymous} from './decorators';
import {DefaultParams, PoorRoute} from './types';

const asyncRoute = routeAsync(
  /* #__LOADABLE__ */ () => import('containers/pages/Main'),
  (module) => module.default,
);

const NAME = 'main';

export const MainRoute: PoorRoute<typeof NAME, DefaultParams> = {
  name: NAME,
  path: '/',
  async: asyncRoute,
  decorator: anonymous,
  scrollBehaviour: 'scroll-restoration',
};

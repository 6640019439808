import type {TypedObjectType} from 'utils/object/typed';

enum CookieType {
  FUNCTIONAL = 'functional',
  PERFORMANCE = 'performance',
  MARKETING = 'marketing',
  MARKETING_USER_DATA = 'marketing_user_data',
  AD_PERSONALIZATION = 'ads_personalization',
}

type CookiesSetting = Readonly<{
  type: CookieType;
  allowed: boolean;
}>;

type CookiesSettings = CookiesSetting[];

type CookiesSettingsResponse = {
  cookies: CookiesSettings;
};

function isCookiesSetting(setting: unknown): setting is CookiesSetting {
  return (
    setting != null &&
    typeof setting === 'object' &&
    Object.values(CookieType).includes((setting as CookiesSetting).type) &&
    typeof (setting as CookiesSetting).allowed === 'boolean'
  );
}

function isCookiesSettings(arr: unknown): arr is CookiesSettings {
  if (!Array.isArray(arr)) {
    return false;
  }

  return arr.every((item) => isCookiesSetting(item));
}

const defaultAllowedTypes: CookieType[] = [CookieType.FUNCTIONAL];

const defaultCookiesSettings: CookiesSettings = defaultAllowedTypes.map((item) => ({
  type: item,
  allowed: true,
}));

const fullyAcceptedCookiesSettings: CookiesSettings = (Object as TypedObjectType)
  .keys(CookieType)
  .map((key) => ({
    type: CookieType[key],
    allowed: true,
  }));

export {
  CookieType,
  CookiesSettings,
  CookiesSettingsResponse,
  isCookiesSettings,
  defaultAllowedTypes,
  defaultCookiesSettings,
  fullyAcceptedCookiesSettings,
};

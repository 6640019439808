import {enhanceParcelOrder} from 'store/enhancer/parcelOrder';
import {enhanceLoadedTime} from 'store/utils/enhancers';
import {Parcel, ParcelOptions, ParcelRaw} from 'types/Parcel';

export function enhanceParcel(parcel: ParcelRaw, options: ParcelOptions): Parcel {
  const result = parcel as Parcel;

  result.orders = parcel.orders
    ? parcel.orders.map((order) => enhanceParcelOrder(order, options))
    : [];

  enhanceLoadedTime(result);

  return result;
}

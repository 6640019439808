import {MutableRefObject} from 'react';

export const identity = <T>(item: T): T => item;
export const nullFunction = (): null => null;
export const voidFunction = (): void => undefined;
export const trueFunction = (): true => true;
export const falseFunction = (): false => false;
export const isDefAndNotNull = <T>(data: T | null | undefined): data is T =>
  data !== undefined && data !== null;
export const ifRef =
  <A extends unknown[], R, K>(ref: MutableRefObject<K>, value: K, fn: (...args: A) => R) =>
  (...args: A): R | undefined => {
    return ref.current === value ? fn(...args) : undefined;
  };
export const ifRefTrue = <A extends unknown[], R>(
  ref: MutableRefObject<boolean>,
  fn: (...args: A) => R,
): ((...args: A) => R | undefined) => ifRef(ref, true, fn);

type Task<R, T = undefined> = (prevResult: T) => R;

export function waterfall<A, B>(...args: [Task<A>, Task<B, A>]): B;
export function waterfall<A, B, C>(...args: [Task<A>, Task<B, A>, Task<C, B>]): C;
export function waterfall<A, B, C, D>(...args: [Task<A>, Task<B, A>, Task<C, B>, Task<D, C>]): D;
export function waterfall<A, B, C, D, E>(
  ...args: [Task<A>, Task<B, A>, Task<C, B>, Task<D, C>, Task<E, D>]
): E;
export function waterfall<A, B, C, D, E, F>(
  ...args: [Task<A>, Task<B, A>, Task<C, B>, Task<D, C>, Task<E, D>, Task<F, E>]
): F;
export function waterfall<A, B, C, D, E, F, H>(
  ...args: [Task<A>, Task<B, A>, Task<C, B>, Task<D, C>, Task<E, D>, Task<F, E>, Task<H, F>]
): H;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function waterfall(...args: Task<any>[]): unknown {
  return args.reduce((result, fn) => fn(result), undefined);
}

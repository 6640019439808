import memoize from 'lodash/memoize';

export const createNetworkError = memoize(
  (type: string, status = 0, userMessage = ''): import('types/Error').ApiError => {
    return {
      status,
      type,
      userMessage,
    };
  },
);

import {CookieType} from 'types/CookiesSettings';

import {CookieValue} from '../Device/CookieValue';
import {stringDecoder, stringEncoder} from './helpers';

const COOKIE_NAME = 'authtoken';

export const AuthTokenCookie: CookieValue<string> = {
  type: CookieType.FUNCTIONAL,
  key: COOKIE_NAME,
  decode: stringDecoder,
  encode: stringEncoder,
  getOptions: () => ({
    httpOnly: true,
  }),
};

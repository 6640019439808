import {identity} from 'utils/function';
import {decodeURIComponentWithPluses} from 'utils/url';

export const SEARCH_POSTFIX_REGEXP = /\/([\w]+\..*?.*)+/g;

const part = (name: string, prefix: string, optional = false): string =>
  `/:${name}(${prefix}\\..*?)${optional ? '?' : ''}`;

const categoryId = part('categoryId', 'c', true);
const tagId = part('tagId', 't', true);
const filters = Array.from({length: 20}, (value, index) =>
  part(`filter${index + 1}`, 'f', true),
).join('');
const sorting = part('sorting', 's', true);
const query = part('query', 'q', true);
const promotedProduct = part('promotedProduct', 'p', true);

// FYI: if you want to add new param please check hasTrendingLinks function
export const SEARCH_FALLBACK_POSTFIX = [categoryId, tagId, filters, sorting, query].join('');

export const SEARCH_TAGID_POSTFIX = [categoryId, part('tagId', 't'), filters, sorting, query].join(
  '',
);

export const SEARCH_POSTFIX = [categoryId, filters, sorting, query, promotedProduct].join('');

export type RouteParams = {
  categoryId?: string;
  tagId?: string;
  sorting?: string;
  query?: string;
  storeId?: string;
  promotionId?: string;
  trendingLinkSlug?: string;
  promotedProduct?: string;
  brandId?: string;
  filter1?: string;
  filter2?: string;
  filter3?: string;
  filter4?: string;
  filter5?: string;
  filter6?: string;
  filter7?: string;
  filter8?: string;
  filter9?: string;
  filter10?: string;
  filter11?: string;
  filter12?: string;
  filter13?: string;
  filter14?: string;
  filter15?: string;
  filter16?: string;
  filter17?: string;
  filter18?: string;
  filter19?: string;
  filter20?: string;
};

export type ParamsData = {
  categoryId?: string;
  tagId?: string;
  sortingField?: string;
  sortingOrder?: string;
  filterParams?: string[];
  trendingLinkSlug?: string;
  query?: string;
  storeId?: string;
  promotionId?: string;
  brandId?: string;
  brandIdFilter?: string;
  parsed?: boolean;
  promotedProduct?: string;
};

function removePrefix(str?: string, prefixLength = 2): string | undefined {
  return str ? decodeURIComponentWithPluses(str).substr(prefixLength) : undefined;
}

function decode(str?: string): string | undefined {
  return str ? decodeURIComponentWithPluses(str) : undefined;
}

function isParamsData(params?: RouteParams | ParamsData): params is ParamsData {
  return Boolean(params && 'parsed' in params && params.parsed);
}

export function getParamsData(params?: RouteParams | ParamsData): ParamsData {
  if (!params) {
    return {};
  }

  if (isParamsData(params)) {
    return params;
  }

  const sortingValue = removePrefix(params.sorting) || '';
  const sortingDotIndex = sortingValue ? sortingValue.indexOf('.') : -1;

  return {
    // to prevent double parsing
    parsed: true,
    storeId: decode(params.storeId),
    promotionId: decode(params.promotionId),
    trendingLinkSlug: decode(params.trendingLinkSlug),
    brandId: decode(params.brandId),
    categoryId: removePrefix(params.categoryId),
    tagId: removePrefix(params.tagId),
    query: removePrefix(params.query),
    filterParams: Array.from({length: 20}, (value, index) =>
      removePrefix(params[`filter${index + 1}` as keyof typeof params]),
    ).filter(identity) as string[],
    sortingField: sortingDotIndex !== -1 ? sortingValue.substr(0, sortingDotIndex) : undefined,
    sortingOrder: sortingDotIndex !== -1 ? sortingValue.substr(sortingDotIndex + 1) : undefined,
    promotedProduct: removePrefix(params.promotedProduct),
  };
}

import {
  ProofOfWorkSolution,
  ProofOfWorkSolveRequest,
} from 'helpers/ApiClient/Transport/ProofOfWork/types';
import {isObject} from 'utils/guards';

const SOLVE_REQUEST_ACTION = 'SOLUTION_REQUEST_ACTION';

export type SolveRequestAction = {
  type: typeof SOLVE_REQUEST_ACTION;
  id: number;
  request: ProofOfWorkSolveRequest;
};

export function solveRequestAction(
  id: number,
  request: ProofOfWorkSolveRequest,
): SolveRequestAction {
  return {type: SOLVE_REQUEST_ACTION, id, request};
}

export function isSolveRequestAction(action: unknown): action is SolveRequestAction {
  return isObject(action) && action.type === SOLVE_REQUEST_ACTION;
}

const SOLUTION_RESPONSE_ACTION = 'SOLUTION_RESPONSE_ACTION';

export type SolutionResponseAction = {
  type: typeof SOLUTION_RESPONSE_ACTION;
  id: number;
  solution: ProofOfWorkSolution;
};

export function solutionResponseAction(
  id: number,
  solution: ProofOfWorkSolution,
): SolutionResponseAction {
  return {type: SOLUTION_RESPONSE_ACTION, id, solution};
}

export function isSolutionResponseAction(action: unknown): action is SolutionResponseAction {
  return isObject(action) && action.type === SOLUTION_RESPONSE_ACTION;
}

import {
  CategoryRedirectRequest,
  CategoryRedirectResponse,
  loadCategoryRedirect as loadCategoryRedirectAction,
} from 'store/modules/categoryRedirect/actions';
import {requestActionCreator} from 'store/utils/requestActions';
import {FuncAction} from 'typesafe-actions';

type LoadCategoryRedirectResponseBody = {
  payload: CategoryRedirectResponse;
};

export function loadCategoryRedirect({categoryId}: CategoryRedirectRequest): FuncAction {
  return requestActionCreator(loadCategoryRedirectAction, {categoryId}, (store, client) =>
    client.api
      .post<LoadCategoryRedirectResponseBody>(`/categories/${categoryId}/redirect/get`)
      .then(({body: {payload}}) => payload),
  );
}

import {ImageBundle} from 'types/Image';
import {Button, Text} from 'types/Layout';

import {Timer} from './common';
import {ContentListBaseItem} from './ContentListBaseItem';

export type PaymentRequisitesRefreshScreenAction = {
  refreshScreen: {
    button?: Button;
    text?: Text;
  };
};

export type PaymentRequisitesAction = PaymentRequisitesRefreshScreenAction;

export function isPaymentRequisitesRefreshScreenAction(
  action?: PaymentRequisitesAction,
): action is PaymentRequisitesRefreshScreenAction {
  return Boolean(action && 'refreshScreen' in action);
}

export type PaymentRequisitesField = {
  title: Text;
  value: Text;
  valueToCopy?: string;
};

export type PaymentRequisites = {
  action?: PaymentRequisitesAction;
  fields: PaymentRequisitesField[];
  icon?: ImageBundle;
  subtitle?: Text;
  timer?: Timer;
  title?: Text;
};

export type ContentListPaymentRequisites = ContentListBaseItem<{
  paymentRequisites: PaymentRequisites;
}>;

export function isContentListPaymentRequisites(
  item: ContentListBaseItem,
): item is ContentListPaymentRequisites {
  return Boolean((item as ContentListPaymentRequisites).content.paymentRequisites);
}

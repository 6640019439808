import {Event, EventArgs} from '../Event';
import {Verbosity} from '../Level';

export type Config = Partial<Record<string, Verbosity>>;

export abstract class Appender<
  TMeta extends Record<string, unknown>,
  TArgs extends EventArgs = EventArgs,
> {
  config: Config;

  constructor(config: Config = {}) {
    this.config = config;
  }

  process(data: Event<TMeta, TArgs>): void {
    const level = this.config[data.name] || this.config['*'];

    if (!level || level.value <= data.level.value) {
      this.processInternal(data);
    }
  }

  abstract processInternal(data: Event<TMeta, TArgs>): void;
}

import type ClientApi from '@joomcode/joom-event-types';
import {Analytics} from 'helpers/ApiClient/Analytics';
import {Device} from 'helpers/ApiClient/Device';
import {DeviceVars} from 'types/deviceVars';
import {checkNever} from 'utils/guards';
import {getUrlHostname} from 'utils/url';

const COLLECT_INTERVAL = 20000;

type DataType = 'http' | 'network';

export class ResourceTimingV2 {
  // eslint-disable-next-line no-useless-constructor
  constructor(
    private device: Device,
    private analytics: Analytics,
    // eslint-disable-next-line no-empty-function
  ) {}

  config: Exclude<DeviceVars['networkStats'], undefined> = {};

  timers = {} as Record<DataType, number | undefined>;

  httpData: ClientApi.AnalyticsEventRequestsHttpStats['payload']['httpRequests'] = [];

  dnsData: ClientApi.AnalyticsEventRequestsDnsStats['payload']['dnsRequests'] = [];

  tcpData: ClientApi.AnalyticsEventRequestsTcpStats['payload']['tcpRequests'] = [];

  tlsData: ClientApi.AnalyticsEventRequestsTlsStats['payload']['tlsRequests'] = [];

  private hasResourceTimings(): boolean {
    return (
      __CLIENT__ &&
      window.performance &&
      typeof window.performance.clearResourceTimings === 'function'
    );
  }

  private isEventsResource(resource: PerformanceResourceTiming): boolean {
    const prefix = this.device.transports.api.getPrefix();
    return (
      resource.name.startsWith(`${prefix}/events?`) ||
      resource.name.startsWith(`${prefix}/eventsBeacon?`)
    );
  }

  private createTimings(startTs: number, endTs: number) {
    return {startTs: Math.round(startTs), durationMs: Math.round(endTs - startTs)};
  }

  private addNetworkData(data: this['dnsData'], host: string, startTs: number, endTs: number) {
    // may be zero - then dont need to keep
    if (startTs) {
      data.push({host, ...this.createTimings(startTs, endTs)});
    }
  }

  private collect = (skipMaxCountCheck?: boolean): void => {
    try {
      (performance.getEntriesByType('resource') as PerformanceResourceTiming[]).forEach((res) => {
        const url = res.name;
        const host = getUrlHostname(url);
        const httpCode = (res as {responseStatus?: number}).responseStatus;

        // dont keep events resource by design
        if (this.isEventsResource(res)) {
          return;
        }

        this.httpData.push({
          url,
          responseBytes: res.transferSize,
          ...(httpCode !== undefined && {httpCode}),
          ...this.createTimings(res.startTime, res.responseEnd),
        });

        if (host) {
          this.addNetworkData(this.dnsData, host, res.domainLookupStart, res.domainLookupEnd);
          this.addNetworkData(this.tcpData, host, res.connectStart, res.connectEnd);
          this.addNetworkData(this.tlsData, host, res.secureConnectionStart, res.connectEnd);
        }
      });
      performance.clearResourceTimings();
    } catch (ex) {
      // do nothing
    }

    if (!skipMaxCountCheck) {
      if (this.httpData.length >= Number(this.config.http?.maxCount)) {
        this.sendData('http');
      }

      if (
        this.dnsData.length + this.tcpData.length + this.tlsData.length >=
        Number(this.config.network?.maxCount)
      ) {
        this.sendData('network');
      }
    }
  };

  private sendData = (type: DataType) => {
    if (type === 'http') {
      if (this.httpData.length) {
        this.analytics.sendEvent({
          type: 'requests.httpStats',
          payload: {httpRequests: this.httpData},
        });
        this.httpData = [];
      }
    } else if (type === 'network') {
      if (this.dnsData.length) {
        this.analytics.sendEvent({
          type: 'requests.dnsStats',
          payload: {dnsRequests: this.dnsData},
        });
        this.dnsData = [];
      }

      if (this.tcpData.length) {
        this.analytics.sendEvent({
          type: 'requests.tcpStats',
          payload: {tcpRequests: this.tcpData},
        });
        this.tcpData = [];
      }

      if (this.tlsData.length) {
        this.analytics.sendEvent({
          type: 'requests.tlsStats',
          payload: {tlsRequests: this.tlsData},
        });
        this.tlsData = [];
      }
    } else {
      checkNever(type);
    }

    this.setSendTimer(type);
  };

  private setSendTimer(type: DataType) {
    window.clearTimeout(this.timers[type]);

    const maxDelayMs = this.config[type]?.maxDelayMs;
    if (maxDelayMs) {
      this.timers[type] = window.setTimeout(() => {
        this.collect(true);
        this.sendData(type);
      }, maxDelayMs);
    }
  }

  init(): void {
    const networkStats = this.device.getDeviceVar('networkStats');

    if (!this.hasResourceTimings() || !networkStats) {
      return;
    }

    this.config = networkStats;

    setInterval(this.collect, COLLECT_INTERVAL);
    this.setSendTimer('http');
    this.setSendTimer('network');

    performance.onresourcetimingbufferfull = () => this.collect();
  }
}

export type LoadableFunction<T, Args extends unknown[] = []> = (...args: Args) => Promise<T>;

export type LoadablePayload<T, Args extends unknown[] = []> = LoadableFunction<never> & {
  chunkName: () => string;
  isReady: () => boolean;
  importAsync: LoadableFunction<T, Args>;
  requireAsync: LoadableFunction<T, Args>;
  // only for server
  requireSync: (...args: Args) => T;
};

export function isLoadablePayload<T, Args extends unknown[]>(
  options: LoadableFunction<T, Args>,
): options is LoadablePayload<T, Args> {
  return 'importAsync' in options;
}

export function requireModule<T, Args extends unknown[]>(
  loader: LoadableFunction<T, Args>,
  ...args: Args
): Promise<T> {
  if (!isLoadablePayload(loader)) {
    return loader(...args);
  }

  if (__SERVER__) {
    return Promise.resolve(loader.requireSync(...args));
  }
  return loader.requireAsync(...args);
}

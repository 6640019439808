import {getCurrentHub} from '@sentry/core';
import {ApiClient} from 'helpers/ApiClient';
import {Route} from 'routes/types';

const setTagIfDefined = (key: string, value: string | boolean | undefined) =>
  value != null && value !== '' && getCurrentHub()?.setTag(key, value);

export function setEnvSentryTags(): void {
  getCurrentHub().setTag('engine', __SERVER__ ? 'server' : 'client');
}

export function setRequestSentryTags(client: ApiClient): void {
  setTagIfDefined('domainId', client.scope.domainConfigId);
  setTagIfDefined('renderingType', client.device.getRenderingConfig()?.option);
  setTagIfDefined('renderingId', client.device.getRenderingConfig()?.id);
  setTagIfDefined('requestId', client.req?.requestId);
}

export function setUserSentryTags(client: ApiClient): void {
  setTagIfDefined('bot', client.device.isBot());
  setTagIfDefined('deviceId', client.device.getDeviceId());
  setTagIfDefined('userId', client.device.getUserId());
}

export function setPageSentryTag(route: Route | undefined): void {
  setTagIfDefined('route', route?.name);
}

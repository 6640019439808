import {routeAsync} from 'helpers/asyncConnect';

import {anonymous} from './decorators';
import {DefaultParams, PoorRoute} from './types';

const NAME = 'entrance';

export const EntranceRoute: PoorRoute<typeof NAME, DefaultParams> = {
  name: NAME,
  path: '/entrance',
  async: routeAsync(
    /* #__LOADABLE__ */ () => import('containers/pages/AuthPage'),
    (module) => module.AuthPage,
  ),
  decorator: anonymous,
};

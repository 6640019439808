import cookie from 'cookie';
import {CookieOptions, Request, Response} from 'express';
import {globalLog} from 'helpers/log';
import {ECSLogger} from 'helpers/log/ECS/types';
import {CookiesSettings, defaultAllowedTypes, defaultCookiesSettings} from 'types/CookiesSettings';

import {CookieNames, getCookie} from '../Device/cookieValues';

class Cookies {
  private cookies: Record<string, string> | null;

  private documentCookieCache = '';

  private setCookieHeaders: Record<string, string>;

  private logger: ECSLogger;

  constructor(
    private req: Request | null = null,
    private res: Response | null = null,
    private permissions: CookiesSettings = defaultCookiesSettings,
  ) {
    this.req = req;
    this.res = res;
    this.permissions = permissions;
    this.cookies = null;
    this.setCookieHeaders = {};
    this.logger = (this.req?.log || globalLog).getLogger('Cookies');
  }

  setPermissions(permissions: CookiesSettings): void {
    this.permissions = permissions;
  }

  private setHeader(name: string, value: string) {
    const alreadyExists = name in this.setCookieHeaders;
    this.setCookieHeaders[name] = value;

    if (alreadyExists) {
      this.logger.warn(`Cookie "${name}" will be overwritten.`);
    }

    this.res?.setHeader('Set-Cookie', Object.values(this.setCookieHeaders));
  }

  getCookies(): Record<string, string | undefined> {
    if (__SERVER__) {
      if (!this.cookies) {
        this.cookies = cookie.parse(this.req?.get('cookie') || '');
      }
      return this.cookies;
    }

    if (!this.cookies || this.documentCookieCache !== document.cookie) {
      this.documentCookieCache = document.cookie;
      this.cookies = cookie.parse(document.cookie);
    }
    return this.cookies;
  }

  remove<N extends CookieNames>(name: N, options: CookieOptions): void {
    const cookieKey = getCookie(name).key;

    const headerValue = cookie.serialize(cookieKey, 'deleted', {
      path: options.path,
      domain: options.domain,
      expires: new Date(0),
    });

    if (__SERVER__) {
      if (this.cookies) {
        delete this.cookies[cookieKey];
        this.setHeader(cookieKey, headerValue);
      }
    } else {
      document.cookie = headerValue;
    }
  }

  get<N extends CookieNames>(name: N, def?: string): string | undefined {
    const cookieKey = getCookie(name)?.key;
    if (!cookieKey) {
      this.req?.log.getLogger('Cookies').warn(`Can not find cookie ${name}`);
    }
    return (cookieKey && this.getCookies()[cookieKey]) || def;
  }

  isCookiePermitted<N extends CookieNames>(name: N): boolean {
    const cookieType = getCookie(name).type;

    if (defaultAllowedTypes.includes(cookieType)) {
      return true;
    }

    const permittedTypes = this.permissions.map(({type}) => type);

    return permittedTypes.includes(cookieType);
  }

  set<N extends CookieNames>(name: N, val: string, options: CookieOptions): boolean {
    const cookieByName = getCookie(name);
    const cookieType = cookieByName.type;
    const cookieKey = cookieByName.key;

    if (defaultAllowedTypes.includes(cookieType) && !this.permissions) {
      if (__DEVELOPMENT__) {
        throw new Error('Can not set cookie with unknown permissions.');
      }
      return false;
    }

    if (!this.isCookiePermitted(name)) {
      return false;
    }

    const headerValue = cookie.serialize(cookieKey, val, options);

    if (__SERVER__) {
      this.getCookies()[cookieKey] = val;
      this.setHeader(cookieKey, headerValue);
    } else {
      document.cookie = headerValue;
    }

    return true;
  }
}

export {Cookies};

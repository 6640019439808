import {ApiError, isApiError} from 'types/Error';

export type ProofOfWorkErrorPayload = {
  challengeToken: string;
  difficulty: number;
  durationMs: number;
};

export type ProofOfWorkError = ApiError & {
  type: 'bot.proof_of_work_required';
  payload: ProofOfWorkErrorPayload;
};

export function isProofOfWorkApiError(error: unknown): error is ProofOfWorkError {
  return isApiError(error) && error.type === 'bot.proof_of_work_required';
}

export type ProofOfWorkSolveRequest = {
  challengeToken: string;
  prefix: string;
  difficulty: number;
};

export type ProofOfWorkSolution = {
  challengeToken: string;
  difficulty: number;
  proof: string;
  executionMs: number;
};

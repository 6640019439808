import {Promotion} from '../Promotion';
import {ContentListBaseItem, contentListBaseItem} from './ContentListBaseItem';

export type ContentListPromotionsCarousel = ContentListBaseItem<{
  promotionsCarousel: {promotions: Promotion[]};
}>;

export function contentListPromotionsCarousel(
  id: string,
  promotionsCarousel: {
    promotions: Promotion[];
  },
): ContentListPromotionsCarousel {
  return contentListBaseItem(id, {promotionsCarousel});
}

export function isContentListPromotionsCarousel(
  item: ContentListBaseItem,
): item is ContentListPromotionsCarousel {
  return Boolean((item as ContentListPromotionsCarousel).content.promotionsCarousel?.promotions);
}

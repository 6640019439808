import {identity} from '../function';

export function removeDuplicates(arr, returnArray = arr, hashFn = identity) {
  const seen = {};
  let cursorInsert = 0;
  let cursorRead = 0;

  while (cursorRead < arr.length) {
    const current = arr[cursorRead++];
    const key = hashFn(current);
    if (!Object.prototype.hasOwnProperty.call(seen, key)) {
      seen[key] = true;
      returnArray[cursorInsert++] = current; // eslint-disable-line no-param-reassign
    }
  }

  returnArray.length = cursorInsert; // eslint-disable-line no-param-reassign
  return returnArray;
}

export function range(start, end) {
  return Array.from({length: end - start + 1}, (value, index) => index + start);
}

export function arrayToObject(arr, keyHandler = identity, valueHandler = identity) {
  const result = {};
  if (arr) {
    arr.forEach((item, index) => {
      result[keyHandler(item, index)] = valueHandler(item, index);
    });
  }
  return result;
}

export function arrayToBucketObject(arr, keyHandler = identity, valueHandler = identity) {
  const result = {};
  if (arr) {
    arr.forEach((item, index) => {
      const key = keyHandler(item, index);
      if (!result[key]) {
        result[key] = [];
      }
      result[key].push(valueHandler(item, index));
    });
  }
  return result;
}

export function keyKey(arr) {
  return arrayToObject(arr);
}

export function compareByItemKey(a, b, key, desc = false) {
  const aValue = a && a[key];
  const bValue = b && b[key];

  if (aValue < bValue) {
    return desc ? 1 : -1;
  }

  if (aValue > bValue) {
    return desc ? -1 : 1;
  }

  return 0;
}

function updateItem(arr, value, index) {
  if (index === -1) {
    return arr;
  }
  return [...arr.slice(0, index), value, ...arr.slice(index + 1)];
}

export function pushOrUpdate(arr, value, finder) {
  if (!arr) {
    return arr;
  }

  const index = arr.findIndex((item) => finder(item, value));
  const updatedArr = updateItem(arr, value, index);
  return arr === updatedArr ? [...arr, value] : updatedArr;
}

export function unshiftOrUpdate(arr, value, hash) {
  if (!arr) {
    return arr;
  }

  const index = arr.findIndex((item) => hash(item) === hash(value));
  const updatedArr = updateItem(arr, value, index);
  return arr === updatedArr ? [value, ...arr] : updatedArr;
}

export function isArrayEmpty(array) {
  return !Array.isArray(array) || array.length === 0;
}

export function isArrayNotEmpty(array) {
  return !isArrayEmpty(array);
}

import {routeAsync} from 'helpers/asyncConnect';

import {anonymous} from './decorators';
import {DefaultParams, PoorRoute} from './types';

const NAME = 'refund';

export const RefundRoute: PoorRoute<typeof NAME, DefaultParams> = {
  name: NAME,
  path: '/refund',
  async: routeAsync(
    /* #__LOADABLE__ */ () => import('containers/pages/RefundPage'),
    (module) => module.RefundPage,
  ),
  decorator: anonymous,
};

import {routeAsync} from 'helpers/asyncConnect';

import {anonymous} from './decorators';
import {PoorRoute} from './types';

const NAME = 'premiumSeoList';

export const PremiumSeoListRoute: PoorRoute<typeof NAME> = {
  name: NAME,
  path: '/premium',
  async: routeAsync(
    /* #__LOADABLE__ */ () => import('containers/pages/PremiumSeoList'),
    (module) => module.PremiumSeoListPage,
  ),
  decorator: anonymous,
};

import 'theme/nprogress.scss';

import NProgress from 'nprogress';

NProgress.configure({
  showSpinner: false,
});

let runningProgressCounter = 0;

export const progressStart = (): void => {
  if (__CLIENT__) {
    if (runningProgressCounter === 0) {
      NProgress.start();
    }

    runningProgressCounter += 1;
  }
};

export const progressDone = (): void => {
  if (__CLIENT__) {
    runningProgressCounter -= 1;

    if (NProgress.isRendered() && runningProgressCounter === 0) {
      NProgress.done();
    }
  }
};

import intersection from 'lodash/intersection';
import uniq from 'lodash/uniq';

import {IKeyValueStorage} from './KeyValueStorage/types';

const STORAGE_KEY = 'cart.sel';
const VERSION = 2;
const TTL = 24 * 60 * 60 * 1000;

export enum SelectionType {
  ALL = 'all',
  NONE = 'none',
  PRODUCT_VARIANT_IDS = 'byIds',
  IDS = 'byItemIds',
}

type CartSelection = {
  type: SelectionType;
  ids: string[];
};

type CartSelectionStorageValue = {
  v: number;
  type: SelectionType;
  ids: string[];
  e: number;
};

export function resetCartSelection(localStorage: IKeyValueStorage): void {
  localStorage.removeItem(STORAGE_KEY);
}

export function getCartSelection(localStorage: IKeyValueStorage): CartSelection {
  const data = localStorage.getItem(STORAGE_KEY) as CartSelectionStorageValue;
  const isExpired = data && data.e && data.e < Date.now();
  if (isExpired) {
    resetCartSelection(localStorage);
  }
  if (!data || data.v !== VERSION || isExpired) {
    return {type: SelectionType.ALL, ids: []};
  }
  return {
    type: data.type || SelectionType.ALL,
    ids: data.ids || [],
  };
}

export function setCartSelection(
  localStorage: IKeyValueStorage,
  type: SelectionType,
  ids?: string[],
): void {
  const data: CartSelectionStorageValue = {
    v: VERSION,
    type,
    ids: uniq(ids || []),
    e: Date.now() + TTL,
  };

  localStorage.setItem(STORAGE_KEY, data);
}

export function addIdToCartSelection(localStorage: IKeyValueStorage, id: string): void {
  const {ids} = getCartSelection(localStorage);

  if (ids.indexOf(id) !== -1) {
    // given item already selected, do nothing
    return;
  }

  const data: CartSelectionStorageValue = {
    v: VERSION,
    type: SelectionType.IDS,
    ids: [...ids, id],
    e: Date.now() + TTL,
  };

  localStorage.setItem(STORAGE_KEY, data);
}

export function syncCartSelection(localStorage: IKeyValueStorage, allowedIds?: string[]): void {
  const {type, ids: currentSelectionIds} = getCartSelection(localStorage);

  // Принудительно переводим тип выделения на выделение по идентификаторам
  if (type === SelectionType.ALL) {
    setCartSelection(localStorage, SelectionType.IDS, allowedIds);
  } else if (type === SelectionType.IDS) {
    setCartSelection(localStorage, type, intersection(currentSelectionIds, allowedIds || []));
  }
}

import {ProductBrand as ProductBrandType} from 'types/ProductBrand';

import {ContentListBaseItem, contentListBaseItem} from './ContentListBaseItem';

export type ProductBrand = {
  productBrand: ProductBrandType;
};

export type ContentListProductBrand = ContentListBaseItem<ProductBrand>;

export function contentListProductBrand(
  id: string,
  productBrand: ProductBrand,
): ContentListProductBrand {
  return contentListBaseItem(id, productBrand);
}

export function isContentListProductBrand(
  item: ContentListBaseItem,
): item is ContentListProductBrand {
  return Boolean((item as ContentListProductBrand).content.productBrand);
}

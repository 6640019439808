import loadable from '@loadable/component';
import {ApiClient} from 'helpers/ApiClient';
import {checkAsyncItems} from 'helpers/asyncConnect/checkAsyncItems';
import {config, getItemCheck, LoadConfig} from 'helpers/asyncConnect/config';
import {ecsError} from 'helpers/log/ECS/ecsError';
import {noop} from 'lodash';
import {ComponentClass, ComponentType} from 'react';
import {ConnectedComponent} from 'react-redux';
import {AsyncFunction, DefaultParams} from 'routes/types';
import {RenderingType} from 'types/Rendering';
import {Store} from 'typesafe-actions';
import {LoadableFunction, requireModule} from 'utils/loadable';
import {nextTickPromise} from 'utils/nextTick';
import {createDetachedPromise} from 'utils/promise';
import {isStoreWithTransactions} from 'utils/redux';

import {AsyncItem, isAsyncConnectComponent, PromiseArg, PromiseArgProps} from './types';

type Result = {
  requiredPromise: Promise<unknown>;
  delayedPromise: Promise<unknown>;
  allPromise: Promise<unknown>;
  delayed: boolean;
  executeDelayed: () => Promise<unknown>;
};

function getPromiseArg<TProps extends PromiseArgProps>(
  client: ApiClient,
  store: Store,
  props: TProps,
): PromiseArg<TProps> {
  return {
    store,
    client,
    ...props,
  };
}

// time after which transaction will complete in any case
const MAX_TRANSACTION_DURATION_MS = 600;
// if time after each response without next response took more than that time, transaction will complete
const MAX_TIME_BETWEEN_RESPONSES_MS = 150;

function createTransaction(store: Store): {
  onItemSettled: () => void;
  completeTransaction: () => void;
} {
  let lastResponseTimerId: ReturnType<typeof setTimeout>;

  let completeTransaction = noop;
  let isTransactionCompleted = false;
  const transactionPromise = new Promise<void>((resolve) => {
    completeTransaction = () => {
      clearTimeout(lastResponseTimerId);
      isTransactionCompleted = true;
      resolve();
    };
  });

  const onItemSettled = () => {
    if (isTransactionCompleted) return;
    clearTimeout(lastResponseTimerId);
    lastResponseTimerId = setTimeout(completeTransaction, MAX_TIME_BETWEEN_RESPONSES_MS);
  };

  if (isStoreWithTransactions(store)) {
    store.transaction(() => transactionPromise);
    setTimeout(completeTransaction, MAX_TRANSACTION_DURATION_MS);
  }

  return {onItemSettled, completeTransaction};
}

type ProcessAsyncItemsContext = {
  splitTasks: boolean | undefined;
  client: ApiClient;
  config: LoadConfig | undefined;
  onItemSettled: () => void;
  delayedExecutePromise: Promise<unknown>;
  delayedPromises: Promise<unknown>[];
  requiredPromises: Promise<unknown>[];
  promiseData: PromiseArg<Record<string, unknown>>;
  delayedPromiseData: PromiseArg<Record<string, unknown>>;
};

function processAsyncItem(
  item: AsyncItem,
  queuePromise: Promise<unknown>,
  context: ProcessAsyncItemsContext,
): Promise<unknown> {
  if ('queue' in item) {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    return processQueueAsyncItems(item.queue, queuePromise, context);
  }
  if ('parallel' in item) {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    return processParallelAsyncItems(item.parallel, queuePromise, context);
  }

  if (!item || context.config?.skip?.(item, context.client)) {
    return queuePromise;
  }

  const isDelayed = getItemCheck(item.delayed, context.client);

  const loader = isDelayed
    ? () => context.delayedExecutePromise.then(() => item.promise(context.delayedPromiseData))
    : () => item.promise(context.promiseData);

  const logger = context.client.device.log.getLogger('helpers/asyncConnect');

  const nextQueuePromise = queuePromise
    .then(() => (context.splitTasks ? nextTickPromise(loader) : loader()))
    .catch((error) => logger.info({error: ecsError(error)}))
    .then(context.onItemSettled);

  if (isDelayed) {
    context.delayedPromises.push(nextQueuePromise);
  } else if (!context.config?.filter || context.config.filter(item, context.client)) {
    context.requiredPromises.push(nextQueuePromise);
  }

  return nextQueuePromise;
}

function processQueueAsyncItems(
  items: AsyncItem[],
  queuePromise: Promise<unknown>,
  context: ProcessAsyncItemsContext,
): Promise<unknown> {
  let nextQueuePromise = queuePromise;

  items?.forEach((item) => {
    nextQueuePromise = processAsyncItem(item, nextQueuePromise, context);
  });

  return nextQueuePromise;
}

function processParallelAsyncItems(
  items: AsyncItem[],
  queuePromise: Promise<unknown>,
  context: ProcessAsyncItemsContext,
): Promise<unknown> {
  const promises: Promise<unknown>[] = [];

  items?.forEach((item) => {
    promises.push(processAsyncItem(item, queuePromise, context));
  });

  return Promise.all(promises.length ? promises : [queuePromise]);
}

export function loadAsyncConnect<TProps extends PromiseArgProps>({
  asyncItems,
  client,
  store,
  delayedStore,
  props,
  config,
  inTransaction,
  splitTasks,
  executeDelayed,
}: {
  asyncItems: AsyncItem[];
  client: ApiClient;
  store: Store;
  delayedStore: Store;
  props: TProps;
  config?: LoadConfig;
  inTransaction?: boolean;
  splitTasks?: boolean;
  executeDelayed: boolean;
}): Result {
  const promiseData = getPromiseArg(
    client,
    isStoreWithTransactions(store) ? store.originalStore : store,
    props,
  );
  const delayedPromiseData = getPromiseArg(client, delayedStore, props);

  const {promise: delayedExecutePromise, resolve: resolveDelayed} = executeDelayed
    ? {promise: Promise.resolve(), resolve: noop}
    : createDetachedPromise<void>();

  const {onItemSettled = noop, completeTransaction = noop} = inTransaction
    ? createTransaction(store)
    : {};

  if (__DEVELOPMENT__) {
    checkAsyncItems(asyncItems, client);
  }

  const context: ProcessAsyncItemsContext = {
    splitTasks,
    promiseData,
    delayedPromiseData,
    delayedExecutePromise,
    requiredPromises: [],
    delayedPromises: [],
    client,
    config,
    onItemSettled,
  };

  const allPromise = processParallelAsyncItems(asyncItems, Promise.resolve(), context).then(
    completeTransaction,
  );

  return {
    // True when has delayed tasks
    delayed: context.delayedPromises.length > 0,
    // This promise will be resolved when you can render the page
    requiredPromise: Promise.all(context.requiredPromises),
    // This promise will be resolved when all delayed promises was loaded
    delayedPromise: Promise.all(context.delayedPromises),
    // This promise will be resolved when all data was loaded
    allPromise,
    // Execute delayed items
    executeDelayed: () => {
      resolveDelayed();
      return Promise.all(context.delayedPromises);
    },
  };
}

const modules = new Map<LoadableFunction<unknown>, unknown>();

export function routeAsync<T extends DefaultParams = DefaultParams, E = Record<string, unknown>>(
  loader: LoadableFunction<E>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  resolveComponent: (module: E) => ComponentType<any> | ComponentClass<any>,
): AsyncFunction<T> {
  return async ({
    client,
    store,
    delayedStore = store,
    location,
    match,
    history,
    initial,
    skipDataLoading,
    inTransaction,
    splitTasks,
    executeDelayed,
  }) => {
    const preloadedModule = modules.get(loader) as E | undefined;
    const module = preloadedModule || (await requireModule(loader));
    if (__CLIENT__ && !preloadedModule) {
      modules.set(loader, module);
    }

    const Component = resolveComponent(module);

    let result;

    if (!skipDataLoading && isAsyncConnectComponent(Component)) {
      const initialAfterSsr =
        __CLIENT__ && initial && client.device.getRenderingConfig()?.option === RenderingType.USER;

      result = loadAsyncConnect({
        asyncItems: Component.asyncConnect,
        client,
        store,
        delayedStore,
        props: {
          match,
          location,
          history,
          initial,
          initialAfterSsr,
          // for backwards compatibility
          ...match,
        },
        config,
        inTransaction,
        splitTasks,
        executeDelayed,
      });

      await result.requiredPromise;
    }

    return {
      component: __CLIENT__ ? Component : loadable(loader, {resolveComponent}),
      executeDelayed: result?.executeDelayed,
      delayed: result?.delayed,
      allPromise: result?.allPromise,
      delayedPromise: result?.delayedPromise,
    };
  };
}

export function asyncConnect<TProps extends Record<string, unknown>>(
  asyncItems: AsyncItem<TProps>[],
) {
  return <T extends ComponentType | ConnectedComponent<ComponentType, unknown>>(
    Component: T,
  ): T & {asyncConnect: AsyncItem<TProps>[]} => {
    const PatchedComponent = Component as T & {asyncConnect: AsyncItem<TProps>[]};
    PatchedComponent.asyncConnect = asyncItems;
    return PatchedComponent;
  };
}

const DEFAULT_START = 1;
const DEFAULT_STEP = 1;
const DEFAULT_STRING_BASE = 36;

const createCounter = (start = DEFAULT_START, step = DEFAULT_STEP): (() => number) => {
  let value = start;

  return (): number => {
    const prevValue = value;

    value += step;

    return prevValue;
  };
};

const createStringCounter = (
  toStringBase = DEFAULT_STRING_BASE,
  start = DEFAULT_START,
  step = DEFAULT_STEP,
): (() => string) => {
  const getCounter = createCounter(start, step);

  return (): string => getCounter().toString(toStringBase);
};

export {createCounter, createStringCounter};

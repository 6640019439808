import {GlobalLoader} from 'components/GlobalLoader';
import {useAnalytics} from 'hooks/useAnalytics';
import {useContextProps} from 'hooks/useContextProps';
import React, {Children, ReactElement, useEffect, useRef} from 'react';
import {isInitialRendering, setInitialRendering} from 'routes/async/initialRendering';
import {DefaultParams, PoorRoute} from 'routes/types';

const BOUNCE_CHECK_DELAY = 10000;

type Props<T extends DefaultParams = DefaultParams> = {
  children: ReactElement;
  location: import('history').Location;
  transition?: boolean;
  route: PoorRoute<string, T>;
};

export function PageViewMiddleware<T extends DefaultParams = DefaultParams>({
  location,
  route,
  children,
  transition,
}: Props<T>): JSX.Element | null {
  const analytics = useAnalytics();
  const url = `${location.pathname}${location.search}${location.hash}`;
  const prevLocationRef = useRef<typeof location>();
  const prevLocationRouteRef = useRef<PoorRoute<string, T>>();
  // for class based pages
  const contextProps = useContextProps();
  const {appHistoryInfo} = contextProps;

  useEffect(() => {
    const initialRendering = isInitialRendering();
    setInitialRendering(false);

    if (initialRendering || transition || prevLocationRef.current?.pathname === location.pathname) {
      return;
    }

    contextProps.popupManager.clear();

    const prevRoute = prevLocationRouteRef.current;
    prevLocationRef.current = location;
    prevLocationRouteRef.current = route;

    if (
      route.scrollBehaviour === 'manual' ||
      (route.scrollBehaviour === 'ignore-pathname' && prevRoute === route)
    ) {
      return;
    }

    if (route.scrollBehaviour === 'scroll-restoration' && appHistoryInfo.popItem) {
      const {scrollPosition} = appHistoryInfo.popItem;
      window.scrollTo(0, scrollPosition);
      return;
    }

    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transition, location.pathname]);

  useEffect(() => {
    const openTimeMs = Date.now();
    analytics.sendEvent({
      type: 'pageOpen',
      payload: {url},
    });
    analytics.dataLayer({
      event: 'Common. Page View',
      lastLocation: url,
    });

    const timer = setTimeout(() => {
      analytics.sendEvent({
        type: 'bounceCheck',
        payload: {
          sinceOpenMs: Date.now() - openTimeMs,
          url,
        },
      });
    }, BOUNCE_CHECK_DELAY);

    return () => clearTimeout(timer);
  }, [analytics, url]);

  if (!children) {
    return <GlobalLoader />;
  }

  const child = Children.only<ReactElement>(children);

  return <child.type {...contextProps} {...child.props} />;
}

import config from 'config';
import {Request, Response} from 'express';
import {VerifyHcaptchaOptions} from 'helpers/ApiClient/Transport/HcaptchaProtectionTransport';
import {optionsWithPowSolution} from 'helpers/ApiClient/Transport/ProofOfWork/utils';
import {ApiResponse} from 'helpers/ApiClient/Transport/Response';
import {
  TransportHeaders,
  TransportMeta,
  TransportMethod,
  TransportOptions,
  TransportQuery,
} from 'types/Transport';
import {TypedObject} from 'utils/object/typed';

import {
  addClientRequestIdHeader,
  addExpAppHeader,
  addHeadersFromRequest,
  addOsTypeHeader,
  addRenderingIdHeader,
  addRequestPathHeader,
  addVersionHeader,
} from './headers';
import {Transport} from './index';
import {PREFIX as WEB_PREFIX} from './WebTransport';

type Device = import('../Device').Device;

/** Transport for token requests to self node.js backend */
export class ApiNoAuthTransport extends Transport {
  public override device!: Device;

  constructor(device: Device, req: Request | undefined, res: Response | undefined) {
    super(__SERVER__ ? device.transportEndpoints.serverApi : WEB_PREFIX, device, req, res);
  }

  override buildQuery(query: TransportQuery, meta: TransportMeta): TransportQuery {
    const result = {
      language: meta.locale || meta.language,
      currency: meta.overrideCurrency,
      ...query,
    };

    TypedObject.keys(result).forEach((key) => {
      if (result[key] === undefined) {
        delete result[key];
      }
    });

    return result;
  }

  override buildHeaders(headers: TransportHeaders, meta: TransportMeta): TransportHeaders {
    const result = super.buildHeaders(headers, meta) || {};
    addHeadersFromRequest(addVersionHeader(result, config.version), this.req);
    addRenderingIdHeader(result, this.device?.getRenderingConfig());
    addRequestPathHeader(result, this.req);
    addExpAppHeader(result, this.device.scope);

    if (__SERVER__) {
      addClientRequestIdHeader(result, this.device.getClientRequestId());
      addOsTypeHeader(result, this.device.getDeviceVersion()?.osType);
    }

    return result;
  }

  override request<TBody>(
    method: TransportMethod,
    path: string,
    {
      hcaptchaOptions,
      ...options
    }: TransportOptions & {hcaptchaOptions?: VerifyHcaptchaOptions} = {},
  ): Promise<ApiResponse<TBody>> {
    return this.device.proofOfWorkController.verifyProtection((powSolution) =>
      this.device.transports.hcaptcha.verifyProtection(
        () => super.request(method, path, optionsWithPowSolution(options, powSolution)),
        hcaptchaOptions,
      ),
    );
  }
}

import cn from 'classnames';
import {useEffectOnce} from 'hooks/useEffectOnce';
import {useMergedRef} from 'hooks/useMergedRef';
import React, {
  DetailedHTMLProps,
  DialogHTMLAttributes,
  ForwardedRef,
  forwardRef,
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {RemoveScroll} from 'react-remove-scroll';

import styles from './index.scss';

type HTMLDialogProps = DetailedHTMLProps<
  DialogHTMLAttributes<HTMLDialogElement>,
  HTMLDialogElement
>;

export type DialogProps = Omit<HTMLDialogProps, 'onClose' | 'open'> & {
  onClose?: () => void;
};

export const DialogModal = forwardRef(function DialogModal(
  {children, onClose, className, ...rest}: DialogProps,
  outerRef: ForwardedRef<HTMLDialogElement>,
): ReactElement | null {
  const [isOpen, setIsOpen] = useState(false);
  const innerRef = useRef<HTMLDialogElement>(null);

  useEffectOnce(() => {
    innerRef.current?.showModal();
    setIsOpen(true);
  });

  useEffect(() => {
    // prevent dialog from close by escape key
    // it can't be prevented from 'close' or 'cancel' event
    const handleKey = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        event.preventDefault();
        onClose?.();
      }
    };

    window.addEventListener('keydown', handleKey);

    return () => {
      window.removeEventListener('keydown', handleKey);
    };
  }, [onClose]);

  const handleClose = useCallback(() => {
    onClose?.();
  }, [onClose]);

  const mergedRef = useMergedRef(outerRef, innerRef);

  return (
    <RemoveScroll ref={mergedRef} forwardProps>
      <dialog {...rest} className={cn(styles.root, className)} onClose={handleClose}>
        {isOpen && children}
      </dialog>
    </RemoveScroll>
  );
});

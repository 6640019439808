import {ContentListBaseItem, contentListBaseItem} from './ContentListBaseItem';
import {TermsHeader} from './ContentListTermsHeader';
import {TermsItem} from './ContentListTermsItem';

export type TermsList = {
  title?: TermsHeader;
  items?: TermsItem[];
};

export type ContentListTermsList = ContentListBaseItem<{termsList: TermsList}>;

export function contentListTermsList(id: string, termsList: TermsList): ContentListTermsList {
  return contentListBaseItem(id, {termsList});
}

export function isContentListTermsList(item: ContentListBaseItem): item is ContentListTermsList {
  return Boolean((item as ContentListTermsList).content.termsList);
}

import {LegalEntity, Scope} from './enums';
import {DomainScopeConfig} from './types';

export const domainScopeJmt: DomainScopeConfig = {
  id: 'jmt',
  scope: Scope.GLOBAL,
  legalEntity: LegalEntity.JMT,
  oppositeEntityId: 'joom',
  prefixScopes: [
    {
      scope: Scope.GEEK,
      scopeHeader: 'joomGeek',
      pathPrefix: 'geek',
      disableMobileApps: true,
      hasOppositeEntity: true,
      companyInfo: {
        companyName: 'Джум Гик',
      },
    },
  ],

  defaultLanguage: 'ru',
  defaultCurrency: 'RUB',
  hostname: 'www.joom.ru',

  companyInfo: {
    companyName: 'Джум',
    supportEmail: 'support@jm.tech',
    copyrightEmail: 'ipr@jm.tech',
  },

  static: {
    folder: 'jmt',
    swFileName: 'swJmt',
  },

  integrations: {
    oauthEnvPrefix: 'JMT_',
    fb: {
      appId: '1482500552269341',
    },
    firebase: {
      hostname: 'joomru.page.link',
      config: {
        apiKey: 'AIzaSyB8h2nXRkPocO4lsv1EiY7tBSZDlZr7-X4',
        authDomain: 'jm-tech-b1185.firebaseapp.com',
        projectId: 'jm-tech-b1185',
        storageBucket: 'jm-tech-b1185.appspot.com',
        messagingSenderId: '448958624042',
        appId: '1:448958624042:web:fe46b87fc3cc6178932e53',
        measurementId: 'G-L9TWMJPWHL',
      },
      vapidKey:
        'BH0wdCNCz4cKFYic8clQ2bzQwZGqgylXpdpGSIvJL1dEmd6fjzr5t0NdAJ1K7HT1VSup9Os3_TtlYhsRfehPvKQ',
    },
    googleMapsKey: 'AIzaSyAO2Hbl_rmM7E6EkHRBvPp-YX6QfhH4Zs4',
    gtmId: 'GTM-MNXVP8K',
  },

  mobileApps: {
    android: {
      id: 'tech.jm',
      name: 'Джум',
      scheme: 'jmt',
      url: 'https://play.google.com/store/apps/details?id=tech.jm',
    },
    ios: {
      id: '1667503252',
      bundleId: 'ru.joom.joom',
      url: 'https://apps.apple.com/app/id1667503252',
    },
    rustore: {
      url: 'https://apps.rustore.ru/app/tech.jm',
    },
  },
};

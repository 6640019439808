import {shallowDiffers} from 'utils/shallowDiffers';

export function memoize<Args extends unknown[], Result>(
  fn: (...args: Args) => Result,
  hashFn: (args: Args) => string = JSON.stringify,
): (...args: Args) => Result {
  const cache: Record<string, Result> = {};

  function wrapper(...args: Args): Result {
    const key = hashFn(args);
    if (!(key in cache)) {
      cache[key] = fn(...args);
    }
    return cache[key]!;
  }

  return wrapper;
}

export function memoizeLastByEqualFunc<Args extends unknown[], Result>(
  fn: (...args: Args) => Result,
  equalFn: (lastArgs: Args, args: Args) => boolean,
): (...args: Args) => Result {
  let lastArgs: Args | null = null;
  let lastResult: Result | null = null;

  function wrapper(...args: Args): Result {
    if (lastArgs === null || !equalFn(lastArgs, args)) {
      lastArgs = args;
      lastResult = fn(...args);
    }
    return lastResult!;
  }

  return wrapper as typeof fn;
}

export function shallowEqual(lastArgs: unknown, args: unknown): boolean {
  return !shallowDiffers(lastArgs, args);
}

export function memoizeLastShallowEqual<Args extends unknown[], Result>(
  fn: (...args: Args) => Result,
): (...args: Args) => Result {
  return memoizeLastByEqualFunc(fn, (lastArgs, args) => !shallowDiffers(lastArgs, args));
}

export function memoizeLastShallowEqualEachArg<Args extends unknown[], Result>(
  fn: (...args: Args) => Result,
): (...args: Args) => Result {
  return memoizeLastByEqualFunc(
    fn,
    (lastArgs, args) =>
      lastArgs.length === args.length &&
      lastArgs.every((lastArg, index) => !shallowDiffers(lastArg, args[index])),
  );
}

export function memoizeByResult<TArgs extends unknown[], TResult>(
  fn: (...args: TArgs) => TResult,
  equalFn: (lastResult: TResult, result: TResult) => boolean,
): (...args: TArgs) => TResult {
  let lastResult: TResult;

  return (...args) => {
    const result = fn(...args);
    if (!equalFn(lastResult, result)) {
      lastResult = result;
    }

    return lastResult;
  };
}

// for backwards compatibility
// eslint-disable-next-line import/no-default-export
export default memoize;

import {ContentListHtmlBlock} from 'types/ContentList/ContentListHtmlBlock';

import type {ContentListAwaitingPaymentOrderGroup} from './ContentListAwaitingPaymentOrderGroup';
import type {ContentListBannersList} from './ContentListBannersList';
import type {ContentListBaseItem} from './ContentListBaseItem';
import type {ContentListCoupon} from './ContentListCoupon';
import type {ContentListGroup} from './ContentListGroup';
import type {ContentListParcel, ContentListParcelRaw} from './ContentListParcel';
import type {ContentListPaymentRequisites} from './ContentListPaymentRequisites';
import type {ContentListProduct, ContentListProductRaw} from './ContentListProduct';
import type {ContentListProductAttributes} from './ContentListProductAttributes';
import type {ContentListProductBrand} from './ContentListProductBrand';
import type {ContentListProductCardGroup} from './ContentListProductCardGroup';
import type {ContentListProductCollectionBanner} from './ContentListProductCollectionBanner';
import type {ContentListProductListPurchaseItems} from './ContentListProductListPurchaseItems';
import type {ContentListProductPresaleDiscount} from './ContentListProductPresaleDiscount';
import type {ContentListProductReviews} from './ContentListProductReviews';
import type {ContentListProductRowList} from './ContentListProductRowList';
import type {ContentListProductsList} from './ContentListProductsList';
import type {ContentListProductStore} from './ContentListProductStore';
import type {ContentListPromotionsCarousel} from './ContentListPromotionsCarousel';
import type {ContentListPurchaseStats} from './ContentListPurchaseStats';
import type {ContentListRowList} from './ContentListRowList';
import type {ContentListSearchFilters} from './ContentListSearchFilters';
import type {ContentListSearchRefinements} from './ContentListSearchRefinements';
import type {ContentListSideBannerGroup} from './ContentListSideBannerGroup';
import type {ContentListSlimBanner} from './ContentListSlimBanner';
import type {ContentListTermsHeader} from './ContentListTermsHeader';
import type {ContentListTermsItem} from './ContentListTermsItem';
import type {ContentListTermsList} from './ContentListTermsList';
import type {ContentListVariantParameters} from './ContentListVariantParameters';
import type {ContentListPowerShop} from './PowerShop';
import type {ContentListRow} from './Row';
import type {ContentListSearchStoreList} from './SearchStoreList';

export enum ContentListItemType {
  ALERT_NOTHING_FOUND = 'alertNothingFound',
  BANNERS_LIST = 'bannersList',
  COUNTDOWN_ROW_V2 = 'countdownRowV2',
  COUPON = 'coupon',
  GROUP = 'group',
  LAYOUT = 'layout',
  POWER_SHOP = 'powerShop',
  PRE_OFFER = 'preOffer',
  PRODUCT = 'product',
  PRODUCT_FROM_COLLECTIONS = 'productFromCollections',
  PRODUCT_COLLECTION_BANNER = 'productCollectionBanner',
  PRODUCT_ATTRIBUTES = 'productAttributes',
  PRODUCT_BRAND = 'productBrand',
  PRODUCT_CARD_GROUP = 'productCardGroup',
  PRODUCT_PRESALE_DISCOUNT = 'productPresaleDiscount',
  PRODUCT_REVIEWS = 'productReviews',
  PRODUCT_STORE = 'productStore',
  PRODUCTS_LIST = 'productsList',
  PROMOTIONS_CAROUSEL = 'promotionsCarousel',
  PURCHASE_STATS = 'purchaseStats',
  ROW = 'row',
  ROW_LIST = 'rowList',
  SCREEN_HEADER = 'screenHeader',
  SEARCH_FILTERS = 'searchFilters',
  SEARCH_STORE_LIST = 'searchStoreList',
  SECTION_HEADER = 'sectionHeader',
  SIDE_BANNER_GROUP = 'sideBannerGroup',
  SLIM_BANNER = 'slimBanner',
  TERMS_HEADER = 'termsHeader',
  TERMS_ITEM = 'termsItem',
  TERMS_LIST = 'termsList',
  VARIANT_PARAMETERS = 'variantParameters',
  HTML_BLOCK = 'htmlBlock',
  PARCEL = 'parcel',
  PAYMENT_REQUISITES = 'paymentRequisites',
  AWAITING_PAYMENT_ORDER_GROUP = 'awaitingPaymentOrderGroup',
  PRODUCT_LIST_PURCHASE_ITEMS = 'productListPurchaseItems',
}
/**
 * Type for describing api responses, for other cases please use ContentListItem
 */
export type ContentListItemRaw = ContentListBaseItem | ContentListProductRaw | ContentListParcelRaw;

export type ContentListItem =
  | ContentListBannersList
  | ContentListCoupon
  | ContentListGroup
  | ContentListPowerShop
  | ContentListProduct
  | ContentListProductAttributes
  | ContentListProductBrand
  | ContentListProductCardGroup
  | ContentListProductPresaleDiscount
  | ContentListProductReviews
  | ContentListProductsList
  | ContentListProductStore
  | ContentListPromotionsCarousel
  | ContentListPurchaseStats
  | ContentListRow
  | ContentListRowList
  | ContentListSearchFilters
  | ContentListSearchRefinements
  | ContentListSearchStoreList
  | ContentListSideBannerGroup
  | ContentListSlimBanner
  | ContentListTermsHeader
  | ContentListTermsItem
  | ContentListTermsList
  | ContentListVariantParameters
  | ContentListProductCollectionBanner
  | ContentListHtmlBlock
  | ContentListParcel
  | ContentListPaymentRequisites
  | ContentListAwaitingPaymentOrderGroup
  | ContentListProductListPurchaseItems
  | ContentListProductRowList;

export type ContentListRaw = {
  items: ContentListItemRaw[];
  nextPageToken?: string;
  totalCount?: number;
};

export type ContentList<TItem extends ContentListItem = ContentListItem> = {
  items: TItem[];
  nextPageToken?: string;
  totalCount?: number;
};

export type ContentListWithCount = ContentList & {
  shownCount: number;
  incompleteChunkLength: number;
};

export function contentList<TItem extends ContentListItem>(
  items: TItem[],
  nextPageToken = '',
  totalCount?: number,
): ContentList<TItem> {
  return {items, nextPageToken, totalCount};
}

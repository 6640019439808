import {CookieType} from 'types/CookiesSettings';
import {CATEGORY_PROMO_LINK_ONBOARDING_COOKIE} from 'utils/categoryPromoLink';

import {CookieValue} from '../Device/CookieValue';
import {stringDecoder, stringEncoder} from './helpers';

const COOKIE_NAME = CATEGORY_PROMO_LINK_ONBOARDING_COOKIE;
const MAX_AGE = 2 * 60 * 60;

export const CategoryPromoLinkOnboarding: CookieValue<string> = {
  type: CookieType.FUNCTIONAL,
  key: COOKIE_NAME,
  decode: stringDecoder,
  encode: stringEncoder,
  getOptions: () => ({
    maxAge: MAX_AGE,
  }),
};

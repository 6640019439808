import type {ContentListItem} from '.';
import {ContentListBaseItem, contentListBaseItem} from './ContentListBaseItem';

type Group = {
  id: string;
  groupId: string;
  type: 'flex' | 'grid';
  items: ContentListItem[];
};

export type ContentListGroup = ContentListBaseItem<Group>;

export function contentListGroup(group: Group): ContentListGroup {
  return contentListBaseItem(group.id, group);
}

export function isContentListGroup(item: ContentListBaseItem): item is ContentListGroup {
  return Boolean((item as ContentListGroup).content.items);
}

import {CookieValue} from 'helpers/ApiClient/Device/CookieValue';
import {CookieType} from 'types/CookiesSettings';

import {getCloseCookieKey, stringDecoder, stringEncoder} from '../helpers';

const JOOM_MOBILE_APP_BANNER_CLOSE_ID = 'joomMobileAppBanner';

const MAX_AGE = 60 * 60 * 24;

export const JoomMobileAppBannerCloseCookie: CookieValue<string> = {
  type: CookieType.FUNCTIONAL,
  key: getCloseCookieKey(JOOM_MOBILE_APP_BANNER_CLOSE_ID),
  decode: stringDecoder,
  encode: stringEncoder,
  getOptions: () => ({
    maxAge: MAX_AGE,
  }),
};

import uuidv4 from 'uuid/v4';

export function guid(): string {
  return uuidv4();
}

// it is safe, see https://stackoverflow.com/questions/51830845/how-safe-is-it-to-remove-the-in-a-randomly-generated-uuid
export function guidWithoutDashes(): string {
  return uuidv4().replace(/-/g, '');
}

import {routeAsync} from 'helpers/asyncConnect';

import {anonymous} from './decorators';
import {DefaultParams, PoorRoute} from './types';

type Params = DefaultParams & {
  productId: string;
};

const NAME = 'productFromProductGroup';

export const ProductFromProductGroupRoute: PoorRoute<typeof NAME, Params> = {
  name: NAME,
  path: '/groups/:productGroupId/:productId',
  async: routeAsync(
    /* #__LOADABLE__ */ () => import('containers/pages/Product'),
    (module) => module.ProductPage,
  ),
  decorator: anonymous,
};

import {ProductRoute} from './ProductRoute';
import {DefaultParams, PoorRoute} from './types';

type Params = DefaultParams & {
  productId: string;
};

const NAME = 'productFeedback';

export const ProductFeedbackRoute: PoorRoute<typeof NAME, Params> = {
  ...ProductRoute,
  path: '/products/:productId/:feedback(feedback)',
  name: NAME,
};

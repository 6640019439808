import {ContentListBaseItem} from 'types/ContentList/ContentListBaseItem';
import {EventParams} from 'types/EventParams';
import {ImageBundle} from 'types/Image';
import {ProductCollectionLite} from 'types/ProductCollection/ProductCollectionLite';
import {ProductCollectionStats} from 'types/ProductCollection/ProductCollectionStats';

export type ProductCollectionBanner = {
  avatar?: ImageBundle;
  productCollectionLite: ProductCollectionLite;
  mainStats?: ProductCollectionStats;
  deeplink?: string;
  eventParams?: EventParams;
};

export type ContentListProductCollectionBanner = ContentListBaseItem<{
  productCollectionBanner: ProductCollectionBanner;
}>;

export function isContentListProductCollectionBanner(
  item: ContentListBaseItem,
): item is ContentListProductCollectionBanner {
  return Boolean((item as ContentListProductCollectionBanner).content.productCollectionBanner);
}

import {isRecord} from 'utils/guards';

import {CheckoutSessionItem} from './CheckoutSession';

export type ApiError = {
  message?: string;
  payload?: {[name: string]: string};
  requestId?: string;
  status: number;
  type: string;
  userTitle?: string;
  userMessage?: string;
};

export type PopupErrorButton = {
  text: string;
  url?: string;
};

export type PopupError = {
  type?: string;
  payload: {
    popup: {
      buttonBreak?: PopupErrorButton;
      buttonContinue?: PopupErrorButton;
      items?: CheckoutSessionItem[];
      text?: string;
      title?: string;
    };
  };
};

export type RuntimeError = {
  error?: RuntimeError;
  stack?: string;
  name?: string;
  message: string;
};

export type Error = ApiError | RuntimeError;

export function isApiError(error: unknown): error is ApiError {
  return typeof error === 'object' && error !== null && 'type' in error && 'status' in error;
}

export function isRuntimeError(error: unknown): error is RuntimeError {
  return (
    typeof error === 'object' &&
    error !== null &&
    typeof (error as RuntimeError).message === 'string'
  );
}

export function isPopupError(error: unknown): error is PopupError {
  return (
    typeof error === 'object' &&
    error !== null &&
    'payload' in error &&
    isRecord((error as PopupError).payload) &&
    'popup' in (error as PopupError).payload
  );
}

export type ErrorShape = {
  body?: {
    message?: string;
  };
  error?: {
    // error message for testing purposes only
    message?: string;
    requestId?: string;
    status?: string;
    type?: string;
    userMessage?: string;
    userTitle?: string;
  };
  message?: string;
  status?: number;
};

import {routeAsync} from 'helpers/asyncConnect';

import {anonymous} from './decorators';
import {DefaultParams, PoorRoute} from './types';

const NAME = 'bloggerTerms';

export const BloggerTermsRoute: PoorRoute<typeof NAME, DefaultParams> = {
  name: NAME,
  path: '/blogger-terms',
  async: routeAsync(
    /* #__LOADABLE__ */ () => import('containers/pages/BloggerTermsPage'),
    (module) => module.BloggerTermsPage,
  ),
  decorator: anonymous,
};

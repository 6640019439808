import PropTypes from 'prop-types';

const VwSizeShape = PropTypes.oneOfType([PropTypes.number, PropTypes.string]);

export const VwFitShape = PropTypes.oneOfType([
  PropTypes.number,
  PropTypes.shape({
    xs: VwSizeShape,
    md: VwSizeShape,
    lg: VwSizeShape,
    xl: VwSizeShape,
  }),
]);

import {Device} from 'helpers/ApiClient/Device';
import {ecsError} from 'helpers/log/ECS/ecsError';
import {DeviceVars} from 'types/deviceVars';

import {Session} from './Session';

type Config = Exclude<DeviceVars['fingerprintjsClient'], undefined>;

type Trigger = Exclude<Config['profilingTriggers'], undefined>[number];

const DEFAULT_CONFIG: Readonly<Config> = {enabled: false};

const STORAGE_KEY = 'fingerprintTriggerSessionIdMap';

export class Fingerprint {
  constructor(
    private device: Device,
    private session: Session,
  ) {
    this.session.on('start', this.run.bind(this, 'sessionStart'));
  }

  private logger = this.device.log.getLogger('Fingerprint');

  private triggerSessionIdMap: Record<string, string | undefined> = {};

  private getSessionIdForTrigger(trigger: Trigger) {
    return this.triggerSessionIdMap[trigger];
  }

  private setSessionIdForTrigger(trigger: Trigger, sessionId: string) {
    this.triggerSessionIdMap[trigger] = sessionId;
    localStorage?.setItem(STORAGE_KEY, JSON.stringify(this.triggerSessionIdMap));
  }

  private FingerprintJSPromise?: Promise<typeof import('@fingerprintjs/fingerprintjs').default>;

  private get config(): Readonly<Config> {
    return this.device.getDeviceVar('fingerprintjsClient') || DEFAULT_CONFIG;
  }

  private get isEnabled() {
    return __CLIENT__ && this.config.enabled;
  }

  private getFingerprintJS() {
    if (!this.FingerprintJSPromise) {
      this.FingerprintJSPromise = import('@fingerprintjs/fingerprintjs').then((lib) => lib.default);
    }

    return this.FingerprintJSPromise;
  }

  async run(trigger: Trigger): Promise<void> {
    const sessionId = this.session.getId();

    if (
      !this.isEnabled ||
      !this.config.profilingTriggers?.includes(trigger) ||
      // send fingerprint once per session for each trigger
      this.getSessionIdForTrigger(trigger) === sessionId
    ) {
      return;
    }

    this.setSessionIdForTrigger(trigger, sessionId);

    const FingerprintJS = await this.getFingerprintJS();

    const timeStart = Date.now();
    let duration;

    try {
      const agent = await FingerprintJS.load({delayFallback: this.config.delayFallback});
      const result = await agent.get();

      duration = Date.now() - timeStart;

      const body = {
        fingerprintJsResult: result,
        profilingDurationMs: duration,
        profilingTrigger: trigger,
      };

      this.logger.log('fingerprint', body);

      await this.device.transports.api.post('/fingerprints', {body});
    } catch (error) {
      this.logger.error({error: ecsError(error)});

      if (duration == null) {
        duration = Date.now() - timeStart;
      }

      await this.device.transports.api
        .post('/fingerprints', {
          body: {
            error: {
              type: Object(error).name || 'UnknownType',
              message: Object(error).message || 'unknown error',
            },
            profilingDurationMs: duration,
            profilingTrigger: trigger,
          },
        })
        .catch(() => null);
    }
  }

  async init(): Promise<void> {
    if (this.isEnabled) {
      try {
        const storageData = localStorage?.getItem(STORAGE_KEY);
        if (storageData) {
          this.triggerSessionIdMap = JSON.parse(storageData);
        }
        // eslint-disable-next-line no-empty
      } catch {}

      // async load
      this.getFingerprintJS();
    }
  }
}

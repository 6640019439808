import {Scope} from 'config';
import {isScope} from 'helpers/ApiClient/Scope/utils';
import {CookieType} from 'types/CookiesSettings';

import {CookieValue} from '../Device/CookieValue';

const MAX_AGE = 60 * 10;
const COOKIE_NAME = 'authscope';

/**
 * Temporary cookie to set auth scope during authorization.
 */
export const AuthPrefixScopeCookie: CookieValue<Scope | undefined> = {
  type: CookieType.FUNCTIONAL,
  key: COOKIE_NAME,
  decode: (str) => (isScope(str) ? str : undefined),
  encode: (scope) => scope || '',
  getOptions: () => ({
    httpOnly: true,
    maxAge: MAX_AGE,
  }),
};

import {enhanceLoadedTime} from 'store/utils/enhancers';
import {getValueByPath} from 'utils/object';
import {discardableByAny} from 'helpers/discardable';
import {enhanceContentListItems} from 'store/enhancer/contentList';

const FILTER_APPLY = 'parcels/FILTER_APPLY';
const FILTER_APPLY_FAIL = 'parcels/FILTER_APPLY_FAIL';
const FILTER_APPLY_SUCCESS = 'parcels/FILTER_APPLY_SUCCESS';
const FILTERS_LOAD = 'parcels/FILTERS_LOAD';
const FILTERS_LOAD_FAIL = 'parcels/FILTERS_LOAD_FAIL';
const FILTERS_LOAD_SUCCESS = 'parcels/FILTERS_LOAD_SUCCESS';
const LOAD = 'parcels/LOAD';
const LOAD_FAIL = 'parcels/LOAD_FAIL';
const LOAD_SUCCESS = 'parcels/LOAD_SUCCESS';
const DISCARD = 'parcels/DISCARD';
const DISCARD_INACTIVE_FILTERS = 'parcels/DISCARD_INACTIVE_FILTERS';

export const DEFAULT_ACTIVE_FILTER = 'all';

const initialState = {
  activeFilter: DEFAULT_ACTIVE_FILTER,
  error: null,
  filtersMeta: null,
  filters: [],
  filtersLoaded: false,
  loaded: false,
  loading: false,
  nextPageLoading: false,
  nextPageToken: '',
  parcelsMap: {
    [DEFAULT_ACTIVE_FILTER]: {
      loaded: false,
    },
  },
};

function getItems(stateItems = [], resultItems = []) {
  return stateItems.concat(
    resultItems.filter(
      (resultItem) => !stateItems.some((stateItem) => stateItem.id === resultItem.id),
    ),
  );
}

export function getParcelsCountByFilter(filters, filter = DEFAULT_ACTIVE_FILTER) {
  const currentFilter = filters.find((one) => one.id === filter);
  return currentFilter ? currentFilter.count : 0;
}

function getParcelsMapByFilter({currentParcelsMap, filterId, items, nextPageToken}) {
  const parcels = getItems(currentParcelsMap[filterId].parcels, items);
  const parcelsMap = {...currentParcelsMap};
  parcelsMap[filterId] = {
    ...parcelsMap[filterId],
    nextPageToken,
    parcels,
    loaded: true,
  };
  return parcelsMap;
}

function reducer(state = initialState, action) {
  switch (action.type) {
    case LOAD: {
      const {pageToken} = action;

      return {
        ...state,
        loading: !pageToken,
        nextPageLoading: Boolean(pageToken),
        error: null,
      };
    }
    case LOAD_SUCCESS:
    case FILTER_APPLY_SUCCESS: {
      const {
        result: {items, nextPageToken, filterId, emailConfirmationBanner},
      } = action;
      const parcelsMap = getParcelsMapByFilter({
        items,
        nextPageToken,
        filterId,
        currentParcelsMap: state.parcelsMap,
      });

      return {
        ...state,
        loading: false,
        nextPageLoading: false,
        parcelsMap,
        emailConfirmationBanner,
      };
    }
    case LOAD_FAIL:
    case FILTER_APPLY_FAIL: {
      return {
        ...state,
        ...initialState,
        error: action.error,
        loading: false,
        nextPageLoading: false,
      };
    }
    case FILTERS_LOAD_SUCCESS: {
      const filters = action.result.items;
      const parcelsMap = {};
      filters.forEach((filter) => {
        parcelsMap[filter.id] = {
          loaded: filter.count === 0,
          parcels: [],
        };
      });

      return {
        ...state,
        parcelsMap,
        filters,
        filtersMeta: {
          language: action.result.language,
        },
        filtersLoaded: true,
      };
    }
    case FILTER_APPLY: {
      const {activeFilter, loadNew} = action;
      if (activeFilter === state.activeFilter) {
        return state;
      }

      return {
        ...state,
        activeFilter,
        loading: loadNew,
        error: null,
      };
    }
    case DISCARD_INACTIVE_FILTERS: {
      const newMap = {
        [state.activeFilter]: state.parcelsMap[state.activeFilter],
      };

      state.filters.forEach((filter) => {
        if (filter.id !== state.activeFilter) {
          newMap[filter.id] = {
            loaded: filter.count === 0,
            parcels: [],
          };
        }
      });

      return {
        ...state,
        parcelsMap: newMap,
      };
    }
    case DISCARD: {
      return {
        ...state,
        ...initialState,
      };
    }
    default:
      return state;
  }
}

export default discardableByAny(reducer);

export function isParcelsLoaded(globalState) {
  const filterId = globalState.parcels.activeFilter;
  return globalState.parcels.parcelsMap[filterId].loaded;
}

export function isParcelFiltersLoaded(globalState) {
  const {parcels} = globalState;
  return parcels.filters.length > 0;
}

export function discardInactiveFilters() {
  return {
    type: DISCARD_INACTIVE_FILTERS,
  };
}

export function discardParcels() {
  return {
    type: DISCARD,
  };
}

export function loadParcels(filterId = DEFAULT_ACTIVE_FILTER, pageToken = null) {
  const query = {pageToken, filterId};

  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    pageToken,
    promise: (client) =>
      client.api.get('/orderList', {query}).then(
        ({
          currency,
          language,
          body: {
            contexts,
            payload: {items, nextPageToken, emailConfirmationBanner},
          },
        }) => ({
          items: enhanceContentListItems(items, language, currency, pageToken, contexts),
          nextPageToken,
          filterId,
          emailConfirmationBanner,
        }),
      ),
  };
}

export function loadFilters() {
  return {
    types: [FILTERS_LOAD, FILTERS_LOAD_SUCCESS, FILTERS_LOAD_FAIL],
    promise: (client) =>
      client.api.get('/parcels/filters').then(({language, body: {payload}}) => ({
        ...payload,
        items: (payload.items || []).map((item) => enhanceLoadedTime(item)),
        language,
      })),
  };
}

export function filterParcels(
  activeFilter = DEFAULT_ACTIVE_FILTER,
  loadNew = true,
  pageToken = null,
) {
  return loadNew
    ? {
        types: [FILTER_APPLY, FILTER_APPLY_SUCCESS, FILTER_APPLY_FAIL],
        activeFilter,
        loadNew,
        promise: (client, dispatch) => dispatch(loadParcels(activeFilter, pageToken)),
      }
    : {
        type: FILTER_APPLY,
        activeFilter,
        loadNew,
      };
}

export function getShippingEstimate(parcelLite) {
  return getValueByPath(parcelLite, 'shippingEstimate');
}

export function getStatusAppearance(parcelLite) {
  return getValueByPath(parcelLite, 'statusAppearance');
}

export function getStatusAppearanceTitle(parcelLite) {
  return getValueByPath(parcelLite, 'statusAppearance', 'title');
}

export function getStatusAppearanceText(parcelLite) {
  return getValueByPath(parcelLite, 'statusAppearance', 'text');
}

export function getDeliveredTimeMs(parcelLite) {
  return getValueByPath(parcelLite, 'deliveredTimeMs') || null;
}

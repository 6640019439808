import {routeAsync} from 'helpers/asyncConnect';

import {anonymous} from './decorators';
import {DefaultParams, PoorRoute} from './types';

type Params = DefaultParams & {
  quizType: string;
  category?: string;
};

const NAME = 'quiz';

export const QuizRoute: PoorRoute<typeof NAME, Params> = {
  name: NAME,
  path: '/quiz/:quizType/:category?',
  async: routeAsync(
    /* #__LOADABLE__ */ () => import('containers/pages/QuarantineQuiz'),
    (module) => module.QuarantineQuizPage,
  ),
  decorator: anonymous,
};

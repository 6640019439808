import type {Request} from 'express';

let requestId = 0;

export function getHost(req: Request): string {
  if (__DEVELOPMENT__) {
    // Host header has port but don't properly handle proxy
    return req.get('Host') || '';
  }

  // req.hostname hasn't port but properly handle proxy
  return req.hostname;
}

export function getOrigin(req: Request): string {
  // req.protocol properly handle proxy
  return `${req.protocol}://${getHost(req)}`;
}

export function getHref(req: Request): string {
  return getOrigin(req) + req.originalUrl;
}

export function getReferrer(req: Request): string | undefined {
  return req.get('Referrer');
}

export function getUniqueRequestId(): number {
  requestId += 1;

  return requestId;
}

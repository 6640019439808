import {CookieType} from 'types/CookiesSettings';
import {createFlagCodec} from 'utils/flagCodec';

import {CookieValue} from '../Device/CookieValue';

const COOKIE_NAME = 'a11ySettings';
// новые ключи добавлять только в конец!
const KEYS = ['highContrast', 'disableAutoload'];

const {encode, decode} = createFlagCodec(KEYS);

export const A11ySettingsCookie: CookieValue<Record<string, boolean>> = {
  type: CookieType.FUNCTIONAL,
  key: COOKIE_NAME,
  encode,
  decode,
};

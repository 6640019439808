import {DependencyList, EffectCallback, useEffect, useLayoutEffect} from 'react';

const EMPTY_LIST: DependencyList = [];

export function useEffectOnce(effect: EffectCallback): void {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(effect, EMPTY_LIST);
}

export function useLayoutEffectOnce(effect: EffectCallback): void {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useLayoutEffect(effect, EMPTY_LIST);
}

import {ApiClient} from 'helpers/ApiClient';
import {AsyncItemBase, AsyncItemCheck} from 'helpers/asyncConnect/types';
import {RenderingType} from 'types/Rendering';

type FilterFunc<T> = (attrs: T, client: ApiClient) => boolean;

export type LoadConfig = {
  filter?: FilterFunc<AsyncItemBase>;
  skip?: FilterFunc<AsyncItemBase>;
};

export function getItemCheck(check: AsyncItemCheck, client: ApiClient): boolean | undefined {
  return typeof check === 'function' ? check(client) : check;
}

const clientConfig: LoadConfig = {
  filter: (item, client) => {
    return (
      getItemCheck(item.deferred, client) !== true && getItemCheck(item.delayed, client) !== true
    );
  },
  skip: (item, client) => {
    return getItemCheck(item.csr, client) === false;
  },
};

const serverConfig: LoadConfig = {
  skip: (item, client): boolean => {
    const renderingConfig = client.device.getRenderingConfig();
    if (getItemCheck(item.ssr, client) === false) {
      return true;
    }
    if (
      renderingConfig?.option === RenderingType.CRAWLER ||
      getItemCheck(item.delayed, client) === true
    ) {
      return false;
    }
    return getItemCheck(item.deferred, client) === true;
  },
};

export const config = __SERVER__ ? serverConfig : clientConfig;

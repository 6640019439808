import {ContentListBaseItem, contentListBaseItem} from './ContentListBaseItem';
import {CountdownRowV2} from './CountdownRowV2';

export type ContentListCountdownRowV2 = ContentListBaseItem<{countdownRowV2: CountdownRowV2}>;

export function contentListCountdownRowV2(
  id: string,
  countdownRowV2: CountdownRowV2,
): ContentListCountdownRowV2 {
  return contentListBaseItem(id, {countdownRowV2});
}

export function isContentListCountdownRowV2(
  item: ContentListBaseItem,
): item is ContentListCountdownRowV2 {
  return Boolean((item as ContentListCountdownRowV2).content.countdownRowV2);
}

export function extractCountdownRowV2(item: ContentListCountdownRowV2): CountdownRowV2 {
  return item.content.countdownRowV2;
}

export function getPrefixiedKey(key: string): string {
  return `joom.${key}`;
}

export type Storage = typeof window.localStorage;

export function isStorageAvailable(storage: Storage): boolean {
  if (!storage) {
    return false;
  }

  const test = getPrefixiedKey('test');
  try {
    storage.setItem(test, test);
    storage.removeItem(test);
    return true;
  } catch (ex) {
    return false;
  }
}

import {Scope} from 'config';
import {routeAsync} from 'helpers/asyncConnect';

import {anonymous} from './decorators';
import {PoorRoute} from './types';

const NAME = 'favoriteLikedCollections';

export const FavoriteLikedCollectionsRoute: PoorRoute<typeof NAME> = {
  name: NAME,
  scope: [Scope.GLOBAL],
  path: '/favorites/collections/liked',
  async: routeAsync(
    /* #__LOADABLE__ */ () => import('containers/pages/FavoriteLikedCollectionsPage'),
    (module) => module.FavoriteLikedCollectionsPage,
  ),
  decorator: anonymous,
  strict: true,
};

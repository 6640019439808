import {Color} from 'types/Color';
import type {EventParams} from 'types/EventParams';
import {Gradient, LinearMulticolorGradient} from 'types/Gradient';
import {ImageBundle} from 'types/Image';
import {StyledButton} from 'types/StyledButton';

import {CornerPosition, Header, HorizontalPosition, Timer, VerticalPosition} from './common';

export enum BannersListImageType {
  ICON = 'icon',
  PICTURE = 'picture',
}

export enum BannersListDesign {
  FULL_SHEET_LONG = 'fullSheetLongWeb',
  THREE_IN_ROW = 'threeInRowWeb',
  SMALL_IMAGE_GRID = 'smallImageGridWeb',
  SMALL_CIRCLE = 'smallCircleWeb',
  CARD_GRID = 'cardGrid',
}

export type BannersListBannerAppearance = {
  showTitle?: boolean;
  showSubtitle?: boolean;
  imageType?: BannersListImageType;
};

export type BannersListItem = {
  id: string;
  title?: string;
  titleImage?: ImageBundle;
  subtitle?: string;
  secondaryText?: string;
  background?: ImageBundle;
  // descktop background for `fullSheetLongWeb` banner
  backgroundFull?: ImageBundle;
  button?: StyledButton;
  icon?: ImageBundle;
  gradient?: Gradient | LinearMulticolorGradient;
  appearance?: {
    horTextPosition?: HorizontalPosition;
    vertTextPosition?: VerticalPosition;
    textBackgroundColor?: Color;
    textColor?: Color;
    showBackgroundBelowText?: boolean;
    counterPosition?: CornerPosition;
  };
  autoscrollTimerMs?: number;
  type?: 'deeplink';
  payload: {
    url?: string;
  };
  timer?: Timer;
  eventParams?: EventParams;
};

export type BannersList = {
  design: BannersListDesign;
  items: Array<BannersListItem>;
  bannerAppearance?: BannersListBannerAppearance;
  headerAppearance?: {
    simple?: Header;
  };
  eventParams?: EventParams;
};

import {routeAsync} from 'helpers/asyncConnect';

import {parcelOpen} from './async/parcelOpen';
import {hasCheckoutAccess} from './decorators';
import {AsyncFunction, DefaultParams, PoorRoute} from './types';

type Params = DefaultParams & {
  parcelId: string;
  action?: string;
};

const NAME = 'order';

const productRouteAsync: AsyncFunction<Params> = routeAsync(
  /* #__LOADABLE__ */ () => import('containers/pages/ParcelPage'),
  (module) => module.ParcelPage,
);

export const OrderRoute: PoorRoute<typeof NAME, Params> = {
  name: NAME,
  path: '/orders/:parcelId/:action?',
  async: async (options) => {
    const redirectUrl = await parcelOpen(options);

    if (redirectUrl) {
      return {
        redirect: {link: redirectUrl},
      };
    }

    return productRouteAsync(options);
  },
  decorator: hasCheckoutAccess,
};

import EventEmitter from 'component-emitter';
import {Expandable} from 'types/utility';
import {TypedObject} from 'utils/object/typed';

import {Appender} from './appenders/Appender';
import {Event, EventArgs, LoggerEvent} from './Event';
import {Level, Verbosity} from './Level';
import {EventType, Logger} from './Logger';

const MAX_TRIMMED_VALUE_LENGTH = 15;
const MAX_TRIMMED_JSON_LENGTH = 300;

class Log<
  // when Log constructed, it inference TMeta from constructor,
  // but when used as type, usually u don't need to set TMeta
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TMeta extends Record<string, unknown> = any,
  TArgs extends EventArgs = EventArgs,
> extends EventEmitter<{
  [EventType.LOG]: [Event<TMeta, TArgs>];
}> {
  appenders: Appender<TMeta, TArgs>[];

  loggers: Record<string, Logger<TArgs>>;

  meta: TMeta;

  constructor(meta: TMeta) {
    super();

    this.appenders = [];
    this.loggers = {};
    this.meta = meta;
  }

  addMeta(meta: Partial<TMeta>): this {
    this.meta = {...this.meta, ...meta};

    return this;
  }

  addAppender(appender: Appender<TMeta, TArgs>): this {
    this.appenders.push(appender);

    return this;
  }

  getLogger(name = 'main'): Logger<TArgs> {
    let logger = this.loggers[name];
    if (!logger) {
      logger = new Logger(name);
      logger.on(EventType.LOG, this.handleLog);
      this.loggers[name] = logger;
    }

    return logger;
  }

  handleLog = (data: LoggerEvent<TArgs>): void => {
    this.appenders.forEach((appender) => appender.process({...data, meta: this.meta}));
  };
}

/**
 * use this log only if u can't get log from context / clientApi
 */
const globalLog = new Log({});

function trim(str: string, length: number): string {
  return str.length > length ? `${str.substr(0, length)}...` : str;
}

function jsonReplacer<TValue>(name: string, value: TValue): TValue | string {
  if (typeof value === 'string') {
    return trim(value, MAX_TRIMMED_VALUE_LENGTH);
  }

  return value;
}

function serializeAndShortObject(data: unknown): string {
  return trim(JSON.stringify(data, jsonReplacer), MAX_TRIMMED_JSON_LENGTH);
}

function jsonToLevelConfig(
  json?: Record<string, keyof typeof Level>,
): Record<string, Verbosity> | null {
  if (!json) {
    return null;
  }
  const result: Record<string, Verbosity> = {};
  let hasKeys = false;
  TypedObject.entries(json).forEach(([key, value]) => {
    const level = Level[value];
    if (level) {
      result[key] = level;
      hasKeys = true;
    }
  });
  return hasKeys ? result : null;
}

function jsonStringToLevelConfig(str: string): Record<string, Verbosity> | null {
  try {
    const level = (Level as Expandable<typeof Level>)[str];
    if (level) {
      return {'*': level};
    }
    return jsonToLevelConfig(JSON.parse(str));
  } catch (ex) {
    if (__DEVELOPMENT__) {
      // eslint-disable-next-line no-console
      console.error(ex);
    }
    return null;
  }
}

export {Level, Log, globalLog, jsonToLevelConfig, jsonStringToLevelConfig, serializeAndShortObject};

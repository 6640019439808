import config from 'config';
import {RequestEvent} from 'helpers/ApiClient/Analytics/types';
import {Device} from 'helpers/ApiClient/Device';
import {Transport} from 'helpers/ApiClient/Transport';
import {ECSLogger} from 'helpers/log/ECS/types';
import {
  getVisibilityChangeEventName,
  isPageVisibilitySupported,
  isPageVisible,
} from 'helpers/pageVisibility';
import {getServerTimestamp} from 'helpers/serverTime';
import {AnalyticsEvent, AnalyticsLogger, AnalyticsSendOptions} from 'types/AnalyticsEvent';
import {arrayToObject} from 'utils/array';
import {guid} from 'utils/guid';

import {gtag, send as sendToDataLayer} from './counters/dataLayer';
import {
  ConsentArg,
  convertCookiesSettingsToGtmConsentParams,
  convertCookiesSettingsToGtmCookiesSettings,
  initGtm,
} from './gtm';

declare global {
  // eslint-disable-next-line no-underscore-dangle
  const __GTM_ENABLED__: boolean;
}

export const ThirdParty = {
  FACEBOOK: 'facebook',
  GOOGLE: 'google',
  RTB_HOUSE: 'rtbhouse',
};

const EVENT_BATCH_DELAY = 10000;
const EVENT_BATCH_TICK = 50;
const EVENT_BATCH_MAX_SIZE = 100;

function setClientId(id: string | undefined, logger: ECSLogger) {
  logger.info('Set clientId', id);
  if (id) {
    document.gtmVars = {
      ...document.gtmVars,
      clientId: id,
    };
  }
}

function setDocumentThirdParties(value: ThirdParties) {
  document.gtmVars = {
    ...document.gtmVars,
    thirdParties: {
      ...document.gtmVars?.thirdParties,
      ...value,
    },
  };
}

type ThirdParties = Record<string, boolean | undefined>;

type ThirdPartiesList = Array<{type: string; enabled?: boolean}>;

type AnalyticsPublicState = {
  thirdParties: ThirdPartiesList | undefined;
  gaClientId: string | undefined;
};

export class Analytics {
  constructor(
    private transport: Transport,
    private device: Device,
  ) {
    // new versions of core-js and babel don't compile initialization of class fields correctly
    // so we have to initialize it in the constructor
    this.logger = this.device.log.getLogger('Analytics');
  }

  private thirdParties: ThirdParties = {};

  private queue: RequestEvent[] = [];

  private lastEventTs = Date.now();

  private queueTimer!: ReturnType<typeof setTimeout>;

  private hydrated = false;

  private analyticsLogger = new AnalyticsLogger((...args) => this.sendAnalyticsEvent(...args));

  private logger;

  getHydrationData(): Promise<AnalyticsPublicState> {
    return Promise.all([this.loadGaClientId(), this.loadThirdPartyPreferences()]).then(
      ([gaClientId, thirdParties]) => ({
        gaClientId,
        thirdParties,
      }),
    );
  }

  hydrate(data: AnalyticsPublicState): void {
    this.hydrated = true;
    const {gaClientId, thirdParties} = data;
    setClientId(gaClientId, this.logger);
    this.setThirdPartyPreferences(thirdParties);
  }

  init(): Promise<void> {
    if (__SERVER__) {
      return Promise.resolve();
    }

    if (__CLIENT__) {
      window.addEventListener('beforeunload', this.sendApiEventsBeacon.bind(this));
      window.addEventListener('pagehide', this.sendApiEventsBeacon.bind(this));

      // Flush pending events before authentication state change
      this.device.auth.on('startAuth', () => {
        this.sendApiEventsBeacon();
      });
      this.device.auth.on('startSignout', () => {
        this.sendApiEventsBeacon();
      });

      const {
        webRetargetingGroup1,
        webRetargetingGroup2,
        webRetargetingGroup3,
        webRetargetingGroup4,
      } = this.device.getDeviceVars() || {};

      document.gtmVars = {
        ...document.gtmVars,
        deviceId: this.device.getDeviceId(),
        userId: this.device.getUserId(),
        osName: this.device.getDeviceVersion().osType,
        releaseStage: config.releaseStage,
        region: this.device.getRegion(),
        originalLocation: `${document.location.protocol}//${document.location.hostname}${document.location.pathname}${document.location.search}`,
        experiments: {
          webRetargetingGroup1,
          webRetargetingGroup2,
          webRetargetingGroup3,
          webRetargetingGroup4,
        },
      };
    }

    if (isPageVisibilitySupported()) {
      document.addEventListener(
        getVisibilityChangeEventName(),
        () => {
          if (isPageVisible()) {
            this.addTimestampToQueue();
            this.sendApiEvents();
          }
        },
        false,
      );
    }

    if (this.hydrated) {
      return Promise.resolve();
    }

    return this.getHydrationData().then((hydrationData) => this.hydrate(hydrationData));
  }

  sendToDataLayer(data: Record<string, unknown>): void {
    if (!this.device.isAppWebView()) {
      sendToDataLayer(data);
    }
  }

  syncThirdPartiesWithGTM(): void {
    setDocumentThirdParties({
      google: this.thirdParties[ThirdParty.GOOGLE],
      facebook: this.thirdParties[ThirdParty.FACEBOOK],
      rtbHouse: this.thirdParties[ThirdParty.RTB_HOUSE],
    });
  }

  setAnalyticsConsent(): void {
    this.syncThirdPartiesWithGTM();

    this.sendToDataLayer({
      event: 'Disable Facebook',
      /* since the events are passed through gtm,
      we have to disable all third-party counters if user had
      chosen to protect his data from Google services */
      disable: !this.thirdParties[ThirdParty.FACEBOOK] || !this.thirdParties[ThirdParty.GOOGLE],
    });
  }

  setCookiesSettings(): void {
    const userCookiesSettings = this.device.getCookiesSettings();
    gtag(
      'consent',
      ConsentArg.DEFAULT,
      convertCookiesSettingsToGtmConsentParams(userCookiesSettings),
    );
    sendToDataLayer({event: 'default_consent'});
    sendToDataLayer({
      event: 'Common. Cookie settings init',
      cookiesSettings: convertCookiesSettingsToGtmCookiesSettings(userCookiesSettings),
    });
  }

  setThirdPartyPreferences(data: ThirdPartiesList | undefined): void {
    if (data) {
      this.thirdParties = arrayToObject(
        data,
        ({type}) => type,
        ({enabled}) => !!enabled,
      );

      this.setAnalyticsConsent();

      this.setCookiesSettings();

      const {webGtmDisabled, webGtmDisabledInWebView8547} = this.device.getDeviceVars();

      if (
        __GTM_ENABLED__ &&
        this.thirdParties[ThirdParty.GOOGLE] &&
        !webGtmDisabled &&
        // Disable in web view by device var
        !(this.device.isAppWebView() && webGtmDisabledInWebView8547)
      ) {
        this.syncThirdPartiesWithGTM();

        initGtm(this.device.scope.integrations.gtmId);
      }
    } else {
      this.logger.warn('No third party preferences. Tracking is disabled!');
    }
  }

  loadThirdPartyPreferences(): Promise<ThirdPartiesList | undefined> {
    return this.transport
      .get<{payload: {thirdParties: ThirdPartiesList}}>('/users/self/preferences')
      .then((data) => data.body.payload.thirdParties)
      .catch((ex) => this.logger.warn(ex) as undefined);
  }

  loadGaClientId(): Promise<string | undefined> {
    return this.transport
      .get<{payload: string}>('/events/getGoogleAnalyticsClientID')
      .then((data) => data.body.payload)
      .catch((ex) => this.logger.warn(ex) as undefined);
  }

  getNotSendedQueue(): RequestEvent[] {
    return this.queue;
  }

  private addTimestampToQueue(): void {
    this.queue.forEach((item) => {
      if (!item.ts) {
        item.ts = Date.now();
      }
    });
  }

  private extractQueueItems(): RequestEvent[] {
    const result: RequestEvent[] = [];
    this.queue = this.queue.filter((item) => {
      if (item.ts) {
        result.push(item);
      }
      return !item.ts;
    });
    return result;
  }

  batchServerQueue(queue: RequestEvent[]): void {
    if (queue && queue.length) {
      this.queue.push(...queue);
      this.sendApiEvents();
    }
  }

  sendAnalyticsEvent = (
    analyticsEvent: AnalyticsEvent,
    options: AnalyticsSendOptions = {},
  ): Promise<void> => {
    this.logger.debug('event', analyticsEvent);
    const now = getServerTimestamp();
    const {beacon, immediately} = options;
    const event = {
      type: analyticsEvent.type,
      payload: 'payload' in analyticsEvent ? analyticsEvent.payload : {},
      params: analyticsEvent.params || [],
      appVersion: config.version,
      clientEventId: guid(),
      // do not set ts for events on hidden page
      // will set ts on visibility change
      ts: __SERVER__ || immediately || isPageVisible() ? now : undefined,
    } as RequestEvent;
    this.queue.push(event);

    if (__CLIENT__) {
      clearTimeout(this.queueTimer);
      const nextSendTs = this.lastEventTs + EVENT_BATCH_DELAY;
      if (immediately || this.queue.length > EVENT_BATCH_MAX_SIZE) {
        this.logger.debug(`Send ${this.queue.length} events to backend`);
        return beacon ? this.sendApiEventsBeacon() : this.sendApiEvents();
      }
      this.queueTimer = setTimeout(
        this.sendApiEvents,
        Math.max(nextSendTs - now, EVENT_BATCH_TICK),
      );
    }

    return Promise.resolve();
  };

  sendEvent(event: AnalyticsEvent, options: AnalyticsSendOptions = {}): Promise<void> {
    return this.analyticsLogger.send(event, options);
  }

  sendApiEvents = async (maybeBody?: RequestEvent[]): Promise<void> => {
    clearTimeout(this.queueTimer);

    let body = maybeBody || this.extractQueueItems();
    // WEB-8454 check for nulls
    if (Array.isArray(body)) {
      body = body.filter(Boolean);
    }
    if (!body.length) {
      return;
    }

    this.lastEventTs = Date.now();
    await this.transport
      .post('/events?strict=false', {body, skipTracing: true})
      .catch((err) => this.logger.error('Can not post apiEvent', err.message, err));
  };

  sendApiEventsBeacon = async (): Promise<void> => {
    clearTimeout(this.queueTimer);

    if (!window.navigator.sendBeacon) {
      this.sendApiEvents();
      return;
    }

    let body = this.extractQueueItems();
    // WEB-8454 check for nulls
    if (Array.isArray(body)) {
      body = body.filter(Boolean);
    }
    if (!body.length) {
      return;
    }

    this.lastEventTs = Date.now();

    const data = this.transport.getRequestData('/eventsBeacon?strict=false', {body});
    const analyticsData = JSON.stringify({
      headers: data.headers,
      body: data.body,
    });

    try {
      window.navigator.sendBeacon(data.path, analyticsData);
    } catch (error) {
      this.sendApiEvents(body);
    }
  };

  dataLayer = (args: Record<string, unknown>): void => {
    if (this.thirdParties[ThirdParty.GOOGLE]) {
      this.sendToDataLayer({
        currency: this.device.getCurrency(),
        language: this.device.getLanguage(),
        country: this.device.getRegion(),
        ipCountry: this.device.getDetectedCountry(),
        ...args,
      });
    }
  };
}

import {routeAsync} from 'helpers/asyncConnect';
import {searchRouteAsyncWrapper} from 'routes/async/searchRouteAsyncWrapper';
import {RouteParams, SEARCH_POSTFIX} from 'utils/search/pattern';

import {contextActivate} from './async/contextActivate';
import {anonymous} from './decorators';
import {DefaultParams, PoorRoute} from './types';

type Params = DefaultParams & RouteParams;

const NAME = 'store';

const asyncStoreRoute = routeAsync(
  /* #__LOADABLE__ */ () => import('containers/pages/StorePage'),
  (module) => module.StorePage,
);

export const StoreRoute: PoorRoute<typeof NAME, Params> = {
  name: NAME,
  path: `/stores/:storeId${SEARCH_POSTFIX}`,
  async: searchRouteAsyncWrapper(async (options) => {
    const redirectUrl = await contextActivate(options);

    if (redirectUrl) {
      return {
        redirect: {link: redirectUrl},
      };
    }

    return asyncStoreRoute(options);
  }),
  decorator: anonymous,
  scrollBehaviour: 'scroll-restoration',
};

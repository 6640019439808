import {ApiClient} from 'helpers/ApiClient';
import {ApiClientContext} from 'providers/ApiClientContext';
import {useContext} from 'react';

export function useApiClient(): ApiClient {
  const context = useContext(ApiClientContext);
  if (!context) {
    throw new Error('ApiClient is not provided.');
  }
  return context;
}

import {CSSProperties} from 'react';
import {
  Color,
  HexColor,
  isHexArgbColor,
  isHexRgbColor,
  isNamedColor,
  NamedColor,
} from 'types/Color';
import {Gradient, LinearGradientOrientation, LinearMulticolorGradient} from 'types/Gradient';
import {hexToRgba, removeHexHash} from 'utils/colors';
import {isRecord} from 'utils/guards';

export function getColorString(color?: Color): HexColor | undefined {
  if (color) {
    if (typeof color === 'string') {
      return color;
    }

    return color.rgb || color.argb || undefined;
  }

  return undefined;
}

export function isGradient(value?: Color | Gradient): value is Gradient {
  return Boolean(isRecord(value) && 'colorStart' in value);
}

// see `types/Color`
function getBackendColorCSSValue(color?: Color): string {
  let colorString = removeHexHash(getColorString(color));

  // backward compatability for hardcoded '#RGB' values
  if (colorString?.length === 3) {
    colorString = colorString
      .split('')
      .map((c) => c + c)
      .join('');
  }

  if (isHexRgbColor(colorString)) {
    return `#${colorString}`;
  }
  if (isHexArgbColor(colorString)) {
    const [r, g, b, a] = hexToRgba(colorString);
    return `rgba(${r},${g},${b},${a})`;
  }

  return '';
}

// mostly we can map "as is", but sometimes need to remap some colors
// due to wrong backend naming, which already exists on android
// https://github.com/joomcode/api/pull/46551/files
// https://github.com/joomcode/api/blob/master/src/joom/app/clientapp/layout/colors.go
const BackendThemedToCssVariableMapping: Partial<Record<string, string>> = {
  surface20Alpha: 'other20',
  onAccent100: 'onAccentHigh',
  onPrimary100: 'onPrimaryHigh',
};

// see `types/Color`
export function convertBackendColorToCSSValue(color?: Color): string {
  const value = getBackendColorCSSValue(color);

  if (isNamedColor(color) && color.themed) {
    return `var(--color-${
      BackendThemedToCssVariableMapping[color.themed] || color.themed
    }, ${value})`;
  }

  return value;
}

// see `shapes/Gradient`
export function convertBackendGradientToCSSValue(
  gradient?: Gradient,
  sideOrCorner = '135deg',
  start = '0%',
  end = '100%',
): string {
  if (gradient) {
    const startColor = convertBackendColorToCSSValue(gradient.colorStart);
    const endColor = convertBackendColorToCSSValue(gradient.colorEnd);
    const value =
      startColor && endColor
        ? `linear-gradient(${sideOrCorner}, ${startColor} ${start}, ${endColor} ${end})`
        : '';

    if (gradient.themed) {
      return `var(--color-${
        BackendThemedToCssVariableMapping[gradient.themed] || gradient.themed
      }, ${value})`;
    }

    return value;
  }

  return '';
}

const ORIENTATIONS_TO_CSS_DEGREES_MAP: Record<LinearGradientOrientation, string> = {
  vertical: '180deg',
  horizontal: '90deg',
};

export function convertLinearMulticolorGradientToCSSValue(
  gradient: LinearMulticolorGradient,
  sideOrCorner?: string,
): string {
  if (!gradient) {
    return '';
  }

  const value = `linear-gradient(${
    sideOrCorner ?? ORIENTATIONS_TO_CSS_DEGREES_MAP[gradient.orientation]
  }, ${gradient.colors
    .map(({color, position}) => `${convertBackendColorToCSSValue(color)} ${position * 100}%`)
    .join(', ')})`;

  if (gradient.themed) {
    return `var(--color-${
      BackendThemedToCssVariableMapping[gradient.themed] || gradient.themed
    }, ${value})`;
  }

  return value;
}

export function convertAnyBackendGradientToCSSValue<G extends LinearMulticolorGradient | Gradient>(
  gradient?: G,
  sideOrCorner?: string,
  start = '0%',
  end = '100%',
): string {
  if (gradient) {
    if ('colorEnd' in gradient) {
      return convertBackendGradientToCSSValue(gradient, sideOrCorner || '135deg', start, end);
    }

    if ('colors' in gradient) {
      return convertLinearMulticolorGradientToCSSValue(gradient, sideOrCorner);
    }
  }

  return '';
}

export function isMonochrome(gradient: Gradient): boolean {
  return gradient.colorStart === gradient.colorEnd;
}

export function getColorStyles(color?: Color, property = 'color'): CSSProperties | undefined {
  const resultColor = convertBackendColorToCSSValue(color);
  return resultColor
    ? {
        [property]: resultColor,
      }
    : undefined;
}

export function getGradientStyles(
  gradient?: Gradient | LinearMulticolorGradient,
): CSSProperties | undefined {
  const background = convertAnyBackendGradientToCSSValue(gradient);

  return background ? {background} : undefined;
}

export function getInlineColor(color?: Color): CSSProperties | undefined {
  return getColorStyles(color);
}

export function colorListToRGB(colors: Array<NamedColor>): {
  colorName: string | null;
  colorRgb: string | null;
} {
  const colorRgb = colors && colors.length === 1 && convertBackendColorToCSSValue(colors[0]);
  const colorName = colors && colors.map((color) => color.name).join(' / ');

  return {
    colorName: colorName || null,
    colorRgb: colorRgb || null,
  };
}

export function getStylesByBackgroundColorAndGradient(
  backgroundColor: Color | undefined,
  backgroundGradient: Gradient | LinearMulticolorGradient | undefined,
): React.CSSProperties {
  const result: React.CSSProperties = {};

  if (backgroundColor) {
    result.backgroundColor = convertBackendColorToCSSValue(backgroundColor);
  }

  if (backgroundGradient) {
    result.background = convertAnyBackendGradientToCSSValue(backgroundGradient);
  }

  return result;
}

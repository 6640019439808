import type {CookieValue} from 'helpers/ApiClient/Device/CookieValue';
import {CookieType} from 'types/CookiesSettings';

import {stringDecoder, stringEncoder} from './helpers';

const COOKIE_NAME = 'userhash';
const MAX_AGE = 60 * 60 * 24 * 365 * 10;

export const UserHashCookie: CookieValue<string> = {
  type: CookieType.FUNCTIONAL,
  key: COOKIE_NAME,
  decode: stringDecoder,
  encode: stringEncoder,
  getOptions: () => ({
    maxAge: MAX_AGE,
  }),
};

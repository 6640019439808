import type {Request} from 'express';
import {ECSLogger} from 'helpers/log/ECS/types';
import {getReferrer} from 'utils/request';
import {getUrlHostname} from 'utils/url';
import {enhanceTrackingParams, getUtmsByUrl, Utms} from 'utils/url/tracking';

import type {Analytics} from './Analytics';
import type {Device} from './Device';
import {getOrigin} from './url';

export class Tracking {
  private analytics: Analytics;

  private device: Device;

  private enabled: boolean;

  private req?: Request;

  private logger: ECSLogger;

  constructor(device: Device, analytics: Analytics, req?: Request) {
    this.analytics = analytics;
    this.device = device;
    this.req = req;
    this.enabled = false;
    this.logger = device.log.getLogger('Tracking');
  }

  getOriginalUrl(): string {
    if (this.req) {
      return this.req.originalUrl;
    }
    if (__CLIENT__) {
      return document.location.pathname + window.document.location.search;
    }
    return '';
  }

  init(): Promise<null> {
    if (this.enabled) {
      this.send();
      this.device.on('create', this.send.bind(this));
    }
    return Promise.resolve(null);
  }

  enable(): void {
    this.enabled = true;
  }

  get(): Utms {
    return getUtmsByUrl(this.getOriginalUrl());
  }

  private getReferrer(): string | undefined {
    if (this.req != null) {
      return getReferrer(this.req) || undefined;
    }

    if (__CLIENT__) {
      return document.referrer || undefined;
    }

    return undefined;
  }

  private isInternalReferrer(referrer: string | undefined) {
    return getUrlHostname(referrer) === getUrlHostname(getOrigin(this.req));
  }

  private getExternalReferrer(): string | undefined {
    const referrer = this.getReferrer();
    return this.isInternalReferrer(referrer) ? undefined : referrer;
  }

  send(): Promise<void> {
    if (__SERVER__) {
      return Promise.resolve();
    }

    const sendReferrerForExternalLink = this.device.getDeviceVar('sendReferrerForExternalLink');
    const referrer = this.getReferrer();

    // WEB-5662 send all events except with internal referrer
    if (this.isInternalReferrer(referrer)) {
      return Promise.resolve();
    }

    const params = this.get();
    const link = this.getOriginalUrl();
    this.logger.log('Send utm params', link, JSON.stringify(params));

    return this.analytics.sendEvent(
      {
        type: 'externalLink',
        payload: {
          link,
          params,
          referrer: sendReferrerForExternalLink ? referrer : undefined,
        },
      },
      {immediately: true},
    );
  }

  sendAppRedirectTracking(additionalParams: Record<string, string>): Promise<void> {
    const params = enhanceTrackingParams(this.get(), additionalParams);

    const link = this.getOriginalUrl();
    const sendReferrerForExternalLink = this.device.getDeviceVar('sendReferrerForExternalLink');
    const referrer = sendReferrerForExternalLink ? this.getExternalReferrer() : undefined;

    return this.analytics.sendEvent({
      type: 'externalLink',
      payload: {
        link,
        params,
        referrer,
        specialLinkType: 'appRedirect',
      },
    });
  }
}

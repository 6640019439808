import {LanguageConfig} from 'config';
import {ScopeConfig} from 'helpers/ApiClient/Scope/ScopeConfig';
import {
  detectLanguageByPath,
  getUserLocalesByHeader,
  getUserLocalesByNavigator,
} from 'utils/language';

export function getUserLocales(req?: import('express').Request): string[] {
  if (req) {
    return getUserLocalesByHeader(req.get('Accept-Language') || '');
  }
  return getUserLocalesByNavigator(window.navigator);
}

export function getUserLanguage(
  req: import('express').Request | undefined,
  scope: ScopeConfig,
  availableLanguagesConfig: LanguageConfig[],
): string {
  const userLocales = getUserLocales(req);

  let languageConfig = availableLanguagesConfig.find(({code, locale}) =>
    userLocales.find((userLocale) => {
      return userLocale === code || userLocale === locale.toLowerCase();
    }),
  );

  if (!languageConfig) {
    languageConfig = availableLanguagesConfig.find(({code}) =>
      userLocales.find((userLocale) => {
        const userLang = userLocale.split('-')[0];
        return userLang === code;
      }),
    );
  }

  return languageConfig?.code || scope.defaultLanguage;
}

export function verifyLanguage(language: string, availableLanguages: string[]): string {
  return availableLanguages.indexOf(language) !== -1 ? language : '';
}

export function getForcedLanguageByRequest(req?: import('express').Request): string {
  const path = req ? req.originalUrl : window.location.pathname;
  return detectLanguageByPath(path) || '';
}

import {CookieType} from 'types/CookiesSettings';

import {CookieValue} from '../Device/CookieValue';
import {stringDecoder, stringEncoder} from './helpers';

const COOKIE_NAME = 'session_id';
const MAX_AGE = 60 * 10;

/**
 * NB! Do not use this cookie for analytics or marketing purposes!
 * You can use it only for security and some features (adult
 * flag expiration, antifraud, etc).
 */
export const SessionIdCookie: CookieValue<string> = {
  type: CookieType.FUNCTIONAL,
  key: COOKIE_NAME,
  decode: stringDecoder,
  encode: stringEncoder,
  getOptions: () => ({
    maxAge: MAX_AGE,
  }),
};

import {Device} from 'helpers/ApiClient/Device';
import {identity} from 'utils/function';
import {getUrlPath} from 'utils/url';

export enum UserType {
  ANONYMOUS = 'anonymous',
  EPHEMERAL = 'ephemeral',
  USER = 'user',
}

interface PathRestrictionsMap {
  [key: string]: boolean | PathRestrictionsMap | undefined;
}

const Restrictions: Partial<Record<UserType, PathRestrictionsMap>> = {
  [UserType.ANONYMOUS]: {
    cart: {
      checkout: true,
    },
    orders: true,
    paymentMethods: true,
  },
  [UserType.EPHEMERAL]: {
    address: true,
    auth: {
      email: {
        setNewPassword: true,
        signIn: true,
        signUp: true,
        signUpOnCheckout: true,
      },
      social: {
        signIn: true,
      },
    },
    device: {
      jmtMigration: {
        createJmtMigrationToken: true,
      },
    },
    cards: true,
    cart: true,
    excluded: true,
    favorites: true,
    games: true,
    orderGroups: true,
    orders: true,
    paymentMethods: true,
    social: {
      posts: {
        '*': {
          like: true,
          unlike: true,
        },
      },
    },
    users: {
      self: {
        dataRequest: true,
        adulthood: true,
      },
    },
    rewardWheel: {
      get: true,
    },
    quiz: {
      '*': {
        start: true,
      },
    },
    checkout: {
      preferences: {
        update: true,
      },
    },
    offers: {
      apply: true,
    },
  },
};

const UnverifiedAllows: PathRestrictionsMap = __SERVER__
  ? {
      auth: true,
      context: {
        activate: true,
      },
      device: true,
      events: true,
      personalData: true,
      products: true,
      promotions: true,
      stores: true,
      rendering: true,
      sitemap: true,
      testing: true,
      users: true,
      quiz: true,
      social: {
        posts: true,
        users: true,
      },
      legality: true,
    }
  : {};

const isEndpointFitted = (map: PathRestrictionsMap, endpoint: string[]) => {
  if (!endpoint.length) {
    return true;
  }

  let edge: PathRestrictionsMap = map;
  for (let i = 0; i < endpoint.length; i += 1) {
    const nextEdge = edge[endpoint[i]!] || edge['*'];

    if (!nextEdge) {
      return false;
    }
    if (nextEdge === true) {
      return true;
    }
    if (i === endpoint.length - 1 && nextEdge['/'] === true) {
      return true;
    }

    edge = nextEdge;
  }
  return false;
};

export function getUserType(device: Device): UserType {
  if (device.isEphemeral()) {
    return UserType.EPHEMERAL;
  }
  return device.isAuthorized() ? UserType.USER : UserType.ANONYMOUS;
}

// alwaysAllowAnonymous arg to allow anonymous checkout (WEB-6145)
export const isEndpointAllowed = (
  userType: UserType,
  path: string,
  verified: boolean,
  alwaysAllowAnonymous: boolean | undefined,
): boolean => {
  const endpoint = getUrlPath(path)?.split('/').filter(identity);
  if (!endpoint) {
    return false;
  }

  if (__SERVER__ && !verified) {
    return isEndpointFitted(UnverifiedAllows, endpoint);
  }

  const restrictionMap = Restrictions[userType];
  if (restrictionMap && (!alwaysAllowAnonymous || userType !== UserType.ANONYMOUS)) {
    return !isEndpointFitted(restrictionMap, endpoint);
  }

  return true;
};

// alwaysAllowAnonymous arg to allow anonymous checkout (WEB-6145)
export function needUpgradeUserType(
  userType: UserType,
  endpoint: string,
  alwaysAllowAnonymous: boolean | undefined,
): boolean {
  if (userType !== UserType.EPHEMERAL) {
    return false;
  }
  return (
    !isEndpointAllowed(UserType.EPHEMERAL, endpoint, false, alwaysAllowAnonymous) &&
    isEndpointAllowed(UserType.ANONYMOUS, endpoint, false, alwaysAllowAnonymous)
  );
}

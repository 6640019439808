import {PageScope} from 'components/PageScope';
import {PageViewMiddleware} from 'connectors/PageViewMiddleware';
import {ApiClient} from 'helpers/ApiClient';
import React, {ComponentType, ReactElement} from 'react';
import {Redirect, RouteComponentProps} from 'react-router-dom';
import {getUrl, RouteNamesUnion} from 'routes';
import {getQueryData} from 'utils/url';

import {
  AsyncFunctionOptions,
  AsyncFunctionResult,
  DefaultParams,
  PoorRoute,
  RenderFunc,
  RenderFuncProps,
} from './types';

function renderPage<T extends DefaultParams = DefaultParams>(
  props: RenderFuncProps<T>,
  client: ApiClient,
  route: PoorRoute<string, T>,
): ReactElement {
  const {element} = props;
  if (element) {
    return element;
  }

  const Component = route.component || props.component;
  const Page = Component as ComponentType<
    RouteComponentProps<T> & {params: Record<string, string | undefined>; route: typeof route}
  >;
  const {location, match, transition} = props;
  const children = Page ? <Page {...props} route={route} params={match.params} /> : <div />;
  return (
    <PageScope>
      <PageViewMiddleware route={route} location={location} transition={transition}>
        {children}
      </PageViewMiddleware>
    </PageScope>
  );
}

export function anonymous<T extends DefaultParams = DefaultParams>(
  client: ApiClient,
  route: PoorRoute<string, T>,
): RenderFunc<T> {
  return (props) => renderPage(props, client, route);
}

export function loggedIn<T extends DefaultParams = DefaultParams>(
  client: ApiClient,
  route: PoorRoute<string, T>,
): RenderFunc<T> {
  return (props) => {
    const user = client.device.getUser();
    if (!user?.anonymous) {
      return renderPage(props, client, route);
    }

    const logger = client.device.log.getLogger('routes/decorators');
    const lang = client.device.getLanguage();
    const {
      location: {pathname, search, hash},
    } = props;
    const query = getQueryData(search);
    const next = query.next || `${pathname}${search}${hash}`;
    logger.log(`Page ${route.name} is forbidden for anonymous user`);
    return <Redirect to={getUrl('entrance', {lang, scope: client.scope}, {next})} />;
  };
}

export function hasCheckoutAccess<T extends DefaultParams = DefaultParams>(
  client: ApiClient,
  route: PoorRoute<string, T>,
): RenderFunc<T> {
  if (client.device.getDeviceVar('webAllowAnonymousCheckout')) {
    return anonymous(client, route);
  }

  return loggedIn(client, route);
}

export async function routeAsyncLanguageRedirect<T extends DefaultParams = DefaultParams>(
  options: AsyncFunctionOptions<T>,
): Promise<AsyncFunctionResult> {
  const {client, location, match, route} = options;
  const logger = client.device.log.getLogger('routes/decorators');
  const lang = client.device.getLanguage();
  const {scope} = client;
  let {pathname} = location;
  if (pathname === '/') {
    pathname = '';
  }

  const to = getUrl(
    route.name as RouteNamesUnion,
    {
      ...match.params,
      lang,
      scope,
    },
    getQueryData(location.search),
  );

  logger.log(`Language redirect for ${pathname}`);

  return {
    redirect: {link: to},
  };
}

export function unauthorizedLolRedirect<T extends DefaultParams = DefaultParams>(
  client: ApiClient,
  route: PoorRoute<string, T>,
): RenderFunc<T> {
  return (props) => {
    const canUseLolLanguage = client.device.getCanUseLolLanguage();

    if (canUseLolLanguage) {
      return renderPage(props, client, route);
    }

    const logger = client.device.log.getLogger('routes/decorators');
    logger.log(`Unauthorized lol/tlh language redirect for page ${route.name}`);

    const user = client.device.getUser();
    const language = client.device.getLanguage();

    if (!user?.anonymous) {
      return <Redirect to={getUrl('main', {lang: language, scope: client.scope})} />;
    }

    return <Redirect to={getUrl('entrance', {lang: language, scope: client.scope})} />;
  };
}

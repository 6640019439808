import {getQueryData} from 'utils/url';

export function getGsAttrs(req?: import('express').Request): string {
  const path = req ? req.originalUrl : window.location.href;

  const {gsAttrs} = getQueryData(path);
  if (Array.isArray(gsAttrs)) {
    return gsAttrs[0] || '';
  }

  return gsAttrs || '';
}

import {routeAsync} from 'helpers/asyncConnect';

import {hasCheckoutAccess} from './decorators';
import {DefaultParams, PoorRoute} from './types';

type Params = DefaultParams & {
  checkoutSessionId: string;
};

const NAME = 'checkoutSession';

export const CheckoutSessionRoute: PoorRoute<typeof NAME, Params> = {
  name: NAME,
  path: '/checkout-session/:checkoutSessionId',
  async: routeAsync(
    /* #__LOADABLE__ */ () => import('containers/pages/CheckoutSessionPage'),
    (module) => module.CheckoutSessionPage,
  ),
  decorator: hasCheckoutAccess,
};

import {Response as SuperagentResponse, ResponseError as SuperagentResponseError} from 'superagent';
import {ApiError, Error as BaseError, isApiError} from 'types/Error';

const REQUEST_ID_HEADER = 'request-id';
const DEFAULT_ERROR_STATUS = 0;
const UNALLOWED_ERROR_STATUS = 400;
const DEFAULT_ERROR_TYPE = 'undefined_error';
const BABYLONE_ERROR_TYPE = 'babylone.error';
const NETWORK_OFFLINE_ERROR_TYPE = 'network_offline';
const SUPERAGENT_ERROR_TYPE = 'superagent_error';

export type Meta = {
  currency?: string;
  language?: string;
  api?: 'default' | 'faq';
};

type SyntheticResponse = {
  header: null;
  body: null;
  status: number;
  synthetic: true;
  error: BaseError | null;
};

const createSyntheticResponseByStatus = (status: number, error?: BaseError): SyntheticResponse => ({
  header: null,
  body: null,
  status,
  synthetic: true,
  error: error || null,
});

type Response = SuperagentResponse | SyntheticResponse;

function isSyntheticResponse(res: Response): res is SyntheticResponse {
  return 'synthetic' in res;
}

function isSuperagentResponse(res: Response): res is SuperagentResponse {
  return Boolean(!isSyntheticResponse(res) && res.get);
}

type ResponseError = {
  status?: number;
  message: string;
};

type ApiResponse<TBody = undefined> = {
  headers: Record<string, string>;
  currency: string;
  language: string;
  requestId: string;
  status: number;
  error?: BaseError | null;
  body: TBody;
  text?: string;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const toString = (val: any): string => (typeof val === 'string' ? val : '');

const getRequestId = (res?: Response, meta?: Meta): string => {
  if (!res) {
    return 'no-response';
  }

  // superagent response
  if ('ok' in res) {
    if (meta?.api === 'faq' && !res.ok && res.body?.requestId) {
      // babylone sometimes sends requestId in body for errors
      return res.body.requestId as string;
    }
    return (res.get && res.get(REQUEST_ID_HEADER)) || 'no-request-id';
  }

  return 'web-client-error';
};

const createApiError = (
  type: string,
  status: number,
  options?: {
    message?: string;
    payload?: {[name: string]: string};
    requestId?: string;
    userTitle?: string;
    userMessage?: string;
  },
): ApiError => ({
  type,
  status,
  ...options,
});

const createApiErrorByDefaultBackendResponse = (
  requestId: string,
  res: Response,
  error: ResponseError,
): ApiError => {
  return createApiError(
    toString(res?.body?.type) || DEFAULT_ERROR_TYPE,
    res.status || DEFAULT_ERROR_STATUS,
    {
      message: toString(res?.body?.message) || error.message,
      payload: res?.body?.payload || null,
      requestId,
      userTitle: toString(res?.body?.userTitle || res?.body?.customerTitle),
      userMessage: toString(res?.body?.userMessage || res?.body?.customerMessage),
    },
  );
};

const createApiErrorByFaqResponse = (
  requestId: string,
  res: Response,
  error: ResponseError,
): ApiError => {
  return createApiError(BABYLONE_ERROR_TYPE, res.status || DEFAULT_ERROR_STATUS, {
    message: toString(res?.body?.error) || error.message,
    userMessage: toString(res?.body?.message),
    requestId,
    payload: res?.body?.data || null,
  });
};

const createApiErrorByResponse = (
  requestId: string,
  res: Response,
  error: ResponseError,
  meta?: Meta,
): ApiError => {
  switch (meta?.api) {
    case 'faq':
      return createApiErrorByFaqResponse(requestId, res, error);
    default:
      return createApiErrorByDefaultBackendResponse(requestId, res, error);
  }
};

const createApiErrorBySuperagentError = (
  requestId: string,
  error: SuperagentResponseError,
): ApiError => {
  const isOffline = typeof navigator !== 'undefined' && navigator.onLine === false;
  return createApiError(
    isOffline ? NETWORK_OFFLINE_ERROR_TYPE : SUPERAGENT_ERROR_TYPE,
    error.status || DEFAULT_ERROR_STATUS,
    {
      requestId: 'network-error',
      userMessage: error.message || '',
    },
  );
};

const createApiErrorByError = (requestId: string, error: ResponseError): ApiError =>
  createApiError(DEFAULT_ERROR_TYPE, error.status || DEFAULT_ERROR_STATUS, {
    requestId,
    userMessage: error.message || '',
  });

const createResponse = <TBody>(
  res?: Response,
  error?: ResponseError,
  meta?: Meta,
): ApiResponse<TBody> => {
  const requestId = getRequestId(res, meta);

  let resultError = null;

  if (error && isApiError(error)) {
    resultError = error;
  } else if (error && 'method' in error) {
    resultError = createApiErrorBySuperagentError(
      requestId,
      error as unknown as SuperagentResponseError,
    );
  } else if (res && error) {
    resultError = createApiErrorByResponse(requestId, res, error, meta);
  } else if (error) {
    resultError = createApiErrorByError(requestId, error);
  }

  const result: ApiResponse<TBody> = {
    headers: res?.header || {},
    body: res?.body || null,
    currency: meta?.currency || '',
    error: resultError,
    language: meta?.language || '',
    requestId,
    status: res?.status || DEFAULT_ERROR_STATUS,
  };

  if (res && isSuperagentResponse(res)) {
    const contentType = res.get('content-type');
    if (contentType && contentType.startsWith('text/')) {
      result.text = res.text;
    }
  }

  return result;
};

const createResponseWithError = (
  status: number,
  error: ResponseError,
  meta?: Meta,
): ApiResponse<null> =>
  createResponse<null>(createSyntheticResponseByStatus(status, error), error, meta);

const createUnallowedResponse = (from: string, meta?: Meta): ApiResponse<null> =>
  createResponseWithError(
    UNALLOWED_ERROR_STATUS,
    {
      status: UNALLOWED_ERROR_STATUS,
      message: `Request is not allowed (${from})`,
    },
    meta,
  );

const needLegalityConsent = (res: ApiResponse): boolean => {
  const apiError = res?.error as ApiError;

  return apiError?.type === 'legality.need_consent';
};

export {
  ApiResponse,
  createApiError,
  createResponseWithError,
  createResponse,
  createUnallowedResponse,
  needLegalityConsent,
};

import {createRequestAction} from 'store/utils/requestActions';

export type CategoryRedirectRequest = {
  categoryId: string;
};

export type CategoryRedirectResponse = {
  toCategoryId?: string;
};

export const loadCategoryRedirect = createRequestAction(
  '@categoryRedirect/LOAD_REQUEST',
  '@categoryRedirect/LOAD_RESPONSE',
  '@categoryRedirect/LOAD_ERROR',
)<CategoryRedirectRequest, CategoryRedirectResponse>();

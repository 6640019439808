import {ExtendedErrorObject, unwrapErrorObject} from './unwrapErrorObject';

export function getErrorMessage(error: ExtendedErrorObject): string {
  const unwrappedError = unwrapErrorObject(error);

  if (unwrappedError && unwrappedError.message) {
    return unwrappedError.message;
  }

  return 'Internal Error';
}

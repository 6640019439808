export enum RenderingType {
  /**
   * Server and browser rendeing must be disabled,
   * show error page instead of application.
   */
  ACCESS_DENIED = 'denied',

  /**
   * Show captcha guard for access (not implemented yet).
   */
  CAPTCHA = 'captcha',

  /**
   * Create html with meta tags only (no page content), usually
   * it needs for preview card in social media and messangers.
   */
  META = 'previewForThirdPartyAppRobot',

  /**
   * Render html for known crawler (e.g. googlebot, yandexbot).
   * It is definitely crawler, because it was checked my some
   * method, like reverse DNS lookup.
   */
  CRAWLER = 'pageForCrawlers',

  /**
   * Prerender html for user, full or partial render is allowed.
   */
  USER = 'server',

  /**
   * Do not render page
   */
  BROWSER = 'client',
}

export type RenderingConfiguration = {
  id: string;
  option: RenderingType;
};

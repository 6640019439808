import {CookiesSettings, CookieType} from 'types/CookiesSettings';
import {loadAsyncScript, ScriptAttributes} from 'utils/dom';
import {TypedObject} from 'utils/object/typed';
import {requestIdleCallback} from 'utils/requestIdleCallback';

import {send} from './counters/dataLayer';

const WAIT_GTM_FOR_OLD_BROWSERS_MS = 10_000;

const loadGtmScript = (gtmId: string, attributes?: ScriptAttributes) =>
  loadAsyncScript(`https://www.googletagmanager.com/gtm.js?id=${gtmId}`, attributes);

const sendGtmStart = () =>
  send({
    'gtm.start': new Date().getTime(),
    event: 'gtm.js',
  });

function initGtm(gtmId: string): void {
  sendGtmStart();

  requestIdleCallback(() => loadGtmScript(gtmId), {timeout: WAIT_GTM_FOR_OLD_BROWSERS_MS});
}

enum ConsentType {
  FUNCTIONALITY_STORAGE = 'functionality_storage',
  ANALYTICS_STORAGE = 'analytics_storage',
  AD_STORAGE = 'ad_storage',
  AD_USER_DATA = 'ad_user_data',
  AD_PERSONALIZATION = 'ad_personalization',
  PERSONALIZATION_STORAGE = 'personalization_storage',
  SECURITY_STORAGE = 'security_storage',
}

enum ConsentArg {
  DEFAULT = 'default',
  UPDATE = 'update',
}

enum ConsentParamValue {
  GRANTED = 'granted',
  DENIED = 'denied',
}

const CookiesSettingToGtmConsent: Record<CookieType, ConsentType[]> = {
  [CookieType.FUNCTIONAL]: [
    ConsentType.FUNCTIONALITY_STORAGE,
    ConsentType.PERSONALIZATION_STORAGE,
    ConsentType.SECURITY_STORAGE,
  ],
  [CookieType.PERFORMANCE]: [ConsentType.ANALYTICS_STORAGE],
  [CookieType.MARKETING]: [ConsentType.AD_STORAGE],
  [CookieType.MARKETING_USER_DATA]: [ConsentType.AD_USER_DATA],
  [CookieType.AD_PERSONALIZATION]: [ConsentType.AD_PERSONALIZATION],
};

type ConsentParams = Record<ConsentType, ConsentParamValue>;

function convertCookiesSettingsToGtmConsentParams(cookiesSettings: CookiesSettings): ConsentParams {
  if (!cookiesSettings.length) {
    return Object.values(ConsentType).reduce((result, curr) => {
      result[curr] = ConsentParamValue.DENIED;
      return result;
    }, {} as ConsentParams);
  }

  const result = {} as ConsentParams;
  cookiesSettings.forEach((setting) => {
    CookiesSettingToGtmConsent[setting.type]?.forEach((consent) => {
      result[consent] = setting.allowed ? ConsentParamValue.GRANTED : ConsentParamValue.DENIED;
    });
  });

  return result;
}

type GtmCookiesSettings = Array<{
  type: ConsentType;
  allowed: boolean;
}>;

function convertCookiesSettingsToGtmCookiesSettings(
  cookiesSettings: CookiesSettings,
): GtmCookiesSettings {
  // gtm cookies settings should have all keys, since api can return partial
  return TypedObject.values(CookieType).flatMap((type) => {
    const setting = cookiesSettings.find((setting) => setting.type === type);

    return CookiesSettingToGtmConsent[type].map((consent) => ({
      type: consent,
      allowed: setting?.allowed ?? false,
    }));
  });
}

export {
  initGtm,
  convertCookiesSettingsToGtmConsentParams,
  convertCookiesSettingsToGtmCookiesSettings,
  ConsentArg,
};

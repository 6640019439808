import {ProductRoute} from './ProductRoute';
import {DefaultParams, PoorRoute} from './types';

type Params = DefaultParams & {
  productId: string;
  reviewId?: string;
};

const NAME = 'productReviewFeedback';

export const ProductReviewFeedbackRoute: PoorRoute<typeof NAME, Params> = {
  ...ProductRoute,
  path: '/products/:productId/reviews/:reviewId/:feedback(feedback)',
  name: NAME,
};

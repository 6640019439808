import {DeviceVars} from 'types/deviceVars';

export const supportedDevicevars = [
  // check config/Devicevars.js
  'cartAuthRedesign',
  'favoriteCategoryPromo',
  'categoryBannersContentListWeb',
  'productInNewTab',
  'miniCartEnabled',
  'miniCartHideTotalPrice',
  'navigationWebElements',
  'navigationWebElementsWithAutoRewardWheel',
  'productPageCardPriceRedesign',
  'productPreviewRevisionBrandNameInBadge',
  'productPreviewRevisionHideName',
  'productPreviewRevisionHideButton',
  'productPreviewRevisionHideReviewsCount',
  'requestExtraUserInfoOnSignInWeb',
  'socialAuth',
  'quarantineQuizUseDeepLinkOnOs',
  'quarantineQuizSingleShareButton',
  'favoritesInHeader',
  'feedback',
  'footerJoomHBPromoLink',
  'webSmartLockEnabled',
  'webSmartLockSigninOnInit',
  'webSmartLockSigninOnProfileClick',
  'newProductPreviewBehavior',
  'newProductPageWarrantyTextsIconInvert',
  'newProductPageSizeTableApplySelection',
  'newProductPagePopupPosition',
  'newProductPageBlocksOrder',
  'newProductPageMobileBlocksOrder',
  'newProductPageOutOfStock',
  'newSignupPromoEnabled',
  'socialPostCreation',
  'signUpCouponPercent',
  'criticalStyles',
  'trendPageItemsCount',
  'hideAppButton',
  'hideAppButtonByTrackingSource',
  'hideAppButtonByJoomAppBannerViews',
  'hideWelcomePopupsByTrackingSource',
  'paymentsProfilingConfig',
  'proxyClientBackend',
  'socialFeedPageCardInfo',
  'socialPostAuthorLinkEnabled',
  'riskifiedClient',
  'fingerprintjsClient',
  'pwaInstallPromptEnabled',
  'favoriteStoresWeb',
  'sendReferrerForExternalLink',
  'showIdlerIntroButton',
  'webAddOpensearch',
  'networkStats',
  'disableCookiesUntilUserSetsPermission',
  'socialPostPageSimilarSliderEnabled',
  'shareOfUsersWithBugsnagAsPercentage',
  'endlessDiscoveryEnabled',
  'socialPostCardAutoplayVideoEnabled',
  'joomAboutLink',
  'joomProLink',
  'webAddOpensearch',
  'joomLogisticsLink',
  'webCollaborativeAds',
  'renderHiddenContextMenu',
  'useOverlayForPopups',
  'webStoreSubscriptionAppearance',
  'sessionParams',
  'cartDefaultAuthEnabled',
  'productLiteLazyHydrateEnabled',
  'preloadHydrateRequestEnabled',
  'nonBlockingSessionConfiguredEnabled',
  'reactServerAsyncRender',
  'variantSelectionSettings',
  'webGooglePayConfig',
  'webSeoPageRedesignBannersEnabled',
  'parcelInvoicesButtonEnabled',
  'webProductSimilarLazyRender',
  'signupPromoEnabled',
  'webGtmDisabled',
  'webReactProfiling',
  'webGtmInWorker',
  'webPreloadScriptsConfig',
  'mobileWebStickyHeader',
  'maidenCouponAutoShow',
  'webLoadIncompleteProduct',
  'webMainDelayed',
  'trendAndSearchPageFirstPageItemsCount',
  'webTrendPageSimilarCategoriesEnabled',
  'webSeoCutMetadataText',
  'webMainLite',
  'webJoomAppMobileBanner',
  'webJoomAppMobileBannerEnabled',
  'searchAddSiblingsToLeafCategoryFilters',
  'webProductMobileStickyButtons',
  'blockedLandingPageEnabled',
  'ibanValidator',
  'hideAddToCartButtonWeb',
  'productCollectionsConfig',
  'webAllowAnonymousCheckout',
  'snowFlakes2023',
  'welcomePopupsDisabled',
  'showFavoriteProducts',
  'webBigFullSheetLongImage',
  'enlargeMediaInReviewsMweb',
  'feedbackLinkAsSeparateBlockMWeb',
  'webBatchProductWithContext',
  'parcelActionRows',
  'rewardWheelDisabledForAnonymous',
  'productCollectionDiscoverySearch',
  'bankCardDeployment',
  'outOfStockLandingPageEnabled',
  'syncProductVariantIdWithHistory',
  'showLegalInfoLinkInFooter',
  'visaSecurity',
  'jmtMigrationRequestConfig',
  'webRetargetingGroup1',
  'webRetargetingGroup2',
  'webRetargetingGroup3',
  'webRetargetingGroup4',
  'jmtMigrationCountries',
  'webSeoPaginationExtension',
  'jmtAutoRedirect',
  'webScopes',
  'pointsPaymentMethod',
  'likeButton8819',
  'webCheckoutStickyPaymentWithPopup',
  'jmtDisableAutoMigration',
  'blockingCookieBanner2115',
  'desktopWebStickyHeader',
  'webShowJoomGeekLinkOnJoom',
  'trustpilot',
  'webHideCartPromocode',
  'cartOneItemLimit',
  'webCouponRedesign2023',
  'webOOSCoupon7895',
  'webRewardWheelRedesign',
  'webPromotionShowcase8020',
  'webLibPhoneNumber',
  'webProductPreviewRedesign8021',
  'webAddressZipMask8109',
  'addressEditorLiveValidation',
  'checkoutSuccessSplashType',
  'webReviewFormRedesign8224',
  'specialPriceByDefaultWithoutVariantSelection',
  'webProductDescriptionAlwaysExpanded',
  'webHideSearchbar',
  'webProductMinimizeContent',
  'webProductCardBmplPresaleBlockPinned',
  'webVitalsINPAttribution',
  'webAppMobileBannerOnProductPage8549',
  'mobWebAdInterlayerConfig',
  'webGtmDisabledInWebView8547',
  'webViewUserDataShowCookieSettings',
  'webProductGalleryLastItem8625',
  'webDisablePushNotifications8591',
  'webMBWayStickyPaymentWithPopup',
  'webProductCardRestylingEnabled',
  'cbtrendFeaturePaymentMethod',
  'ratingBadgeOnProductCardEnabled',
  'webFooterRedesign8709',
  'webMiniCartQuantitativePrices',
  'webMobilePromocodeConfig8756',
  'webSkipWarningsForMultiPageAddressForm8786',
  'coolbeTrendingProductPage',
  'coolbeTrendingProductPageConfig',
  'webCardNumberInputDebounceDelay',
  'referralUserProgramConfig',
  'webProductSimilarsLanding8844',
  'webProductStoreReviewsConfig8938',
  'backendCartItemsSelection',
  'webProductRedesignLanding8923',
  'webCookiesBannerConfig8922',
  'webInAppPrice8933',
] as const;

type DeviceVarNames = keyof DeviceVars;
type SupportedDeviceVarNames = (typeof supportedDevicevars)[number];

type DeviceVarsDiff =
  | Exclude<DeviceVarNames, SupportedDeviceVarNames>
  | Exclude<SupportedDeviceVarNames, DeviceVarNames>;

type CheckNever<T extends '' | never> = T;
// this type only for checking consistence between value `config.supportedDevicevars` and type `DeviceVars`
export type DeviceVarsGuard = CheckNever<DeviceVarsDiff>;

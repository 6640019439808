import config from 'config';
import {Request} from 'express';
import {ScopeConfig} from 'helpers/ApiClient/Scope/ScopeConfig';
import {getDevDomainByScopeMap} from 'helpers/ApiClient/Scope/utils';

import {getOrigin} from '../url';

export function getJmtOrigin(scope: ScopeConfig, req: Request | undefined): string {
  if (config.releaseStage === 'prod') {
    const hostname =
      scope.notJmt && scope.oppositeEntityId
        ? config.scopes[scope.oppositeEntityId].hostname
        : scope.hostname;
    return `https://${hostname}`;
  }

  const currentOrigin = getOrigin(req);

  if (scope.isJmt || !scope.oppositeEntityId) {
    return currentOrigin;
  }

  const maybeHostname = getDevDomainByScopeMap()?.[scope.oppositeEntityId];
  if (maybeHostname) {
    return `https://${maybeHostname}`;
  }

  // fix for stage
  const [, stageName] = currentOrigin.match(/https:\/\/([^.]+)\.joomdev\.net/) || [];
  if (stageName) {
    return `https://stage-${stageName}-web-client-jmt.joomdev-devenvs.k8s.joomdev.net`;
  }

  return currentOrigin.replace('web-client', 'web-client-jmt');
}

export function getJoomOrigin(scope: ScopeConfig, req: Request | undefined): string {
  if (config.releaseStage === 'prod') {
    const hostname =
      scope.isJmt && scope.oppositeEntityId
        ? config.scopes[scope.oppositeEntityId].hostname
        : scope.hostname;
    return `https://${hostname}`;
  }

  const currentOrigin = getOrigin(req);

  if (scope.notJmt || !scope.oppositeEntityId) {
    return currentOrigin;
  }

  const maybeHostname = getDevDomainByScopeMap()?.[scope.oppositeEntityId];
  if (maybeHostname) {
    return `https://${maybeHostname}`;
  }

  // fix for stage
  const [, stageName] =
    currentOrigin.match(/https:\/\/stage-([^.]+)-web-client-jmt\.riley\.k8s\.joomdev\.net/) || [];
  if (stageName) {
    return `https://${stageName}.joomdev.net`;
  }

  return currentOrigin.replace('web-client-jmt', 'web-client');
}

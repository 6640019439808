import {ApiClient} from 'helpers/ApiClient';
import {sentLegalityConsentPromise} from 'store/modules/legality';
import {Error} from 'types/Error';
import {Action, createAsyncAction, FuncAction, Store, TypeConstant} from 'typesafe-actions';

const createRequestAction =
  <
    TRequestType extends TypeConstant,
    TResponseType extends TypeConstant,
    TFailureType extends TypeConstant,
  >(
    requestType: TRequestType,
    responseType: TResponseType,
    failureType: TFailureType,
  ) =>
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  <TRequestPayload, TResponsePayload>() =>
    // undefined as never should be removed after typesafe-actions fix
    // https://github.com/piotrwitek/typesafe-actions/pull/152
    createAsyncAction(requestType, responseType, failureType, undefined)<
      TRequestPayload,
      [TResponsePayload, TRequestPayload],
      [Error, TRequestPayload],
      never
    >();

const requestActionCreator =
  <TRequestPayload, TSuccessPayload, TFailurePayload>(
    actionBuilder: {
      request: (request: TRequestPayload) => Action | FuncAction;
      success: (data: TSuccessPayload, request: TRequestPayload) => Action | FuncAction;
      failure: (error: TFailurePayload, request: TRequestPayload) => Action | FuncAction;
    },
    request: TRequestPayload,
    handler: (store: Store, client: ApiClient) => Promise<TSuccessPayload>,
  ): FuncAction<ReturnType<typeof handler>> =>
  (store, client) =>
    Promise.resolve()
      .then(() => store.dispatch(actionBuilder.request(request)))
      .then(() => handler(store, client))
      .catch((error: unknown) => {
        const {body} = (error || {}) as {body?: {type?: string; payload?: {id?: string}}};
        // auto consent analogue from clientMiddleware
        if (body?.type === 'legality.need_consent') {
          return sentLegalityConsentPromise(client, body?.payload?.id || '', '').then(() =>
            handler(store, client),
          );
        }
        throw error;
      })
      .then((data) => {
        store.dispatch(actionBuilder.success(data, request));
        return data;
      })
      .catch((err: TFailurePayload) => {
        store.dispatch(actionBuilder.failure(err, request));
        throw err;
      });

export {createRequestAction, requestActionCreator};

import {ContentListBaseItem, contentListBaseItem} from './ContentListBaseItem';
import {SlimBanner} from './SlimBanner';

export type ContentListSlimBanner = ContentListBaseItem<{slimBanner: SlimBanner}>;

export function contentListSlimBanner(id: string, slimBanner: SlimBanner): ContentListSlimBanner {
  return contentListBaseItem(id, {slimBanner});
}

export function isContentListSlimBanner(item: ContentListBaseItem): item is ContentListSlimBanner {
  return Boolean((item as ContentListSlimBanner).content.slimBanner);
}

export function extractSlimBanner(item: ContentListSlimBanner): SlimBanner {
  return item.content.slimBanner;
}

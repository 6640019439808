import {ContextItemRaw, ContextList} from 'types/Context';
import {removeDuplicates} from 'utils/array';
import {waterfall} from 'utils/function';

import {
  ContextMixin,
  CurrencyMixin,
  enhanceContext,
  enhanceCurrency,
  enhanceLanguage,
  enhanceLoadedTime,
  enhancePageDependedId,
  LanguageMixin,
  LoadedTimeMixin,
  PageDependedIdMixin,
} from '.';

export type ProductEnhanceOptions = {
  contextType?: string;
  contextValue?: ContextItemRaw;
  currency: string;
  language: string;
  pageToken?: string;
};

export type ProductsEnhanceOptions = Omit<ProductEnhanceOptions, 'contextType' | 'contextValue'> & {
  contexts?: ContextList[];
};

export type ProductEnhanceType = LoadedTimeMixin &
  CurrencyMixin &
  LanguageMixin &
  Partial<PageDependedIdMixin> &
  Partial<ContextMixin>;

export function enhanceProduct<T extends {id: string}>(
  raw: T,
  {currency, language, contextType, contextValue, pageToken}: ProductEnhanceOptions,
): T & ProductEnhanceType {
  return waterfall(
    () => enhanceLoadedTime(raw),
    (result) => enhanceCurrency(result, currency),
    (result) => enhanceLanguage(result, language),
    (result) => (pageToken ? enhancePageDependedId(result, pageToken, result.id) : result),
    (result) =>
      contextType && contextValue ? enhanceContext(result, contextType, contextValue) : result,
  );
}

export function enhanceProducts<T extends {id: string}>(
  products: T[],
  {language, currency, pageToken, contexts}: ProductsEnhanceOptions,
): (T & ProductEnhanceType)[] {
  if (!products) {
    return [];
  }

  const contextList = contexts?.[0]?.value || [];
  const contextType = contexts?.[0]?.type || '';

  const enhancedProducts = products.map((item, index) =>
    enhanceProduct(item, {
      currency,
      language,
      contextType,
      contextValue: contextList[index],
      pageToken,
    }),
  );

  // Backend can not filter duplicates, so do it on frontend
  return removeDuplicates(enhancedProducts, enhancedProducts, ({id}) => id);
}

import {CookieType} from 'types/CookiesSettings';

import type {CookieValue} from '../Device/CookieValue';
import {falseDecoder, falseEncoder} from './helpers';

const COOKIE_NAME = 'appWebView';

export const WebViewCookie: CookieValue<boolean> = {
  type: CookieType.FUNCTIONAL,
  key: COOKIE_NAME,
  decode: falseDecoder,
  encode: falseEncoder,
  getOptions: () => ({
    httpOnly: true,
  }),
};

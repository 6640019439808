import {RootState} from 'store/rootReducer';

type Preferences = {currency?: string; language?: string};

export type SelectorFactory<TState> = <TArgs extends unknown[], TResult>(
  selector: SelectorHandler<TState, TResult, TArgs>,
) => Selector<TResult, TArgs>;

export type SelectorFactoryState<T> = T extends SelectorFactory<infer U> ? U : never;

export type Selector<TResult, TArgs extends unknown[] = []> = (
  globalState: RootState,
  ...args: TArgs
) => TResult;

export type SelectorMeta<TState> = {
  globalState: RootState;
  state: TState;
  language: string;
  currency: string;
};

export type SelectorHandler<TState, TResult, TArgs extends unknown[] = []> = (
  meta: SelectorMeta<TState>,
  ...args: TArgs
) => TResult;

export type StateSelector<TState> = (globalState: RootState) => TState;

const createStateGetter =
  <TKey extends keyof RootState>(key: TKey) =>
  (state: RootState): RootState[TKey] =>
    state[key];

const getPreferences = (globalState: RootState): Preferences => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const state = globalState as any;

  return (state.preferences || {}) as Preferences;
};

const getCurrency = (globalState: RootState): string => getPreferences(globalState)?.currency || '';

const getLanguage = (globalState: RootState): string => getPreferences(globalState)?.language || '';

const createSelectorFactory =
  <TState>(getState: Selector<TState>) =>
  <TArgs extends unknown[], TResult>(
    selector: SelectorHandler<TState, TResult, TArgs>,
  ): Selector<TResult, TArgs> =>
  (globalState: RootState, ...args: TArgs): TResult =>
    selector(
      {
        currency: getCurrency(globalState),
        globalState,
        language: getLanguage(globalState),
        state: getState(globalState),
      },
      ...args,
    );

export {createStateGetter, createSelectorFactory};

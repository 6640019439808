const SEPARATOR = '.';

export const createFlagCodec = (
  keys: string[],
): {
  encode(input: Record<string, boolean>): string;
  decode(input: string): Record<string, boolean>;
} => {
  return {
    encode: (input: Record<string, boolean>) =>
      Object.entries(input)
        .reduce<number[]>((acc, [key, value]) => {
          if (!keys.includes(key)) {
            throw new Error('Unexpected flag, aborting encode');
          }

          acc[keys.indexOf(key)] = Number(value);
          return acc;
        }, [])
        .join(SEPARATOR),
    decode: (input: string) =>
      input.split(SEPARATOR).reduce<Record<string, boolean>>((acc, value, index) => {
        const key = keys[index];
        if (key && value !== '') {
          acc[key] = Boolean(Number(value));
        }
        return acc;
      }, {}),
  };
};

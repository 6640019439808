type RequestIdleCallbackHandle = number;

declare global {
  interface Window {
    requestIdleCallback: (
      callback: IdleRequestCallback,
      opts?: IdleRequestOptions,
    ) => RequestIdleCallbackHandle;
    cancelIdleCallback: (handle: RequestIdleCallbackHandle) => void;
  }
}

const requestIdleCallbackPolyfill: Window['requestIdleCallback'] = (
  cb,
  {timeout} = {timeout: 1},
) => {
  const start = Date.now();
  return (setTimeout as Window['setTimeout'])(
    () => {
      cb({
        didTimeout: false,
        timeRemaining() {
          return Math.max(0, 50 - (Date.now() - start));
        },
      });
    },
    __CLIENT__ ? timeout : 0,
  );
};

const cancelIdleCallbackPolyfill: Window['cancelIdleCallback'] = (id) => {
  clearTimeout(id);
};

export const requestIdleCallback =
  (__CLIENT__ && window.requestIdleCallback) || requestIdleCallbackPolyfill;

export const cancelIdleCallback =
  (__CLIENT__ && window.cancelIdleCallback) || cancelIdleCallbackPolyfill;

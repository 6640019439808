import {ContentListBaseItem, contentListBaseItem} from './ContentListBaseItem';
import {SideBannerGroup} from './SideBannerGroup';

export type ContentListSideBannerGroup = ContentListBaseItem<{sideBannerGroup: SideBannerGroup}>;

export function contentListSideBannerGroup(
  id: string,
  sideBannerGroup: SideBannerGroup,
): ContentListSideBannerGroup {
  return contentListBaseItem(id, {sideBannerGroup});
}

export function isContentListSideBannerGroup(
  item: ContentListBaseItem,
): item is ContentListSideBannerGroup {
  return Boolean((item as ContentListSideBannerGroup).content.sideBannerGroup);
}

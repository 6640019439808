export enum DeephemerizeReasonType {
  AUTH = 'auth',
  RESTRICTED_ENDPOINT = 'restrictedEndpoint',
  UPDATE_PREFERENCES = 'updatePreferences',
}

export type DeephemerizeReason =
  | {type: DeephemerizeReasonType.AUTH}
  | {type: DeephemerizeReasonType.RESTRICTED_ENDPOINT; endpoint: string}
  | {type: DeephemerizeReasonType.UPDATE_PREFERENCES; list: string[]};

export type DeephemerizeReasonPayload =
  | {reason: DeephemerizeReasonType.AUTH}
  | {reason: DeephemerizeReasonType.RESTRICTED_ENDPOINT; payload: {restrictedEndpoint: string}}
  | {reason: DeephemerizeReasonType.UPDATE_PREFERENCES; payload: {updatePreferences: string[]}};

export function getDeephemerizeReasonPayload(
  reason?: DeephemerizeReason,
): DeephemerizeReasonPayload | undefined {
  switch (reason?.type) {
    case 'auth':
      return {
        reason: reason.type,
      };

    case 'restrictedEndpoint':
      return {
        reason: reason.type,
        payload: {
          restrictedEndpoint: reason.endpoint,
        },
      };

    case 'updatePreferences':
      return {
        reason: reason.type,
        payload: {
          updatePreferences: reason.list,
        },
      };

    default:
      return undefined;
  }
}

import {useEffectOnce} from 'hooks/useEffectOnce';
import React, {AriaAttributes, useCallback, useContext, useId, useMemo, useState} from 'react';
import {nextTick} from 'utils/nextTick';

export const ModalOverlayContext = React.createContext<{
  ariaAttributes: AriaAttributes;
  updateAriaAttributes?(attributes: AriaAttributes): void;
}>({
  ariaAttributes: {},
});
ModalOverlayContext.displayName = 'ModalOverlayContext';

export const useModalOverlayLabelledById = (): string | undefined => {
  const {updateAriaAttributes} = useContext(ModalOverlayContext);
  const newHeaderUid = useId();

  useEffectOnce(() => {
    updateAriaAttributes?.({'aria-labelledby': newHeaderUid, 'aria-label': undefined});

    return () => {
      updateAriaAttributes?.({'aria-labelledby': undefined});
    };
  });

  return newHeaderUid;
};

export const useModalOverlayLabel = (label: string): void => {
  const {updateAriaAttributes} = useContext(ModalOverlayContext);

  useEffectOnce(() => {
    updateAriaAttributes?.({'aria-label': label, 'aria-labelledby': undefined});

    return () => {
      updateAriaAttributes?.({'aria-label': undefined});
    };
  });
};

export const ModalOverlayContextProvider = ({
  children,
}: React.PropsWithChildren<unknown>): JSX.Element => {
  const [ariaAttributes, setAriaAttributes] = useState<AriaAttributes>({});

  const updateAriaAttributes = useCallback(
    (attributes: AriaAttributes) =>
      nextTick(() => setAriaAttributes((ariaAttributes) => ({...ariaAttributes, ...attributes}))),
    [],
  );

  const contextValue = useMemo(
    () => ({ariaAttributes, updateAriaAttributes}),
    [ariaAttributes, updateAriaAttributes],
  );

  return (
    <ModalOverlayContext.Provider value={contextValue}>{children}</ModalOverlayContext.Provider>
  );
};

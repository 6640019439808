import {globalLog} from 'helpers/log';
import {ecsError} from 'helpers/log/ECS/ecsError';
import {unwrapErrorObject} from 'utils/error/unwrapErrorObject';
import {guidWithoutDashes} from 'utils/guid';

import {isProofOfWorkApiError, ProofOfWorkErrorPayload, ProofOfWorkSolution} from './types';
import {ProofOfWorkWorkerBus} from './worker/ProofOfWorkWorkerBus';

export class ProofOfWorkController {
  private readonly syncDurationThresholdMs = 10;

  private readonly syncDifficultyThreshold = 10;

  private readonly workerBus = new ProofOfWorkWorkerBus();

  private config?: {
    challengeToken: string;
    difficulty: number;
    endTime: number;
    inWorker: boolean;
  };

  constructor() {
    /* eslint-disable no-underscore-dangle */
    if (__CLIENT__ && window.__proofOfWorkError) {
      this.setConfigFromErrorPayload(window.__proofOfWorkError);
      window.__proofOfWorkError = undefined;
    }
    /* eslint-enable no-underscore-dangle */
  }

  public errorPayload?: ProofOfWorkErrorPayload;

  private logger = globalLog.getLogger('ProofOfWorkController');

  private readonly prefixSessionId = guidWithoutDashes();

  private prefixIncrement = 0;

  private generatePrefix(): string {
    this.prefixIncrement += 1;
    return this.prefixSessionId + String(this.prefixIncrement);
  }

  setConfigFromErrorPayload({
    challengeToken,
    difficulty,
    durationMs,
  }: ProofOfWorkErrorPayload): void {
    const endTime = Date.now() + durationMs;
    const inWorker = difficulty > this.syncDifficultyThreshold;

    this.config = {challengeToken, difficulty, endTime, inWorker};
  }

  needToSolve(): boolean {
    return Boolean(__CLIENT__ && this.config && this.config.endTime >= Date.now());
  }

  async generateSolution(): Promise<ProofOfWorkSolution | undefined> {
    if (!this.config || !this.needToSolve()) {
      if (this.workerBus.hasWorker() && !this.workerBus.hasJobs()) {
        this.workerBus.terminateWorker();
      }

      return undefined;
    }

    const {challengeToken, difficulty, inWorker} = this.config;
    const prefix = this.generatePrefix();

    if (inWorker) {
      try {
        return await this.workerBus.solve({challengeToken, difficulty, prefix});
      } catch (error) {
        this.logger.error({error: ecsError(error)});
      }
    }

    const {solveProofOfWork} = await import('./solveProofOfWork');
    const solution = solveProofOfWork({challengeToken, difficulty, prefix});

    if (
      solution.executionMs > this.syncDurationThresholdMs &&
      this.config.challengeToken === challengeToken
    ) {
      this.config.inWorker = true;
    }

    return solution;
  }

  async verifyProtection<T>(
    handler: (powSolution: ProofOfWorkSolution | undefined) => Promise<T>,
  ): Promise<T> {
    try {
      const powSolution = await this.generateSolution();
      return await handler(powSolution);
    } catch (exception) {
      const error = unwrapErrorObject((exception as Error) || {});
      if (isProofOfWorkApiError(error)) {
        if (__SERVER__) {
          this.errorPayload = error.payload;
        } else {
          this.setConfigFromErrorPayload(error.payload);
          const powSolution = await this.generateSolution();

          return handler(powSolution);
        }
      }

      throw exception;
    }
  }
}

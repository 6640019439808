import {ImageBundle} from 'types/Image';

import {ContentListBaseItem, contentListBaseItem} from './ContentListBaseItem';
import {PowerShop, Store} from './PowerShop';

export type FoundStore = {
  url?: string;
  gallery: ImageBundle[];
  store: Store;
};

export type SearchStoreList = {
  storeList: {
    headerAppearance: {
      simple: {
        title: string;
        titleCounter?: number;
        url?: string;
      };
    };
    items: FoundStore[];
  };
};

export type ContentListSearchStoreList = ContentListBaseItem<SearchStoreList>;

export function contentListSearchStoreList(
  id: string,
  searchStoreList: SearchStoreList,
): ContentListSearchStoreList {
  return contentListBaseItem(id, searchStoreList);
}

export function isContentListSearchStoreList(
  item: ContentListBaseItem,
): item is ContentListSearchStoreList {
  return Boolean((item as ContentListSearchStoreList).content?.storeList?.items);
}

export function extractSearchStoreList(item: ContentListSearchStoreList): SearchStoreList {
  return item.content;
}

export function transformStoreToPowerShop(store: FoundStore): PowerShop {
  return {
    productsList: {
      items: store.gallery.map((images, index) => ({
        content: {product: {mainImage: images, id: `${store.store.id}-product-${index}`}},
      })),
      headerAppearance: {
        store: {
          store: store.store,
        },
      },
    },
  };
}

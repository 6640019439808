import {Scope} from 'config';
import type {Request} from 'express';
import {Device} from 'helpers/ApiClient/Device';
import {CookiesRegistry} from 'helpers/ApiClient/Device/cookiesRegistry';
import {ClientBackendResponse} from 'types/ClientBackendResponse';
import {getHref} from 'utils/request';

export class CoolbeMigration {
  // eslint-disable-next-line no-useless-constructor
  constructor(
    private readonly device: Device,
    private readonly cookiesRegistry: CookiesRegistry,
    private readonly req: Request | undefined,
    // eslint-disable-next-line no-empty-function
  ) {}

  async shouldRedirect(): Promise<boolean> {
    if (
      this.device.scope.notDomain(Scope.GLOBAL) ||
      this.device.scope.not(Scope.COOLBE) ||
      this.device.isBot()
    ) {
      return false;
    }

    if (this.device.isEphemeral()) {
      return true;
    }

    try {
      if (!this.cookiesRegistry.coolbeHasOrders.exists()) {
        const {
          body: {
            payload: {items},
          },
        } =
          await this.device.transports.api.get<ClientBackendResponse<{items: {count?: number}[]}>>(
            '/parcels/filters',
          );

        const hasOrders = items?.some((item) => (item?.count ?? 0) > 0);

        this.cookiesRegistry.coolbeHasOrders.store(hasOrders);
      }

      return !this.cookiesRegistry.coolbeHasOrders.restore();
    } catch {
      return false;
    }
  }

  // redirect from path prefix to Joom (WEB-8907)
  async getRedirect(): Promise<{url: string; status: number} | undefined> {
    if (await this.shouldRedirect()) {
      const currentUrl = this.req ? getHref(this.req) : window.location.href;

      return {
        url: currentUrl.replace(`/${this.device.scope.pathPrefix}`, ''),
        status: 301,
      };
    }

    return undefined;
  }
}

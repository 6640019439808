import cn from 'classnames';
import {Portal} from 'components/Portal';
import React, {
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {CSSTransition, SwitchTransition} from 'react-transition-group';
import {guid} from 'utils/guid';

import {ToastContext, ToastRendererFn} from './ToastContext';
import styles from './ToastProvider.scss';

const TOAST_DURATION = 4_500;

function ToastRenderer({
  renderToast,
  closeToast,
}: {
  renderToast: ToastRendererFn | undefined;
  closeToast: () => void;
}): ReactElement | null {
  const toastRef = useRef<HTMLDivElement>(null);

  const closeTimerRef = useRef<ReturnType<typeof setTimeout>>();

  const clearCloseTimeout = useCallback(() => {
    clearTimeout(closeTimerRef.current);
  }, []);

  const setCloseTimeout = useCallback(() => {
    if (renderToast) {
      closeTimerRef.current = setTimeout(closeToast, TOAST_DURATION);
    }
  }, [closeToast, renderToast]);

  useEffect(() => {
    clearCloseTimeout();
    setCloseTimeout();
  }, [clearCloseTimeout, setCloseTimeout]);

  const toastId = useMemo(() => {
    if (renderToast) {
      return guid();
    }
    return null;
  }, [renderToast]);

  const content =
    renderToast?.({
      onClose: closeToast,
    }) || null;

  return (
    <SwitchTransition>
      <CSSTransition
        key={toastId || 'empty'}
        nodeRef={toastRef}
        classNames={styles}
        timeout={300}
        unmountOnExit
      >
        <Portal>
          <div
            ref={toastRef}
            className={cn(styles.toastWrap, !content && styles.empty)}
            onMouseEnter={clearCloseTimeout}
            onMouseLeave={setCloseTimeout}
          >
            {content}
          </div>
        </Portal>
      </CSSTransition>
    </SwitchTransition>
  );
}

export function ToastProvider({children}: {children: ReactNode}): ReactElement {
  const [renderToast, setRenderToast] = useState<ToastRendererFn | undefined>();

  const openToast = useCallback(
    (renderFunc: ToastRendererFn | undefined) => setRenderToast(() => renderFunc),
    [],
  );
  const closeToast = useCallback(() => setRenderToast(undefined), []);

  const context = useMemo(() => ({openToast}), [openToast]);

  return (
    <ToastContext.Provider value={context}>
      {children}
      <ToastRenderer renderToast={renderToast} closeToast={closeToast} />
    </ToastContext.Provider>
  );
}

import {extractResponsePayload} from 'store/utils';
import {requestActionCreator} from 'store/utils/requestActions';
import {ClientBackendResponse} from 'types/ClientBackendResponse';
import {FuncAction} from 'typesafe-actions';
import {resetCartSelection} from 'utils/cartSelection';

import {
  discardCartCount,
  loadCartCount as loadCartCountAction,
  LoadCartCountResponse,
  setCartCount,
} from './actions';

export {setCartCount};

export function loadCartCount(): FuncAction<Promise<LoadCartCountResponse>> {
  return requestActionCreator(loadCartCountAction, {}, (store, client) =>
    client.device.isEphemeral()
      ? Promise.resolve({count: 0})
      : client.api
          .get<ClientBackendResponse<LoadCartCountResponse>>('/cart/items/count')
          .then(extractResponsePayload),
  );
}

export function discardCart(dropSelections?: boolean): FuncAction {
  return (store, client) => {
    if (dropSelections) {
      resetCartSelection(client.storages.local);
    }
    return store.dispatch(discardCartCount());
  };
}

import {ImageBundle} from 'types/Image';
import {ActionLine} from 'types/Layout';

import {ContentListBaseItem} from './ContentListBaseItem';

export type ProductListPurchaseItemsSimpleHeaderAppearance = {
  simple: ActionLine;
};

export type ProductListPurchaseItemsHeaderAppearance =
  ProductListPurchaseItemsSimpleHeaderAppearance;

export function isProductListPurchaseItemsSimpleHeaderAppearance(
  productListPurchaseItemsHeaderAppearance?: ProductListPurchaseItemsHeaderAppearance,
): productListPurchaseItemsHeaderAppearance is ProductListPurchaseItemsSimpleHeaderAppearance {
  return Boolean(
    productListPurchaseItemsHeaderAppearance &&
      'simple' in productListPurchaseItemsHeaderAppearance,
  );
}

export type ProductListPurchaseItemsItem = {
  image?: ImageBundle;
  productId: string;
  productVariantId: string;
  quantity: number;
};

export type ProductListPurchaseItems = {
  headerAppearance?: ProductListPurchaseItemsHeaderAppearance;
  products?: ProductListPurchaseItemsItem[];
};

export type ContentListProductListPurchaseItems = ContentListBaseItem<{
  productListPurchaseItems: ProductListPurchaseItems;
}>;

export function isContentListProductListPurchaseItems(
  item: ContentListBaseItem,
): item is ContentListProductListPurchaseItems {
  return Boolean((item as ContentListProductListPurchaseItems).content.productListPurchaseItems);
}

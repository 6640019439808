import './global.scss';
import './geek.scss';
import './coolbe.scss';
import './coolbeTrending.scss';

import React from 'react';

type Props = {
  children: React.ReactNode;
};

export function PageScope({children}: Props): JSX.Element {
  return <>{children}</>;
}

import {getSelectors} from 'store/utils/reducerCreators/simple/selectors';
import {createSelectorFactory} from 'store/utils/selectors';

export const {
  isLoading: isCategoryRedirectLoading,
  isLoaded: isCategoryRedirectLoaded,
  getData: getCategoryRedirect,
  getError: getCategoryRedirectError,
  getRequest: getCategoryRedirectRequest,
} = getSelectors(createSelectorFactory((state) => state.categoryRedirect));

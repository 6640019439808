import {CookieType} from 'types/CookiesSettings';

import {CookieValue} from '../Device/CookieValue';
import {falseDecoder, falseEncoder} from './helpers';

const COOKIE_NAME = 'clbHasOrd';

const MAX_AGE = 60 * 60 * 24;

export const CoolbeHasOrders: CookieValue<boolean> = {
  type: CookieType.FUNCTIONAL,
  key: COOKIE_NAME,
  decode: falseDecoder,
  encode: falseEncoder,
  getOptions: () => ({
    maxAge: MAX_AGE,
  }),
};

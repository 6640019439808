import {voidFunction} from 'utils/function';

type TakeHandler<TData, TRes> = (promise: Promise<TData>) => TRes;

export function takeAny() {
  return <TData, TRes>(
    creator: () => Promise<TData>,
    handler: TakeHandler<TData, TRes>,
  ): Promise<TRes> =>
    creator().then(
      (data: TData) => handler(Promise.resolve(data)),
      (error: Error) => handler(Promise.reject(error)),
    );
}

// NB! Think twice if you want to use it on server,
// because your users can make race for this promise.
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function takeLast() {
  let counter = 0;
  return <TData, TRes>(
    creator: () => Promise<TData>,
    handler: TakeHandler<TData, TRes>,
    otherwiseHandler: TakeHandler<TData, TRes>,
  ): Promise<TRes> => {
    counter += 1;
    const id = counter;
    return creator().then(
      (data) => {
        const promise = Promise.resolve(data);
        return id === counter ? handler(promise) : otherwiseHandler(promise);
      },
      (error) => {
        const promise = Promise.reject(error);
        return id === counter ? handler(promise) : otherwiseHandler(promise);
      },
    );
  };
}

export function resolveWithDelay<TData = undefined>(ms: number, data?: TData): Promise<TData> {
  return new Promise((resolve) => setTimeout(() => resolve(data!), ms));
}

export function wrapToPromise<TData>(maybePromise: TData): Promise<Awaited<TData>> {
  return Promise.resolve().then(() => maybePromise) as Promise<Awaited<TData>>;
}

type DetachedPromiseResolveCallback<T> = (data: T | PromiseLike<T>) => void;
type DetachedPromiseRejectCallback = (reason?: unknown) => void;
type DetachedPromiseReturns<T> = {
  promise: Promise<T>;
  resolve: DetachedPromiseResolveCallback<T>;
  reject: DetachedPromiseRejectCallback;
};

export function createDetachedPromise<T>(): DetachedPromiseReturns<T> {
  let resolve = voidFunction as DetachedPromiseResolveCallback<T>;
  let reject = voidFunction as DetachedPromiseRejectCallback;
  const promise = new Promise<T>((originalResolve, originalReject) => {
    resolve = originalResolve;
    reject = originalReject;
  });

  return {
    promise,
    resolve,
    reject,
  };
}

export {promiseAllWithParallelLimit} from './promiseAllWithParallelLimit';
export {promiseRetry} from './promiseRetry';
export {promiseTimeout} from './promiseTimeout';

import type firebase from 'firebase';
import {Device} from 'helpers/ApiClient/Device';
import {loadFirebase} from 'helpers/ApiClient/firebase';
import {ecsError} from 'helpers/log/ECS/ecsError';

export class PushNotifications {
  // eslint-disable-next-line no-empty-function,no-useless-constructor
  constructor(private device: Device) {}

  private logger = this.device.log.getLogger('PushNotifications');

  registered = false;

  messaging?: firebase.messaging.Messaging;

  private isSupported(): boolean {
    return __CLIENT__ && 'Notification' in window;
  }

  private subscribe = (): Promise<unknown> => {
    if (!this.messaging) {
      return Promise.reject(new Error('notifications.messagingNotDefined'));
    }
    return this.messaging
      .requestPermission()
      .then(() => this.messaging?.getToken())
      .then((token) =>
        token
          ? this.device.setPushToken(token)
          : Promise.reject(new Error('notifications.noToken')),
      )
      .catch((error) => this.logger.error({error: ecsError(error)}));
  };

  private handleMessage = (payload: unknown): void => {
    this.logger.log('Message received. ', payload);
    // new Notification(payload.notification.title, payload.notification);
  };

  private handleRegister = (registration: ServiceWorkerRegistration): void => {
    if (this.registered) {
      return;
    }

    this.registered = true;

    registration.update();

    loadFirebase(this.device.scope)
      .then((firebase) => firebase && this.initializeFirebase(firebase, registration))
      .catch((error) => this.logger.error({error: ecsError(error)}));
  };

  private initializeFirebase = (
    firebase: typeof import('firebase').default,
    registration: ServiceWorkerRegistration,
  ): void => {
    if (!this.device.scope.integrations.firebase) {
      return;
    }
    this.messaging = firebase.messaging();
    this.messaging.useServiceWorker(registration);
    this.messaging.usePublicVapidKey(this.device.scope.integrations.firebase.vapidKey);
    this.messaging.onTokenRefresh(this.subscribe);
    this.messaging.onMessage(this.handleMessage);

    if (
      Notification.permission !== 'denied' &&
      !this.device.getDeviceVar('webDisablePushNotifications8591')
    ) {
      this.subscribe();
    }
  };

  init(): void {
    if (!this.isSupported() || !navigator.serviceWorker) {
      return;
    }

    navigator.serviceWorker
      .register('/sw.js')
      .then(this.handleRegister)
      .catch((error) => this.logger.error({error: ecsError(error)}));
  }
}

// only for browser
let initial = true;

/**
 * Returns `true` while landing page is rendering
 */
export function isInitialRendering(): boolean {
  if (__SERVER__) {
    // every rendering on server is initial
    return true;
  }
  return initial;
}

/**
 * Should be called with `false` when any page was mounted
 */
export function setInitialRendering(value: boolean): void {
  initial = value;
}

import {traceRequestPrometheus} from 'helpers/ApiClient/Transport/traceRequest/traceRequestPrometheus';
import {traceRequestServerTiming} from 'helpers/ApiClient/Transport/traceRequest/traceRequestServerTiming';
import {TraceRequestHandler} from 'helpers/ApiClient/Transport/traceRequest/types';

const {traceRequestJaeger} = __SERVER__
  ? // eslint-disable-next-line @typescript-eslint/no-var-requires,global-require
    (require('helpers/tracing/traceRequest') as typeof import('helpers/tracing/traceRequest'))
  : {traceRequestJaeger: undefined};

const traceRequestHandlers: TraceRequestHandler[] = [
  traceRequestJaeger,
  traceRequestServerTiming,
  traceRequestPrometheus,
].filter((item): item is TraceRequestHandler => Boolean(item));

export const traceRequest: TraceRequestHandler = (options) => {
  const callbackList = traceRequestHandlers.map((handler) => handler(options));

  return (res, err) => {
    callbackList.forEach((callback) => callback?.(res, err));
  };
};

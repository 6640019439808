import {assignSet, assignRemove} from 'utils/object';

const LOAD = 'legality/LOAD';
const LOAD_SUCCESS = 'legality/LOAD_SUCCESS';
const LOAD_FAIL = 'legality/LOAD_FAIL';

const LOAD_MANAGEMENT = 'legality/LOAD_MANAGEMENT';
const LOAD_MANAGEMENT_SUCCESS = 'legality/LOAD_MANAGEMENT_SUCCESS';
const LOAD_MANAGEMENT_FAIL = 'legality/LOAD_MANAGEMENT_FAIL';

const LOAD_STATUS = 'legality/LOAD_STATUS';
const LOAD_STATUS_SUCCESS = 'legality/LOAD_STATUS_SUCCESS';
const LOAD_STATUS_FAIL = 'legality/LOAD_STATUS_FAIL';

const CONSENT = 'legality/CONSENT';
export const CONSENT_SUCCESS = 'legality/CONSENT_SUCCESS';
export const CONSENT_FAIL = 'legality/CONSENT_FAIL';

export const CONSENT_REQUEST = 'legality/CONSENT_REQUEST';
export const CONSENT_CANCEL = 'legality/CONSENT_CANCEL';

const initialState = {
  consentAgree: null,
  consentError: null,
  consentLegality: [],
  consentLoaded: null,
  consentLoading: null,
  consentMeta: null,
  managementError: null,
  managementLoaded: false,
  managementLoading: false,
  managementNeed: null,
  data: {},
  meta: {},
  loading: {},
  loaded: {},
  error: {},
};

export function enhanceLegalityDocument(document) {
  if (!document || !document.id) {
    return null;
  }
  return document;
}

// TODO: this shouldn't exist
function unique(value, index, self) {
  return self.findIndex((item) => item.id === value.id) === index;
}

function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        error: assignRemove(state.error, action.id),
        loaded: assignRemove(state.loaded, action.id),
        loading: assignSet(state.loading, action.id, true),
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        data: assignSet(state.data, action.id, action.result.payload),
        meta: assignSet(state.meta, action.id, {
          language: action.result.language,
        }),
        loaded: assignSet(state.loaded, action.id, true),
        loading: assignRemove(state.loading, action.id),
      };
    case LOAD_FAIL:
      return {
        ...state,
        error: assignSet(state.error, action.id, action.error),
        loading: assignRemove(state.loading, action.id),
      };
    case CONSENT_REQUEST:
      return {
        ...state,
        consentAgree: null,
        consentMeta: {
          language: action.language,
        },
        consentLegality: [...state.consentLegality, action.legality].filter(unique),
        consentLoaded: true,
      };
    case CONSENT:
      return {
        ...state,
        consentError: null,
        consentLoading: true,
      };
    case CONSENT_SUCCESS:
      return {
        ...state,
        consentAgree: action.agree,
        consentLoaded: true,
        consentLoading: false,
        consentLegality: state.consentLegality.filter((item) => item.id !== action.id),
      };
    case CONSENT_FAIL:
      return {
        ...state,
        consentAgree: null,
        consentError: action.error,
        consentLoaded: false,
        consentLoading: false,
      };
    case CONSENT_CANCEL:
      return {
        ...state,
        consentAgree: null,
        consentLoaded: false,
        consentLoading: false,
        consentLegality: state.consentLegality.filter((item) => item.id !== action.id),
      };
    case LOAD_MANAGEMENT:
      return {
        ...state,
        managementError: null,
        managementLoading: true,
      };
    case LOAD_MANAGEMENT_FAIL:
      return {
        ...state,
        managementError: action.error,
        managementLoaded: false,
        managementLoading: false,
      };
    case LOAD_MANAGEMENT_SUCCESS:
      return {
        ...state,
        managementLoading: false,
        managementLoaded: true,
        managementNeed: action.result.need,
      };
    case LOAD_STATUS_FAIL:
      return {
        ...state,
        consentError: action.error,
        consentLoaded: false,
      };
    case LOAD_STATUS_SUCCESS:
      return {
        ...state,
        consentAgree: null,
        consentMeta: {
          language: action.result.language,
        },
        consentLegality: action.result.payload,
        consentLoaded: true,
      };
    default:
      return state;
  }
}

export default reducer;

export function isLegalityLoaded(globalState, id) {
  const {legality} = globalState;
  return !!legality.loaded[id];
}

export function isLegalityLoading(globalState, id) {
  return !!globalState.legality.loading[id];
}

export function getLegalityError(globalState, id) {
  return globalState.legality.error[id] || null;
}

export function getLegality(globalState, id) {
  return isLegalityLoaded(globalState, id) ? globalState.legality.data[id] : null;
}

export function isLegalityConsentLoading(globalState) {
  return globalState.legality.consentLoading;
}

export function isLegalityConsentLoaded(globalState) {
  const {legality} = globalState;
  return legality.consentLoaded;
}

export function getLegalityConsent(globalState) {
  return isLegalityConsentLoaded(globalState) ? globalState.legality.consentAgree : null;
}

export function getLegalityConsentRequires(globalState, documentType = 'anonymousUsagePopup') {
  const {legality} = globalState;
  return isLegalityConsentLoaded(globalState)
    ? legality.consentLegality?.find((document) => document.type === documentType)
    : null;
}

export function getLegalityConsentRequiresByDocumentType(documentType) {
  return (globalState) => getLegalityConsentRequires(globalState, documentType);
}

export function hasLegalityConsent(globalState) {
  return getLegalityConsent(globalState) === true;
}

export function getLegalityConsentError(globalState) {
  return globalState.legality.consentError;
}

export function isLegalityManagementLoaded(globalState) {
  return globalState.legality.managementLoaded;
}

export function isLegalityManagementLoading(globalState) {
  return globalState.legality.managementLoading;
}

export function getLegalityManagementError(globalState) {
  return globalState.legality.managementError;
}

export function hasLegalityManagement(globalState) {
  return isLegalityManagementLoaded(globalState) && globalState.legality.managementNeed;
}

export function requestLegalityConsent(legality, language) {
  return {
    type: CONSENT_REQUEST,
    legality,
    language,
  };
}

export function cancelLegalityConsent() {
  return {
    type: CONSENT_CANCEL,
  };
}

function getLegalityEndpoint(id) {
  switch (id) {
    case 'termsOfServicePopupSettings':
      return `/legality/experiment/${id}`;

    case 'bloggerTerms':
      return '/legality/blogger/terms';

    default:
      return `/legality/${id}`;
  }
}

export function loadLegality(id) {
  const endpoint = getLegalityEndpoint(id);
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    id,
    promise: (client) =>
      client.api.get(endpoint).then(({language, body: {payload}}) => ({
        payload: enhanceLegalityDocument(payload),
        language,
      })),
  };
}

export function getConsentCookieData(client) {
  let data = client.cookiesRegistry.legalConsent.restore();

  try {
    data = JSON.parse(data);
    if (data.id && data.buttonId) {
      return {
        id: data.id,
        buttonId: data.buttonId,
      };
    }
  } catch (ex) {
    // do nothing
  }

  return null;
}

export function loadLegalityConsent() {
  return {
    types: [LOAD_STATUS, LOAD_STATUS_SUCCESS, LOAD_STATUS_FAIL],
    promise: (client) =>
      client.api.get('/users/self/needConsent').then(({language, body: {payload}}) => ({
        language,
        payload: Array.isArray(payload)
          ? payload
              .map((item) => {
                if (item.type === 'anonymousUsagePopup' && client.device.isEphemeral()) {
                  const cookieConsent = getConsentCookieData(client);

                  if (cookieConsent && cookieConsent.id === item.id) {
                    return null;
                  }
                }

                return enhanceLegalityDocument(item);
              })
              .filter((item) => item?.id)
          : null,
      })),
  };
}

export function sendLegalityConsentRequest(client, body, disableHcaptcha) {
  return client.api.post('/users/self/consent', {
    body,
    hcaptchaOptions: {disable: disableHcaptcha},
  });
}

export function sentLegalityConsentPromise(client, id, buttonId, disableHcaptcha) {
  const body = {id, buttonId};
  if (client.device.isEphemeral()) {
    client.cookiesRegistry.legalConsent.store(JSON.stringify(body));
    return Promise.resolve(null);
  }
  return sendLegalityConsentRequest(client, body, disableHcaptcha).then(
    ({body: {payload}}) => payload,
  );
}

export function sendLegalityConsent(id, buttonId, agree = null, disableHcaptcha) {
  return {
    types: [CONSENT, CONSENT_SUCCESS, CONSENT_FAIL],
    id,
    buttonId,
    agree, // true - agree, false - disagree, null - close
    promise: (client) => sentLegalityConsentPromise(client, id, buttonId, disableHcaptcha),
  };
}

export function loadLegalityManagement() {
  return {
    types: [LOAD_MANAGEMENT, LOAD_MANAGEMENT_SUCCESS, LOAD_MANAGEMENT_FAIL],
    promise: (client) =>
      client.api.post('/users/self/needDataManagement').then(({body: {payload}}) => payload),
  };
}

export function sendStoredConsentFromCookie(client) {
  if (__SERVER__) {
    const body = getConsentCookieData(client);
    if (body) {
      const {legalConsent} = client.cookiesRegistry;
      if (legalConsent.exists()) {
        legalConsent.remove();
      }
      return sendLegalityConsentRequest(client, body);
    }
  }
  return Promise.resolve(null);
}

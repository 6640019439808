import PropTypes from 'prop-types';
import {objectValues} from 'utils/object';

export const ReturnStatusType = {
  AVAILABLE: 'available',
  NONE: 'none',
  INPROGRESS: 'inProgress',
  EXPIRED: 'expired',
  DECLINED: 'declined',
  COMPLETED: 'completed',
};

const OrderReturnStatusList = objectValues(ReturnStatusType);

export const ReturnInfoShape = PropTypes.shape({
  status: PropTypes.oneOf(OrderReturnStatusList),
  message: PropTypes.shape({
    action: PropTypes.string, // html
    title: PropTypes.string, // html
    text: PropTypes.string, // html
    url: PropTypes.string, // deeplink
  }),
});

import config from 'config';
import {Request} from 'express';
import {getRenderingModeHeader} from 'helpers/ApiClient/Transport/headers';
import {RenderingConfiguration, RenderingType} from 'types/Rendering';
import {guidWithoutDashes} from 'utils/guid';

type Device = import('./Device').Device;

export function createRenderingId(): string {
  // generate rendering id for sales, with 24 symbols
  return guidWithoutDashes().slice(0, 24);
}

export function loadRenderingConfig(device: Device, req: Request): RenderingConfiguration {
  const logger = device.log.getLogger('ApiClient/serverRendering');

  const renderingId = createRenderingId();

  if (process?.env?.RENDERING_MODE) {
    const option = process.env.RENDERING_MODE as RenderingType;
    logger.warn(`Rendering configuration override by environment variable: ${option}`);
    return {id: renderingId, option};
  }

  const forcedRenderingType = device.getForcedRenderingType();
  const canUseForcedRenderingType =
    config.releaseStage !== 'prod' || forcedRenderingType === RenderingType.BROWSER;
  if (canUseForcedRenderingType && forcedRenderingType) {
    return {id: renderingId, option: forcedRenderingType};
  }

  const renderingModeHeader = getRenderingModeHeader(req);
  if (
    renderingModeHeader &&
    Object.values(RenderingType).includes(renderingModeHeader as RenderingType)
  ) {
    return {
      id: renderingId,
      option: renderingModeHeader as RenderingType,
    };
  }

  logger.info('rendering mode header is not defined');

  return {
    id: renderingId,
    option: RenderingType.BROWSER,
  };
}

import {CookieType} from 'types/CookiesSettings';

import {CookieValue} from '../Device/CookieValue';
import {stringDecoder, stringEncoder} from './helpers';

const COOKIE_NAME = 'analytics_purchase';
const MAX_AGE = 60 * 60 * 24;

export const AnalyticsPurchaseCookie: CookieValue<string> = {
  type: CookieType.PERFORMANCE,
  key: COOKIE_NAME,
  decode: stringDecoder,
  encode: stringEncoder,
  getOptions: () => ({
    maxAge: MAX_AGE,
  }),
};

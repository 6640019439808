import {useSelector} from 'hooks/redux';
import React, {ReactNode} from 'react';
import {isUserAdult as getIsUserAdult} from 'store/modules/adult';
import {RootState} from 'store/rootReducer';

export const UserAdulthoodContext = React.createContext<boolean>(false);

function isUserAdult(state: RootState): boolean {
  return getIsUserAdult(state);
}

type Props = {
  children: ReactNode;
};

export function UserAdulthoodProvider({children}: Props): JSX.Element {
  const value = useSelector(isUserAdult);
  return <UserAdulthoodContext.Provider value={value}>{children}</UserAdulthoodContext.Provider>;
}

import {globalLog} from 'helpers/log';
import debounce from 'utils/debounce';
import {nextTick} from 'utils/nextTick';

const CLEAN_UP_DELAY = 5000;

function init(): void {
  if (__SERVER__ || window.dataLayer) {
    return;
  }
  window.dataLayer = [];
}

const cleanUpTags = debounce(() => {
  const {scripts} = window.document;
  const gtmScripts = [];
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < scripts.length; i++) {
    const script = scripts[i]!;
    if (script.id && script.id.includes('GTM-') && script.id.includes('-macro')) {
      gtmScripts.push(script);
    }
  }

  gtmScripts.forEach((block) => {
    document.body.removeChild(block);
  });
}, CLEAN_UP_DELAY);

function send(data: Record<string, unknown>): void {
  init();
  nextTick(() => {
    try {
      window.dataLayer?.push({
        ...data,
        eventCallback: cleanUpTags,
      });
      cleanUpTags();
    } catch (ex) {
      globalLog.getLogger('DataLayer').warn(ex);
    }
  });
}

function gtag(...args: unknown[]): void {
  window.dataLayer?.push(args);
}

export {init, send, gtag};

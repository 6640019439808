import {routeAsync} from 'helpers/asyncConnect';

import {anonymous} from './decorators';
import {DefaultParams, PoorRoute} from './types';

type Params = DefaultParams & {
  categoryId: string;
};

const NAME = 'categoryPagination';

export const CategoryPaginationRoute: PoorRoute<typeof NAME, Params> = {
  name: NAME,
  path: '/pagination/c.:categoryId',
  async: routeAsync(
    /* #__LOADABLE__ */ () => import('containers/pages/CategoryPagination'),
    (module) => module.CategoryPagination,
  ),
  decorator: anonymous,
};

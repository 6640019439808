import {CookieOptions} from 'express';

const stringDecoder = (encodedString: string): string => String(encodedString);

const stringEncoder = (value: string): string => value?.toString() || '';

const numberDecoder = (value: string): number | undefined => {
  return value && Number.isFinite(Number(value)) ? Number(value) : undefined;
};

const numberEncoder = (encodedNumber: number | undefined): string => {
  return encodedNumber === undefined ? '' : String(encodedNumber);
};

const CLOSED_COOKIE_NAME_PREFIX = 'close_';

const getCloseCookieKey = (id: string): string => `${CLOSED_COOKIE_NAME_PREFIX}${id}`;

const trueDecoder = (string: string): boolean => {
  return string !== 'n';
};

const trueEncoder = (value: boolean): string => {
  return value ? '' : 'n';
};

const falseDecoder = (string: string): boolean => {
  return string === 'y';
};

const falseEncoder = (value: boolean): string => {
  return value ? 'y' : '';
};

const DEFAULT_COOKIE_OPTIONS: CookieOptions = {
  maxAge: 60 * 60 * 24 * 365 * 10,
  secure: !__DEVELOPMENT__,
  path: '/',
  domain: undefined,
};

const cookieOptions = (options?: CookieOptions): CookieOptions => ({
  ...DEFAULT_COOKIE_OPTIONS,
  ...options,
});

export {
  stringDecoder,
  stringEncoder,
  numberDecoder,
  numberEncoder,
  getCloseCookieKey,
  trueDecoder,
  trueEncoder,
  falseDecoder,
  falseEncoder,
  cookieOptions,
};

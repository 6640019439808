import config from 'config';
import {OS} from 'helpers/userAgent';

export type DeviceVersion = {
  appType: 'web';
  appVersion: string;
  osType: string;
  osVersion: string;
  hardwareType: string;
  hardwareModel: string;
};

export function getDeviceVersion(userAgent: UAParser): DeviceVersion {
  const os = userAgent.getOS();
  const osName = os.name === OS.ANDROID ? 'android' : os.name;
  const device = userAgent.getDevice();
  return {
    appType: 'web',
    appVersion: config.version,
    osType: osName || '',
    osVersion: os.version || '',
    hardwareType: device.type || '',
    hardwareModel: device.model || '',
  };
}

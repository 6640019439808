import {isRecord} from 'utils/guards';

import {ImageBundle} from './Image';

export type RgbColor = [r: number, g: number, b: number];

export type RgbaColor = [r: number, g: number, b: number, a: number];

// 'RRGGBB' or '#RRGGBB' or 'AARRGGBB' or '#AARRGGBB' from backend
export type HexColor = string;

export type HslColor = [h: number, s: number, l: number];

export type NamedColor = {
  name?: string;
  rgb?: HexColor;
  argb?: HexColor;
  image?: ImageBundle;
  themed?: string;
};

export type Color = HexColor | NamedColor;

export function isHexRgbColor(color: unknown): color is HexColor {
  return typeof color === 'string' && /^#?[0-9a-f]{6}$/i.test(color);
}

export function isHexArgbColor(color: unknown): color is HexColor {
  return typeof color === 'string' && /^#?[0-9a-f]{8}$/i.test(color);
}

export function isNamedColor(color: unknown): color is NamedColor {
  return isRecord(color) && ['name', 'rgb', 'argb', 'image', 'themed'].some((key) => key in color);
}

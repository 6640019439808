import type {AnalyticsEvent as AnalyticsEventBase} from '@joomcode/joom-event-types';
import type {EventParamsList} from 'types/EventParams';

type AnalyticsEvent = AnalyticsEventBase & {
  params?: EventParamsList;
};

type AnalyticsSendOptions = {
  beacon?: boolean;
  immediately?: boolean;
};

type AnalyticsSendFunc = {
  (event: AnalyticsEvent, options: AnalyticsSendOptions): Promise<void>;
};

const defaultOptions: AnalyticsSendOptions = {
  beacon: false,
  immediately: false,
};

class AnalyticsLogger {
  private readonly sendFunc: AnalyticsSendFunc;

  public constructor(sendFunc: AnalyticsSendFunc) {
    this.sendFunc = sendFunc;
  }

  public send(event: AnalyticsEvent, options?: AnalyticsSendOptions): Promise<void> {
    return this.sendFunc(event, {
      ...defaultOptions,
      ...options,
    });
  }
}

export {AnalyticsLogger, AnalyticsSendFunc, AnalyticsSendOptions, AnalyticsEvent};

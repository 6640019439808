import {routeAsync} from 'helpers/asyncConnect';

import {anonymous} from './decorators';
import {DefaultParams, PoorRoute} from './types';

const asyncRoute = routeAsync(
  /* #__LOADABLE__ */ () => import('containers/pages/Main'),
  (module) => module.default,
);

const NAME = 'sharePopup';

export const SharePopupRoute: PoorRoute<typeof NAME, DefaultParams> = {
  name: NAME,
  path: '/popups/:popupId',
  async: asyncRoute,
  decorator: anonymous,
};

import classnames from 'classnames/bind';
import {ConditionalWrapper} from 'components/ConditionalWrapper';
import {Loader} from 'components/UIKit/Loader';
import React from 'react';

import styles from './index.scss';

const cn = classnames.bind(styles);

export enum Size {
  BIG = 'big',
  SMALL = 'small',
  INLINE = 'inline',
  COVER = 'cover',
  FULL_COVER = 'fullCover',
}

type Props = {
  block?: boolean;
  size?: Size;
};

export const Preloader: React.FC<Props> = ({block = false, size = Size.BIG}: Props) => {
  const classNames = cn('preloaderWrapper', size, {
    block,
  });

  return (
    <div className={classNames}>
      <ConditionalWrapper
        condition={[Size.COVER, Size.FULL_COVER].includes(size)}
        // eslint-disable-next-line react/jsx-no-bind
        wrapper={(child) => <div className={styles.squareContent}>{child}</div>}
      >
        <Loader />
      </ConditionalWrapper>
    </div>
  );
};

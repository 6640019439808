import {getRemainingTime} from 'helpers/serverTime';
import {enhanceProduct, enhanceProducts} from 'store/utils/enhancers/enhanceProducts';
import {isDiscountBadge} from 'types/ProductBadge';
import {
  ProductLite,
  ProductLiteOptions,
  ProductLiteRaw,
  ProductLitesOptions,
} from 'types/ProductLite';

export const enhanceProductLite = (raw: ProductLiteRaw, options: ProductLiteOptions): ProductLite =>
  enhanceProduct(raw, options);

export const enhanceProductLites = (
  rawList: ProductLiteRaw[],
  options: ProductLitesOptions,
): ProductLite[] => {
  const {contexts, currency, language, pageToken = ''} = options;
  return enhanceProducts(rawList, {language, currency, pageToken, contexts});
};

export function isOfferShown({badge, loadedTimeMs}: ProductLite): boolean {
  if (isDiscountBadge(badge)) {
    if (badge.discount.timer) {
      return getRemainingTime(loadedTimeMs + badge.discount.timer.remainingMs) > 0;
    }

    return true;
  }

  return false;
}

import {Request, Response} from 'express';
import {traceRequest} from 'helpers/ApiClient/Transport/traceRequest';
import {TraceRequestCallback} from 'helpers/ApiClient/Transport/traceRequest/types';
import {getNumberEnv} from 'helpers/env';
import {globalLog, serializeAndShortObject} from 'helpers/log';
import {ecsError} from 'helpers/log/ECS/ecsError';
import {ecsHttpHeaders} from 'helpers/log/ECS/ecsHttpHeaders';
import {ecsUrl} from 'helpers/log/ECS/ecsUrl';
import {ECSLogger} from 'helpers/log/ECS/types';
import superagent, {SuperAgentRequest} from 'superagent';
import {
  TransportHeaders,
  TransportMeta,
  TransportMethod,
  TransportOptions,
  TransportQuery,
} from 'types/Transport';
import {createUrl} from 'utils/url';

import {Device} from '../Device';
import {addRetryNumberHeader, getUserAgentHeader} from './headers';
import {ApiResponse, createResponse} from './Response';
import {needToRetry, retryDelay} from './retry';

export type TransportPrefix = string | (() => string);

export type RequestData = Pick<TransportOptions, 'id' | 'body' | 'withCredentials' | 'attach'> & {
  requestPath: string;
  requestQuery: TransportQuery;
  path: string;
  meta: TransportMeta;
  headers: TransportHeaders;
};

/** Base transport for all other transports */
export abstract class Transport {
  prefix: () => string;

  superagentInstance = superagent;

  activeRequestsById: Partial<Record<string, SuperAgentRequest>> = {};

  get logger(): ECSLogger {
    return this.device?.log.getLogger('Transport') || globalLog.getLogger('Transport');
  }

  constructor(
    prefix: TransportPrefix,
    protected readonly device: Device | undefined,
    protected readonly req: Request | undefined,
    protected readonly res: Response | undefined,
  ) {
    this.prefix = typeof prefix === 'function' ? prefix : () => prefix || '';
  }

  getPrefix(): string {
    return this.prefix();
  }

  abort(id: string): boolean {
    const request = this.activeRequestsById[id];
    delete this.activeRequestsById[id];
    if (request) {
      request.abort();
      return true;
    }
    return false;
  }

  // for testing purpose
  setSuperagentInstance(instance: typeof superagent): void {
    this.superagentInstance = instance;
  }

  buildMeta(options: TransportOptions): TransportMeta {
    // currency uses only in response
    const currency =
      this.device?.getTransportCurrencyOverride() || this.device?.getCurrency() || '';
    const overrideCurrency = this.device?.getTransportCurrencyOverride() || undefined;
    const region = this.device?.getTransportRegionOverride() || this.device?.getRegion() || '';
    const language = this.device?.getLanguage() || '';
    const locale = this.device?.getLanguageConfig().locale;

    return {
      retryNumber: (options && options.retryNumber) || 0,
      region,
      currency,
      overrideCurrency,
      language,
      locale,
    };
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  buildPath(path: string, meta: TransportMeta): string {
    return `${this.prefix()}${path}`;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  buildQuery(query: TransportQuery, meta: TransportMeta): TransportQuery {
    return query;
  }

  buildHeaders(
    headers: TransportHeaders | null | undefined,
    meta: TransportMeta,
  ): TransportHeaders {
    return addRetryNumberHeader(headers || {}, meta.retryNumber);
  }

  getRequestData(path: string, options: TransportOptions = {}): RequestData {
    const {query, querySerialization, body, headers, attach, withCredentials, id} = options || {};
    const meta = this.buildMeta(options);
    const requestHeaders = this.buildHeaders(headers, meta);
    const requestPath = this.buildPath(path, meta);
    const requestQuery = this.buildQuery(
      {
        ...(query || {}),
        _: Date.now().toString(36),
      },
      meta,
    );

    return {
      id,
      requestPath,
      requestQuery,
      path: createUrl(requestPath, requestQuery || {}, {querySerialization}),
      headers: requestHeaders,
      attach,
      meta,
      body,
      withCredentials: Boolean(withCredentials),
    };
  }

  request<TBody = undefined>(
    method: TransportMethod,
    reqPath: string,
    options: TransportOptions = {},
  ): Promise<ApiResponse<TBody>> {
    const requestData = this.getRequestData(reqPath, options);
    const {headers, path, body, meta, attach, withCredentials, id} = requestData;

    let traceCallback: TraceRequestCallback | undefined;
    if (!options.skipTracing) {
      try {
        traceCallback = traceRequest({
          req: this.req,
          res: this.res,
          requestData,
          method,
          prefix: this.prefix(),
        });
      } catch (error) {
        this.logger.error({error: ecsError(error)});
      }
    }

    // check access token to avoid inifinite recusrion when user was changed,
    // but you can not update user because your token was invalidated
    if (
      reqPath !== '/users/self' &&
      this.device &&
      this.device.getAccessToken() &&
      this.device.isUserChanged()
    ) {
      this.device.loadUser();
    }

    const request = this.superagentInstance[method](path);

    if (withCredentials && __CLIENT__) {
      request.withCredentials();
    }

    if (headers) {
      Object.keys(headers).forEach((name) => {
        request.set(name, headers[name] as string);
      });
    }

    if (options && options.onProgress) {
      request.on('progress', options.onProgress);
    }

    if (__SERVER__) {
      const timeout = options.serverTimeout || 5_000;

      request.timeout({
        response: getNumberEnv('REQUEST_RESPONSE_TIMEOUT', timeout),
        deadline: getNumberEnv('REQUEST_DEADLINE_TIMEOUT', timeout + 25_000),
      });
    }

    if (attach) {
      // @ts-expect-error superagent type Blob is from buffer, but we need Blob from browser
      attach.forEach((attachParams) => request.attach(...attachParams));
    } else if (body) {
      request.send(body);
    }

    const startTime = performance.now();

    const handleResponse = (
      err: superagent.ResponseError | undefined,
      res: superagent.Response | undefined,
    ): ApiResponse<TBody> | Promise<ApiResponse<TBody>> => {
      const endTime = performance.now();
      if (id) {
        delete this.activeRequestsById[id];
      }

      const result = createResponse<TBody>(res, err, meta);

      this.logger.info({
        url: ecsUrl(request.url),
        event: {
          duration: endTime - startTime,
        },
        error: ecsError(result.error),
        user_agent: {
          original: getUserAgentHeader(headers),
        },
        http: {
          request: {
            method: method.toUpperCase(),
            body: {content: serializeAndShortObject(body || {})},
            headers: ecsHttpHeaders(headers),
            retry_number: meta.retryNumber,
            with_credentials: withCredentials,
          },
          response: {
            status_code: result.status,
            request_id: result.requestId,
            ...(__DEVELOPMENT__ && {
              body: {content: serializeAndShortObject(result.body)},
              headers: ecsHttpHeaders(result.headers),
            }),
          },
        },
      });

      try {
        traceCallback?.(result, err);
      } catch (error) {
        this.logger.error({error: ecsError(error)});
      }

      this.device?.updateTokensWithHeaders(result.headers);

      const retry =
        typeof (options && options.retry) === 'boolean' ? options.retry : method === 'get';
      const nextRetryNumber = meta.retryNumber + 1;
      if (retry && needToRetry(result, nextRetryNumber)) {
        return retryDelay(nextRetryNumber).then(() =>
          this.request<TBody>(method, reqPath, {
            ...options,
            retryNumber: nextRetryNumber,
          }),
        );
      }

      if (err) {
        throw result;
      }

      return result;
    };

    if (id) {
      this.activeRequestsById[id] = request;
    }

    return request.then(
      (res) => handleResponse(undefined, res),
      (err) => handleResponse(err, err.response),
    );
  }

  get<TBody = undefined>(path: string, options?: TransportOptions): Promise<ApiResponse<TBody>> {
    return this.request<TBody>(TransportMethod.GET, path, options);
  }

  post<TBody = undefined>(path: string, options?: TransportOptions): Promise<ApiResponse<TBody>> {
    return this.request<TBody>(TransportMethod.POST, path, options);
  }

  put<TBody = undefined>(path: string, options?: TransportOptions): Promise<ApiResponse<TBody>> {
    return this.request<TBody>(TransportMethod.PUT, path, options);
  }

  delete<TBody = undefined>(path: string, options?: TransportOptions): Promise<ApiResponse<TBody>> {
    return this.request<TBody>(TransportMethod.DELETE, path, options);
  }
}

import {RenderingType} from 'types/Rendering';

export enum Type {
  BOT = 'bot',
  HUMAN = 'human',
}

export type UserType = {
  type: Type;
  verified: boolean;
};

export function getUserTypeByRenderingType(option: RenderingType): UserType {
  switch (option) {
    case RenderingType.USER:
      return {type: Type.HUMAN, verified: true};
    case RenderingType.META:
      return {type: Type.BOT, verified: false};
    case RenderingType.CRAWLER:
      return {type: Type.BOT, verified: true};
    default:
      return {type: Type.HUMAN, verified: false};
  }
}

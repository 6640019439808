import {ProgressEvent} from 'superagent';
import {QueryMap, QuerySerializationOptions} from 'utils/url';

export enum TransportMethod {
  GET = 'get',
  POST = 'post',
  PUT = 'put',
  DELETE = 'delete',
}

export type TransportQuery = QueryMap;

export type TransportMeta = {
  retryNumber: number;
  region: string;
  currency: string;
  overrideCurrency: string | undefined;
  language: string;
  locale: string | undefined;
};

export type TransportHeaders = Record<string, string | number>;

export type CacheOptions = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  qd: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  body: any;
  time: number;
  requestTime: number;
};
type AttachValue = Blob | Buffer | import('fs').ReadStream | string | boolean | number;

type AttachOptions = string | {filename?: string; contentType?: string};

export type TransportOptions = {
  // for aborting, check Transport.abort()
  id?: string;
  query?: TransportQuery | null | undefined;
  querySerialization?: QuerySerializationOptions;
  body?: Record<string, unknown> | unknown[] | ArrayBuffer | null | undefined;
  headers?: TransportHeaders | null | undefined;
  onProgress?: (event: ProgressEvent) => unknown;
  attach?: Array<[field: string, file: AttachValue, options?: AttachOptions]>;
  retry?: boolean;
  withCredentials?: boolean;
  cache?: CacheOptions;
  deviceCreateDegradationPolicy?: 'disabled' | 'retry' | 'error' | 'silent';
  skipTracing?: boolean;
  serverTimeout?: number;
  // for internal usage
  retryNumber?: number;
};

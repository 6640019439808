import {Image} from 'components/Image';
import {Icon} from 'components/UIKit/Icon';
import React, {forwardRef, ReactNode, Ref, SyntheticEvent, useCallback} from 'react';
import {ImageBundle} from 'types/Image';

import styles from './Toast.scss';

export type ToastProps = {
  image?: ImageBundle;
  title: ReactNode;
  subtitle?: ReactNode;
  isRestricted?: boolean;
  onClick?: () => void;
  onClose: () => void;
};

export const Toast = forwardRef(function Toast(
  {image, title, subtitle, isRestricted = false, onClick, onClose}: ToastProps,
  ref: Ref<HTMLElement>,
) {
  const handleClose = useCallback(
    (event: SyntheticEvent) => {
      event.stopPropagation();
      onClose();
    },
    [onClose],
  );

  return React.cloneElement(
    onClick ? <button type="button" onClick={onClick} /> : <div />,
    {ref, className: styles.toast},
    <>
      {image && (
        <div className={styles.image}>
          <Image
            blurred={isRestricted}
            image={image}
            cover
            multiply
            width="100%"
            height="100%"
            sizes="40px"
            loadImmediately
          />
          {isRestricted && (
            <div className={styles.restricted}>
              <Icon type="mono" name="eye-off-filled-24" />
            </div>
          )}
        </div>
      )}
      <div className={styles.text}>
        <div className={styles.title}>{title}</div>
        {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
      </div>
      <button type="button" className={styles.closeButton} onClick={handleClose}>
        <div className={styles.closeIcon}>
          <Icon name="close-linear-24" type="mono" />
        </div>
      </button>
    </>,
  );
});

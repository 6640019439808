import {createContext, ReactElement, useContext} from 'react';

type ToastRendererProps = {
  onClose: () => void;
};
export type ToastRendererFn = (props: ToastRendererProps) => ReactElement;

export type OpenToastFn = (toast: ToastRendererFn) => void;

export type ToastContextValue = {
  openToast: OpenToastFn;
};

export const ToastContext = createContext<ToastContextValue>({
  openToast() {
    if (__DEVELOPMENT__) {
      throw new Error('usage of ToastContext outside ToastProvider');
    }
  },
});

export function useOpenToast(): OpenToastFn {
  return useContext(ToastContext).openToast;
}

const LOAD_POPUP = 'adult/LOAD_POPUP';
const LOAD_POPUP_SUCCESS = 'adult/LOAD_POPUP_SUCCESS';
const LOAD_POPUP_ERROR = 'adult/LOAD_POPUP_ERROR';
const REPORT_ADULTHOOD = 'adult/REPORT_ADULTHOOD';
export const REPORT_ADULTHOOD_SUCCESS = 'adult/REPORT_ADULTHOOD_SUCCESS';
const REPORT_ADULTHOOD_ERROR = 'adult/REPORT_ADULTHOOD_ERROR';
const SET_ADULTHOOD = 'adult/SET_ADULTHOOD';

const initialState = {
  popupData: null,
  popupError: null,
  isPopupLoading: true,
  isPopupLoaded: false,
  isUserAdult: false,
  isAdulthoodSaving: false,
  adulthoodSavingError: null,
  meta: {},
};

function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_POPUP:
      return {
        ...state,
        popupData: null,
        popupError: null,
        isPopupLoading: true,
        isPopupLoaded: false,
      };
    case LOAD_POPUP_SUCCESS:
      return {
        ...state,
        popupData: action.result,
        popupError: null,
        isPopupLoading: false,
        isPopupLoaded: true,
        meta: {
          language: action.result.language,
        },
      };
    case LOAD_POPUP_ERROR:
      return {
        ...state,
        popupData: null,
        popupError: action.error,
        isPopupLoading: false,
        isPopupLoaded: false,
      };
    case REPORT_ADULTHOOD:
      return {
        ...state,
        isAdulthoodSaving: true,
        adulthoodSavingError: null,
      };
    case REPORT_ADULTHOOD_SUCCESS:
      return {
        ...state,
        isUserAdult: action.result,
        isAdulthoodSaving: false,
        adulthoodSavingError: null,
      };
    case REPORT_ADULTHOOD_ERROR:
      return {
        ...state,
        isAdulthoodSaving: false,
        adulthoodSavingError: action.error,
      };
    case SET_ADULTHOOD:
      return {
        ...state,
        isUserAdult: action.isAdult,
      };
    default:
      return state;
  }
}

export default reducer;

function getState(globalState) {
  return globalState.adult;
}

export function isPopupLoading(globalState) {
  return getState(globalState).isPopupLoading;
}

export function isPopupLoaded(globalState) {
  const state = getState(globalState);
  return state.isPopupLoaded;
}

export function getPopupData(globalState) {
  return getState(globalState).popupData;
}

export function getPopupError(globalState) {
  return getState(globalState).popupError;
}

export function isUserAdult(globalState) {
  return getState(globalState).isUserAdult;
}

export function getAdulthoodSavingError(globalState) {
  return getState(globalState).adulthoodSavingError;
}

export function isAdulthoodSaving(globalState) {
  return getState(globalState).isAdulthoodSaving;
}

export function loadPopupData() {
  return {
    types: [LOAD_POPUP, LOAD_POPUP_SUCCESS, LOAD_POPUP_ERROR],
    promise: (client) =>
      client.api.get('/users/self/adulthoodPopup').then(({language, body: {payload}}) => ({
        ...payload,
        language,
      })),
  };
}

export function saveUserAdulthood() {
  return {
    types: [REPORT_ADULTHOOD, REPORT_ADULTHOOD_SUCCESS, REPORT_ADULTHOOD_ERROR],
    promise: (client) => client.device.reportAdulthood(),
  };
}

export function setUserAdulthood(isAdult) {
  return {
    type: SET_ADULTHOOD,
    isAdult,
  };
}

import EventEmitter from 'component-emitter';
import {AuthMigrationMethod} from 'components/Auth/Views/Type';
import {DeephemerizeReasonType} from 'helpers/ApiClient/Device/deephemerizeReason';
import {ECSLogger} from 'helpers/log/ECS/types';
import {ClientBackendResponse} from 'types/ClientBackendResponse';
import {Error} from 'types/Error';
import {BackendUser, User, UserWithPopup} from 'types/User';
import {getErrorMessage} from 'utils/error/getErrorMessage';
import {getErrorType} from 'utils/error/getErrorType';
import {identity} from 'utils/function';

import {Transport} from '../Transport';
import {Device} from '.';
import tokenDegradation from './tokenDegradation';

export type SocialSignInRequest = {
  type: 'facebook' | 'google' | 'ok' | 'vk' | 'apple';
  token: string;
  email?: string;
  first_name?: string;
  last_name?: string;
  id_token?: string;
};
/* eslint-enable camelcase */

export type EmailSignInRequest = {
  email: string;
  password: string;
};

export type EmailSignUpRequest = {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  source?: 'main' | 'checkout' | 'subscription';
};

export type EmailCheckoutSignUpRequest = {
  email: string;
  firstName: string;
  lastName: string;
};

export type NewPasswordRequest = {
  token: string;
  password: string;
};

export type AuthResult<U extends User = User> =
  | {
      type: 'confirmed';
      payload: {
        user: U;
      };
    }
  | {
      type: 'confirmationRequired';
      message: string;
    };

function isAlreadyRegisteredError(error: Error): boolean {
  return (
    getErrorType(error) === 'api.bad_request' &&
    // backend sometimes capitalize 1st letter
    getErrorMessage(error).toLowerCase() === 'already signed in'
  );
}

function convertUserToAuthResult(user: User): AuthResult {
  return {
    type: 'confirmed',
    payload: {user},
  };
}

type AuthEvents = {
  userchange: [AuthResult<BackendUser>];
  startAuth: [];
  startSignout: [];
};

export class Auth extends EventEmitter<AuthEvents> {
  private logger: ECSLogger;

  constructor(
    private device: Device,
    private api: Transport,
  ) {
    super();
    this.logger = device.log.getLogger('Auth');
  }

  async signout(): Promise<AuthResult> {
    this.emit('startSignout');

    const data = await this.api.post<ClientBackendResponse<User>>('/auth/signOut');
    const result = convertUserToAuthResult(data.body.payload);
    this.emit('userchange', result);
    return result;
  }

  private async auth<TResponse>(
    endpoint: string,
    body: Record<string, unknown>,
    extractor: (response: TResponse) => AuthResult,
  ): Promise<AuthResult> {
    this.emit('startAuth');

    /**
     * Upgrade user before auth to prevent config changing and page reloading.
     */
    if (this.device.isEphemeral()) {
      await tokenDegradation(this.device, () => {
        this.logger.info('Upgrade ephemeral user');
        return this.device.upgradeEphemeral({type: DeephemerizeReasonType.AUTH}, true);
      });
    }

    let result: AuthResult;

    try {
      const data = await this.api.post<ClientBackendResponse<TResponse>>(endpoint, {body});
      result = extractor(data.body.payload);
    } catch (error) {
      if (!isAlreadyRegisteredError(error as Error)) {
        throw error;
      }
      const data = await this.api.get<ClientBackendResponse<User>>('/users/self');
      result = convertUserToAuthResult(data.body.payload);
    }

    this.emit('userchange', result);
    return result;
  }

  setNewPassword(data: NewPasswordRequest): Promise<AuthResult> {
    return this.auth<User>('/auth/email/setNewPassword', data, convertUserToAuthResult);
  }

  socialSignIn(data: SocialSignInRequest): Promise<AuthResult> {
    return this.auth<User>('/auth/social/signIn', data, convertUserToAuthResult);
  }

  socialMigrate(
    data: SocialSignInRequest,
    migrationMethod: AuthMigrationMethod,
  ): Promise<AuthResult<UserWithPopup>> {
    return this.auth<UserWithPopup>(
      migrationMethod === AuthMigrationMethod.RECOVERY
        ? '/auth/social/recovery'
        : '/auth/social/migrate',
      data,
      convertUserToAuthResult,
    );
  }

  emailSignIn(data: EmailSignInRequest): Promise<AuthResult> {
    return this.auth<User>('/auth/email/signIn', data, convertUserToAuthResult);
  }

  emailSignUp(data: EmailSignUpRequest): Promise<AuthResult> {
    return this.auth<AuthResult>('/auth/email/signUp', data, identity);
  }

  /**
   * TODO(4u@): Change to base signup method when it will have been implemented on backend
   * https://joom.myjetbrains.com/youtrack/issue/BACKEND-3989
   */
  emailCheckoutSignUp(data: EmailCheckoutSignUpRequest): Promise<AuthResult> {
    return this.auth<AuthResult>('/auth/email/signUpOnCheckout', data, identity);
  }
}

import {getQueryData, QueryMap} from './index';

export type Utms = QueryMap;

export const UTM_SOURCE_KEY = 'utm_source';
export const UTM_PROXY_KEY = 'utm_provider';
export const UTM_VALUE = 'joomweb';

export function getUtmsByUrl(url: string): Utms {
  return getQueryData(url);
}

export function enhanceTrackingParams(utmsDict: Utms, ...dicts: Utms[]): Utms {
  const params = Object.assign({}, utmsDict, ...dicts);
  const joomWebKey = params[UTM_SOURCE_KEY] ? UTM_PROXY_KEY : UTM_SOURCE_KEY;
  params[joomWebKey] = UTM_VALUE;
  return params;
}

import {Analytics} from 'helpers/ApiClient/Analytics';
import {useEffectOnce} from 'hooks/useEffectOnce';
import {AnalyticsContext} from 'providers/AnalyticsContext';
import {DependencyList, useCallback, useContext, useEffect, useRef} from 'react';
import type {AnalyticsEvent, AnalyticsSendOptions} from 'types/AnalyticsEvent';

type CallbackWithAnalytics = (analytics: Analytics) => void;

// Prepare events for sendAnalytics
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type FilterEvent<E> = E extends any ? ({} extends Omit<E, 'type' | 'payload'> ? E : never) : never;
type SendAnalyticsEvent = FilterEvent<AnalyticsEvent>;
type ExtractEventPayload<E, T> = E extends {type: T; payload?: infer U} ? U : undefined;
type EventWithPayload = Extract<SendAnalyticsEvent, {payload?: unknown}>;
type EventWithoutPayload = Exclude<SendAnalyticsEvent, {payload: unknown}>;
type EventOptions = Pick<AnalyticsEvent, 'params'>;

export type SendAnalytics = {
  <E extends EventWithPayload, T extends E['type']>(
    type: T,
    payload: ExtractEventPayload<E, T>,
    options?: EventOptions,
  ): void;
  <T extends EventWithoutPayload['type']>(type: T, options?: EventOptions): void;
};

export function useAnalytics(): Analytics {
  const analytics = useContext(AnalyticsContext);

  if (!analytics) {
    throw Error('AnalyticsContext must be defined');
  }

  return analytics;
}

export function useSendAnalytics(): SendAnalytics {
  const analytics = useAnalytics();

  return useCallback<SendAnalytics>(
    <T extends SendAnalyticsEvent['type']>(
      type: T,
      payload?: ExtractEventPayload<SendAnalyticsEvent, T>,
      options?: EventOptions,
    ): void => {
      const {params} = options || {};
      if (payload) {
        analytics.sendEvent({type, payload, params} as EventWithPayload);
      } else {
        analytics.sendEvent({type, params} as EventWithoutPayload);
      }
    },
    [], // eslint-disable-line react-hooks/exhaustive-deps
  );
}

export function useSendAnalyticsOnMount(fn: CallbackWithAnalytics): void {
  const analytics = useAnalytics();
  useEffectOnce(() => {
    fn(analytics);
  });
}

export function useSendAnalyticsOnUnmount(fn: CallbackWithAnalytics): void {
  const analytics = useAnalytics();
  useEffectOnce(() => () => (analytics ? fn(analytics) : undefined));
}

export function useSendAnalyticsOnUpdate(fn: CallbackWithAnalytics, deps?: DependencyList): void {
  const analytics = useAnalytics();
  useEffect(() => {
    fn(analytics);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps || []);
}

export type EventOpenCreator = () => AnalyticsEvent | undefined;

export type EventCloseCreator = (params: {sinceOpenMs: number}) => AnalyticsEvent | undefined;

export function useSendAnalyticsOpenClose(
  eventOpenCreator: EventOpenCreator,
  eventCloseCreator: EventCloseCreator,
  deps?: DependencyList,
): void {
  const analytics = useAnalytics();

  useEffect(() => {
    if (!analytics) {
      return undefined;
    }

    const mountTime = Date.now();
    const maybeEvent = eventOpenCreator();
    if (maybeEvent) {
      analytics.sendEvent(maybeEvent);
    }

    return () => {
      const maybeEvent = eventCloseCreator({sinceOpenMs: Date.now() - mountTime});
      if (maybeEvent) {
        analytics.sendEvent(maybeEvent);
      }
    };
  }, deps || []); // eslint-disable-line react-hooks/exhaustive-deps
}

export type EventCreatorWithSinceOpenMs = (params: {sinceOpenMs: number}) => AnalyticsEvent;

export function useSendAnalyticsWithSinceOpenMs(): (
  eventCreator: EventCreatorWithSinceOpenMs,
  options?: AnalyticsSendOptions,
) => void {
  const analytics = useAnalytics();
  const mountTime = useRef<number>(Date.now());

  useEffect(() => {
    mountTime.current = Date.now();
  }, []);

  return useCallback((eventCreator, options) => {
    analytics.sendEvent(eventCreator({sinceOpenMs: Date.now() - mountTime.current}), options);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
}

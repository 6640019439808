import {ContentListBaseItem, contentListBaseItem} from './ContentListBaseItem';

export type SectionHeader = {
  title: string;
};

export type ContentListSectionHeader = ContentListBaseItem<{header: SectionHeader}>;

export function contentListSectionHeader(
  id: string,
  header: SectionHeader,
): ContentListSectionHeader {
  return contentListBaseItem(id, {header});
}

export function isContentListSectionHeader(
  item: ContentListBaseItem,
): item is ContentListSectionHeader {
  return Boolean((item as ContentListSectionHeader).content.header);
}

export function extractSectionHeader(item: ContentListSectionHeader): SectionHeader {
  return item.content.header;
}

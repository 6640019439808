import {routeAsync} from 'helpers/asyncConnect';

import {anonymous} from './decorators';
import {DefaultParams, PoorRoute} from './types';

type Params = DefaultParams & {
  categoryId: string;
};

const NAME = 'bestsPagination';

export const BestsPaginationRoute: PoorRoute<typeof NAME, Params> = {
  name: NAME,
  path: '/bests/c.:categoryId',
  async: routeAsync(
    /* #__LOADABLE__ */ () => import('containers/pages/BestsPagination'),
    (module) => module.BestsPagination,
  ),
  decorator: anonymous,
};

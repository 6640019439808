import type {Request} from 'express';
import {CookiesRegistry} from 'helpers/ApiClient/Device/cookiesRegistry';
import {DeephemerizeReasonType} from 'helpers/ApiClient/Device/deephemerizeReason';
import {getJmtOrigin, getJoomOrigin} from 'helpers/ApiClient/JmtMigration/utils';
import {PREFIX_SCOPE_GET_PARAM} from 'helpers/ApiClient/Scope/ScopeConfig';
import {isProofOfWorkApiError} from 'helpers/ApiClient/Transport/ProofOfWork/types';
import {ecsError} from 'helpers/log/ECS/ecsError';
import {isMobileOrTabletDevice, OS} from 'helpers/userAgent';
import {omit} from 'lodash';
import {getUrl, matchRoute} from 'routes';
import superagent from 'superagent';
import {ExtendedErrorObject, unwrapErrorObject} from 'utils/error/unwrapErrorObject';
import {createUrl, getQueryData, QueryMap} from 'utils/url';

import {Device} from '../Device';

export class JmtMigration {
  // eslint-disable-next-line no-useless-constructor
  constructor(
    private readonly device: Device,
    private readonly cookiesRegistry: CookiesRegistry,
    private readonly req: Request | undefined, // eslint-disable-next-line no-empty-function
  ) {}

  private readonly logger = this.device.log.getLogger('JmtMigration');

  private popupShown = false;

  private getMigrationAttemptsCount(): number {
    return this.cookiesRegistry.jmtMigrationCloseCount.restore() || 0;
  }

  private getMigrationMaxAttempts(): number {
    return this.device.getDeviceVar('jmtMigrationRequestConfig')?.maxShowNumber || Infinity;
  }

  private getQuery(): QueryMap {
    return this.req ? (this.req.query as QueryMap) : getQueryData(window.location.search);
  }

  private getPathname(): string {
    return this.req ? this.req.path : window.location.pathname;
  }

  private ruPathRegExp = /(^|\/)ru(\/|$)/;

  canShowMigrationPopup(): boolean {
    return (
      this.device.scope.hasOppositeEntity &&
      !this.device.isAppWebView() &&
      !this.device.isBot() &&
      !this.popupShown &&
      this.device.getIsJmtMigrationNeeded() &&
      !this.cookiesRegistry.jmtMigrationClose.restore().shown &&
      this.cookiesRegistry.jmtMigrationAvailable.restore() &&
      this.getMigrationAttemptsCount() < this.getMigrationMaxAttempts()
    );
  }

  setMigrationPopupShown(): void {
    this.popupShown = true;

    if (!this.cookiesRegistry.jmtMigrationClose.restore().shown) {
      this.cookiesRegistry.jmtMigrationClose.store({
        shown: true,
        maxAge: this.device.getDeviceVar('jmtMigrationRequestConfig')?.showIntervalSeconds,
      });
      this.cookiesRegistry.jmtMigrationCloseCount.store(this.getMigrationAttemptsCount() + 1);
    }
  }

  async getMigrationUrl(autoMigration?: boolean): Promise<string> {
    const query = this.getQuery();
    const path = this.getPathname();

    const data = await this.device.transports.api.post<{payload: {jmtMigrationToken: string}}>(
      '/device/jmtMigration/createJmtMigrationToken',
    );
    const {jmtMigrationToken} = data.body.payload;

    const normalizedQuery = omit(query, [
      'jmtMigrationError',
      'jmtMigrationSuccess',
      'jmtAutoMigration',
      'jmtDisableAutoRedirect',
    ]);
    const jmtMigrationPath = createUrl(path, normalizedQuery as QueryMap);

    const migrationUrlQuery: QueryMap = {
      jmtMigrationToken,
      jmtMigrationPath,
      [PREFIX_SCOPE_GET_PARAM]: this.device.scope.prefixScope,
    };
    if (autoMigration) {
      migrationUrlQuery.jmtAutoMigration = true;
    }

    return createUrl(
      `${getJoomOrigin(this.device.scope, this.req)}/joomMigration`,
      migrationUrlQuery,
    );
  }

  async startJmtMigration(): Promise<void> {
    if (__SERVER__ || !this.device.scope.hasOppositeEntity || this.device.scope.notJmt) {
      throw new Error('jmt migration is not available');
    }

    const migrationUrl = await this.getMigrationUrl();

    window.location.assign(migrationUrl);
  }

  async getJmtAutoMigrationFromRedirect(): Promise<string | undefined> {
    const {jmtAutoMigration} = this.getQuery();
    const jmtDisableAutoMigration = this.device.getDeviceVar('jmtDisableAutoMigration');

    if (
      this.device.scope.hasOppositeEntity &&
      this.device.scope.isJmt &&
      jmtAutoMigration &&
      !this.device.isAppWebView() &&
      !this.device.isBot() &&
      this.device.getIsJmtMigrationNeeded()
    ) {
      this.cookiesRegistry.jmtMigrationAvailable.store(true);

      const isDesktop = !isMobileOrTabletDevice(this.device.getUserAgent().getResult());
      const osName = this.device.getUserAgent().getOS().name;

      /* eslint-disable no-nested-ternary */
      const disableAutoMigration = isDesktop
        ? jmtDisableAutoMigration?.desktop
        : osName === OS.ANDROID
          ? jmtDisableAutoMigration?.android
          : osName === OS.IOS
            ? jmtDisableAutoMigration?.ios
            : false;
      /* eslint-enable no-nested-ternary */

      if (!disableAutoMigration) {
        // need to manually upgrade ephemeral user on server to start migration
        if (this.device.isEphemeral()) {
          await this.device.upgradeEphemeral(
            {
              type: DeephemerizeReasonType.RESTRICTED_ENDPOINT,
              endpoint: '/device/jmtMigration/createJmtMigrationToken',
            },
            true,
          );
        }

        return this.getMigrationUrl(true);
      }
    }

    return undefined;
  }

  getAutoRedirectToJmt(): string | undefined {
    const entity = this.device.getCopyrightEntity();
    const isJmtEntity = entity === 'jmt' || entity === 'ip';
    const query = this.getQuery();

    if (query.jmtDisableAutoRedirect) {
      this.cookiesRegistry.jmtDisableAutoRedirect.store(true);

      return undefined;
    }

    // redirect to relevant entity site
    if (
      !__DEVELOPMENT__ &&
      this.device.scope.hasOppositeEntity &&
      this.device.scope.notJmt &&
      isJmtEntity &&
      !this.device.isAppWebView() &&
      !this.device.isBot() &&
      !this.cookiesRegistry.jmtDisableAutoRedirect.restore() &&
      this.device.getDeviceVar('jmtAutoRedirect')
    ) {
      const origin = getJmtOrigin(this.device.scope, this.req);
      const path = this.getPathname();

      const redirectUrlQuery = {
        ...query,
        // auto migrate of non-ephemeral users when redirect to jmt
        ...(this.device.scope.notJmt && !this.device.isEphemeral() && {jmtAutoMigration: true}),
      } as QueryMap;

      return createUrl(origin + path, redirectUrlQuery);
    }

    return undefined;
  }

  // redirect for SEO from /ru
  getServerRuLocaleRedirect(): {url: string; status: 301 | 302} | undefined {
    if (
      __SERVER__ &&
      this.device.scope.hasOppositeEntity &&
      this.device.scope.notJmt &&
      this.req?.path.match(this.ruPathRegExp)
    ) {
      if (this.device.isBot()) {
        const url = getJmtOrigin(this.device.scope, this.req) + this.req.originalUrl;

        return {url, status: 301};
      }

      const url = createUrl(
        getJoomOrigin(this.device.scope, this.req) +
          this.req.path.replace(this.ruPathRegExp, '$1ru-ua$2'),
        this.req.query as QueryMap,
      );

      return {url, status: 302};
    }

    return undefined;
  }

  async getMigrationOrEntityRedirect(): Promise<
    {url: string; status: 301 | 302; clearSiteCache?: boolean} | undefined
  > {
    const jmtAutoRedirectUrl = this.getAutoRedirectToJmt();
    if (jmtAutoRedirectUrl) {
      // for SEO redirect from ru must be 301
      const status = this.getPathname().match(this.ruPathRegExp) ? 301 : 302;

      return {url: jmtAutoRedirectUrl, status};
    }

    try {
      const jmtAutoMigrationUrl = await this.getJmtAutoMigrationFromRedirect();
      if (jmtAutoMigrationUrl) {
        return {url: jmtAutoMigrationUrl, status: 302, clearSiteCache: true};
      }
    } catch (error) {
      if (isProofOfWorkApiError(unwrapErrorObject(error as ExtendedErrorObject))) {
        throw error;
      }

      this.logger.error({error: ecsError(error)});
    }

    const ruLocaleRedirect = this.getServerRuLocaleRedirect();
    if (ruLocaleRedirect) {
      return ruLocaleRedirect;
    }

    return undefined;
  }

  manualRedirectToOppositeEntity(): void {
    if (__SERVER__ || !this.device.scope.hasOppositeEntity) {
      return;
    }

    const origin = this.device.scope.isJmt
      ? getJoomOrigin(this.device.scope, this.req)
      : getJmtOrigin(this.device.scope, this.req);

    const match = matchRoute(this.device.scope, window.location.pathname);

    // checkout sessions aren't correlate with each other between entities (WEB-8532)
    const path =
      match.route && match.route.name === 'checkoutSession'
        ? getUrl('cart', match.result.params)
        : window.location.pathname;

    const redirectUrl = createUrl(origin + path + window.location.search, {
      ...(this.device.scope.isJmt && {jmtDisableAutoRedirect: true}),
      ...(this.device.scope.notJmt && !this.device.isEphemeral() && {jmtAutoMigration: true}),
    });

    window.location.assign(redirectUrl);
  }

  getUrlWithoutFlags(): string | undefined {
    const {jmtAutoMigration, jmtDisableAutoRedirect, ...restQuery} = this.getQuery();
    if (jmtAutoMigration || jmtDisableAutoRedirect) {
      return createUrl(this.getPathname(), restQuery);
    }

    return undefined;
  }

  init(): void {
    if (
      __CLIENT__ &&
      this.device.scope.hasOppositeEntity &&
      this.device.scope.notJmt &&
      !this.device.isEphemeral() &&
      !this.cookiesRegistry.jmtMigrationAvailable.restore()
    ) {
      this.cookiesRegistry.jmtMigrationAvailable.store(true);

      // set migration available for jmt in background
      superagent
        .post(
          createUrl(`${getJmtOrigin(this.device.scope, this.req)}/setMigrationAvailable`, {
            [PREFIX_SCOPE_GET_PARAM]: this.device.scope.prefixScope,
          }),
        )
        .withCredentials()
        .catch((error) => this.logger.error({error: ecsError(error)}));
    }

    if (__CLIENT__) {
      const urlWithoutFlags = this.getUrlWithoutFlags();
      if (urlWithoutFlags) {
        window.history.replaceState(window.history.state, '', urlWithoutFlags);
      }
    }
  }
}

import {routeAsync} from 'helpers/asyncConnect';

import {anonymous} from './decorators';
import {DefaultParams, PoorRoute} from './types';

const NAME = 'appViewLegal';

export const AppViewLegalRoute: PoorRoute<typeof NAME, DefaultParams> = {
  name: NAME,
  path: '/app-view/legal',
  async: routeAsync(
    /* #__LOADABLE__ */ () => import('containers/pages/AppView/Legal'),
    (module) => module.LegalPage,
  ),
  decorator: anonymous,
};

import {routeAsync} from 'helpers/asyncConnect';

import {anonymous} from './decorators';
import {DefaultParams, PoorRoute} from './types';

const NAME = 'terms';

export const TermsRoute: PoorRoute<typeof NAME, DefaultParams> = {
  name: NAME,
  path: '/terms',
  async: routeAsync(
    /* #__LOADABLE__ */ () => import('containers/pages/TermsPage'),
    (module) => module.TermsPage,
  ),
  decorator: anonymous,
};

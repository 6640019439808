import breakpoints from 'theme/gridBreakpoints.export.scss';
import ImageBundleShape from 'shapes/ImageBundle';
import {VwFitShape} from 'shapes/VwFit';
import PropTypes from 'prop-types';
import React, {Component, useContext} from 'react';
import {srcByMaxSide} from 'utils/image';
import {useBot} from 'hooks/useBot';

import {Helmet} from 'react-helmet-async';
import {useIsAutoDark} from 'hooks/useIsAutoDark';
import {LcpImageSsrStateContext} from 'providers/LcpImageSsrState';
import {convertBackendColorToCSSValue} from 'utils/styles/color';
import {Icon} from 'components/UIKit/Icon';
import {isNumber} from 'utils/guards';
import styles from './index.scss';
import {useNativeLazy} from './useNativeLazy';
import {ImageBundleNameToIconName} from './ImageBundleNameToIconName';

const HTTP_PREFIX = 'http://';

const BREAKPOINTS = ['xl', 'lg', 'md', 'sm', 'xs'].map((name) => ({
  name,
  media: `(min-width: ${breakpoints[name]})`,
}));

class ImageBase extends Component {
  static listeners = [];

  static emit(err, image) {
    ImageBase.listeners.forEach((callback) => callback(err, image));
  }

  static listen(callback) {
    ImageBase.listeners.push(callback);
  }

  static unlisten(callback) {
    const index = ImageBase.listeners.indexOf(callback);
    if (index !== -1) {
      ImageBase.listeners.splice(index, 1);
    }
  }

  static propTypes = {
    alt: PropTypes.string,
    block: PropTypes.bool,
    blurred: PropTypes.bool,
    borderRadius: PropTypes.string,
    bot: PropTypes.bool,
    className: PropTypes.string,
    classNameBroken: PropTypes.string,
    classNameEmpty: PropTypes.string,
    classNameLoading: PropTypes.string,
    contain: PropTypes.bool,
    cover: PropTypes.bool,
    fullSizePreloader: PropTypes.bool,
    height: PropTypes.string,
    httpsOnly: PropTypes.bool,
    image: ImageBundleShape,
    layoutBgInherit: PropTypes.bool,
    loadImmediately: PropTypes.bool,
    loadingHidden: PropTypes.bool,
    maxSourceSize: PropTypes.number,
    multiply: PropTypes.bool,
    nativeLazy: PropTypes.bool,
    objectPosition: PropTypes.string,
    onComplete: PropTypes.func,
    preload: PropTypes.bool,
    pxFit: PropTypes.number,
    setSizesWhenLoaded: PropTypes.bool,
    sizes: PropTypes.string,
    src: PropTypes.string,
    transparentLoading: PropTypes.bool,
    vwFit: VwFitShape,
    width: PropTypes.string,
  };

  static defaultProps = {
    alt: '',
    block: false,
    blurred: false,
    borderRadius: null,
    bot: false,
    className: '',
    classNameBroken: '',
    classNameEmpty: '',
    classNameLoading: '',
    contain: false,
    cover: false,
    fullSizePreloader: false,
    height: null,
    httpsOnly: true,
    image: null,
    layoutBgInherit: false,
    loadImmediately: false,
    loadingHidden: false,
    maxSourceSize: null,
    multiply: false,
    nativeLazy: false,
    objectPosition: null,
    onComplete: null,
    preload: false,
    pxFit: 100,
    setSizesWhenLoaded: false,
    sizes: null,
    src: '',
    transparentLoading: false,
    vwFit: null,
    width: null,
  };

  constructor(props) {
    super(props);

    this.state = {
      broken: false,
      loading: __CLIENT__,
      realHeight: null,
      realWidth: null,
      visible: props.loadImmediately,
      aspectRatio: 'auto',
    };

    this.brokenProtocol = this.isProtocolBorken(props);
    this.image = null;
    this.lazy = null;
    this.mounted = false;
    this.intersectionObserver = null;

    this.handleError = this.handleError.bind(this);
    this.handleLoad = this.handleLoad.bind(this);
  }

  componentDidMount() {
    this.mounted = true;

    if (__CLIENT__) {
      if (this.image && this.image.complete) {
        this.updateStateAfterSuccessLoad(this.image);
      }

      if (this.lazy && !this.props.nativeLazy) {
        try {
          this.intersectionObserver = new IntersectionObserver(this.handleIntersection, {
            threshold: 0.01,
            rootMargin: '50px',
          });
          this.intersectionObserver.observe(this.lazy);
        } catch (ex) {
          this.setState({visible: true});
        }
      }
    } else if (this.intersectionObserver) {
      this.intersectionObserver.disconnect();
      this.intersectionObserver = null;
    }
  }

  componentWillUnmount(nextProps) {
    this.mounted = false;
  }

  getSrcSet() {
    const {image, maxSourceSize} = this.props;
    if (!image || !image.images || image.images.length < 2) {
      return null;
    }

    const images = maxSourceSize
      ? image.images.filter(({width, height}) => maxSourceSize >= width && maxSourceSize >= height)
      : image.images;

    return images.map(({width, url}) => `${url} ${width}w`).join(', ');
  }

  getSizes() {
    const {image} = this.props;
    if (!image || !image.images || image.images.length < 2) {
      return null;
    }

    if (this.props.sizes) {
      return this.props.sizes;
    }

    const {vwFit} = this.props;
    if (!vwFit) {
      return '100vw';
    }

    if (typeof vwFit === 'number') {
      return `${vwFit}vw`;
    }

    return BREAKPOINTS.filter(({name}) => vwFit[name])
      .map(({name, media}, index, arr) => {
        const fit = vwFit[name];
        const value = typeof fit === 'number' ? `${fit}vw` : fit;
        return index === arr.length - 1 ? value : `${media} ${value}`;
      })
      .join(', ');
  }

  handleIntersection = (entries) => {
    if (entries[0].isIntersecting) {
      this.setState({visible: true});
      this.intersectionObserver?.disconnect();
      this.intersectionObserver = null;
    }
  };

  isProtocolBorken(props) {
    const url = props.src || srcByMaxSide(props.image) || '';
    return props.httpsOnly && url.indexOf(HTTP_PREFIX) === 0;
  }

  handleError() {
    if (this.mounted) {
      const {onComplete} = this.props;
      this.setState(
        {
          broken: true,
          loading: false,
        },
        () => {
          if (onComplete) {
            onComplete(true);
          }
          ImageBase.emit(true, this);
        },
      );
    }
  }

  handleLoad(evt) {
    this.updateStateAfterSuccessLoad(evt.target);
  }

  updateStateAfterSuccessLoad(image) {
    if (this.mounted) {
      const {onComplete} = this.props;
      const rawAspectRatio = image.naturalWidth / image.naturalHeight;
      const aspectRatio =
        isNumber(rawAspectRatio) && Number.isFinite(rawAspectRatio) ? rawAspectRatio : 'auto';

      this.setState(
        {
          realHeight: image.height,
          realWidth: image.width,
          loading: false,
          aspectRatio,
        },
        () => {
          if (onComplete) {
            onComplete(false);
          }
          ImageBase.emit(false, this);
        },
      );
    }
  }

  render() {
    const {
      alt,
      block,
      blurred,
      borderRadius,
      className,
      classNameBroken,
      classNameEmpty,
      classNameLoading,
      contain,
      cover,
      // eslint-disable-next-line no-unused-vars
      httpsOnly,
      image,
      loadingHidden,
      // eslint-disable-next-line no-unused-vars
      onComplete,
      // eslint-disable-next-line no-unused-vars
      maxSourceSize,
      // eslint-disable-next-line no-unused-vars
      sizes,
      transparentLoading,
      multiply,
      objectPosition,
      pxFit,
      // eslint-disable-next-line no-unused-vars
      vwFit,
      setSizesWhenLoaded,
      src,
      bot,
      loadImmediately,
      preload,
      nativeLazy,
      // eslint-disable-next-line no-unused-vars
      lcpImageCandidate,
      layoutBgInherit,
      fullSizePreloader,
      ...props
    } = this.props;

    const showIconInsteadOfImage = Boolean(ImageBundleNameToIconName[image?.name]);
    let {height, width} = this.props;

    if (setSizesWhenLoaded) {
      height = `${height || this.state.realHeight}px`;
      width = `${width || this.state.realWidth}px`;
    }

    const classNames = [
      styles.image,
      transparentLoading ? styles.transparentLoading : '',
      className,
      layoutBgInherit ? styles.layoutBgInherit : '',
    ];
    const style = {...this.props.style};

    if (borderRadius) {
      style.borderRadius = borderRadius;
    }

    if (objectPosition && !this.state.loading) {
      style.objectPosition = objectPosition;
      style.backgroundPosition = objectPosition;
    }

    if (block) {
      classNames.push(styles.block);
    }

    if (blurred) {
      classNames.push(styles.blurred);
    }

    if (!image && !src) {
      classNames.push(classNameEmpty || styles.empty);
      if (height && width) {
        style.height = height;
        style.width = width;
      }
      return <span className={classNames.join(' ')} style={style} />;
    }

    if (this.state.broken || this.brokenProtocol) {
      classNames.push(classNameBroken || styles.broken);
      if (height && width) {
        style.height = height;
        style.width = width;
      }
      return <span className={classNames.join(' ')} style={style} />;
    }
    if (!showIconInsteadOfImage && !loadingHidden && this.state.loading) {
      classNames.push(classNameLoading || styles.loading);
    }
    if (!showIconInsteadOfImage && fullSizePreloader && this.state.loading) {
      classNames.push(styles.fullSizePreloader);
    }
    if (cover) {
      classNames.push(styles.cover);
    }
    if (contain) {
      classNames.push(styles.contain);
    }
    if (multiply && image?.showOverlay) {
      classNames.push(styles.multiply);
    }

    if (!this.state.visible && height && width) {
      style.height = height;
      style.width = width;
    }

    if (showIconInsteadOfImage && ImageBundleNameToIconName[image.name]) {
      const [name, defaultColor] = ImageBundleNameToIconName[image.name];
      const color = image.tint ? convertBackendColorToCSSValue(image.tint) : defaultColor;

      return (
        <span className={classNames.join(' ')} style={style}>
          <Icon type="mono" color={color} name={name} />
        </span>
      );
    }

    const imageSrc = src || srcByMaxSide(image, pxFit);
    const imageAlt = image?.accessibilityLabel || alt;
    const loadingAttr = loadImmediately ? 'eager' : 'lazy';
    const hasTint = Boolean(image?.tint);

    let imageClassName = `${classNames.join(' ')} ${loadingHidden ? '' : styles.lazy}`;
    let imageElement = (
      <img
        {...props}
        alt={imageAlt}
        suppressHydrationWarning
        ref={(element) => {
          this.lazy = element;
        }}
        className={hasTint ? undefined : imageClassName}
        style={style}
        onError={this.handleError}
        onLoad={this.handleLoad}
      />
    );

    if (bot || this.state.visible || nativeLazy) {
      const imageSrcSet = bot ? null : this.getSrcSet();
      const imageSizes = bot ? null : this.getSizes();

      imageClassName = classNames.join(' ');
      imageElement = (
        <>
          <img
            suppressHydrationWarning
            ref={(element) => {
              this.image = element;
            }}
            {...props}
            key="image"
            loading={nativeLazy ? loadingAttr : undefined}
            alt={imageAlt}
            className={hasTint ? undefined : imageClassName}
            onError={this.handleError}
            onLoad={this.handleLoad}
            src={imageSrc}
            srcSet={imageSrcSet}
            sizes={imageSizes}
            style={style}
          />
          {__SERVER__ && !bot && preload && loadImmediately ? (
            <Helmet>
              <link
                rel="preload"
                as="image"
                // eslint-disable-next-line react/no-unknown-property
                imagesrcset={imageSrcSet || imageSrc}
                // eslint-disable-next-line react/no-unknown-property
                imagesizes={imageSizes}
                // eslint-disable-next-line react/no-unknown-property
                fetchpriority="high"
              />
            </Helmet>
          ) : null}
        </>
      );
    }

    if (image?.tint) {
      // NB! By using an image from src we will get blurry results for
      //     devices with pixel ratio more than 1. In this case
      //     we use weak logic that all images in ImageBundle have
      //     asc sorting their width. So we peek second for a slightly
      //     better result with no significant effort.
      const mask = image.images.length > 2 ? image.images[1].url : imageSrc;
      return (
        <span
          className={`${imageClassName} ${styles.tintWrapper}`}
          style={{
            '--tint-color': convertBackendColorToCSSValue(image.tint),
            '--tint-mask': `url("${mask}")`,
            '--tint-ratio': this.state.aspectRatio,
            width: this.props.width,
            height: this.props.height,
          }}
        >
          {imageElement}
        </span>
      );
    }

    return imageElement;
  }
}

export const Image = (props) => {
  const bot = useBot();
  const nativeLazy = useNativeLazy();
  const isAutoDark = useIsAutoDark();

  const lcpImageSsrState = useContext(LcpImageSsrStateContext);
  let overrideProps;
  if (__SERVER__ && props.lcpImageCandidate && lcpImageSsrState?.available) {
    lcpImageSsrState.available = false;
    overrideProps = {
      loadImmediately: true,
      preload: true,
    };
  }

  return (
    <ImageBase
      {...props}
      {...overrideProps}
      bot={bot}
      multiply={props.multiply && !isAutoDark}
      nativeLazy={nativeLazy}
    />
  );
};

Image.listeners = ImageBase.listeners;
Image.emit = ImageBase.emit;
Image.listen = ImageBase.listen;
Image.unlisten = ImageBase.unlisten;
Image.propTypes = ImageBase.propTypes;

import {Request} from 'express';
import {EXP_APP_HEADER, ScopeConfig} from 'helpers/ApiClient/Scope/ScopeConfig';
import {ProofOfWorkSolution} from 'helpers/ApiClient/Transport/ProofOfWork/types';
import {pick} from 'lodash';
import {RenderingConfiguration} from 'types/Rendering';
import {TransportHeaders} from 'types/Transport';
import {AUTHORIZATION_HEADER, createAuthorizationHeader} from 'utils/authorizationHeader';

const RENDERING_ID_HEADER = 'x-rendering-id';
const CLIENT_REQUEST_ID_HEADER = 'x-client-request-id';
const RETRY_NUMBER_HEADER = 'x-retry-number';
const PROOF_OF_WORK_SOLUTION_HEADER = 'joom-proof-of-work-solution';
const REQUEST_PATH_HEADER = 'x-request-path';
const USER_AGENT_HEADER = 'user-agent';
const FORDWARDED_FOR_HEADER = 'x-forwarded-for';
const VERSION_HEADER = 'x-version';
const OS_TYPE_HEADER = 'x-ostype';
const RENDERING_MODE_HEADER = 'joom-rendering-mode';
const REQUEST_PROXY_HEADERS = [
  USER_AGENT_HEADER,
  FORDWARDED_FOR_HEADER,
  RENDERING_ID_HEADER,
  PROOF_OF_WORK_SOLUTION_HEADER,
  RENDERING_MODE_HEADER,
  EXP_APP_HEADER,
];

// this headers are added to logs
export function pickAppHeaders(headers: TransportHeaders): TransportHeaders {
  return pick(headers, [
    RENDERING_ID_HEADER,
    CLIENT_REQUEST_ID_HEADER,
    RETRY_NUMBER_HEADER,
    REQUEST_PATH_HEADER,
    USER_AGENT_HEADER,
    FORDWARDED_FOR_HEADER,
    VERSION_HEADER,
    OS_TYPE_HEADER,
    RENDERING_MODE_HEADER,
    PROOF_OF_WORK_SOLUTION_HEADER,
  ]);
}

export function addVersionHeader(headers: TransportHeaders, version: string): TransportHeaders {
  headers[VERSION_HEADER] = version;
  return headers;
}

export function addOsTypeHeader(headers: TransportHeaders, osType?: string): TransportHeaders {
  if (osType) {
    headers[OS_TYPE_HEADER] = osType;
  }
  return headers;
}

export function addAuthorizationHeader(
  headers: TransportHeaders,
  accessToken: string,
): TransportHeaders {
  headers[AUTHORIZATION_HEADER] = createAuthorizationHeader(accessToken);
  return headers;
}

export function addRetryNumberHeader(
  headers: TransportHeaders,
  retryNumber: number,
): TransportHeaders {
  if (retryNumber) {
    headers[RETRY_NUMBER_HEADER] = retryNumber;
  }
  return headers;
}

export function addProofOfWorkSolutionHeader(
  headers: TransportHeaders,
  powSolution: ProofOfWorkSolution | undefined,
): TransportHeaders {
  if (!headers[PROOF_OF_WORK_SOLUTION_HEADER] && powSolution) {
    headers[PROOF_OF_WORK_SOLUTION_HEADER] = btoa(JSON.stringify(powSolution));
  }
  return headers;
}

export function addRenderingIdHeader(
  headers: TransportHeaders,
  renderingConfig?: RenderingConfiguration | null,
): TransportHeaders {
  if (!headers[RENDERING_ID_HEADER] && renderingConfig && renderingConfig.id) {
    headers[RENDERING_ID_HEADER] = renderingConfig.id;
  }
  return headers;
}

export function addExpAppHeader(headers: TransportHeaders, scope: ScopeConfig): TransportHeaders {
  if (!headers[EXP_APP_HEADER] && scope.scopeHeader) {
    headers[EXP_APP_HEADER] = scope.scopeHeader;
  }
  return headers;
}

export function addClientRequestIdHeader(
  headers: TransportHeaders,
  clientRequestId: string | undefined,
): TransportHeaders {
  if (!headers[CLIENT_REQUEST_ID_HEADER] && clientRequestId) {
    headers[CLIENT_REQUEST_ID_HEADER] = clientRequestId;
  }
  return headers;
}

export function getReqRenderingIdHeader(headers: TransportHeaders): string | undefined {
  const id = headers[RENDERING_ID_HEADER.toLowerCase()];
  return id ? String(id) : undefined;
}

const MAX_PATH_LENGTH = 256;

export function addRequestPathHeader(
  headers: TransportHeaders,
  req: Request | undefined,
): TransportHeaders {
  const path = __SERVER__ ? req?.path : window.location.pathname;
  if (!headers[REQUEST_PATH_HEADER] && path) {
    headers[REQUEST_PATH_HEADER] = path.slice(0, MAX_PATH_LENGTH);
  }
  return headers;
}

export function getUserAgentHeader(headers: TransportHeaders): string | undefined {
  return headers[USER_AGENT_HEADER] as string;
}

export function getRenderingModeHeader(req: Request): string | undefined {
  return req.get(RENDERING_MODE_HEADER);
}

export function addHeadersFromRequest(headers: TransportHeaders, req?: Request): TransportHeaders {
  if (__SERVER__ && req) {
    REQUEST_PROXY_HEADERS.forEach((name) => {
      const value = req.get(name);
      if (value) {
        headers[name] = value;
      }
    });

    const forwardedFor = [req.get(FORDWARDED_FOR_HEADER), req.socket.remoteAddress]
      .filter(Boolean)
      .join(', ');
    if (forwardedFor) {
      headers[FORDWARDED_FOR_HEADER] = forwardedFor;
    }
  }
  return headers;
}

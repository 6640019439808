import {Scope} from 'config';
import {routeAsync} from 'helpers/asyncConnect';

import {anonymous} from './decorators';
import {PoorRoute} from './types';

const NAME = 'favoriteCollections';

export const FavoriteCollectionsRoute: PoorRoute<typeof NAME> = {
  name: NAME,
  scope: [Scope.GLOBAL],
  path: '/favorites/collections',
  async: routeAsync(
    /* #__LOADABLE__ */ () => import('containers/pages/FavoriteCollectionsPage'),
    (module) => module.FavoriteCollectionsPage,
  ),
  decorator: anonymous,
  strict: true,
};

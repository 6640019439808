import {ScopeConfig} from 'helpers/ApiClient/Scope/ScopeConfig';
import {getPrefixScopeConfigByUrl} from 'helpers/ApiClient/Scope/utils';
import {History} from 'history';
import {getHistoryBlock} from 'hooks/useHistoryBlock';

export function protectHistoryScope(scope: ScopeConfig, history: History): void {
  if (__SERVER__) {
    return;
  }
  const historyBlock = getHistoryBlock(history);

  historyBlock((location) => {
    const nextPrefixScopeConfig = getPrefixScopeConfigByUrl(location.pathname, scope.domainConfig);

    if (nextPrefixScopeConfig?.scope !== scope.prefixScope) {
      const url = history.createHref(location);

      window.location.assign(url);

      return false;
    }

    return true;
  });
}

import {identity} from 'utils/function';

function get(obj, keys, index = 0) {
  if (obj) {
    const key = keys[index];
    if (index < keys.length - 1) {
      if (obj[key]) {
        return get(obj[key], keys, index + 1);
      }
      return undefined;
    }

    if (key in obj) {
      return obj[key];
    }
  }

  return undefined;
}

function set(obj, keys, value) {
  if (obj) {
    const result = {...obj};
    keys.reduce((next, key, index) => {
      if (index === keys.length - 1) {
        next[key] = value;
      } else {
        next[key] = next[key] ? {...next[key]} : {};
      }
      return next[key];
    }, result);
    return result;
  }

  return obj;
}

export function getValueByPath(obj, ...keys) {
  return get(obj, keys);
}

export function assignRemove(obj, id) {
  const result = {...obj};
  delete result[id];
  return result;
}

export function assignSetByPath(obj, path, value) {
  return set(obj, path, value);
}

export function assignSet(obj, id, value) {
  return assignSetByPath(obj, [id], value);
}

export function objectMap(obj, handler, context) {
  return Object.keys(obj || {}).reduce((next, name) => {
    next[name] = handler.call(context, obj[name], name);
    return next;
  }, {});
}

export function objectFilter(obj, handler, context) {
  return Object.keys(obj || {}).reduce((next, name) => {
    if (handler.call(context, obj[name], name)) {
      next[name] = obj[name];
    }
    return next;
  }, {});
}

export function objectValues(obj) {
  return Object.keys(obj || {}).map((key) => obj[key]);
}

export function isObjectNotEmpty(obj) {
  return Object.keys(obj || {}).length > 0;
}

export function invertObject(obj, keyHandler = identity) {
  return Object.keys(obj).reduce((next, key) => {
    next[keyHandler(obj[key])] = key;
    return next;
  }, {});
}

export function isObjectEmpty(obj) {
  // eslint-disable-next-line no-restricted-syntax, guard-for-in
  for (const key in obj) {
    return false;
  }
  return true;
}

export function getAnyObjectValue(obj) {
  // eslint-disable-next-line no-restricted-syntax, guard-for-in
  for (const key in obj) {
    return obj[key];
  }
  return null;
}

import {VERSION} from '../../version';
import {AppLanguageCode, AppLanguageLocale, LanguageConfig, languagesConfig} from './languages';
import {DomainConfigId} from './scope/DomainConfigId';
import {
  domainScopeCoolbe,
  domainScopeCoolbeAt,
  domainScopeCoolbeCh,
  domainScopeCoolbeCz,
  domainScopeCoolbeDe,
  domainScopeCoolbeEs,
  domainScopeCoolbeFi,
  domainScopeCoolbeFr,
  domainScopeCoolbeGr,
  domainScopeCoolbeHu,
  domainScopeCoolbeIe,
  domainScopeCoolbeIl,
  domainScopeCoolbeIt,
  domainScopeCoolbeNl,
  domainScopeCoolbePl,
  domainScopeCoolbePt,
  domainScopeCoolbeRo,
  domainScopeCoolbeSe,
  domainScopeCoolbeSk,
  domainScopeCoolbeTr,
  domainScopeCoolbeUk,
} from './scope/domainScopeCoolbe';
import {domainScopeJmt} from './scope/domainScopeJmt';
import {domainScopeJoom} from './scope/domainScopeJoom';
import {LegalEntity, Scope} from './scope/enums';
import {DomainScopeConfig, PrefixScopeConfig, ScopeCompanyInfo} from './scope/types';
import {supportedDevicevars} from './supportedDevicevars';

export {Scope, LegalEntity};
export type {
  LanguageConfig,
  AppLanguageCode,
  AppLanguageLocale,
  DomainConfigId,
  DomainScopeConfig,
  ScopeCompanyInfo,
  PrefixScopeConfig,
};

/* eslint-disable import/no-default-export, no-underscore-dangle */
const env = process.env.NODE_ENV || 'development';
const commit = parseInt((process.env.GIT_COMMIT || '123456').substr(0, 6), 16);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const anyGlobal = global as any;
const releaseStage = (process.env.APP_ENV || anyGlobal.__RELEASE_STAGE__) as
  | 'prod'
  | 'stage'
  | 'dev'
  | 'local';

const SENTRY_CLIENT_KEY = 'b68f31beac04417da5e79086aa76f8d6';

const config = {
  releaseStage,
  paymentFormPrefixBlue:
    process.env.PAYMENT_FORM_PREFIX_BLUE || anyGlobal.__PAYMENT_FORM_PREFIX_BLUE__,
  paymentFormPrefixGreen:
    process.env.PAYMENT_FORM_PREFIX_GREEN || anyGlobal.__PAYMENT_FORM_PREFIX_GREEN__,
  commit,
  env,
  sentry: {
    key: SENTRY_CLIENT_KEY,
    dsn: `https://${SENTRY_CLIENT_KEY}@sentry.joom.it/3`,
    release: `web-client@${VERSION}`,
    environment: releaseStage,
    autoSessionTracking: true,
    tracePropagationTargets: [/^\/tokens\/hydrate/],
  },
  scopes: {
    joom: domainScopeJoom,
    jmt: domainScopeJmt,
    coolbe: domainScopeCoolbe,
    'coolbe-de': domainScopeCoolbeDe,
    'coolbe-fr': domainScopeCoolbeFr,
    'coolbe-uk': domainScopeCoolbeUk,
    'coolbe-se': domainScopeCoolbeSe,
    'coolbe-at': domainScopeCoolbeAt,
    'coolbe-pl': domainScopeCoolbePl,
    'coolbe-pt': domainScopeCoolbePt,
    'coolbe-nl': domainScopeCoolbeNl,
    'coolbe-it': domainScopeCoolbeIt,
    'coolbe-il': domainScopeCoolbeIl,
    'coolbe-ch': domainScopeCoolbeCh,
    'coolbe-cz': domainScopeCoolbeCz,
    'coolbe-sk': domainScopeCoolbeSk,
    'coolbe-hu': domainScopeCoolbeHu,
    'coolbe-ro': domainScopeCoolbeRo,
    'coolbe-fi': domainScopeCoolbeFi,
    'coolbe-gr': domainScopeCoolbeGr,
    'coolbe-ie': domainScopeCoolbeIe,
    'coolbe-es': domainScopeCoolbeEs,
    'coolbe-tr': domainScopeCoolbeTr,
  } satisfies Record<DomainConfigId, DomainScopeConfig>,
  languages: languagesConfig as ReadonlyArray<LanguageConfig>,
  productsPageSize: 36,
  supportedDevicevars,
  version: VERSION,
};

export default config;

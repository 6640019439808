import {AuthMigrationMethodCookie} from 'helpers/ApiClient/Cookies/AuthMigrate';
import {CoolbeHasOrders} from 'helpers/ApiClient/Cookies/CoolbeHasOrders';
import {DevDomainScope} from 'helpers/ApiClient/Cookies/DevDomainScope';
import {EndpointsPresetCookie} from 'helpers/ApiClient/Cookies/EndpointsPreset';
import {ForcedRenderingType} from 'helpers/ApiClient/Cookies/ForcedRenderingType';
import {ForcedRtlCookie} from 'helpers/ApiClient/Cookies/ForcedRtl';
import {JmtDisableAutoRedirect} from 'helpers/ApiClient/Cookies/JmtDisableAutoRedirect';
import {JmtMigrationAvailable} from 'helpers/ApiClient/Cookies/JmtMigrationAvailable';
import {JmtMigrationCloseCookie} from 'helpers/ApiClient/Cookies/JmtMigrationClose';
import {JmtMigrationCloseCountCookie} from 'helpers/ApiClient/Cookies/JmtMigrationCloseCount';
import {RenderingIdCookie} from 'helpers/ApiClient/Cookies/RenderingId';
import {TimezoneCookie} from 'helpers/ApiClient/Cookies/Timezone';
import {TimezoneNameCookie} from 'helpers/ApiClient/Cookies/TimezoneName';

import {A11ySettingsCookie} from '../Cookies/A11ySettings';
import {AccessTokenCookie} from '../Cookies/AccessToken';
import {AccessTokenHashCookie} from '../Cookies/AccessTokenHash';
import {AnalyticsPurchaseCookie} from '../Cookies/AnalyticsPurchase';
import {AppButtonHiddenCookie} from '../Cookies/AppButtonHidden';
import {AuthPrefixScopeCookie} from '../Cookies/AuthPrefixScope';
import {AuthTokenCookie} from '../Cookies/AuthToken';
import {BuildVersionCookie} from '../Cookies/BuildVersion';
import {CategoryPromoLinkOnboarding} from '../Cookies/CategoryPromoLinkOnboarding';
import {AppOrderPushesCloseCookie} from '../Cookies/close/AppOrderPushes';
import {EmailPromoCloseCookie} from '../Cookies/close/EmailPromo';
import {JoomMobileAppBannerCloseCookie} from '../Cookies/close/JoomMobileAppBanner';
import {ForcedRegionCookie} from '../Cookies/ForcedRegion';
import {LanguageCookie} from '../Cookies/Language';
import {LegalConsentCookie} from '../Cookies/LegalConsent';
import {RefreshTokenCookie} from '../Cookies/RefreshToken';
import {SessionIdCookie} from '../Cookies/SessionId';
import {TrackingTokenCookie} from '../Cookies/TrackingToken';
import {UserHashCookie} from '../Cookies/UserHash';
import {WebViewCookie} from '../Cookies/WebView';
import {CookieValue} from './CookieValue';

const closeCookiesNames = {
  emailPromo: 'closeEmailPromo',
  appOrderPushes: 'closeAppOrderPushes',
  joomMobileAppBanner: 'joomMobileAppBanner',
} as const;

const cookieValues = {
  a11ySettings: A11ySettingsCookie,
  accessToken: AccessTokenCookie,
  accessTokenHash: AccessTokenHashCookie,
  analyticsPurchase: AnalyticsPurchaseCookie,
  authToken: AuthTokenCookie,
  authMigrationMethod: AuthMigrationMethodCookie,
  buildVersion: BuildVersionCookie,
  categoryPromoLinkOnboarding: CategoryPromoLinkOnboarding,
  forcedRegion: ForcedRegionCookie,
  forcedRtl: ForcedRtlCookie,
  forcedRenderingType: ForcedRenderingType,
  endpointsPreset: EndpointsPresetCookie,
  language: LanguageCookie,
  legalConsent: LegalConsentCookie,
  refreshToken: RefreshTokenCookie,
  sessionId: SessionIdCookie,
  renderingId: RenderingIdCookie,
  trackingToken: TrackingTokenCookie,
  userHash: UserHashCookie,
  webView: WebViewCookie,
  timezone: TimezoneCookie,
  timezoneName: TimezoneNameCookie,
  appButtonHidden: AppButtonHiddenCookie,
  jmtMigrationClose: JmtMigrationCloseCookie,
  authPrefixScope: AuthPrefixScopeCookie,
  devDomainScope: DevDomainScope,
  jmtMigrationCloseCount: JmtMigrationCloseCountCookie,
  jmtDisableAutoRedirect: JmtDisableAutoRedirect,
  jmtMigrationAvailable: JmtMigrationAvailable,
  coolbeHasOrders: CoolbeHasOrders,
  [closeCookiesNames.emailPromo]: EmailPromoCloseCookie,
  [closeCookiesNames.appOrderPushes]: AppOrderPushesCloseCookie,
  [closeCookiesNames.joomMobileAppBanner]: JoomMobileAppBannerCloseCookie,
};

type CookieValues = typeof cookieValues;

type CookieNames = keyof CookieValues;

type ExtractCookieValueType<T> = T extends CookieValue<infer R> ? R : never;

function getCookie<N extends keyof CookieValues>(name: N): CookieValues[N] {
  return cookieValues[name];
}

type ValueOf<T> = T[keyof T];

type CloseCookiesValue = ValueOf<typeof closeCookiesNames>;

export {
  closeCookiesNames,
  cookieValues,
  CookieValues,
  CookieNames,
  ExtractCookieValueType,
  getCookie,
  CloseCookiesValue,
};

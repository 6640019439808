import {TypedObject} from 'utils/object/typed';

import {Cookies} from '../Cookies';
import {CookieManager} from '../Cookies/CookieManager';
import {CookieNames, cookieValues} from './cookieValues';
import {createCookie} from './createCookie';

type CookiesRegistry = {
  [K in CookieNames]: CookieManager<K>;
};

const createCookieRegistry = (cookies: Cookies): CookiesRegistry => {
  const result = {} as CookiesRegistry;
  const keys = TypedObject.keys(cookieValues);
  keys.forEach((key) => {
    // ts can't handle this properly
    result[key] = createCookie(key, cookies, cookieValues[key].managerOptions) as never;
  });

  return result;
};

export {CookiesRegistry, createCookieRegistry};

import {CouponCard} from 'types/CouponCard';

import {ContentListBaseItem, contentListBaseItem} from './ContentListBaseItem';

export type ContentListCoupon = ContentListBaseItem<{coupon: CouponCard}>;

export function contentListCoupon(coupon: CouponCard): ContentListCoupon {
  return contentListBaseItem(coupon.id, {coupon});
}

export function isContentListCoupon(item: ContentListBaseItem): item is ContentListCoupon {
  return Boolean((item as ContentListCoupon).content.coupon);
}

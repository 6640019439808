import {stringDecoder, stringEncoder} from 'helpers/ApiClient/Cookies/helpers';
import {CookieValue} from 'helpers/ApiClient/Device/CookieValue';
import {CookieType} from 'types/CookiesSettings';

export const COOKIE_NAME = 'renderingId';
const MAX_AGE = 60 * 10;

export const RenderingIdCookie: CookieValue<string> = {
  type: CookieType.FUNCTIONAL,
  key: COOKIE_NAME,
  decode: stringDecoder,
  encode: stringEncoder,
  getOptions: () => ({
    maxAge: MAX_AGE,
  }),
};

/**
 * @fileoverview
 * `Session` class manages 2 types of sessions:
 * 1. activity session,
 * 2. analytics session.
 *
 * Both of them are pretty similar, but analytics session has some
 * restrictions and requirements.
 *
 * ## Activity session
 *
 * Activity session commonly needs for security and functional purposes.
 * We SHOULD NOT use activity session for analytics or marketing.
 *
 * Activity session was based on `utils/Idle` with some crosstab /
 * crosswindow logic. Any user activity (mousemove, scrolling, clicking)
 * MUST prolongate activity session. For sharing activity session across
 * browser tabs we are using cookie `session_id` with small expiration time.
 *
 * ## Analytics session
 *
 * Analytics session is about sending `sessionConfigured` event. We MUST
 * send `sessionConfigured in theese cases:
 * 1. Application start.
 * 2. Device configure. Doesn't matter was config changed or not.
 * 3. Every `sessionParams.pingIntervalMs` ms while user is active.
 *
 * Also, we MUST avoid any client storages (cookies, local storage, etc.).
 * For using storages we HAVE TO ask user about it. For splitting analytics
 * sessions we use backend logic, which described in the task BACKEND-9020.
 */

import EventEmitter from 'component-emitter';
import {guid} from 'utils/guid';
import {Idle, State} from 'utils/Idle';
import throttle from 'utils/throttle';

import {Analytics} from './Analytics';
import type {Device} from './Device';
import type {CookiesRegistry} from './Device/cookiesRegistry';

const PROLONGATE_THROTTLE_TIME = 1000 * 10;
export class Session extends EventEmitter<{
  /**
   * New activity session was started.
   * NB! If the user has recently had activity in another tab, the
   * event will not be dispatched on application start.
   */
  start: [];
}> {
  private idle = new Idle();

  /**
   * Timer for sending `ping` event to analytics. Timer exists
   * only while user is active.
   */
  private aliveTimer: undefined | ReturnType<typeof setTimeout>;

  constructor(
    private device: Device,
    private analytics: Analytics,
    private cookiesRegistry: CookiesRegistry,
  ) {
    super();
  }

  private logger = this.device.log.getLogger('ApiClient/Session');

  /**
   * Short term cookie for activity session.
   * NEVER USE IT FOR ANALYTICS OR MARKETING PURPOSES.
   */
  private cookie = this.cookiesRegistry.sessionId;

  /**
   * Prolongate activity session if the user has active session.
   */
  private prolongateCurrentSession = (): boolean => {
    const id = this.getId();
    if (id) {
      // store same value to update expiration date
      this.cookie.store(id);
      this.logger.log('session was prolongated');
      return true;
    }
    return false;
  };

  /**
   * Return activity session id (GUID) for active session or empty
   * string if session is not present.
   */
  getId(): string {
    return this.cookie.restore();
  }

  /**
   * Send `sessionConfigured` after `device/configure`.
   */
  private handleConfigChange = (): void => {
    this.logger.log('device config was changed');
    this.analytics.sendEvent({type: 'sessionConfigured'});
    this.createTimerForAliveEvent();
  };

  /**
   * Handle user activity status changing.
   * For ACTIVE we start activity session and schedule alive event.
   * For IDLE we destroy alive timer.
   */
  private handleIdleChangle = (state: State): void => {
    this.logger.log(`change activity state: ${state}`);
    if (state === State.ACTIVE) {
      this.sendStart();
      this.createTimerForAliveEvent();
    } else {
      this.destroyAliveTimer();
    }
  };

  /**
   * Create new activity session and emits `start` event. If session
   * already exists just prolong the session without any event dispatching.
   * For ACTIVE we start activity session and schedule alive event.
   * For IDLE we destroy alive timer.
   */
  private sendStart(): void {
    if (!this.prolongateCurrentSession()) {
      this.cookie.store(guid());
      this.emit('start');
      this.logger.log('session was started');
    }
  }

  /**
   * Stop sending alive event.
   */
  private destroyAliveTimer = (): void => {
    if (this.aliveTimer) {
      clearTimeout(this.aliveTimer);
      this.aliveTimer = undefined;
      this.logger.log('alive timer was destroyed');
    }
  };

  /**
   * Alive timer handler. Sends `sessionConfigured` if had recent user activity.
   */
  private handleAlive = (): void => {
    this.aliveTimer = undefined;

    if (this.idle.state === State.ACTIVE) {
      this.analytics.sendEvent({type: 'sessionConfigured'});
      this.createTimerForAliveEvent();
      this.logger.log('alive');
    }
  };

  /**
   * Create alive timer if needed. This is only 2 requirements:
   * 1. User SHOULD be active on the page recently.
   * 2. Devicevar `sessionParams` SHOULD has `pingIntervalMs` property.
   */
  private createTimerForAliveEvent = (): void => {
    this.destroyAliveTimer();

    const sessionParams = this.device.getDeviceVar('sessionParams');
    if (this.idle.state === State.ACTIVE && sessionParams?.pingIntervalMs) {
      this.aliveTimer = setTimeout(this.handleAlive, sessionParams.pingIntervalMs);
      this.logger.log(`alive timer was scheduled after ${sessionParams.pingIntervalMs}ms`);
    }
  };

  /**
   * Sends `sessionConfigured` analytics event and creates subscriptions.
   */
  async init(): Promise<void> {
    this.logger.log('init');

    if (__CLIENT__) {
      this.sendStart();
      this.idle.on('change', this.handleIdleChangle);
      this.idle.on('activity', throttle(this.prolongateCurrentSession, PROLONGATE_THROTTLE_TIME));
      this.device.on('configChange', this.handleConfigChange);

      const sendEventPromise = this.analytics.sendEvent(
        {type: 'sessionConfigured'},
        {immediately: true},
      );
      if (!this.device.getDeviceVar('nonBlockingSessionConfiguredEnabled')) {
        await sendEventPromise;
      }
    }
  }
}

import {routeAsync} from 'helpers/asyncConnect';

import {anonymous} from './decorators';
import {DefaultParams, PoorRoute} from './types';

const NAME = 'appViewPrivacy';

export const AppViewPrivacyRoute: PoorRoute<typeof NAME, DefaultParams> = {
  name: NAME,
  path: '/app-view/legal/privacy',
  async: routeAsync(
    /* #__LOADABLE__ */ () => import('containers/pages/AppView/Privacy'),
    (module) => module.PrivacyPage,
  ),
  decorator: anonymous,
};

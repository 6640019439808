import {discardableByUser} from 'helpers/discardable';
import {assignRemove, assignSetByPath, assignSet, getValueByPath} from 'utils/object';
import {DeliveryConfirmationModeType} from 'shapes/ParcelDeliveryConfirmationMode';
import {ReturnStatusType} from 'shapes/ReturnInfo';
import {enhanceParcel} from 'store/enhancer/parcel';
import {signout} from 'store/modules/auth';
import {
  getDeliveredTimeMs as getDeliveredTimeMsFromParcelLite,
  getShippingEstimate as getShippingEstimateFromParcelLite,
  getStatusAppearanceTitle as getStatusAppearanceTitleFromParcelLite,
  getStatusAppearanceText as getStatusAppearanceTextFromParcelLite,
  discardInactiveFilters,
  discardParcels,
  getStatusAppearance as getStatusAppearanceFromParcelLite,
  isParcelsLoaded,
} from './parcels';

const CANCEL = 'parcel/CANCEL';
export const CANCEL_SUCCESS = 'parcel/CANCEL_SUCCESS';
const CANCEL_FAIL = 'parcel/CANCEL_FAIL';

const LOAD = 'parcel/LOAD';
const LOAD_SUCCESS = 'parcel/LOAD_SUCCESS';
const LOAD_FAIL = 'parcel/LOAD_FAIL';

const DELIVERED = 'parcel/DELIVERED';
export const DELIVERED_SUCCESS = 'parcel/DELIVERED_SUCCESS';
const DELIVERED_FAIL = 'parcel/DELIVERED_FAIL';

const NOT_DELIVERED = 'parcel/NOT_DELIVERED';
export const NOT_DELIVERED_SUCCESS = 'parcel/NOT_DELIVERED_SUCCESS';
const NOT_DELIVERED_FAIL = 'parcel/NOT_DELIVERED_FAIL';

const UPDATE_ADDRESS = 'parcel/UPDATE_ADDRESS';
const UPDATE_ADDRESS_SUCCESS = 'parcel/UPDATE_ADDRESS_SUCCESS';
const UPDATE_ADDRESS_FAIL = 'parcel/UPDATE_ADDRESS_FAIL';

const REMOVE_ORDER_REVIEW = 'parcel/REMOVE_ORDER_REVIEW';
const REMOVE_ORDER_REVIEW_SUCCESS = 'parcel/REMOVE_ORDER_REVIEW_SUCCESS';
const REMOVE_ORDER_REVIEW_FAIL = 'parcel/REMOVE_ORDER_REVIEW_FAIL';
const SAVE_ORDER_REVIEW = 'parcel/SAVE_ORDER_REVIEW';
const SAVE_ORDER_REVIEW_SUCCESS = 'parcel/SAVE_ORDER_REVIEW_SUCCESS';
const SAVE_ORDER_REVIEW_FAIL = 'parcel/SAVE_ORDER_REVIEW_FAIL';

const LOAD_POINT_INFO = 'parcel/LOAD_POINT_INFO';
const LOAD_POINT_INFO_SUCCESS = 'parcel/LOAD_POINT_INFO_SUCCESS';
const LOAD_POINT_INFO_FAIL = 'parcel/LOAD_POINT_INFO_FAIL';

const initialState = {
  addressUpdateError: {},
  addressUpdating: {},
  cancelError: {},
  cancelling: {},
  deliveredError: {},
  deliveredLoading: {},
  dict: {},
  error: {},
  loaded: {},
  loading: {},
  meta: {},
  notDeliveredError: {},
  notDeliveredLoading: {},
  pointInfoLoading: {},
  pointInfoLoaded: {},
  pointInfo: {},
  pointInfoLoadError: {},
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case CANCEL:
      return {
        ...state,
        cancelError: assignRemove(state.cancelError, action.id),
        cancelling: assignSet(state.loading, action.id, true),
      };
    case CANCEL_SUCCESS:
      return {
        ...state,
        dict: assignSet(state.dict, action.id, action.result.parcel),
        loaded: assignSet(state.loaded, action.id, true),
        cancelling: assignRemove(state.cancelling, action.id),
        meta: assignSet(state.meta, action.id, {language: action.result.language}),
      };
    case CANCEL_FAIL:
      return {
        ...state,
        cancelError: assignSet(state.cancelError, action.id, action.error),
        cancelling: assignRemove(state.cancelling, action.id),
      };
    case LOAD:
      return {
        ...state,
        error: assignRemove(state.error, action.id),
        loaded: assignRemove(state.loaded, action.id),
        loading: assignSet(state.loading, action.id, true),
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        dict: assignSet(state.dict, action.id, action.result.parcel),
        loaded: assignSet(state.loaded, action.id, true),
        loading: assignRemove(state.loading, action.id),
        meta: assignSet(state.meta, action.id, {language: action.result.language}),
      };
    case LOAD_FAIL:
      return {
        ...state,
        error: assignSet(state.error, action.id, action.error),
        loading: assignRemove(state.loading, action.id),
      };
    case UPDATE_ADDRESS:
      return {
        ...state,
        addressUpdateError: assignRemove(state.addressUpdateError, action.id),
        addressUpdating: assignSet(state.addressUpdating, action.id, true),
      };
    case UPDATE_ADDRESS_SUCCESS:
      return {
        ...state,
        addressUpdating: assignRemove(state.addressUpdating, action.id),
        dict: assignSet(state.dict, action.id, action.result.parcel),
        loaded: assignSet(state.loaded, action.id, true),
        meta: assignSet(state.meta, action.id, {language: action.result.language}),
      };
    case UPDATE_ADDRESS_FAIL:
      return {
        ...state,
        addressUpdateError: assignSet(state.addressUpdateError, action.id, action.error),
        addressUpdating: assignRemove(state.addressUpdating, action.id),
      };
    case DELIVERED:
      return {
        ...state,
        deliveredError: assignRemove(state.deliveredError, action.id),
        deliveredLoading: assignSet(state.deliveredLoading, action.id, true),
      };
    case DELIVERED_SUCCESS:
      return {
        ...state,
        dict: assignSet(state.dict, action.id, action.result.parcel),
        loaded: assignSet(state.loaded, action.id, true),
        meta: assignSet(state.meta, action.id, {language: action.result.language}),
        deliveredLoading: assignRemove(state.deliveredLoading, action.id),
      };
    case DELIVERED_FAIL:
      return {
        ...state,
        deliveredError: assignSet(state.deliveredError, action.id, action.error),
        deliveredLoading: assignRemove(state.deliveredLoading, action.id),
      };
    case NOT_DELIVERED:
      return {
        ...state,
        notDeliveredError: assignRemove(state.notDeliveredError, action.id),
        notDeliveredLoading: assignSet(state.notDeliveredLoading, action.id, true),
      };
    case NOT_DELIVERED_SUCCESS:
      return {
        ...state,
        dict: assignSet(state.dict, action.id, action.result.parcel),
        loaded: assignSet(state.loaded, action.id, true),
        meta: assignSet(state.meta, action.id, {language: action.result.language}),
        notDeliveredLoading: assignRemove(state.notDeliveredLoading, action.id),
      };
    case NOT_DELIVERED_FAIL:
      return {
        ...state,
        notDeliveredError: assignSet(state.notDeliveredError, action.id, action.error),
        notDeliveredLoading: assignRemove(state.notDeliveredLoading, action.id),
      };
    case SAVE_ORDER_REVIEW: {
      return assignSetByPath(
        assignSetByPath(state, ['dict', action.id, 'isSavingOrderReview', action.orderId], true),
        ['dict', action.id, 'reviewSaveError', action.orderId],
        null,
      );
    }
    case SAVE_ORDER_REVIEW_FAIL: {
      return assignSetByPath(
        assignSetByPath(state, ['dict', action.id, 'isSavingOrderReview', action.orderId], false),
        ['dict', action.id, 'reviewSaveError', action.orderId],
        action.error,
      );
    }
    case SAVE_ORDER_REVIEW_SUCCESS: {
      return assignSetByPath(
        assignSetByPath(state, ['dict', action.id, 'isSavingOrderReview', action.orderId], false),
        ['dict', action.id, 'orders'],
        state.dict[action.id].orders.reduce((acc, current) => {
          if (current.order.id === action.orderId) {
            acc.push({
              ...current,
              reviewInfo: action.result.order.reviewInfo,
            });
          } else {
            acc.push(current);
          }
          return acc;
        }, []),
      );
    }
    case REMOVE_ORDER_REVIEW: {
      return assignSetByPath(
        assignSetByPath(state, ['dict', action.id, 'isRemovingOrderReview', action.orderId], true),
        ['dict', action.id, 'reviewRemoveError', action.orderId],
        null,
      );
    }
    case REMOVE_ORDER_REVIEW_FAIL: {
      return assignSetByPath(
        assignSetByPath(state, ['dict', action.id, 'isRemovingOrderReview', action.orderId], false),
        ['dict', action.id, 'reviewRemoveError', action.orderId],
        action.error,
      );
    }
    case REMOVE_ORDER_REVIEW_SUCCESS: {
      return assignSetByPath(
        assignSetByPath(
          assignSetByPath(
            state,
            ['dict', action.id, 'isRemovingOrderReview', action.orderId],
            false,
          ),
          ['dict', action.id, 'reviewRemoveError', action.orderId],
          null,
        ),
        ['dict', action.id, 'orders'],
        state.dict[action.id].orders.map((item) => {
          if (item.order.id === action.orderId) {
            return assignSetByPath(item, ['reviewInfo', 'review'], null);
          }
          return item;
        }),
      );
    }
    case LOAD_POINT_INFO: {
      return {
        ...state,
        pointInfoLoading: assignSet(state.pointInfoLoading, action.id, true),
        pointInfoLoadError: assignSet(state.pointInfoLoadError, action.id, null),
        pointInfoLoaded: assignSet(state.pointInfoLoaded, action.id, false),
      };
    }
    case LOAD_POINT_INFO_FAIL: {
      return {
        ...state,
        pointInfoLoading: assignSet(state.pointInfoLoading, action.id, false),
        pointInfoLoadError: assignSet(state.pointInfoLoadError, action.id, action.error),
        pointInfoLoaded: assignSet(state.pointInfoLoaded, action.id, false),
      };
    }
    case LOAD_POINT_INFO_SUCCESS: {
      return {
        ...state,
        pointInfoLoading: assignSet(state.pointInfoLoading, action.id, false),
        pointInfoLoadError: assignSet(state.pointInfoLoadError, action.id, null),
        pointInfo: assignSet(state.pointInfo, action.id, action.result.item),
        pointInfoLoaded: assignSet(state.pointInfoLoaded, action.id, true),
      };
    }
    default:
      return state;
  }
}

export default discardableByUser(reducer);

function getParcelState(globalState) {
  return globalState.parcel;
}

export function isParcelLoading(globalState, id) {
  const {loading} = getParcelState(globalState);
  return !!loading[id];
}

export function isParcelLoaded(globalState, id) {
  const {loaded} = getParcelState(globalState);
  return !!loaded[id];
}

export function isPointInfoLoading(globalState, parcelId) {
  const {pointInfoLoading} = getParcelState(globalState);
  return !!pointInfoLoading[parcelId];
}

export function isPointInfoLoaded(globalState, parcelId) {
  const {pointInfoLoaded} = getParcelState(globalState);
  return isParcelLoaded(globalState, parcelId) && !!pointInfoLoaded[parcelId];
}

export function getPointInfo(globalState, parcelId) {
  const {pointInfo} = getParcelState(globalState);
  return pointInfo[parcelId];
}

export function getPointInfoLoadError(globalState, parcelId) {
  const {pointInfoLoadError} = getParcelState(globalState);
  return pointInfoLoadError[parcelId];
}

export function isParcelCancelling(globalState, id) {
  const {cancelling} = getParcelState(globalState);
  return !!cancelling[id];
}

export function getParcel(globalState, id) {
  return isParcelLoaded(globalState, id) ? globalState.parcel.dict[id] : null;
}

export function getParcelError(globalState, id) {
  return getParcelState(globalState).error[id] || null;
}

export function getParcelCancelError(globalState, id) {
  return getParcelState(globalState).cancelError[id] || null;
}

export function isParcelMarkingDelivered(globalState, id) {
  return !!getParcelState(globalState).deliveredLoading[id];
}

export function getParcelMarkDeliveredError(globalState, id) {
  return getParcelState(globalState).deliveredError[id] || null;
}

export function isParcelMarkingNotDelivered(globalState, id) {
  return !!getParcelState(globalState).notDeliveredLoading[id];
}

export function getParcelMarkNotDeliveredError(globalState, id) {
  return getParcelState(globalState).notDeliveredError[id] || null;
}

export function isParcelAddressUpdating(globalState, id) {
  return !!getParcelState(globalState).addressUpdating[id];
}

export function getParcelAddressUpdateError(globalState, id) {
  return getParcelState(globalState).addressUpdateError[id] || null;
}

export function isParcelCancellable(parcel) {
  return parcel.isCancelable;
}

export function isParcelAddressCanBeChanged(parcel) {
  return parcel.isAddressEditable;
}

export function getParcelLite(parcel) {
  return getValueByPath(parcel, 'lite');
}

export function getOrders(parcel) {
  return getValueByPath(parcel, 'orders') || [];
}

export function getFirstOrder(parcel) {
  const orders = getOrders(parcel);

  if (orders && orders.length > 0) {
    return orders[0].order;
  }

  return null;
}

export function getFirstOrderId(parcel) {
  return getFirstOrder(parcel)?.id;
}

export function getFirstOrderParcelId(parcel) {
  const parcelIds = getFirstOrder(parcel)?.parcelIds;

  if (parcelIds && parcelIds.length > 0) {
    return parcelIds[0];
  }

  return null;
}

export function getShippingEstimate(parcel) {
  return getShippingEstimateFromParcelLite(getParcelLite(parcel));
}

export function getDeliveredTimeMs(parcel) {
  return getDeliveredTimeMsFromParcelLite(getParcelLite(parcel));
}

export function getStatusAppearance(parcel) {
  return getStatusAppearanceFromParcelLite(getParcelLite(parcel));
}

export function getStatusAppearanceTitle(parcel) {
  return getStatusAppearanceTitleFromParcelLite(getParcelLite(parcel));
}

export function getStatusAppearanceText(parcel) {
  return getStatusAppearanceTextFromParcelLite(getParcelLite(parcel));
}

export function isParcelDeliveryConfirmed(parcel) {
  return (
    parcel.deliveryConfirmationMode !== DeliveryConfirmationModeType.YES &&
    parcel.deliveryConfirmationMode !== DeliveryConfirmationModeType.YES_NO
  );
}

export function getParcelDeliveryPointId(parcel) {
  return getValueByPath(parcel, 'deliveryPoint', 'id');
}

function getParcelOrders(parcel) {
  return getValueByPath(parcel, 'orders') || [];
}

function getOrderReturnStatus(order) {
  return getValueByPath(order, 'returnInfo', 'status');
}

export function isAnyOrderReturnAvailable(parcel) {
  const orders = getParcelOrders(parcel);
  return orders.some((order) => getOrderReturnStatus(order) === ReturnStatusType.AVAILABLE);
}

export function isAnyOrderReturnInProgress(parcel) {
  const orders = getParcelOrders(parcel);
  return orders.some((order) => getOrderReturnStatus(order) === ReturnStatusType.INPROGRESS);
}

export function getParcelShowAddress(parcel) {
  return getValueByPath(parcel, 'appearance', 'showAddress');
}

export function loadParcel(id) {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    id,
    promise: async (client, dispatch) => {
      try {
        const {language, body} = await client.api.get(`/parcels/${id}`);

        return {
          parcel: enhanceParcel(body.payload, {language, currency: client.device.getCurrency()}),
          language,
        };
      } catch (error) {
        // If the user opens someone else's order he will be shown an entrance form. This is necessary if the user
        // came by email or using a push notification.
        if ([401, 404].includes(error.status) && client.device.isAuthorized()) {
          await dispatch(signout());
        }

        throw error;
      }
    },
  };
}

export function cancelParcel(id) {
  return {
    types: [CANCEL, CANCEL_SUCCESS, CANCEL_FAIL],
    id,
    promise: (client, dispatch, getState) =>
      client.api.post(`/parcels/${id}/cancel`).then(({language, body: {payload}}) => {
        if (isParcelsLoaded(getState())) {
          dispatch(discardParcels());
          dispatch(discardInactiveFilters());
        }

        return {
          parcel: enhanceParcel(payload, {language, currency: client.device.getCurrency()}),
          language,
        };
      }),
  };
}

export function markParcelDelivered(id) {
  return {
    types: [DELIVERED, DELIVERED_SUCCESS, DELIVERED_FAIL],
    id,
    promise: (client, dispatch, getState) =>
      client.api.post(`/parcels/${id}/delivered`).then(({language, body: {payload}}) => {
        if (isParcelsLoaded(getState())) {
          dispatch(discardParcels());
          dispatch(discardInactiveFilters());
        }

        return {
          parcel: enhanceParcel(payload, {language, currency: client.device.getCurrency()}),
          language,
        };
      }),
  };
}

export function markParcelNotDelivered(id, feedbackEmail) {
  return {
    types: [NOT_DELIVERED, NOT_DELIVERED_SUCCESS, NOT_DELIVERED_FAIL],
    id,
    promise: (client, dispatch, getState) =>
      client.api
        .post(`/parcels/${id}/notDelivered`, {
          body: {feedbackEmail},
        })
        .then(({language, body: {payload}}) => {
          if (isParcelsLoaded(getState())) {
            dispatch(discardParcels());
            dispatch(discardInactiveFilters());
          }

          return {
            parcel: enhanceParcel(payload, {language, currency: client.device.getCurrency()}),
            language,
          };
        }),
  };
}

export function updateParcelAddress(id, {metainfoId, ...address}, updateUserAddress = false) {
  return {
    types: [UPDATE_ADDRESS, UPDATE_ADDRESS_SUCCESS, UPDATE_ADDRESS_FAIL],
    id,
    promise: (client) =>
      client.api
        .put(`/parcels/${id}/address`, {
          body: {
            address,
            metainfoId,
            updateUserAddress,
          },
        })
        .then(({language, body: {payload}}) => ({
          parcel: enhanceParcel(payload, {language, currency: client.device.getCurrency()}),
          language,
        })),
  };
}

export function removeOrderReview(id, orderId) {
  return {
    types: [REMOVE_ORDER_REVIEW, REMOVE_ORDER_REVIEW_SUCCESS, REMOVE_ORDER_REVIEW_FAIL],
    id,
    orderId,
    promise: (client) => client.api.delete(`/orders/${orderId}/review`),
  };
}

export function saveOrderReview(id, orderId, review) {
  return {
    types: [SAVE_ORDER_REVIEW, SAVE_ORDER_REVIEW_SUCCESS, SAVE_ORDER_REVIEW_FAIL],
    id,
    orderId,
    review,
    promise: (client) =>
      client.api
        .put(`/orders/${orderId}/review`, {body: review})
        .then(({language, body: {payload}}) => ({
          order: payload,
          language,
        })),
  };
}

export function loadPointInfo(deliveryPointId, id) {
  return {
    types: [LOAD_POINT_INFO, LOAD_POINT_INFO_SUCCESS, LOAD_POINT_INFO_FAIL],
    id,
    promise: (client) => {
      const query = {
        parcelId: id,
      };

      return client.api
        .get(`/deliveryPoints/infos/${deliveryPointId}`, {query})
        .then(({body: {payload}}) => ({item: payload}));
    },
  };
}

export function getParcelOrderReviewSaving(globalState, parcelId) {
  return getValueByPath(globalState, 'parcel', 'dict', parcelId, 'isSavingOrderReview');
}

export function getParcelOrderReviewRemoving(globalState, parcelId) {
  return getValueByPath(globalState, 'parcel', 'dict', parcelId, 'isRemovingOrderReview');
}

export function getParcelOrderSaveReviewError(globalState, parcelId) {
  return getValueByPath(globalState, 'parcel', 'dict', parcelId, 'reviewSaveError');
}

export function getParcelOrderRemoveReviewError(globalState, parcelId) {
  return getValueByPath(globalState, 'parcel', 'dict', parcelId, 'reviewRemoveError');
}

export function getParcelOrderGroupIds(globalState, parcelId) {
  return getValueByPath(globalState, 'parcel', 'dict', parcelId, 'orderGroupIDs') || [];
}

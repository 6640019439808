/* eslint-disable react/destructuring-assignment */
import loadable from '@loadable/component';
import classnames from 'classnames';
import {Image} from 'components/Image';
import {ImageProps} from 'components/Image/types';
import {Locator} from 'components/Locator';
import {Preloader, Size} from 'components/Preloader';
import React, {CSSProperties, PureComponent, ReactElement, SVGProps, useMemo} from 'react';
import {checkNever, isNumber} from 'utils/guards';

import {FORMAT_SPECIFIC_CONFIGURATIONS, PREFIXES, TYPE_CONFIGURATIONS} from './config';
import {IllustrationsNames, MonochromeIconNames, PolychromeIconNames} from './iconNames';
import styles from './styles.scss';

type Props = {
  loader?: ReactElement | null;
  fallback?: ReactElement | null;
  'aria-labelledby'?: string;
  'aria-label'?: string;
  width?: number | string;
  height?: number | string;
  'data-testid'?: string;
} & (
  | {
      name: MonochromeIconNames;
      type: 'mono';
      color?: string;
    }
  | {
      name: PolychromeIconNames;
      type: 'multi';
      color?: never;
    }
  | (ImageProps & {
      name: IllustrationsNames;
      type: 'illustration';
      color?: never;
    })
);

const TYPE_MAPPING = {
  mono: PREFIXES.mono,
  multi: PREFIXES.multi,
  illustration: PREFIXES.illustration,
} as const;

type State = {
  hasError: boolean;
};

const Loader = ({
  type = 'icon',
  style,
}: {
  type?: 'icon' | 'illustration';
  style?: CSSProperties;
}) => {
  let size = Size.COVER;

  if (type === 'illustration') {
    size = Size.BIG;
  }

  return (
    <div
      className={classnames(styles.loaderWrapper, {
        [styles.illustration!]: type === 'illustration',
        [styles.icon!]: type === 'icon',
      })}
      style={style}
    >
      <Preloader size={size} />
    </div>
  );
};

const IconComponent = loadable(
  (
    props: SVGProps<SVGSVGElement> &
      Pick<Props, 'type' | 'name'> & {
        config: (typeof TYPE_CONFIGURATIONS)[keyof typeof TYPE_CONFIGURATIONS] & {
          dirName: string;
          extension: string;
        };
      },
  ) => import(`./img/${props.config.dirName}/x1/${props.name}${props.config.extension}`),
  {cacheKey: ({type, name}) => `${type}-${name}`},
);

const IllustrationComponent = ({
  name,
  type,
  ...props
}: Pick<Props, 'name' | 'type' | 'aria-label' | 'aria-labelledby'>) => {
  const {
    format,
    extension,
    originalWidth = 1,
    aspectRatio,
  } = TYPE_CONFIGURATIONS[TYPE_MAPPING[type]];

  const {sizes} = FORMAT_SPECIFIC_CONFIGURATIONS[format || ''];

  const [images, error] = useMemo(() => {
    try {
      const loadedImages = sizes?.map((size) =>
        // eslint-disable-next-line import/no-dynamic-require, global-require
        require(`./img/illustration/x${size}/${name}${extension}`),
      );
      return [loadedImages as string[]];
    } catch (error) {
      return [undefined, error];
    }
  }, [name, sizes, extension]);

  if (error || !images) {
    throw error;
  }

  const imageBundle = useMemo(
    () => ({
      images: sizes.map((size, index) => ({
        url: images[index]!,
        width: originalWidth * size,
        height: originalWidth * size * (aspectRatio || 1),
      })),
      showOverlay: false,
    }),
    [images, originalWidth, aspectRatio, sizes],
  );

  if (images) {
    return (
      <Image
        {...props}
        httpsOnly={!__DEVELOPMENT__}
        aria-labelledby={props['aria-labelledby']}
        aria-label={props['aria-label']}
        image={imageBundle}
        alt={name}
        className={styles.illustration}
      />
    );
  }

  return null;
};

export class Icon extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError(): Partial<State> {
    return {hasError: true};
  }

  override componentDidUpdate(prevProps: Props): void {
    const {hasError} = this.state;
    const {name, type} = this.props;

    if (hasError && (prevProps.name !== name || prevProps.type !== type)) {
      this.setState({hasError: false});
    }
  }

  override render(): ReactElement | null {
    const {
      name,
      color,
      type,
      loader,
      fallback = null,
      width,
      height,
      'data-testid': testId,
    } = this.props;
    const {hasError} = this.state;

    if (hasError) {
      return fallback;
    }

    const commonProps = {
      name,
      'aria-labelledby': this.props['aria-labelledby'],
      'aria-label': this.props['aria-label'],
    };
    const style = {
      color,
      width: isNumber(width) ? `${width}px` : width,
      height: isNumber(height) ? `${height}px` : height,
    };

    const config = TYPE_CONFIGURATIONS[TYPE_MAPPING[type]];

    switch (type) {
      case 'illustration':
        return <IllustrationComponent {...commonProps} type={type} />;
      case 'mono':
      case 'multi':
        return (
          <Locator id={testId}>
            <IconComponent
              {...commonProps}
              type={type}
              className={styles.icon}
              style={style}
              config={config}
              // fallback prop in loadable component used as loader
              fallback={loader === undefined ? <Loader style={style} /> : loader || undefined}
            />
          </Locator>
        );
      default:
        checkNever(type);
        return null;
    }
  }
}

import config from 'config';
import {getAppPort} from 'helpers/port';
import {TransportHeaders, TransportMeta} from 'types/Transport';

import {addExpAppHeader, addRenderingIdHeader, addVersionHeader} from './headers';
import {Transport} from './index';

export const PREFIX = __SERVER__ ? `http://localhost:${getAppPort()}` : '';

/** Transport for requests to self node.js backend */
export class WebTransport extends Transport {
  public override device!: import('../Device').Device;

  constructor(device: import('../Device').Device) {
    super(PREFIX, device, undefined, undefined);
  }

  override buildHeaders(headers: TransportHeaders, meta: TransportMeta): TransportHeaders {
    const result = super.buildHeaders(headers, meta) || {};
    addVersionHeader(result, config.version);
    addRenderingIdHeader(result, this.device.getRenderingConfig());
    addExpAppHeader(result, this.device.scope);

    return result;
  }
}

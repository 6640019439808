import {ScopeConfig} from 'helpers/ApiClient/Scope/ScopeConfig';
import {getUrl} from 'routes';
import type {socialSignin} from 'store/modules/auth';
import {UserWithPopup} from 'types/User';
import {getQueryData} from 'utils/url';

export enum AuthViewType {
  SOCIAL = 'social',
  CONFIRMATION = 'confirmation',
  SIGNIN = 'signin',
  SIGNUP_CHECKOUT = 'signup-checkout',
  REGISTER = 'register',
  RECOVER_PASSWORD = 'recover-password',
  RECOVER_PASSWORD_COMPLETE = 'recover-password-complete',
  NEW_PASSWORD = 'new-password',
  SMARTLOCK_FAILURE = 'smartlock-failure',
  COMBINED = 'combined',
  MIGRATION = 'migration',
}

export enum AuthPopupType {
  DEFAULT = 'default',
  POPUP = 'popup',
  REWARD_WHEEL = 'reward-wheel',
}

export enum AuthMigrationMethod {
  MIGRATE = 'migrate',
  RECOVERY = 'recovery',
}

const TypeWithToken: Partial<Record<AuthViewType, boolean>> = {
  [AuthViewType.CONFIRMATION]: true,
  [AuthViewType.RECOVER_PASSWORD_COMPLETE]: true,
};

const TypeWithUser: Partial<Record<AuthViewType, boolean>> = {
  [AuthViewType.CONFIRMATION]: true,
  [AuthViewType.RECOVER_PASSWORD_COMPLETE]: true,
};

export function isTokenRequired(type: AuthViewType): boolean {
  return Boolean(TypeWithToken[type]);
}

export function isUserAllowed(type: AuthViewType): boolean {
  return Boolean(TypeWithUser[type]);
}

export function getViewUrl(
  scope: ScopeConfig,
  lang: string,
  type: AuthViewType,
  search?: string,
): string {
  return getUrl('auth', {scope, lang, type}, search ? getQueryData(search) : undefined);
}

export function createChangeViewHandler<R>(
  handler: ((type: AuthViewType) => R) | null | undefined,
  type: AuthViewType,
): (() => R) | null {
  return handler ? () => handler(type) : null;
}

export type SocialSigninCallback = (
  ...args: Parameters<typeof socialSignin>
) => Promise<UserWithPopup>;

export type FormFieldValue = string;
export type FormFieldValues = Record<string, FormFieldValue>;

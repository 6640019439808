import {routeAsync} from 'helpers/asyncConnect';

import {hasCheckoutAccess} from './decorators';
import {DefaultParams, PoorRoute} from './types';

type Params = DefaultParams & {
  orderGroupId: string;
};

const NAME = 'checkoutSuccess';

export const CheckoutSuccessRoute: PoorRoute<typeof NAME, Params> = {
  name: NAME,
  path: '/checkout/:orderGroupId/success',
  async: routeAsync(
    /* #__LOADABLE__ */ () => import('containers/pages/CheckoutSuccessPage'),
    (module) => module.CheckoutSuccessPage,
  ),
  decorator: hasCheckoutAccess,
};

import {CookieType} from 'types/CookiesSettings';

import {CookieValue} from '../Device/CookieValue';
import {numberDecoder, numberEncoder} from './helpers';

const COOKIE_NAME = 'timezone';

// stores `new Date().getTimezoneOffset()` value
export const TimezoneCookie: CookieValue<number | undefined> = {
  type: CookieType.FUNCTIONAL,
  key: COOKIE_NAME,
  decode: numberDecoder,
  encode: numberEncoder,
};

import {globalLog} from 'helpers/log';
import {ecsError} from 'helpers/log/ECS/ecsError';
import {CouponCard} from 'types/CouponCard';

const STORAGE_KEY = 'coupons';

const logger = globalLog.getLogger('CouponsStorage');

type CouponsStorageState = {
  onboardingShown: boolean;
  viewed: string[];
};

function getInitialState() {
  return {
    onboardingShown: false,
    viewed: [],
  };
}

function getState(): CouponsStorageState {
  if (__SERVER__) {
    return getInitialState();
  }

  try {
    const state = window.localStorage?.getItem(STORAGE_KEY);
    if (!state) {
      return getInitialState();
    }

    return JSON.parse(state) as CouponsStorageState;
  } catch (error) {
    logger.error({error: ecsError(error)});
    return getInitialState();
  }
}

function setState(state: CouponsStorageState): void {
  if (__CLIENT__) {
    try {
      const stateStr = JSON.stringify(state);
      window.localStorage?.setItem(STORAGE_KEY, stateStr);
    } catch (error) {
      logger.error({error: ecsError(error)});
    }
  }
}

// onboarding

export function getStorageCouponOnboardingShown(): boolean {
  return getState()?.onboardingShown ?? false;
}

export function setStorageCouponOnboardingShown(onboardingShown: boolean): void {
  setState({...getState(), onboardingShown});
}

// viewed coupons

export function getStorageViewedCoupons(): string[] {
  return getState()?.viewed || [];
}

export function setStorageViewedCoupons(viewed: string[]): void {
  setState({...getState(), viewed});
}

export function setStorageViewedCoupon(couponId: string): void {
  setStorageViewedCoupons([...getStorageViewedCoupons(), couponId]);
}

export function enhanceStorageViewedCoupon(coupon: CouponCard): CouponCard {
  coupon.viewed = getStorageViewedCoupons().includes(coupon.id);
  return coupon;
}

// function mutate coupons
export function syncStorageViewedCoupons(coupons: CouponCard[]): CouponCard[] {
  if (__SERVER__) {
    return coupons;
  }

  const viewedCoupons = getStorageViewedCoupons();
  const nextViewedCoupons: string[] = [];

  coupons.forEach((item) => {
    if (viewedCoupons.includes(item.id)) {
      nextViewedCoupons.push(item.id);
      item.viewed = true;
    } else {
      item.viewed = false;
    }
  });

  if (nextViewedCoupons.length !== viewedCoupons.length) {
    setStorageViewedCoupons(nextViewedCoupons);
  }

  return coupons;
}

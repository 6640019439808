import type {CookieOptions} from 'express';

import {CookieValue} from '../Device/CookieValue';
import type {CookieNames, CookieValues, ExtractCookieValueType} from '../Device/cookieValues';
import {getCookie} from '../Device/cookieValues';
import {cookieOptions} from './helpers';

function isEmptyValue(value?: unknown): boolean {
  return value === '' || value === undefined || value === null;
}

export type CookieManagerOptions = {
  // if true, data will be kept in memory when not permitted to use cookie
  inMemoryFallback?: boolean;
};

export class CookieManager<N extends CookieNames, R = ExtractCookieValueType<CookieValues[N]>> {
  protected cookie: CookieValue<R>;

  protected memoryValue: string | undefined;

  constructor(
    protected readonly name: N,
    protected readonly cookies: import('./index').Cookies,
    protected readonly options: CookieManagerOptions = {},
  ) {
    this.cookie = getCookie(name) as unknown as CookieValue<R>;
  }

  protected setInMemory(value: string | undefined): void {
    if (this.options.inMemoryFallback) {
      this.memoryValue = this.isPermitted() ? undefined : value;
    }
  }

  protected removeInMemory(): void {
    this.memoryValue = undefined;
  }

  protected getFromMemory(): string | undefined {
    return this.memoryValue;
  }

  exists(): boolean {
    return !isEmptyValue(this.cookies.get(this.name));
  }

  isPermitted(): boolean {
    return this.cookies.isCookiePermitted(this.name);
  }

  private getCookieOptions(data?: R): CookieOptions {
    return cookieOptions(this.cookie.getOptions?.(data));
  }

  store(data: R): void {
    const value = this.cookie.encode(data);
    if (isEmptyValue(value)) {
      this.removeInMemory();
      this.cookies.remove(this.name, this.getCookieOptions(data));
    } else {
      this.setInMemory(value);
      this.cookies.set(this.name, value, this.getCookieOptions(data));
    }
  }

  decode(string: string): R {
    return this.cookie.decode(string);
  }

  encode(data: R): string {
    return this.cookie.encode(data);
  }

  restore(): R {
    return this.cookie.decode(this.getFromMemory() || this.cookies.get(this.name) || '');
  }

  remove(): void {
    this.removeInMemory();
    this.cookies.remove(this.name, this.getCookieOptions());
  }
}

// eslint-disable-next-line import/no-default-export
export default function throttle<Args extends unknown[]>(
  this: unknown,
  fn: (...args: Args) => void,
  threshhold = 250,
): (...args: Args) => void {
  let last: number;
  let deferTimer: NodeJS.Timeout;
  return (...args: Args): void => {
    const now = Date.now();
    if (last && now < last + threshhold) {
      // hold on to it
      clearTimeout(deferTimer);
      deferTimer = setTimeout(() => {
        last = now;
        fn.apply(this, args);
      }, threshhold);
    } else {
      last = now;
      fn.apply(this, args);
    }
  };
}

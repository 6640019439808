import {ECSLog} from 'helpers/log/ECS/types';

import {MapStorage} from './MapStorage';
import {IKeyValueStorage} from './types';
import {isLocalStorageAvailable, isSessionStorageAvailable, WindowStorage} from './WindowStorage';

export enum KeyValueStorageStrategy {
  MAP = 'map',
  LOCAL = 'local',
  SESSION = 'session',
}

export function createKeyValueStorage<T>(
  strategy: KeyValueStorageStrategy,
  log: ECSLog,
): IKeyValueStorage<T> {
  if (strategy === KeyValueStorageStrategy.LOCAL && isLocalStorageAvailable()) {
    return new WindowStorage<T>(window.localStorage, log);
  }
  if (strategy === KeyValueStorageStrategy.SESSION && isSessionStorageAvailable()) {
    return new WindowStorage<T>(window.sessionStorage, log);
  }
  return new MapStorage<T>();
}

import {routeAsync} from 'helpers/asyncConnect';
import {anonymous} from 'routes/decorators';

import {DefaultParams, PoorRoute} from './types';

type Params = DefaultParams;

const NAME = 'socialFeed';

export const SocialFeedRoute: PoorRoute<typeof NAME, Params> = {
  name: NAME,
  path: '/social/feed',
  async: routeAsync(
    /* #__LOADABLE__ */ () => import('containers/pages/SocialFeedPage'),
    (module) => module.SocialFeedPage,
  ),
  decorator: anonymous,
};

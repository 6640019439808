import {discardableByUser} from 'helpers/discardable';
import {enhanceLoadedTime} from 'store/utils/enhancers';
import {extractResponsePayloadEnhanced} from 'store/utils';

const SET = 'points/account/SET';

const LOAD = 'points/account/LOAD';
const LOAD_SUCCESS = 'points/account/LOAD_SUCCESS';
const LOAD_FAIL = 'points/account/LOAD_FAIL';

const initialState = {
  data: null,
  error: null,
  loaded: false,
  loading: false,
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        error: null,
        loaded: false,
        loading: true,
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        data: action.result,
        loaded: true,
        loading: false,
      };
    case LOAD_FAIL:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case SET:
      return {
        ...state,
        data: action.account,
        loaded: true,
        loading: false,
      };
    default:
      return state;
  }
}

export default discardableByUser(reducer);

export function isPointsAccountLoading(globalState) {
  return globalState.points.account.loading;
}

export function isPointsAccountLoaded(globalState) {
  const {loaded} = globalState.points.account;
  return loaded;
}

export function getPointsAccount(globalState) {
  return isPointsAccountLoaded(globalState) ? globalState.points.account.data : null;
}

export function getPointsAccountError(globalState) {
  return globalState.points.account.error || null;
}

export function enhancePointsAccount(response, getPayload = null) {
  return enhanceLoadedTime(extractResponsePayloadEnhanced(response, getPayload));
}

export function setPointsAccount(account) {
  return {
    type: SET,
    account,
  };
}

export function loadPointsAccount() {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: (client) =>
      client.device.isEphemeral()
        ? Promise.resolve(null)
        : client.api.get('/points/account').then(enhancePointsAccount),
  };
}

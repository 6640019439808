import {LegalEntity, Scope} from './scope/enums';

export type LanguageConfig = Readonly<{
  code: string;
  locale: string;
  legalEntity?: LegalEntity;
  scope?: Scope;
  rtl?: boolean;
  contextL10n?: boolean;
}>;

export const languagesConfig = [
  {code: 'en', locale: 'en-US', legalEntity: LegalEntity.JOOM},
  {code: 'cs', locale: 'cs-CZ', legalEntity: LegalEntity.JOOM},
  {code: 'de', locale: 'de-DE', legalEntity: LegalEntity.JOOM},
  {code: 'es', locale: 'es-ES', legalEntity: LegalEntity.JOOM},
  {code: 'et', locale: 'et-EE', legalEntity: LegalEntity.JOOM},
  {code: 'fr', locale: 'fr-FR', legalEntity: LegalEntity.JOOM},
  {code: 'hu', locale: 'hu-HU', legalEntity: LegalEntity.JOOM},
  {code: 'it', locale: 'it-IT', legalEntity: LegalEntity.JOOM},
  {code: 'lt', locale: 'lt-LT', legalEntity: LegalEntity.JOOM},
  {code: 'lv', locale: 'lv-LV', legalEntity: LegalEntity.JOOM},
  {code: 'nb', locale: 'nb-NO', legalEntity: LegalEntity.JOOM},
  {code: 'nl', locale: 'nl-NL', legalEntity: LegalEntity.JOOM},
  {code: 'pl', locale: 'pl-PL', legalEntity: LegalEntity.JOOM},
  {code: 'pt', locale: 'pt-PT', legalEntity: LegalEntity.JOOM},
  {code: 'pt-br', locale: 'pt-BR', legalEntity: LegalEntity.JOOM},
  {code: 'ro', locale: 'ro-RO', legalEntity: LegalEntity.JOOM},
  {code: 'sk', locale: 'sk-SK', legalEntity: LegalEntity.JOOM},
  {code: 'sv', locale: 'sv-SE', legalEntity: LegalEntity.JOOM},
  {code: 'tr', locale: 'tr-TR', legalEntity: LegalEntity.JOOM},
  {code: 'el', locale: 'el-GR', legalEntity: LegalEntity.JOOM},
  {code: 'kk', locale: 'kk-KZ', legalEntity: LegalEntity.JOOM},
  {code: 'ru', locale: 'ru-RU', legalEntity: LegalEntity.JMT},
  {code: 'ru-ua', locale: 'ru-RU', legalEntity: LegalEntity.JOOM},
  {code: 'uk', locale: 'uk-UA', legalEntity: LegalEntity.JOOM},
  {code: 'ka', locale: 'ka-GE', legalEntity: LegalEntity.JOOM},
  {code: 'he', locale: 'he-IL', rtl: true, legalEntity: LegalEntity.JOOM},
  {code: 'ar', locale: 'ar-SA', rtl: true, legalEntity: LegalEntity.JOOM},
  {code: 'ja', locale: 'ja-JP', legalEntity: LegalEntity.JOOM, scope: Scope.GEEK},
  {code: 'lol', locale: 'lol-US', contextL10n: true},
  // {code: 'tlh', locale: 'ru-RU', contextL10n: true},
] as const satisfies ReadonlyArray<LanguageConfig>;

export type AppLanguageCode = (typeof languagesConfig)[number]['code'];

export type AppLanguageLocale = (typeof languagesConfig)[number]['locale'];

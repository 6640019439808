import {routeAsync} from 'helpers/asyncConnect';

import {loggedIn} from './decorators';
import {DefaultParams, PoorRoute} from './types';

const NAME = 'profileSecurity';

export const ProfileSecurityRoute: PoorRoute<typeof NAME, DefaultParams> = {
  name: NAME,
  path: '/profile/security',
  async: routeAsync(
    /* #__LOADABLE__ */ () => import('containers/pages/ProfileSecurityPage'),
    (module) => module.ProfileSecurityPage,
  ),
  decorator: loggedIn,
};

import config, {
  AppLanguageCode,
  DomainConfigId,
  DomainScopeConfig,
  LegalEntity,
  PrefixScopeConfig,
  Scope,
  ScopeCompanyInfo,
} from 'config';
import type {Request} from 'express';
import {createCookie} from 'helpers/ApiClient/Device/createCookie';
import {memoizeLastShallowEqual} from 'utils/memoize';

import {Cookies} from '../Cookies';
import {getDomainScopeConfigByHostname, getPrefixScopeConfigByUrl} from './utils';

export const PREFIX_SCOPE_GET_PARAM = 'webAppScope';

export const EXP_APP_HEADER = 'x-exp-app';

export class ScopeConfig {
  readonly domainConfig: DomainScopeConfig;

  private readonly prefixConfig: PrefixScopeConfig | undefined;

  get domainConfigId(): DomainConfigId {
    return this.domainConfig.id;
  }

  get topScope(): Scope {
    return this.prefixConfig?.scope || this.domainConfig.scope;
  }

  get domainScope(): Scope {
    return this.domainConfig.scope;
  }

  get prefixScope(): Scope | undefined {
    return this.prefixConfig?.scope;
  }

  get oppositeEntityId(): DomainConfigId | undefined {
    return this.domainConfig.oppositeEntityId;
  }

  get hasOppositeEntity(): boolean {
    return (
      Boolean(this.domainConfig.oppositeEntityId) &&
      (!this.prefixConfig || this.prefixConfig.hasOppositeEntity)
    );
  }

  private getCompanyInfoMemo = memoizeLastShallowEqual(
    (prefixConfig: PrefixScopeConfig | undefined) => ({
      ...this.domainConfig.companyInfo,
      ...prefixConfig?.companyInfo,
    }),
  );

  get companyInfo(): ScopeCompanyInfo {
    return this.getCompanyInfoMemo(this.prefixConfig);
  }

  get mobileApps(): DomainScopeConfig['mobileApps'] {
    return this.prefixConfig?.disableMobileApps ? undefined : this.domainConfig.mobileApps;
  }

  get integrations(): DomainScopeConfig['integrations'] {
    return this.domainConfig.integrations;
  }

  get legalEntity(): LegalEntity {
    return this.domainConfig.legalEntity;
  }

  get scopeHeader(): string | undefined {
    return this.prefixConfig?.scopeHeader || this.domainConfig.scopeHeader;
  }

  get deviceCustomDomain(): string | undefined {
    return this.domainConfig.deviceCustomDomain;
  }

  get pathPrefix(): string | undefined {
    return this.prefixConfig?.pathPrefix;
  }

  get defaultLanguage(): AppLanguageCode {
    return this.domainConfig.defaultLanguage;
  }

  get defaultCurrency(): string {
    return this.domainConfig.defaultCurrency;
  }

  get hostname(): string {
    return this.domainConfig.hostname;
  }

  get availablePrefixScopes(): PrefixScopeConfig[] | undefined {
    return this.domainConfig.prefixScopes as PrefixScopeConfig[] | undefined;
  }

  get isJmt(): boolean {
    return this.legalEntity === LegalEntity.JMT;
  }

  get notJmt(): boolean {
    return this.legalEntity !== LegalEntity.JMT;
  }

  is(...scopes: [Scope, ...Scope[]]): boolean {
    return scopes.some((scope) => this.topScope === scope);
  }

  not(...scopes: [Scope, ...Scope[]]): boolean {
    return scopes.every((scope) => this.topScope !== scope);
  }

  isDomain(...scopes: [Scope, ...Scope[]]): boolean {
    return scopes.some((scope) => this.domainScope === scope);
  }

  notDomain(...scopes: [Scope, ...Scope[]]): boolean {
    return scopes.every((scope) => this.domainScope !== scope);
  }

  hasPrefixScope(scope: Scope): boolean {
    return Boolean(
      this.availablePrefixScopes?.find((prefixConfig) => prefixConfig.scope === scope),
    );
  }

  constructor(domainScopeConfig: DomainScopeConfig, prefixScope: Scope | undefined) {
    this.domainConfig = domainScopeConfig;
    this.prefixConfig = domainScopeConfig.prefixScopes?.find(({scope}) => scope === prefixScope);
  }

  static configurePrefixScope(
    domainConfig: DomainScopeConfig,
    req: Request | undefined,
  ): ScopeConfig {
    if (req) {
      let serverPrefixConfig: PrefixScopeConfig | undefined;

      if (!serverPrefixConfig && req?.headers[EXP_APP_HEADER]) {
        serverPrefixConfig = domainConfig.prefixScopes?.find(
          (config) => config.scopeHeader === req?.headers[EXP_APP_HEADER],
        );
      }

      if (!serverPrefixConfig && req?.query[PREFIX_SCOPE_GET_PARAM]) {
        serverPrefixConfig = domainConfig.prefixScopes?.find(
          (config) => config.scope === req?.query[PREFIX_SCOPE_GET_PARAM],
        );
      }

      if (!serverPrefixConfig) {
        serverPrefixConfig = getPrefixScopeConfigByUrl(req.originalUrl, domainConfig);
      }

      return new ScopeConfig(domainConfig, serverPrefixConfig?.scope);
    }

    const prefixScopeConfig = getPrefixScopeConfigByUrl(window.location.pathname, domainConfig);

    return new ScopeConfig(domainConfig, prefixScopeConfig?.scope);
  }

  static configure(req: Request | undefined, cookies: Cookies): ScopeConfig {
    let domainConfig: DomainScopeConfig | undefined;
    if (config.releaseStage !== 'prod') {
      const cookieConfigId = createCookie('devDomainScope', cookies).restore();
      if (cookieConfigId) {
        domainConfig = config.scopes[cookieConfigId];
      }
    }

    if (!domainConfig) {
      domainConfig = getDomainScopeConfigByHostname(req?.hostname || window.location.hostname);
    }

    return ScopeConfig.configurePrefixScope(domainConfig, req);
  }
}

import {routeAsync} from 'helpers/asyncConnect';

import {anonymous} from './decorators';
import {DefaultParams, PoorRoute} from './types';

const NAME = 'bests';

export const BestsRoute: PoorRoute<typeof NAME, DefaultParams> = {
  name: NAME,
  path: '/bests',
  async: routeAsync(
    /* #__LOADABLE__ */ () => import('containers/pages/BestsPage'),
    (module) => module.BestsPage,
  ),
  decorator: anonymous,
};

import {init, reactRouterV5Instrumentation} from '@sentry/react';
import {BrowserTracing} from '@sentry/tracing';
import config from 'config';
import {setEnvSentryTags} from 'helpers/sentry/setTags';
import {transformApiErrorEvent} from 'helpers/sentry/transformApiErrorEvent';
import {History} from 'history';

export function initClientSentry({history}: {history: History}): void {
  if (config.releaseStage !== 'prod') {
    return;
  }

  init({
    ...config.sentry,
    tracesSampleRate: 0.1,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: reactRouterV5Instrumentation(history),
        tracePropagationTargets: config.sentry.tracePropagationTargets,
      }),
    ],
    beforeSend: transformApiErrorEvent,
    ignoreErrors: [
      // Random plugins/extensions
      'top.GLOBALS',
      // Facebook borked
      'fb_xd_fragment',
    ],
    denyUrls: [
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
    ],
  });

  setEnvSentryTags();
}

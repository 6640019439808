import {ProductAttributesGroup} from 'types/ProductAttributes';

import {ContentListBaseItem, contentListBaseItem} from './ContentListBaseItem';

type ProductAttributes = {
  productAttributes: {
    title: string;
    featured: ProductAttributesGroup;
    all?: ProductAttributesGroup[];
  };
};

export type ContentListProductAttributes = ContentListBaseItem<ProductAttributes>;

export function contentListProductAttributes(
  id: string,
  productAttributes: ProductAttributes,
): ContentListProductAttributes {
  return contentListBaseItem(id, productAttributes);
}

export function isContentListProductAttributes(
  item: ContentListBaseItem,
): item is ContentListProductAttributes {
  return Boolean((item as ContentListProductAttributes).content.productAttributes);
}

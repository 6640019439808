import {enhanceProductsList} from 'store/enhancer/productsList';
import {ParcelOrder, ParcelOrderOptions, ParcelOrderRaw} from 'types/ParcelOrder';

export function enhanceParcelOrder(
  order: ParcelOrderRaw,
  options: ParcelOrderOptions,
): ParcelOrder {
  const result = order as ParcelOrder;

  if (order.similarProducts) {
    result.similarProducts = enhanceProductsList(order.similarProducts, options);
  }

  return result;
}

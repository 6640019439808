import {routeAsync} from 'helpers/asyncConnect';

import {anonymous} from './decorators';
import {DefaultParams, PoorRoute} from './types';

const NAME = 'refundSuccess';

export const RefundSuccessRoute: PoorRoute<typeof NAME, DefaultParams> = {
  name: NAME,
  path: '/refund/success',
  async: routeAsync(
    /* #__LOADABLE__ */ () => import('containers/pages/RefundSuccessPage'),
    (module) => module.RefundSuccessPage,
  ),
  decorator: anonymous,
};

import {routeAsync} from 'helpers/asyncConnect';
import {anonymous} from 'routes/decorators';

import {DefaultParams, PoorRoute} from './types';

type Params = DefaultParams;

const NAME = 'socialCollections';

export const CollectionsDiscoveryRoute: PoorRoute<typeof NAME, Params> = {
  name: NAME,
  path: '/collections/discovery',
  async: routeAsync(
    /* #__LOADABLE__ */ () => import('containers/pages/CollectionsDiscoveryPage'),
    (module) => module.CollectionsDiscoveryPage,
  ),
  decorator: anonymous,
};

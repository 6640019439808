import {Color} from 'types/Color';
import type {EventParams} from 'types/EventParams';
import {ImageBundle} from 'types/Image';

import {ContentListBaseItem, contentListBaseItem} from './ContentListBaseItem';

export type Row = {
  image: ImageBundle | null;
  imageSize?: 'small' | 'normal';
  imageType?: 'icon' | 'picture';
  isBoldTitle?: boolean;
  subtitle?: string;
  titleSuffix?: string;
  titleSuffixColor?: Color;
  title: string;
  titleColor?: Color;
  titleCounter?: string;
  url: string;
  eventParams?: EventParams;
  content?: {
    icons?: ImageBundle[];
    caption?: {
      endIcon?: ImageBundle;
    };
  };
};

export type ContentListRow = ContentListBaseItem<{row: Row}>;

export function contentListRow(id: string, row: Row): ContentListRow {
  return contentListBaseItem(id, {row});
}

export function isContentListRow(item: ContentListBaseItem): item is ContentListRow {
  return Boolean((item as ContentListRow).content.row);
}

export function extractRow(item: ContentListRow): Row {
  return item.content.row;
}

/**
 * @see https://developer.chrome.com/blog/auto-dark-theme/#detecting-auto-dark-theme
 */
import {useEffect, useState} from 'react';
import {rgbStringToRgb, rgbToBrightness} from 'utils/colors';
import {requestAnimationFrame} from 'utils/raf';

type AutoDarkOptions = {
  detectionElementId: string;
  prefersColorSchemeMediaQuery: string;
};
type AutoDarkCalcCallback = (isAutoDark: boolean) => void;

const AUTO_DARK_OPTIONS_DEFAULT: AutoDarkOptions = {
  detectionElementId: 'autodark_detection',
  prefersColorSchemeMediaQuery: '(prefers-color-scheme: dark)',
};

class AutoDarkObserver {
  private options: AutoDarkOptions;

  /**
   * List of subscribers.
   */
  private callbacks: AutoDarkCalcCallback[] = [];

  /**
   * The last calculated result to send to new subscribers.
   */
  private lastResult = false;

  constructor(options: AutoDarkOptions = AUTO_DARK_OPTIONS_DEFAULT) {
    this.options = options;

    if (typeof window !== 'undefined') {
      // Calculate initial result.
      this.calc();
      this.subscribeOnMediaQueryChange();
    }
  }

  private createDetectionElement(): HTMLElement {
    const element = document.createElement('div');

    element.id = this.options.detectionElementId;
    element.style.backgroundColor = 'canvas';
    element.style.color = '#000000';
    element.style.colorScheme = 'light';
    element.style.display = 'none';

    document.body.appendChild(element);

    return element;
  }

  private getDetectionElement(): HTMLElement {
    return (
      document.getElementById(this.options.detectionElementId) || this.createDetectionElement()
    );
  }

  private calc() {
    const detectionElement = this.getDetectionElement();

    // Wait until dark mode extension changes colors.
    requestAnimationFrame(() => {
      const {backgroundColor, color} = getComputedStyle(detectionElement);
      const backgroundColorRgb = rgbStringToRgb(backgroundColor) || [255, 255, 255];
      const textColorRgb = rgbStringToRgb(color) || [0, 0, 0];
      const middleColorBrightness = 255 / 2;

      // The background color with an initial "canvas" value use to detect the chrome auto dark mode
      // and color with an initial hex color value use to detect dark mode add-ons and extensions.
      // Also use an approximate comparison of brightness, because some browsers may convert
      // the named colors to different values.
      this.lastResult =
        rgbToBrightness(backgroundColorRgb) < middleColorBrightness ||
        rgbToBrightness(textColorRgb) > middleColorBrightness;
    });
  }

  private broadcast() {
    // Calc the next result.
    this.calc();
    this.callbacks.forEach((callback) => callback(this.lastResult));
  }

  private subscribeOnMediaQueryChange() {
    if ('matchMedia' in window) {
      window.matchMedia(this.options.prefersColorSchemeMediaQuery).addListener(() => {
        this.broadcast();
      });
    }
  }

  unsubscribe(callback: AutoDarkCalcCallback) {
    this.callbacks = this.callbacks.filter((item) => item !== callback);
  }

  subscribe(callback: AutoDarkCalcCallback) {
    this.callbacks.push(callback);
    callback(this.lastResult);

    return () => this.unsubscribe(callback);
  }
}

const autoDarkObserverInstance = new AutoDarkObserver();

function useIsAutoDarkClient(): boolean {
  const [isAutoDark, setIsAutoDark] = useState(false);

  useEffect(() => autoDarkObserverInstance.subscribe(setIsAutoDark), []);

  return isAutoDark;
}

function useIsAutoDarkServer(): boolean {
  return false;
}

export const useIsAutoDark = __CLIENT__ ? useIsAutoDarkClient : useIsAutoDarkServer;

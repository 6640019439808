import type {ContextList} from 'types/Context';
import type {ProductLiteRaw} from 'types/ProductLite';

import {Header} from './common';
import {ContentListBaseItem, contentListBaseItem} from './ContentListBaseItem';
import {ContentListProduct} from './ContentListProduct';

const RELATED_PRODUCTS_LIST_ID = 'productRelated';
const SIMILAR_PRODUCTS_LIST_ID = 'productSimilar';

export enum ListApperanceDesign {
  Row = 'rowWeb',
}

type ProductsListBase = {
  nextPageToken?: string;
  contexts: ContextList[];
  headerAppearance: {
    simple?: Header;
  };
  title?: string;
  appearance?: {
    showBrand?: boolean;
    showShippingBadge?: boolean;
  };
  all?: {
    label: string;
    uri: string;
  };
  endpointPath?: string;
  endpointParams?: Record<string, unknown>;
  eventParams?: string;
  listAppearance?: {
    design: ListApperanceDesign;
  };
};

export type ProductsList = ProductsListBase & {
  items: ContentListProduct[];
};
export type ProductsListRaw = ProductsListBase & {
  items: {product: ProductLiteRaw}[];
};
export type ContentListProductsList = ContentListBaseItem<{productsList: ProductsList}>;
export type ContentListProductsListRaw = ContentListBaseItem<{productsList: ProductsListRaw}>;

export function contentListProductsList(
  id: string,
  productsList: ProductsList,
): ContentListProductsList {
  return contentListBaseItem(id, {productsList});
}

export function isContentListProductsList(
  item: ContentListBaseItem,
): item is ContentListProductsList {
  return Boolean((item as ContentListProductsList).content.productsList);
}

export function isContentListRelatedProductsList(
  item: ContentListBaseItem,
): item is ContentListProductsList {
  return isContentListProductsList(item) && item.id === RELATED_PRODUCTS_LIST_ID;
}

export function isContentListSimilarProductsList(
  item: ContentListBaseItem,
): item is ContentListProductsList {
  return isContentListProductsList(item) && item.id === SIMILAR_PRODUCTS_LIST_ID;
}

export function isContentListProductsListRaw(
  item: ContentListBaseItem,
): item is ContentListProductsListRaw {
  return Boolean((item as ContentListProductsListRaw).content.productsList?.items[0]?.product);
}

import {discardableByUser} from 'helpers/discardable';
import {enhanceCurrency, enhanceLanguage} from 'store/utils/enhancers';

const LOAD = 'points/history/LOAD';
const LOAD_SUCCESS = 'points/history/LOAD_SUCCESS';
const LOAD_FAIL = 'points/history/LOAD_FAIL';

const initialState = {
  data: {},
  loading: true,
  error: {},
  loaded: false,
};

function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case LOAD_SUCCESS: {
      const isFirstRequest = !action.pageToken || action.pageToken !== state.data.nextPageToken;
      return {
        ...state,
        data: isFirstRequest
          ? action.result
          : {
              ...state.data,
              ...action.result,
              items: [...state.data.items, ...action.result.items],
            },
        loaded: true,
        loading: false,
      };
    }
    case LOAD_FAIL:
      return {
        ...state,
        error: action.error,
        loaded: false,
        loading: false,
      };
    default:
      return state;
  }
}

export default discardableByUser(reducer);

export function isPointsHistoryLoaded(globalState) {
  const {loaded} = globalState.points.history;
  return loaded;
}

export function getPointsHistory(globalState) {
  return isPointsHistoryLoaded(globalState) ? globalState.points.history.data : null;
}

export function getPointsHistoryNextPageToken(globalState) {
  return isPointsHistoryLoaded(globalState) ? globalState.points.history.data.nextPageToken : '';
}

export function isPointsHistoryLoading(globalState) {
  return !!globalState.points.history.loading;
}

export function getPointsHistoryError(globalState) {
  return globalState.points.history.error || null;
}

export function loadPointsHistory(pageToken) {
  const query = {
    count: 10,
  };

  if (pageToken) {
    query.pageToken = pageToken;
  }

  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    pageToken,
    promise: (client) =>
      client.api.get('/points/history', {query}).then(({currency, language, body: {payload}}) => {
        const items = payload.items || [];
        return {
          currency,
          language,
          items: items.map((item) => enhanceCurrency(enhanceLanguage(item, language), currency)),
          nextPageToken: payload.nextPageToken || '',
        };
      }),
  };
}

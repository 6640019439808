import {CookieType} from 'types/CookiesSettings';

import {CookieValue} from '../Device/CookieValue';
import {falseDecoder, falseEncoder} from './helpers';

const COOKIE_NAME = 'jmtMigrationClose';

// 1 day
const DEFAULT_MAX_AGE = 60 * 60 * 24;

export const JmtMigrationCloseCookie: CookieValue<{shown: boolean; maxAge?: number}> = {
  type: CookieType.FUNCTIONAL,
  key: COOKIE_NAME,
  decode: (encodedString) => ({shown: falseDecoder(encodedString)}),
  encode: ({shown}) => falseEncoder(shown),
  getOptions: (value) => ({
    maxAge: value?.maxAge || DEFAULT_MAX_AGE,
  }),
};

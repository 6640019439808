import {routeAsync} from 'helpers/asyncConnect';
import {ComponentType} from 'react';

import {anonymous} from './decorators';
import {DefaultParams, PoorRoute} from './types';

const NAME = 'notfound';

export const NotFoundRoute: PoorRoute<typeof NAME, DefaultParams> = {
  name: NAME,
  path: '',
  exact: false,
  strict: false,
  async: routeAsync(
    /* #__LOADABLE__ */ () => import('containers/pages/NotFoundPage'),
    (module) => module.NotFoundPage as ComponentType,
  ),
  decorator: anonymous,
};

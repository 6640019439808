import config from 'config';
import {TransportHeaders} from 'types/Transport';

export const JOOM_SET_ACCESS_TOKEN_HEADER = 'joom-set-access-token';
export const JOOM_SET_REFRESH_TOKEN_HEADER = 'joom-set-refresh-token';
export const JOOM_SET_TRACKING_TOKEN_HEADER = 'joom-set-tracking-token';

const RESET_TOKEN = 'reset';
const HIDE_HEADER_REGEX = /^(.{8}).+(.{8})$/;

type TokenHeaders = TransportHeaders & {
  [JOOM_SET_ACCESS_TOKEN_HEADER]?: string;
  [JOOM_SET_REFRESH_TOKEN_HEADER]?: string;
  [JOOM_SET_TRACKING_TOKEN_HEADER]?: string;
};

type Tokens = {
  accessToken?: string;
  refreshToken?: string;
  trackingToken?: string;
};

function transformHeader(value?: string): string | undefined {
  return value === RESET_TOKEN ? '' : value;
}

function hideHeader(value: string): string {
  if (config.releaseStage === 'prod') {
    return value.replace(HIDE_HEADER_REGEX, '$1...$2');
  }

  return value;
}

export function getAuthTokensFromHeaders(headers: TokenHeaders): Tokens {
  return {
    accessToken: transformHeader(headers[JOOM_SET_ACCESS_TOKEN_HEADER]),
    refreshToken: transformHeader(headers[JOOM_SET_REFRESH_TOKEN_HEADER]),
    trackingToken: transformHeader(headers[JOOM_SET_TRACKING_TOKEN_HEADER]),
  };
}

export function hideAuthTokensInHeaders(headers: TokenHeaders): TokenHeaders {
  return [
    JOOM_SET_ACCESS_TOKEN_HEADER,
    JOOM_SET_REFRESH_TOKEN_HEADER,
    JOOM_SET_TRACKING_TOKEN_HEADER,
  ].reduce((nextHeaders, name) => {
    const value = nextHeaders[name];

    if (value) {
      return {
        ...nextHeaders,
        [name]: hideHeader(String(value)),
      };
    }

    return nextHeaders;
  }, headers);
}

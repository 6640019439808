import type {EventParams} from 'types/EventParams';
import {ImageBundle} from 'types/Image';

import {ContentListBaseItem, contentListBaseItem} from './ContentListBaseItem';

type ProductStore = {
  productStore: {
    id: string;
    name: string;
    rating: number;
    image: ImageBundle;
    subtitle: string;
    eventParams: EventParams;
  };
};

export type ContentListProductStore = ContentListBaseItem<ProductStore>;

export function contentListProductStore(
  id: string,
  productStore: ProductStore,
): ContentListProductStore {
  return contentListBaseItem(id, productStore);
}

export function isContentListProductStore(
  item: ContentListBaseItem,
): item is ContentListProductStore {
  return Boolean((item as ContentListProductStore).content.productStore);
}

import {ImageBundle} from 'types/Image';
import {Text} from 'types/Layout';

import {ContentListBaseItem, contentListBaseItem} from './ContentListBaseItem';

export type TermsItem = {
  icon?: ImageBundle;
  title?: Text;
  text: Text;
};

export type ContentListTermsItem = ContentListBaseItem<{termsItem: TermsItem}>;

export function contentListTermsItem(id: string, termsItem: TermsItem): ContentListTermsItem {
  return contentListBaseItem(id, {termsItem});
}

export function isContentListTermsItem(item: ContentListBaseItem): item is ContentListTermsItem {
  return Boolean((item as ContentListTermsItem).content.termsItem);
}

import config from 'config';
import {TransportHeaders} from 'types/Transport';

export const AUTHORIZATION_HEADER = 'Authorization';

type AuthorizationHeaders = TransportHeaders & {
  [AUTHORIZATION_HEADER]?: string;
};

const HIDE_HEADER_REGEX = /^(Bearer\s+.{8}).+(.{8})$/;

export function parseAuthorizationHeader(header?: string): string | null {
  if (header) {
    const parts = header.split(' ');
    if (parts.length === 2 || parts[0] === 'Bearer' || parts[1]) {
      return parts[1]!;
    }
  }
  return null;
}

export function createAuthorizationHeader(token: string): string {
  return `Bearer ${token}`;
}

export function hideAuthorizationToken(headerValue: string): string {
  if (config.releaseStage === 'prod') {
    return headerValue.replace(HIDE_HEADER_REGEX, '$1...$2');
  }
  return headerValue;
}

export function hideAuthorizationTokenInHeaders(
  headers: AuthorizationHeaders,
): AuthorizationHeaders {
  const value = headers[AUTHORIZATION_HEADER];

  if (value) {
    return {
      ...headers,
      [AUTHORIZATION_HEADER]: hideAuthorizationToken(value),
    };
  }

  return headers;
}

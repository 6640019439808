import {isWebkit, parseUserAgent} from 'helpers/userAgent';
import kebabCase from 'lodash/kebabCase';
import {TypedObject} from 'utils/object/typed';

import {Primitive} from './guards';
import {memoize} from './memoize';
import {getViewportHeight, getViewportWidth} from './viewport';

export function isCss1CompatMode(doc: Document): boolean {
  return doc.compatMode === 'CSS1Compat';
}

export function getPageOffsetTop(element: HTMLElement): number {
  let offset = element.offsetTop || 0;
  let current = element;
  while (current && current.offsetParent) {
    current = current.offsetParent as HTMLElement;
    offset += current.offsetTop || 0;
  }
  return offset;
}

export function getDocumentScrollElement(doc?: Document): HTMLElement | null {
  if (__SERVER__) {
    return null;
  }

  const document = doc || window.document;

  if (document.scrollingElement) {
    return document.scrollingElement as HTMLElement;
  }

  if (!isWebkit(parseUserAgent(window.navigator.userAgent)) && isCss1CompatMode(document)) {
    return document.documentElement;
  }

  return document.body || document.documentElement;
}

export function createInput(name: string, type: string, value: string): HTMLInputElement {
  const input = window.document.createElement('input');
  input.setAttribute('type', type);
  input.setAttribute('name', name);
  input.setAttribute('value', value);
  return input;
}

export function sendForm(method: string, action: string, data = {}, target = '_self'): void {
  const form = window.document.createElement('form');
  form.setAttribute('method', method);
  form.setAttribute('action', action);
  form.setAttribute('target', target);

  TypedObject.keys(data || {}).forEach((key) => {
    const value = data[key] as string | string[];
    if (Array.isArray(value)) {
      value.forEach((val) => form.appendChild(createInput(`${key}[]`, 'hidden', val)));
    } else {
      form.appendChild(createInput(key, 'hidden', value));
    }
  });

  window.document.body.appendChild(form);
  form.submit();
}

export function externalRedirect(method: string, url: string, body = {}): void {
  if (method.toLowerCase() === 'get') {
    window.location.href = url;
  } else {
    sendForm(method, url, body);
  }
}

export function insertScript(attributes?: Partial<HTMLScriptElement>): HTMLScriptElement {
  const script = document.createElement('script');

  script.type = 'text/javascript';
  script.async = true;

  if (attributes) {
    TypedObject.keys(attributes).forEach((name) =>
      script.setAttribute(name, String(attributes[name])),
    );
  }

  document.body.appendChild(script);

  return script;
}

export type ScriptAttributes = Partial<Pick<HTMLScriptElement, 'type' | 'async' | 'defer'>>;

export function loadAsyncScript(src: string, attributes?: ScriptAttributes): Promise<void> {
  if (__SERVER__) {
    return Promise.reject(new Error('Can not load script on server'));
  }
  return new Promise((resolve, reject) => {
    try {
      const script = insertScript({
        ...attributes,
        src,
      });

      script.addEventListener('load', () => setTimeout(resolve, 0));
      script.addEventListener('error', reject);
    } catch (ex) {
      reject(ex);
    }
  });
}

export const isPassiveSupported = memoize((): boolean => {
  let passiveSupported = false;
  if (!__SERVER__) {
    try {
      const options = Object.defineProperty({}, 'passive', {
        // eslint-disable-next-line getter-return
        get: () => {
          passiveSupported = true;
        },
      });
      const noop = () => {};

      window.addEventListener('test', noop, options);
      window.removeEventListener('test', noop, options);
    } catch (err) {
      passiveSupported = false;
    }
  }

  return passiveSupported;
});

export const getPassiveEventOptions = memoize(function getPassiveEventOptions() {
  return isPassiveSupported() ? {passive: true} : false;
});

export function isElementInViewport(element: Element): boolean {
  if (!element) {
    return false;
  }
  const {top, left, bottom, right} = element.getBoundingClientRect();
  return top >= 0 && left >= 0 && bottom <= getViewportHeight() && right <= getViewportWidth();
}

export function isElementOutOfViewport(element: Element): boolean {
  const {top, left, bottom, right} = element.getBoundingClientRect();
  return bottom < 0 || right < 0 || top > getViewportHeight() || left > getViewportWidth();
}

export function isElementXPercentInViewport(element: Element, percentVisible: number): boolean {
  const {height, bottom} = element.getBoundingClientRect();

  return 1 - percentVisible / 100 > (bottom - getViewportHeight()) / height;
}

export function isElementVisible(element?: HTMLElement | null): boolean {
  return Boolean(
    element && (element.offsetWidth || element.offsetHeight || element.getClientRects().length),
  );
}

export function getScrollableParent(element: Element, doc?: Document): Element | null {
  let node = element?.parentElement;

  while (node) {
    const {overflow} = getComputedStyle(node);
    if (overflow === 'auto' || overflow === 'scroll') {
      return node;
    }

    node = node.parentElement;
  }

  return getDocumentScrollElement(doc);
}

export function getDataAttributes(attributes: Record<string, Primitive>): Record<string, string> {
  return Object.entries(attributes).reduce(
    (acc, [key, value]) => {
      const kebabCaseKey = kebabCase(key);

      acc[`data-${kebabCaseKey}`] = String(value);

      return acc;
    },
    {} as Record<string, string>,
  );
}

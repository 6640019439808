import noop from 'lodash/noop';
import {Image as ImageType, ImageBundle} from 'types/Image';

export function imageByMaxSide(images?: ImageBundle, maxSide = 1000): ImageType | undefined {
  if (!images || !images.images || images.images.length === 0) {
    return undefined;
  }

  const result = images.images.find((image) => Math.max(image.width, image.height) >= maxSide);

  return result || images.images[images.images.length - 1];
}

export function scrSetByMaxViewportSide(images?: ImageBundle, ratio = 1): string | undefined {
  if (!images || !images.images || images.images.length < 2) {
    return undefined;
  }

  return images.images.map((image) => `${image.url} ${Math.round(image.width / ratio)}w`).join(',');
}

export function srcByMaxSide(images?: ImageBundle, maxSide?: number): string {
  const image = imageByMaxSide(images, maxSide);

  return image ? image.url : '';
}

export function getLargestImageSrc(bundle: ImageBundle): string | undefined {
  const image = bundle.images.reduce<ImageType | undefined>((acc, image) => {
    if (!acc || acc.width < image.width) {
      return image;
    }

    return acc;
  }, undefined);

  if (image) {
    return image.url;
  }

  return undefined;
}

export function peekSrc(images?: ImageBundle): string | undefined {
  if (!images || !images.images || !images.images.length) {
    return undefined;
  }

  return images.images[0]?.url || '';
}

export function getImageKey(images?: ImageBundle): string | undefined {
  if (!images || !images.images || !images.images.length) {
    return undefined;
  }

  return images.images[0]?.url;
}

type ImageDimensions = {
  width: number;
  height: number;
};

export async function getImageDimensions(imageSrc: string | File): Promise<ImageDimensions | null> {
  const imagePlaceholder = new Image();

  let objectUrl = '';

  const eventPromise = new Promise<ImageDimensions>((resolve, reject) => {
    imagePlaceholder.addEventListener('load', () => {
      const {width, height} = imagePlaceholder;

      resolve({width, height});
    });
    imagePlaceholder.addEventListener('error', () => {
      reject();
    });
  }).catch(noop);

  if (typeof imageSrc === 'string') {
    imagePlaceholder.src = imageSrc;
  } else {
    objectUrl = URL.createObjectURL(imageSrc);
    imagePlaceholder.src = objectUrl;
  }

  const result = await eventPromise;

  if (objectUrl) {
    URL.revokeObjectURL(objectUrl);
  }

  return result || null;
}

export async function getImageAspectRatio(imageSrc: string | File): Promise<number | null> {
  const dimensions = await getImageDimensions(imageSrc);

  if (!dimensions) {
    return null;
  }

  return dimensions.width / dimensions.height;
}

export function getImageBundleAspectRatio(images?: ImageBundle): number {
  const image = imageByMaxSide(images);
  return image ? image.width / image.height : 0;
}

type ImageOpenGraphTags = {
  'og:image': string;
  'og:image:width': string;
  'og:image:height': string;
};

export function getImageOpenGraphTags(images?: ImageBundle): ImageOpenGraphTags | undefined {
  const image = imageByMaxSide(images);
  if (!image) {
    return undefined;
  }
  return {
    'og:image': image.url,
    'og:image:width': String(image.width),
    'og:image:height': String(image.height),
  };
}

import {resolveWithDelay} from 'utils/promise';

import {ApiResponse} from './Response';

const MAX_RETRIES = __SERVER__ ? 1 : 3;
const DELAY = __SERVER__ ? 100 : 5000;

export function needToRetry<TBody>(response: ApiResponse<TBody>, retryNumber: number): boolean {
  if (MAX_RETRIES < retryNumber) {
    return false;
  }

  return (
    // network error or internal error
    response.status === 0 ||
    // 502 Bad Gateway
    // 503 Service Unavailable
    // 504 Gateway Timeout
    (response.status >= 502 && response.status <= 504) ||
    // 599 Network Connect Timeout Error
    response.status === 599
  );
}

export function retryDelay(retryNumber: number): Promise<void> {
  return resolveWithDelay(retryNumber * DELAY);
}

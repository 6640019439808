import config from 'config';
import React, {ReactElement} from 'react';

export const getLocatorName = (key: string): string => {
  return key === 'id' ? 'data-testid' : `data-test-${key}`;
};

const convertObjectToDataTestingProps = (
  dict: Record<string, unknown>,
): Record<string, unknown> => {
  const keys = Object.keys(dict);
  const result: Record<string, unknown> = {};

  keys.forEach((key) => {
    const value = dict[key];

    if (value != null) {
      const attr = getLocatorName(key);
      result[attr] = typeof value === 'string' ? value : JSON.stringify(value);
    }
  });

  return result;
};

type PropTypes = {
  children: ReactElement | null;
  id?: string;
  [x: string]: unknown;
};

export const ProdLocator = React.forwardRef(({children}: PropTypes, ref): ReactElement | null => {
  if (!children) {
    return null;
  }

  if (!ref) {
    return children;
  }

  return React.cloneElement(children, {ref});
});

export const DevLocator = React.forwardRef(
  ({children, ...restProps}: PropTypes, ref): ReactElement | null => {
    if (!children) {
      return null;
    }

    const locatorProps = convertObjectToDataTestingProps(restProps);
    if (ref) {
      locatorProps.ref = ref;
    }

    return React.cloneElement(children, locatorProps);
  },
);

DevLocator.displayName = 'Locator';

export const Locator = config.releaseStage === 'prod' ? ProdLocator : DevLocator;

import EventEmitter from 'component-emitter';
import {useConst} from 'hooks/useConst';
import React, {
  ComponentType,
  createContext,
  FC,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {Falsy} from 'types/utility';
import {assert} from 'utils/asserts';
import {isNumber} from 'utils/guards';
import {nextTick} from 'utils/nextTick';

import {ModalOverlay} from '../ModalOverlay';
import {PopupUserActions} from './types';

export type PopupViewProps = {
  action: 'init' | 'open' | 'back' | 'close';
  onBack?: () => void;
  onClose: () => void;
};

export type PopupViewRender<T extends ReactNode = ReactNode> = (props: PopupViewProps) => T;

export type PopupViewExtended<T extends ReactNode = ReactNode> = PopupUserActions & {
  render: PopupViewRender<T>;
  withoutOverlay?: boolean;
  priority?: number;
  closeDisabled?: boolean;
};

export type PopupView<T extends ReactNode = ReactNode> = PopupViewRender<T> | PopupViewExtended<T>;

export type PopupClose = () => void;
export type RemoveFromQueueCallback = () => void;

export type PopupManagerContextType = {
  // Shows popup. If you already have rendered popup, your popup will have back button.
  open(renderView: PopupView): PopupClose;
  // Same as open, but this function replaces rendered popup.
  replace(renderView: PopupView): PopupClose;
  // Update popup in stack without changing position. Useful for rerenders.
  update(prevView: PopupView, nextView: PopupView): PopupClose;
  // Closes all opened popups. Call it only on user action, beacuse this function
  // triggers onClose handler for rendered popup.
  close(): void;
  // Closes all opened popups without any `onClose` calls.
  clear(): void;
  // Closes rendered popup, calls `onBack` trigger.
  back(): void;
  // Adds popup to queue
  pushToQueue(view: PopupView): RemoveFromQueueCallback;
  // Removes popup from queue
  removeFromQueue(view: PopupView): void;
  // Updates popup in queue
  updateInQueue(prevView: PopupView, nextView: PopupView): RemoveFromQueueCallback;

  events: EventEmitter<{
    visibilitychange: [isShown: boolean];
  }>;
};

export const POPUPS_PRIORITY = {
  JMT_MIGRATION_POPUP: 300,
  DIALOG_POPUP: 200,
  SPLASH_BANNER: 100,
  LOCATION_POPUP: 90,
  OPEN_CART_POPUP: 85,
  OPEN_POINTS_POPUP: 85,
  OPEN_APP_POPUP: 80,
  COOKIES_SETTINGS: 70,
  ONBOARDING_POPUP: 60,
  EMAIL_PROMO: 50,
};

export const PopupManagerContext = createContext<PopupManagerContextType | void>(undefined);
export const PopupStackContext = createContext<PopupViewExtended[]>([]);
export const PopupShownContext = createContext<boolean>(false);

type ProviderProps = {
  children: ReactNode;
  DeepLinksManager: FC<{children: ReactNode}>;
};

export type PopupType = {
  // Show popup, put it in popup stack. Returns function
  // which remove this popup from popup stack.
  open: () => PopupClose;
  // Show popup, replace already rendered popup. Returns function
  // which remove this popup from popup stack.
  replace: () => PopupClose;
  close: PopupClose;
};

function extendPopupView<T extends ReactNode>(popupView: PopupView<T>): PopupViewExtended<T> {
  if ('render' in popupView) {
    return popupView;
  }
  return {render: popupView};
}

function getPopupViewRender<T extends ReactNode>(popupView: PopupView<T>): PopupViewRender<T> {
  if ('render' in popupView) {
    return popupView.render;
  }
  return popupView;
}

function getPopupViewPriority(popupView: PopupView): number {
  if ('priority' in popupView && isNumber(popupView.priority)) {
    return popupView.priority;
  }

  return 0;
}

function comparePopupViewsByPriority(popupViewA: PopupView, popupViewB: PopupView): number {
  return getPopupViewPriority(popupViewB) - getPopupViewPriority(popupViewA);
}

export function isSamePopupView<T extends ReactNode>(a?: PopupView<T>, b?: PopupView<T>): boolean {
  return (a && getPopupViewRender(a)) === (b && getPopupViewRender(b));
}

export function usePopupManager(): PopupManagerContextType {
  const context = useContext(PopupManagerContext);
  assert(context, 'Popup context is not defined');
  return context;
}

export function withPopupManager<P extends {popupManager: PopupManagerContextType}>(
  InnerComponent: ComponentType<P>,
): FC<P> {
  const WithPopup: FC<P> = (props) => {
    const popupManager = usePopupManager();

    return <InnerComponent {...props} popupManager={popupManager} />;
  };

  return WithPopup;
}

export function usePopupsShown(): boolean {
  return useContext(PopupShownContext);
}

export function usePopupShown(view?: PopupView): boolean {
  const stack = useContext(PopupStackContext);
  return isSamePopupView(view, stack[stack.length - 1]);
}

export function usePopupInStack(view?: PopupView): boolean {
  const stack = useContext(PopupStackContext);
  return useMemo(
    () => (view && stack.some((item) => isSamePopupView(item, view))) || false,
    [stack, view],
  );
}

export function usePopup<T extends PopupView>(view: T, openOnMount = false): PopupType {
  const closeRef = useRef<PopupClose>();
  const viewRef = useRef(view);
  const popupManager = usePopupManager();
  const extendedView = extendPopupView(view);

  const close = useCallback(() => {
    closeRef.current?.();
  }, [closeRef]);

  // render popup on mount
  useEffect(() => {
    if (openOnMount) {
      closeRef.current = popupManager.open(extendedView);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (viewRef.current && !isSamePopupView(view, viewRef.current)) {
      closeRef.current = popupManager.update(viewRef.current, view);
    }
    viewRef.current = view;
  }, [closeRef, popupManager, viewRef, view]);

  return useMemo(
    () => ({
      open: () => {
        closeRef.current = popupManager.open(viewRef.current);
        return close;
      },
      replace: () => {
        closeRef.current = popupManager.replace(viewRef.current);
        return close;
      },
      close,
    }),
    [popupManager, viewRef, close],
  );
}

export function usePopupInQueue(view: PopupView | Falsy): RemoveFromQueueCallback {
  const popupManager = usePopupManager();
  const closeRef = useRef<RemoveFromQueueCallback>();
  const viewRef = useRef<PopupView>();

  useEffect(() => {
    const prevView = viewRef.current;

    if (!prevView && view) {
      closeRef.current = popupManager.pushToQueue(view);
    } else if (prevView && !view) {
      closeRef.current?.();
    } else if (prevView && view && !isSamePopupView(prevView, view)) {
      closeRef.current = popupManager.updateInQueue(prevView, view);
    }

    viewRef.current = view || undefined;
  }, [popupManager, view]);

  return useCallback(() => {
    if (closeRef.current) {
      closeRef.current();
    }
  }, []);
}

function processBack(extendedView: PopupViewExtended): void {
  // There is some tricky logic for design purpose.
  // If we have `onBack` trigger, then just call it.
  // But if we have only `onClose` we have to call `onClose`
  // to let programmer do not think about "back button" cases.
  if (extendedView.onBack) {
    extendedView.onBack();
  } else {
    extendedView.onClose?.();
  }
}

export function PopupProvider({children, DeepLinksManager}: ProviderProps): JSX.Element {
  const [action, setAction] = useState<PopupViewProps['action']>('init');
  const [stack, setStack] = useState<PopupViewExtended[]>([]);
  const [queue, setQueue] = useState<PopupView[]>([]);
  const stackBusy = useRef(false);

  const updateStack = useCallback((newStack: SetStateAction<typeof stack>) => {
    setStack(newStack);
    stackBusy.current = false;
  }, []);

  const events = useConst((): PopupManagerContextType['events'] => new EventEmitter());
  const handleBack = useCallback(() => {
    nextTick(() => {
      updateStack((prev) => {
        if (prev.length) {
          const next = prev.slice(0, prev.length - 1);
          const view = prev[prev.length - 1]!;
          if (next.length) {
            setAction('back');
            processBack(view);
          } else {
            setAction('close');
            view.onClose?.();
          }
          return next;
        }
        return prev;
      });
    });
  }, [updateStack]);
  const handleClose = useCallback(() => {
    nextTick(() => {
      updateStack((prev) => {
        const view = prev[prev.length - 1];
        if (view) {
          setAction('close');
          view.onClose?.();
          return [];
        }
        return prev;
      });
    });
  }, [updateStack]);
  const handleClear = useCallback(() => {
    setAction('init');
    updateStack([]);
  }, [updateStack]);
  const handleRemove = useCallback(
    (view: PopupView) => {
      nextTick(() => {
        updateStack((prev) => {
          const next = prev.filter((item) => !isSamePopupView(item, view));
          if (prev.length === next.length) {
            return prev;
          }
          const renderedView = prev[prev.length - 1]!;
          if (isSamePopupView(renderedView, view)) {
            // we want to remove rendered popup
            if (next.length > 1) {
              setAction('back');
              processBack(renderedView);
            } else {
              setAction('close');
              renderedView.onClose?.();
            }
          }
          return next;
        });
      });
    },
    [updateStack],
  );
  const open = useCallback<PopupManagerContextType['open']>(
    (view) => {
      nextTick(() => {
        setAction('open');
        updateStack((prev) => {
          if (prev[prev.length - 1] === view) {
            return prev;
          }

          return [...prev, extendPopupView(view)];
        });
      });

      return () => handleRemove(view);
    },
    [handleRemove, updateStack],
  );
  const replace = useCallback<PopupManagerContextType['replace']>(
    (view) => {
      nextTick(() => {
        setAction('open');
        updateStack((prev) => [...prev.slice(0, prev.length - 1), extendPopupView(view)]);
      });
      return () => handleRemove(view);
    },
    [handleRemove, updateStack],
  );
  const update = useCallback<PopupManagerContextType['update']>(
    (prevView: PopupView, nextView: PopupView) => {
      nextTick(() => {
        updateStack((prev) => {
          const index = prev.findIndex((item) => isSamePopupView(item, prevView));
          if (index !== -1) {
            return [...prev.slice(0, index), extendPopupView(nextView), ...prev.slice(index + 1)];
          }
          return prev;
        });
      });
      return () => handleRemove(nextView);
    },
    [handleRemove, updateStack],
  );
  const removeFromQueue = useCallback<PopupManagerContextType['removeFromQueue']>((view) => {
    setQueue((prevQueue) => {
      const nextQueue = prevQueue.filter((item) => !isSamePopupView(item, view));

      if (prevQueue.length === nextQueue.length) {
        return prevQueue;
      }

      return nextQueue;
    });
  }, []);
  const pushToQueue = useCallback<PopupManagerContextType['pushToQueue']>(
    (view) => {
      setQueue((prevQueue) => {
        return [...prevQueue, view].sort(comparePopupViewsByPriority);
      });

      return () => removeFromQueue(view);
    },
    [removeFromQueue],
  );
  const updateInQueue = useCallback<PopupManagerContextType['updateInQueue']>(
    (prevView, nextView) => {
      setQueue((prevQueue) => {
        const index = prevQueue.findIndex((item) => isSamePopupView(item, prevView));
        const nextQueue = prevQueue.slice();

        if (index < 0) {
          nextQueue.push(nextView);
        } else {
          nextQueue.splice(index, 1, nextView);
        }

        if (getPopupViewPriority(prevView) !== getPopupViewPriority(nextView) || index < 0) {
          return nextQueue.sort(comparePopupViewsByPriority);
        }

        return nextQueue;
      });

      return () => removeFromQueue(nextView);
    },
    [removeFromQueue],
  );
  const context = useMemo(
    () => ({
      open,
      pushToQueue,
      removeFromQueue,
      replace,
      update,
      updateInQueue,
      back: handleBack,
      close: handleClose,
      clear: handleClear,
      events,
    }),
    [
      open,
      pushToQueue,
      removeFromQueue,
      replace,
      update,
      updateInQueue,
      handleBack,
      handleClose,
      handleClear,
      events,
    ],
  );

  useEffect(() => {
    if (stack.length === 0 && !stackBusy.current) {
      setQueue((prevQueue) => {
        if (queue.length === 0) {
          return prevQueue;
        }

        const [next, ...nextQueue] = prevQueue;

        stackBusy.current = true;
        open(next!);

        return nextQueue;
      });
    }
  }, [open, stack, queue]);

  const view = stack[stack.length - 1];
  const viewContent = view?.render({
    action,
    onBack: stack.length > 1 ? handleBack : undefined,
    onClose: handleClose,
  });
  const popupContent = view?.withoutOverlay
    ? viewContent
    : viewContent && (
        <ModalOverlay onClose={handleClose} closeDisabled={view?.closeDisabled}>
          {viewContent}
        </ModalOverlay>
      );

  // this is done to restore focus on popup stack clearing;
  // focus to the popup is set by FocusOn autoFocus
  const savedFocusRef = useRef<HTMLElement | null>();

  useEffect(() => {
    if (stack.length > 0 && !savedFocusRef.current) {
      savedFocusRef.current = document.activeElement as HTMLElement;
    }

    if (stack.length === 0 && savedFocusRef.current) {
      // > Trap will be still active by the time you may want move(return) focus on componentWillUnmount. Please deffer this action with a zero-timeout.
      // https://github.com/theKashey/react-focus-lock#unmounting-and-focus-management
      setTimeout(() => {
        savedFocusRef.current?.focus();
        savedFocusRef.current = null;
      }, 1);
    }
  }, [stack, queue]);

  const isShown = stack.length > 0;

  useEffect(() => {
    events.emit('visibilitychange', isShown);
  }, [events, isShown]);

  return (
    <PopupShownContext.Provider value={isShown}>
      <PopupStackContext.Provider value={stack}>
        <PopupManagerContext.Provider value={context}>
          <DeepLinksManager>
            {children}
            {popupContent}
          </DeepLinksManager>
        </PopupManagerContext.Provider>
      </PopupStackContext.Provider>
    </PopupShownContext.Provider>
  );
}

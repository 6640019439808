import {ContentListBaseItem, contentListBaseItem} from './ContentListBaseItem';
import {ProductCardGroup} from './ProductCartGroup';

export type ContentListProductCardGroup = ContentListBaseItem<{
  productCardGroup: ProductCardGroup;
}>;

export function contentListProductCardGroup(
  id: string,
  productCardGroup: ProductCardGroup,
): ContentListProductCardGroup {
  return contentListBaseItem(id, {productCardGroup});
}

export function isContentListProductCardGroup(
  item: ContentListBaseItem,
): item is ContentListProductCardGroup {
  return Boolean((item as ContentListProductCardGroup).content.productCardGroup);
}

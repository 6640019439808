import {LegalEntity, Scope} from './enums';
import {DomainScopeConfig} from './types';

export const domainScopeJoom: DomainScopeConfig = {
  id: 'joom',
  scope: Scope.GLOBAL,
  legalEntity: LegalEntity.JOOM,
  oppositeEntityId: 'jmt',
  prefixScopes: [
    {
      scope: Scope.GEEK,
      scopeHeader: 'joomGeek',
      pathPrefix: 'geek',
      disableMobileApps: true,
      hasOppositeEntity: true,
      companyInfo: {
        companyName: 'Joom Geek',
      },
    },
    {
      scope: Scope.COOLBE,
      scopeHeader: 'coolBe',
      pathPrefix: 'coolbe',
      disableMobileApps: true,
      hasOppositeEntity: false,
      companyInfo: {
        companyName: 'Coolbe',
        supportEmail: 'coolbe-support@joom.com',
      },
    },
  ],

  defaultLanguage: 'en',
  defaultCurrency: 'EUR',
  hostname: 'www.joom.com',

  companyInfo: {
    companyName: 'Joom',
    supportEmail: 'support@joom.com',
    copyrightEmail: 'ipr@joom.com',
  },

  static: {
    folder: 'joom',
    swFileName: 'swJoom',
  },

  integrations: {
    oauthEnvPrefix: '',
    fb: {
      appId: '788956724543695',
      pixelId: '494083217600896',
    },
    firebase: {
      hostname: 'br2m3.app.goo.gl',
      config: {
        apiKey: 'AIzaSyDFyqBoiWhIkHqiFO57s-FIKM6bG3bSdhA',
        authDomain: 'aga-1252.firebaseapp.com',
        databaseURL: 'https://aga-1252.firebaseio.com',
        projectId: 'aga-1252',
        storageBucket: 'aga-1252.appspot.com',
        messagingSenderId: '184244223836',
        appId: '1:184244223836:web:082fb5086458e36cf18b99',
        measurementId: 'G-VFXXSRQ03X',
      },
      vapidKey:
        'BInDFUefoVPmWCKURlrPdVjAVS1ZUWWl2SzsSdhELAgeag-Z7AYWVbxtHpjuSLenIqyne8tkTZjABFQjZQ57r00',
    },
    googleMapsKey: 'AIzaSyDRcSSjEEptSWiK9Sy_8qOuAr_krg0sIuI',
    gtmId: 'GTM-57VG9N7',
  },

  mobileApps: {
    android: {
      id: 'com.joom',
      name: 'Joom',
      scheme: 'joom',
      url: 'https://play.google.com/store/apps/details?id=com.joom',
    },
    ios: {
      id: '1117424833',
      bundleId: 'com.joom.joom',
      url: 'https://apps.apple.com/app/id1117424833',
    },
    huawei: {
      id: 'C101182031',
      url: 'https://appgallery.huawei.com/#/app/C101182031',
    },
  },
};

import {routeAsync} from 'helpers/asyncConnect';

import {DefaultParams, PoorRoute} from './types';

type Params = DefaultParams & {
  parcelId: string;
  orderGroupId?: string;
};

const NAME = 'vatByParcel';

export const VatByParcelRoute: PoorRoute<typeof NAME, Params> = {
  name: NAME,
  path: '/orders/:parcelId/vat/:orderGroupId?',
  async: routeAsync(
    /* #__LOADABLE__ */ () => import('containers/pages/VatPage'),
    (module) => module.VatPage,
  ),
};

import classnames from 'classnames/bind';
import React from 'react';

import styles from './index.scss';

const cn = classnames.bind(styles);

type Size = 'small' | 'medium';
type Theme = 'default' | 'primary' | 'accent' | 'white';

type Props = {
  size?: Size;
  theme?: Theme;
};

export function Loader({size, theme}: Props): JSX.Element {
  return (
    <div
      className={cn('loader', {
        [`size-${size}`]: Boolean(size),
        [`theme-${theme}`]: Boolean(theme),
      })}
    />
  );
}

import {isMobileOrTabletDevice} from 'helpers/userAgent';
import {UserAgentContext} from 'providers/UserAgentContext';
import {useContext, useMemo} from 'react';
import {UserAgent} from 'types/UserAgent';

export function useUserAgent(): UserAgent {
  const ua = useContext(UserAgentContext);

  if (!ua) {
    throw new Error('UserAgent must be defined');
  }

  return ua;
}

export function useIsMobileOrTablet(): boolean {
  const ua = useUserAgent();

  return useMemo(() => isMobileOrTabletDevice(ua), [ua]);
}

import {AnyAction} from 'redux';
import {Reducer} from 'typesafe-actions';

export const DISCARD_USER_RELEATED_DATA = 'discard/USER_RELEATED_DATA';
export const DISCARD_PAYMENT_RELEATED_DATA = 'discard/PAYMENT_RELEATED_DATA';

export function discardableBy<State, Action extends AnyAction>(
  actionTypes: string[],
  reducer: Reducer<State, Action>,
): Reducer<State, Action> {
  return function wrappedReducer(state: State | undefined, action: Action): State {
    if (action && actionTypes.indexOf(action.type) !== -1) {
      // init like
      return reducer(undefined, {} as Action);
    }
    return reducer(state, action);
  };
}

export function discardableByUser<State, Action extends AnyAction>(
  reducer: Reducer<State, Action>,
): Reducer<State, Action> {
  return discardableBy([DISCARD_USER_RELEATED_DATA], reducer);
}

export function discardableByPayment<State, Action extends AnyAction>(
  reducer: Reducer<State, Action>,
): Reducer<State, Action> {
  return discardableBy([DISCARD_PAYMENT_RELEATED_DATA], reducer);
}

export function discardableByAny<State, Action extends AnyAction>(
  reducer: Reducer<State, Action>,
): Reducer<State, Action> {
  return discardableBy([DISCARD_USER_RELEATED_DATA, DISCARD_PAYMENT_RELEATED_DATA], reducer);
}

/* eslint-disable no-underscore-dangle */
import 'utils/safeStorages';
import './index.scss';

import {getCurrentHub} from '@sentry/core';
import {BrowserClient} from '@sentry/react';
import {protectHistoryScope} from 'helpers/ApiClient/Scope/protectHistoryScope';
import {globalLog, jsonStringToLevelConfig, Level} from 'helpers/log';
import {ConsoleAppender} from 'helpers/log/appenders/Console';
import {progressDone, progressStart} from 'helpers/nprogress';
import {setWebpackPublicPath} from 'helpers/publicPath';
import {initClientSentry} from 'helpers/sentry/initClient';
import {setRequestSentryTags, setUserSentryTags} from 'helpers/sentry/setTags';
import {setServerTimeDifference} from 'helpers/serverTime';
import {createBrowserHistory} from 'history';
import {unwrapErrorObject} from 'utils/error/unwrapErrorObject';
import {requireModule} from 'utils/loadable';
import {nextTickPromise} from 'utils/nextTick';

import {loadableChunk} from './loadableChunk';
import {reportCrash} from './reportError';

progressStart();

if ('scrollRestoration' in window.history) {
  window.history.scrollRestoration = 'manual';
}

async function main() {
  const history = createBrowserHistory();

  await nextTickPromise(() => initClientSentry({history}));

  const {ApiClient} = (await nextTickPromise(() =>
    // eslint-disable-next-line global-require
    require('helpers/ApiClient'),
  )) as typeof import('helpers/ApiClient');

  // divide all next code
  await nextTickPromise();

  const win = window as unknown as Record<string, unknown>;

  if (typeof win.__publicPath === 'string') {
    setWebpackPublicPath(win.__publicPath);
  }

  const client = new ApiClient();

  protectHistoryScope(client.scope, history);

  if (typeof win.__VERBOSITY__ === 'string') {
    const logConfig = jsonStringToLevelConfig(win.__VERBOSITY__) || {'*': Level.OFF};
    globalLog.addAppender(new ConsoleAppender(logConfig));
  }

  if (typeof win.__renderingConfig === 'object') {
    client.device.setRenderingConfig(
      win.__renderingConfig as import('types/Rendering').RenderingConfiguration,
    );
  }

  if (win.__appWebView) {
    client.device.enableWebView();
  }

  client.device.autoForceLanguage();
  client.tracking.enable();

  if (client.device.isBot()) {
    const sentryClientOptions = getCurrentHub().getClient<BrowserClient>()?.getOptions();
    if (sentryClientOptions) sentryClientOptions.enabled = false;
  }

  setRequestSentryTags(client);

  // do not set time difference for small diff, because ping
  if (typeof win.__serverTimeDifference === 'number' && win.__serverTimeDifference > 10000) {
    setServerTimeDifference(win.__serverTimeDifference);
  }

  const element = document.getElementById('content') as HTMLElement;
  if (!element) {
    throw new Error('Can not find #content element');
  }

  const state = win.__data as import('./main').AppOptions['state'];
  const eventsQueue = win.__eventsQueue as import('./main').AppOptions['eventsQueue'];

  // cleanup global scope for security reasons
  win.__data = undefined;
  win.__eventsQueue = undefined;

  const clientInitPromise = nextTickPromise(() =>
    client.init().catch((error) => {
      // Skip the hcaptcha error throwing and continue the render.
      if (!client.device.transports.hcaptcha.hcaptchaRequiredError) {
        throw error;
      }
    }),
  );

  setUserSentryTags(client);

  Promise.all([requireModule(loadableChunk), clientInitPromise])
    .then(([{createApp}]) =>
      nextTickPromise(() =>
        createApp({
          client,
          element,
          state,
          eventsQueue,
          history,
        }),
      ),
    )
    .catch((ex) => {
      const error = unwrapErrorObject(ex);
      const message = ('userMessage' in error ? error.userMessage : '') || error.message || '';

      // TODO(4u@): remove it after joom geek dogfooders tests
      const isScoped404 = Boolean('type' in error && error.type === 'scope/notfound');

      if (!isScoped404) {
        reportCrash(client, error);
        client.analytics.sendEvent(
          {
            type: 'crashOnInit',
            payload: {message},
          },
          {immediately: true},
        );
      }

      const divStyle = [
        'display: flex',
        'align-items: center',
        'justify-content: center',
        'line-height: 1.6',
        'max-width: 400px',
        'margin: 40px auto',
        'white-space: pre-wrap',
        'box-sizing: border-box',
        'padding: 20px',
        'text-align: center',
      ].join(';');

      const status = 'status' in error ? `Error ${error.status}` : 'Internal Error';
      const requestId = 'requestId' in error ? error.requestId : client.device.getClientRequestId();
      element.innerHTML = `
        <div style="${divStyle}">
          <div>
            <img width="100" height="auto" src="/logo.png"><br>
            <h1>${status}</h1>
            ${message ? `<br>${message}` : ''}
            <br><br><small>ID: ${requestId}</small>
          </div>
        </div>
      `;

      if (!isScoped404) {
        throw ex;
      }
    })
    .finally(() => {
      progressDone();

      const appLoader = document.getElementById('apploader');

      if (appLoader && appLoader.parentElement) {
        appLoader.parentElement.removeChild(appLoader);
      }
    });
}

main();

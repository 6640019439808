function createNextTick(): (callback: () => void) => void {
  if (__SERVER__) {
    return process.nextTick;
  }

  let id = 0;

  const NEXT_TICK_TYPE = '@@NEXT_TICK';

  // eslint-disable-next-line no-spaced-func,func-call-spacing
  const nextTickCallbackMap = new Map<string, () => void>();

  window.addEventListener('message', (event) => {
    if (
      (event.source === window || event.source === null) &&
      event.data &&
      event.data.type === NEXT_TICK_TYPE
    ) {
      event.stopPropagation();
      const callback = nextTickCallbackMap.get(event.data.key);

      if (callback) {
        nextTickCallbackMap.delete(event.data.key);
        callback();
      }
    }
  });

  return function nextTick(callback) {
    const key = `key-${id}`;
    id += 1;
    nextTickCallbackMap.set(key, callback);
    window.postMessage({type: NEXT_TICK_TYPE, key}, window.location.origin);
  };
}

// nextTick used for divide microtasks to different tasks
export const nextTick = createNextTick();

export function nextTickPromise<T = void>(callback?: () => T): Promise<Awaited<T>> {
  return new Promise<Awaited<T>>((resolve, reject) => {
    nextTick(async () => {
      try {
        resolve((await callback?.()) as Awaited<T>);
      } catch (error) {
        reject(error);
      }
    });
  });
}

import {RouteParams, SEARCH_POSTFIX} from 'utils/search/pattern';

import {StoreRoute} from './StoreRoute';
import {DefaultParams, PoorRoute} from './types';

type Params = DefaultParams & RouteParams;

const NAME = 'storeFeedback';

export const StoreFeedbackRoute: PoorRoute<typeof NAME, Params> = {
  ...StoreRoute,
  path: `/stores/:storeId/:feedback(feedback)${SEARCH_POSTFIX}`,
  name: NAME,
};

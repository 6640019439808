import cache from 'memory-cache';
import superagent from 'superagent';

export type EndpointsConfig = Array<{
  babyloneClientApi: string;
  babyloneClientFaqApi: string;
  babyloneClientFaqRestApi: string;
  babyloneClientFiles: string;
  baseApi: string;
  baseApiSecure: string;
  baseGrpcApi: string;
  basePayment: string;
  baseWeb: string;
  id: string;
  title: string;
  version: string;
}>;

const ENDPOINTS_CONFIG_API = 'https://config.joom.it/discovery_beta';

const CACHE_TIME_MS = 1000 * 60 * 15;

export async function loadEndpointsConfig(ignoreCache = false): Promise<EndpointsConfig> {
  let data = cache.get(ENDPOINTS_CONFIG_API) as EndpointsConfig;
  if (ignoreCache || !data) {
    if (__CLIENT__) {
      const response = await superagent.get('/dev/config-discovery');
      if (response.ok) {
        data = response.body as EndpointsConfig;
        cache.put(ENDPOINTS_CONFIG_API, data, CACHE_TIME_MS);
      }
    } else {
      // endpoints API returns `plain/text` res type
      const response = await superagent.get(ENDPOINTS_CONFIG_API);
      if (response.ok) {
        data = JSON.parse(response.text) as EndpointsConfig;
        cache.put(ENDPOINTS_CONFIG_API, data, CACHE_TIME_MS);
      }
    }
  }

  return data;
}

export type ExtendedErrorObject =
  | import('helpers/ApiClient/Transport/Response').ApiResponse<unknown>
  | import('types/Error').Error;

export function unwrapErrorObject(error: ExtendedErrorObject): import('types/Error').Error {
  if ('type' in error) {
    return error;
  }

  if ('error' in error && error.error) {
    return error.error;
  }

  return error as import('types/Error').Error;
}

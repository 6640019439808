import {ScopeConfig} from 'helpers/ApiClient/Scope/ScopeConfig';
import {requestIdleCallback} from 'utils/requestIdleCallback';

const WAIT_TIME_MS = 10000;

async function createLoadPromise(
  scope: ScopeConfig,
): Promise<typeof import('firebase').default | undefined> {
  if (!scope.integrations.firebase) {
    return undefined;
  }

  if (__CLIENT__) {
    await new Promise((resolve) => {
      requestIdleCallback(resolve, {timeout: WAIT_TIME_MS});
    });
  }
  const [{default: firebase}] = await Promise.all([
    import('firebase/app'),
    import('firebase/messaging'),
    import('firebase/performance'),
  ]);

  firebase.initializeApp(scope.integrations.firebase.config);

  return firebase;
}

let loadPromise: ReturnType<typeof createLoadPromise>;

export function loadFirebase(
  scope: ScopeConfig,
): Promise<typeof import('firebase').default | undefined> {
  if (!loadPromise) {
    loadPromise = createLoadPromise(scope);
  }

  return loadPromise;
}

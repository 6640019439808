export type Primitive = boolean | string | number;

export function isRecord<K extends string, V = unknown>(record: unknown): record is Record<K, V> {
  return Boolean(record && typeof record === 'object');
}

export function isNumber(value: unknown): value is number {
  return typeof value === 'number';
}

export function isString(value: unknown): value is string {
  return typeof value === 'string';
}

export function isBoolean(value: unknown): value is boolean {
  return typeof value === 'boolean';
}

export function isPrimitive(value: unknown): value is Primitive {
  return isNumber(value) || isString(value) || isBoolean(value);
}

/* eslint-disable @typescript-eslint/no-unused-vars */
// use this function to check that all cases for variable are checked
export function checkNever(value: never): void {}
/* eslint-enable @typescript-eslint/no-unused-vars */

export function isObject(value: unknown): value is Record<string | number | symbol, unknown> {
  return !!value && typeof value === 'object' && !Array.isArray(value);
}

export {isNotNullish} from './isNotNullish';
export {isNullish} from './isNullish';

import {routeAsync} from 'helpers/asyncConnect';

import {anonymous} from './decorators';
import {DefaultParams, PoorRoute} from './types';

const NAME = 'cart';

export const CartRoute: PoorRoute<typeof NAME, DefaultParams> = {
  name: NAME,
  path: '/cart',
  async: routeAsync(
    /* #__LOADABLE__ */ () => import('containers/pages/GroupedCartPage'),
    (module) => module.GroupedCartPage,
  ),
  decorator: anonymous,
  scrollBehaviour: 'scroll-restoration',
};

import {OS} from 'helpers/userAgent';
import {useUserAgent} from 'hooks/useUserAgent';

export function useNativeLazy(): boolean {
  const userAgent = useUserAgent();

  if (userAgent.os.name === OS.IOS || userAgent.browser.name === 'IE') {
    // Using user-agent instead of feature detecting for stable server side rendering.
    // iOS and Internet Explorer do not support native lazy attribute.
    // https://caniuse.com/loading-lazy-attr
    return false;
  }

  return true;
}

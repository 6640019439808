import {Action, History, Location, UnregisterCallback} from 'history';
import {useHistory} from 'react-router-dom';

type HistoryBlockListener = (location: Location, action: Action) => boolean | void;

type HistoryBlockFunction = (listener: HistoryBlockListener) => UnregisterCallback;

function subscribe(history: History, listeners: Set<HistoryBlockListener>): UnregisterCallback {
  return history.block((location, action) => {
    let canDoTransition = true;

    listeners.forEach((listener) => {
      const listenerCanDoTransition = listener(location, action);
      canDoTransition &&= listenerCanDoTransition !== false;
    });

    if (!canDoTransition) {
      return false;
    }
    return undefined;
  });
}

const historyBlockMap = new WeakMap<History, HistoryBlockFunction>();

// history.block can have only one listener
// this history block function can have any number of listeners
export function getHistoryBlock(history: History): HistoryBlockFunction {
  let historyBlock = historyBlockMap.get(history);

  if (!historyBlock) {
    const listeners: Set<HistoryBlockListener> = new Set();
    let unsubscribe: UnregisterCallback | undefined;

    historyBlock = (listener) => {
      if (listeners.size === 0) {
        unsubscribe = subscribe(history, listeners);
      }

      listeners.add(listener);

      return () => {
        listeners.delete(listener);

        if (listeners.size === 0) {
          unsubscribe?.();
          unsubscribe = undefined;
        }
      };
    };

    historyBlockMap.set(history, historyBlock);
  }

  return historyBlock;
}

export function useHistoryBlock(): HistoryBlockFunction {
  const history = useHistory();
  return getHistoryBlock(history);
}

import {routeAsync} from 'helpers/asyncConnect';

import {anonymous} from './decorators';
import {DefaultParams, PoorRoute} from './types';

type Params = DefaultParams & {
  postId: string;
};

const NAME = 'socialPost';

export const SocialPostRoute: PoorRoute<typeof NAME, Params> = {
  name: NAME,
  path: '/social/posts/:postId',
  async: routeAsync(
    /* #__LOADABLE__ */ () => import('containers/pages/SocialPostPage'),
    (module) => module.SocialPostPage,
  ),
  decorator: anonymous,
  scrollBehaviour: 'scroll-restoration',
};

import {routeAsync} from 'helpers/asyncConnect';

import {anonymous} from './decorators';
import {DefaultParams, PoorRoute} from './types';

const NAME = 'legalDoc';

export const LegalDocRoute: PoorRoute<typeof NAME, DefaultParams & {id: string}> = {
  name: NAME,
  path: '/docs/:id',
  async: routeAsync(
    /* #__LOADABLE__ */ () => import('containers/pages/LegalDocPage'),
    (module) => module.LegalDocPage,
  ),
  decorator: anonymous,
};

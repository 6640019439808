import {CookieValue} from 'helpers/ApiClient/Device/CookieValue';
import {CookieType} from 'types/CookiesSettings';

import {getCloseCookieKey, stringDecoder, stringEncoder} from '../helpers';

const APP_ORDER_PUSHES_BANNER_CLOSE_ID = 'appOrderPushes';

const MAX_AGE = 60 * 60 * 24;

export const AppOrderPushesCloseCookie: CookieValue<string> = {
  type: CookieType.FUNCTIONAL,
  key: getCloseCookieKey(APP_ORDER_PUSHES_BANNER_CLOSE_ID),
  decode: stringDecoder,
  encode: stringEncoder,
  getOptions: () => ({
    maxAge: MAX_AGE,
  }),
};

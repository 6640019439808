// eslint-disable-next-line max-classes-per-file
import {Request, Response} from 'express';
import type {VerifyHcaptchaOptions} from 'helpers/ApiClient/Transport/HcaptchaProtectionTransport';
import {optionsWithPowSolution} from 'helpers/ApiClient/Transport/ProofOfWork/utils';
import {ApiResponse} from 'helpers/ApiClient/Transport/Response';
import {TransportHeaders, TransportMeta, TransportMethod, TransportOptions} from 'types/Transport';

import {GoTransport} from './GoTransport';
import {addClientRequestIdHeader, addExpAppHeader, addRequestPathHeader} from './headers';

export const PROXY_PREFIX = '/api/1.1';

let proxyClientBackendEnabled = false;

export function enableClientBackendProxy(enabled?: boolean): void {
  proxyClientBackendEnabled = Boolean(enabled);
}

export function getClientBackendPrefixCreator(device: Device): () => string {
  return function getClientBackendPrefix() {
    // do not proxy in dev and server modes and when has endpoints preset
    if (
      proxyClientBackendEnabled &&
      __CLIENT__ &&
      !__DEVELOPMENT__ &&
      !device.getEndpointsConfigPreset()
    ) {
      return PROXY_PREFIX;
    }

    if (__SERVER__) {
      return device.transportEndpoints.serverApi();
    }

    return device.transportEndpoints.api();
  };
}

type Device = import('../Device').Device;

abstract class BaseApiTransport extends GoTransport {
  public override device!: Device;

  override buildHeaders(headers: TransportHeaders, meta: TransportMeta): TransportHeaders {
    const result = super.buildHeaders(headers, meta);
    addRequestPathHeader(result, this.req);
    addExpAppHeader(result, this.device.scope);

    if (__SERVER__) {
      addClientRequestIdHeader(result, this.device.getClientRequestId());
    }

    return result;
  }

  override request<TBody>(
    method: TransportMethod,
    path: string,
    {
      hcaptchaOptions,
      ...options
    }: TransportOptions & {hcaptchaOptions?: VerifyHcaptchaOptions} = {},
  ): Promise<ApiResponse<TBody>> {
    return this.device.proofOfWorkController.verifyProtection((powSolution) =>
      this.device.transports.hcaptcha.verifyProtection(
        () => super.request(method, path, optionsWithPowSolution(options, powSolution)),
        hcaptchaOptions,
      ),
    );
  }
}

/** Transport for all common API requests */
export class ApiTransport extends BaseApiTransport {
  constructor(device: Device, req: Request | undefined, res: Response | undefined) {
    super(getClientBackendPrefixCreator(device), device, req, res);
  }
}

/** Base class for protection transports (hcaptcha) */
export abstract class BaseApiProtectionTransport extends ApiTransport {
  // replace ApiTransport request, because don't need verifyProtection for self
  override request = GoTransport.prototype.request;

  abstract verifyProtection<T>(handler: () => Promise<T>): Promise<T>;
}

/** Transport for secure API requests with user personal data */
export class SecureApiTransport extends BaseApiTransport {
  constructor(device: Device, req: Request | undefined, res: Response | undefined) {
    super(device.transportEndpoints.secureApi, device, req, res);
  }
}

import {TransitionAppearance} from 'components/UIKit/Appearance/TransitionAppearance';

import styles from './default.scss';

export const appearance: TransitionAppearance = {
  className: styles.design!,
  transitionProps: {
    classNames: {
      appear: styles.appear,
      appearActive: styles.appearActive,
    },
    in: true,
    appear: true,
    timeout: {appear: 300},
    enter: false,
    exit: false,
  },
};

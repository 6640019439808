import {ScopeConfig} from 'helpers/ApiClient/Scope/ScopeConfig';
import {memoize} from 'utils/memoize';
import {objectFilter} from 'utils/object';
import {TypedObject} from 'utils/object/typed';

const URL_REGEXP = /^(.*?)(\?.+?)?(#.+)?$/;
const DOMAIN_REGEXP = /^(?:.+:)?\/\/([^:/?#(]+)/;
const ORIGIN_REGEXP = /^(?:.+:)?\/\/([^/?#]+)/;
const SLASH = '/';

const getJoomUrlRegexp = memoize(
  (hostname: string) =>
    // /^(?:.+:)?\/\/(?:.*\.)?joom\.com/
    new RegExp(`^(?:.+:)?\\/\\/(?:.*\\.)?${hostname.replace('www.', '').replace(/\./g, '\\.')}`),
);

type QueryValue = string | number | boolean | null | undefined;
export type QueryMap<TValue = QueryValue> = Record<string, TValue | TValue[]>;
export type QuerySerializationOptions = {
  arrayFormat?: 'bracket' | 'none';
};

function encodeQueryValue(value?: string | number | boolean | null): string {
  return value !== null && value !== undefined ? encodeURIComponent(value) : '';
}

export function serializeQueryData(
  params: QueryMap,
  {arrayFormat = 'bracket'}: QuerySerializationOptions = {},
): string {
  return Object.keys(params)
    .map((key) => {
      const value = params[key];
      if (Array.isArray(value)) {
        return value
          .map((val) => `${key}${arrayFormat === 'bracket' ? '[]' : ''}=${encodeQueryValue(val)}`)
          .join('&');
      }
      return `${key}=${encodeQueryValue(value)}`;
    })
    .join('&');
}

function replacePluses(str: string) {
  if (!str?.length) {
    return '';
  }
  return str.replace(/\+/g, '%20');
}

export function decodeURIWithPluses(uri: string): string {
  return decodeURI(replacePluses(uri));
}

export function decodeURIComponentWithPluses(uri: string): string {
  return decodeURIComponent(replacePluses(uri));
}

export function deserializeQueryData(
  query = '',
  {arrayFormat = 'bracket'}: QuerySerializationOptions = {},
): QueryMap<string> {
  return query
    .split('&')
    .map((kv) => kv.split('=') as [string, string])
    .reduce((next, [key, value]) => {
      if (arrayFormat === 'bracket') {
        if (key[key.length - 2] === '[' && key[key.length - 1] === ']') {
          const arrKey = key.slice(0, key.length - 2);
          if (!Array.isArray(next[arrKey])) {
            next[arrKey] = [];
          }
          (next[arrKey] as string[]).push(decodeURIComponentWithPluses(value));
        } else {
          next[key] = decodeURIComponentWithPluses(value);
        }
      } else {
        const decodedValue = decodeURIComponentWithPluses(value);

        if (key in next && !Array.isArray(next[key])) {
          next[key] = [next[key] as string];
        }

        if (Array.isArray(next[key])) {
          (next[key] as string[]).push(decodedValue);
        } else {
          next[key] = decodedValue;
        }
      }
      return next;
    }, {} as QueryMap<string>);
}

export function getQueryStringParam<T>(qd: QueryMap<T>, key: string): T | undefined {
  const value = qd[key];
  switch (typeof value) {
    case 'string':
      return value;
    default:
      return undefined;
  }
}

export function getQueryArrayParam<T>(qd: QueryMap<T>, key: string): T[] | undefined {
  const value = qd[key];
  if (Array.isArray(value)) {
    return value;
  }
  return undefined;
}

export function getQueryData(url: string): QueryMap<string> {
  const match = url.match(URL_REGEXP);
  if (match) {
    const query = match[2];
    if (query) {
      return deserializeQueryData(query.substr(1));
    }
  }
  return {};
}

export function getUrlHostname(url: string | undefined): string | null {
  const match = url ? url.match(DOMAIN_REGEXP) : null;
  return match?.[1] || null;
}

export function getUrlPath(url: string): string | null {
  const withoutOrigin = url.replace(ORIGIN_REGEXP, '');

  if (withoutOrigin !== url || withoutOrigin[0] === SLASH) {
    const path = withoutOrigin.replace(/(?:#|\?).*/, '');
    return path || '/';
  }
  return null;
}

type CreateUrlOptions = {
  querySerialization?: QuerySerializationOptions;
  removeEmptyQueryParams?: boolean;
};

export function createUrl(
  baseUrl: string,
  params: QueryMap = {},
  options: CreateUrlOptions = {},
): string {
  const match = baseUrl.match(URL_REGEXP);
  if (match) {
    const [url, base, query, hash] = match;
    const queryData =
      url && query
        ? {...deserializeQueryData(query.substr(1), options.querySerialization), ...params}
        : params;
    const search = serializeQueryData(
      options.removeEmptyQueryParams ? objectFilter(queryData, Boolean) : queryData,
      options.querySerialization,
    );
    return `${base}${search ? `?${search}` : ''}${hash || ''}`;
  }

  const search = serializeQueryData(params);
  return search ? `?${search}` : '';
}

const Scheme = {
  HTTPS: 'https:',
  HTTP: 'http:',
  MAILTO: 'mailto:',
  TEL: 'tel:',
};

export function isExternalUrl(url: string): boolean {
  if (url) {
    return (
      TypedObject.keys(Scheme).some((key) => url.indexOf(Scheme[key]) === 0) ||
      (url[0] === SLASH && url[1] === SLASH)
    );
  }
  return false;
}

export function isJoomUrl(scope: ScopeConfig, url: string): boolean {
  return getJoomUrlRegexp(scope.hostname).test(url);
}

export function trimJoomUrl(scope: ScopeConfig, url: string): string {
  return url.replace(getJoomUrlRegexp(scope.hostname), '');
}

export function trimTrailingSlash(path: string): string {
  return path.endsWith('/') && path !== '/' ? path.slice(0, -1) : path;
}

export function trimAloneSlash(path: string): string {
  return path === '/' ? '' : path;
}

export {isFirebaseUrl} from './isFirebaseUrl';

import {ApiClient} from 'helpers/ApiClient';
import {History, Location} from 'history';
import {ComponentType} from 'react';
import {match} from 'react-router-dom';
import {Overwrite} from 'typelevel-ts';
import {Store} from 'typesafe-actions';

export type PromiseArgProps = {
  match: match;
  location: Location;
  history?: History;
  initial: boolean;
  initialAfterSsr: boolean;
};

export type PromiseArg<TProps extends Record<string, unknown>> = Overwrite<
  TProps,
  {
    client: ApiClient;
    store: Store;
  } & PromiseArgProps
>;

export type AsyncItemCheck = undefined | boolean | ((client: ApiClient) => boolean);

export type AsyncItemPromiseCreator<
  TProps extends Record<string, unknown> = Record<string, unknown>,
> = (data: PromiseArg<TProps>) => Promise<unknown> | null | undefined;

export type AsyncItemBase<TProps extends Record<string, unknown> = Record<string, unknown>> = {
  deferred?: AsyncItemCheck;
  delayed?: AsyncItemCheck;
  ssr?: AsyncItemCheck;
  csr?: AsyncItemCheck;
  promise: AsyncItemPromiseCreator<TProps>;
};

export type AsyncItemParallel<TProps extends Record<string, unknown> = Record<string, unknown>> = {
  parallel: AsyncItem<TProps>[];
};

export type AsyncItemQueue<TProps extends Record<string, unknown> = Record<string, unknown>> = {
  queue: AsyncItem<TProps>[];
};

export type AsyncItem<TProps extends Record<string, unknown> = Record<string, unknown>> =
  | AsyncItemBase<TProps>
  | AsyncItemQueue<TProps>
  | AsyncItemParallel<TProps>;

export type AsyncConnectComponent = ComponentType & {
  asyncConnect: Array<AsyncItem>;
};

export function isAsyncConnectComponent(
  Component: ComponentType,
): Component is AsyncConnectComponent {
  return Boolean(Component && 'asyncConnect' in Component);
}

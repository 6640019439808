import config from 'config';
import type {Request, Response} from 'express';
import {CoolbeMigration} from 'helpers/ApiClient/CoolbeMigration';
import {getGsAttrs} from 'helpers/ApiClient/Device/gsAttrs';
import {JmtMigration} from 'helpers/ApiClient/JmtMigration';
import {ResourceTimingV2} from 'helpers/ApiClient/ResourceTimingV2';
import {PREFIX_SCOPE_GET_PARAM, ScopeConfig} from 'helpers/ApiClient/Scope/ScopeConfig';
import {addProofOfWorkSolutionHeader} from 'helpers/ApiClient/Transport/headers';
import {PayhubTransport} from 'helpers/ApiClient/Transport/PayhubTransport';
import {createApiError} from 'helpers/ApiClient/Transport/Response';
import {noop} from 'lodash';
import {createKeyValueStorage, KeyValueStorageStrategy} from 'utils/KeyValueStorage';
import {objectFilter} from 'utils/object';
import {getReferrer} from 'utils/request';
import {createUrl} from 'utils/url';

import {Analytics} from './Analytics';
import {Cookies} from './Cookies';
import {Device} from './Device';
import {createCookieRegistry} from './Device/cookiesRegistry';
import {Fingerprint} from './Fingerprint';
import {Performance} from './Performance';
import {PushNotifications} from './PushNotifications';
import {Riskified} from './Riskified';
import {Session} from './Session';
import {Tracking} from './Tracking';
import {getOrigin} from './url';

const HYDRATABLE_COMPONENTS = [
  // order is important
  'device',
  'analytics',
  'session',
  'tracking',
] as const;

export class ApiClient {
  // eslint-disable-next-line no-useless-constructor
  constructor(
    public req?: Request,
    public res?: Response,
    // eslint-disable-next-line no-empty-function
  ) {}

  private cookies = this.req?.cookies || new Cookies(this.req, this.res);

  cookiesRegistry = createCookieRegistry(this.cookies);

  device = new Device(this.cookiesRegistry, this.cookies, this.req, this.res);

  riskified = new Riskified(this.device);

  faqApi = this.device.transports.faqApi;

  webApi = this.device.transports.webApi;

  secureApi = this.device.transports.secureApi;

  api = this.device.transports.api;

  paymentApi = this.device.transports.paymentApi;

  pureApi = this.device.transports.pureApi;

  payhubApi = new PayhubTransport(this.device, this.req, this.res);

  analytics = new Analytics(this.api, this.device);

  performance = new Performance(this.analytics, this.device);

  pushNotifications = new PushNotifications(this.device);

  origin = getOrigin(this.req);

  tracking = new Tracking(this.device, this.analytics, this.req);

  private session = new Session(this.device, this.analytics, this.cookiesRegistry);

  jmtMigration = new JmtMigration(this.device, this.cookiesRegistry, this.req);

  coolbeMigration = new CoolbeMigration(this.device, this.cookiesRegistry, this.req);

  fingerprint = new Fingerprint(this.device, this.session);

  buildVersionCookie = this.cookiesRegistry.buildVersion;

  resourceTimingV2 = new ResourceTimingV2(this.device, this.analytics);

  storages = {
    local: createKeyValueStorage(KeyValueStorageStrategy.LOCAL, this.device.log),
    session: createKeyValueStorage(KeyValueStorageStrategy.SESSION, this.device.log),
  };

  getOrigin(): string {
    return this.origin;
  }

  getOriginalUrl(): string {
    if (this.req) {
      return this.req.originalUrl;
    }

    if (__CLIENT__) {
      const {pathname, search, hash} = document.location;
      return `${pathname}${search}${hash}`;
    }

    return '';
  }

  get scope(): ScopeConfig {
    return this.device.scope;
  }

  getReferrer(): string | undefined {
    if (this.req) {
      return getReferrer(this.req);
    }

    if (__CLIENT__) {
      return document.referrer;
    }

    return '';
  }

  getHydrateUrl(): string {
    const query = objectFilter(
      {
        [PREFIX_SCOPE_GET_PARAM]: this.scope.prefixScope,
        language: this.device.overrideDeviceLanguage() ? this.device.getLanguage() : undefined,
        renderingId: this.device.getRenderingConfig()?.id,
        currency: this.device.getTransportCurrencyOverride(),
        gsAttrs: getGsAttrs(this.req),
      },
      Boolean,
    );

    return createUrl('/tokens/hydrate', query);
  }

  hydrate(): Promise<unknown> {
    if (__SERVER__) {
      return Promise.resolve(null);
    }

    return this.device.proofOfWorkController.verifyProtection((powSolution) => {
      const options: RequestInit = {credentials: 'include'};
      if (powSolution) {
        options.headers = addProofOfWorkSolutionHeader({}, powSolution) as HeadersInit;
      }

      // don't change this fetch since it preloaded in `src/helpers/Html`
      return fetch(this.getHydrateUrl(), options)
        .then(async (res) => {
          const body = await res.json();
          if (!res.ok) {
            throw createApiError(body?.type, res.status, body);
          }
          return body;
        })
        .then(({payload}: {payload?: Record<string, unknown>}) =>
          HYDRATABLE_COMPONENTS.forEach((key) => {
            const component = this[key];
            const data = payload && payload[key];
            if (data && component && 'hydrate' in component && component.hydrate) {
              (component.hydrate as (arg: unknown) => void)(data);
            }
          }),
        );
    });
  }

  async getHydrationData(): Promise<Record<string, unknown>> {
    if (__CLIENT__) {
      return {};
    }

    await this.device.init();

    const result: Record<string, unknown> = {};
    await Promise.all(
      HYDRATABLE_COMPONENTS.map((key) => {
        const component = this[key];
        if (component && 'getHydrationData' in component && component.getHydrationData) {
          return Promise.resolve(component.getHydrationData()).then((data) => {
            result[key] = data;
          });
        }
        return undefined;
      }),
    );

    return result;
  }

  async init(): Promise<void> {
    this.res?.startTime('aci', 'Api Client Initialization');
    await this.device.setupTransportOverrides();
    await this.hydrate();

    await this.device.init();

    // check jmt redirect when client rendering
    if (__CLIENT__) {
      const redirect =
        (await this.jmtMigration.getMigrationOrEntityRedirect()) ||
        (await this.coolbeMigration.getRedirect());

      if (redirect) {
        this.device.log
          .getLogger('MigrationRedirect')
          .info(`Migration redirect to ${redirect.url} (${redirect.status})`);

        window.location.replace(redirect.url);
        // wait eternal promise while redirect
        await new Promise(noop);
      }
    }

    await Promise.all([
      this.analytics.init(),
      this.tracking.init(),
      this.session.init(),
      this.fingerprint.init(),
      this.resourceTimingV2.init(),
      this.jmtMigration.init(),
    ]);

    // async inits
    this.performance.init();
    // riskified should be strictly after session init,
    // because it uses SessionIdCookie
    this.riskified.init();

    if (!this.device.isAppWebView()) {
      this.pushNotifications.init();
    }

    // update user on new session start
    this.session.on('start', () => this.device.loadUser());

    this.res?.endTime('aci');
  }

  getBuildVersionMismatch(): string | undefined {
    const serverVersion = this.buildVersionCookie.restore();
    if (serverVersion && serverVersion !== config.version) {
      return serverVersion;
    }
    return undefined;
  }

  isBuildVersionCriticalMismatch(): boolean {
    const versionNumberRegExp = /^(\d+\.\d+\.\d+)/;
    const serverVersion = this.getBuildVersionMismatch();
    if (!serverVersion) {
      return false;
    }
    const [, serverVersionNumber] = serverVersion.match(versionNumberRegExp) || [];
    const [, versionNumber] = config.version.match(versionNumberRegExp) || [];

    return Boolean(serverVersionNumber && versionNumber) && serverVersionNumber !== versionNumber;
  }
}

import type {EventParams} from 'types/EventParams';
import {ImageBundle} from 'types/Image';
import {Count} from 'types/ObfuscatedCount';
import {Price} from 'types/Price';

import {ContentListBaseItem, contentListBaseItem} from './ContentListBaseItem';

type DescriptionProperty = {
  title: string;
  text: string;
};

type ProductItem = {
  content: {
    // ProductLite
    product: {
      mainImage?: ImageBundle;
      price?: Price;
      id?: string | number;
    };
  };
};

export type Store = {
  id: string;
  rating?: number;
  name: string;
  enabled: boolean;
  image?: ImageBundle;
  favorite: boolean;
  verified: boolean;
  favoritesCount: Count;
  productsCount: Count;
  ordersCount?: Count;
  location?: string;
  reviewsCount: Count;
  description?: DescriptionProperty[];
  flag?: ImageBundle;
  shortDescription?: string;
  background?: ImageBundle;
  socialUserId?: string;
  eventParams?: EventParams;
  context?: Record<string, unknown>;
};

export type PowerShop = {
  // ProductsList
  productsList: {
    items: ProductItem[];
    headerAppearance: {
      store: {
        store: Store;
      };
    };
    appearance?: {
      showName: boolean;
      showReviewsCount: boolean;
      showShippingBadge: boolean;
      showShippingBadgeOnDetails: boolean;
    };
    endpointPath?: string;
    endpointParams?: Record<string, unknown>;
  };
};

export type ContentListPowerShop = ContentListBaseItem<PowerShop>;

export function contentListPowerShop(id: string, powerShop: PowerShop): ContentListPowerShop {
  return contentListBaseItem(id, powerShop);
}

export function isContentListPowerShop(item: ContentListBaseItem): item is ContentListPowerShop {
  return Boolean((item as ContentListPowerShop).content?.productsList?.headerAppearance?.store);
}

export function extractPowerShop(item: ContentListPowerShop): PowerShop {
  return item.content;
}

// history dep try to access sessionStorage withou try/catch, which races
// exception in private mode. Nullification is our true way!

function safeGlobalProp(prop: 'localStorage' | 'sessionStorage'): boolean {
  try {
    return prop in window && window[prop] !== null;
  } catch (ex) {
    try {
      (window as {[K in typeof prop]: Window[K] | null})[prop] = null;
    } catch (ex2) {} // eslint-disable-line no-empty
  }
  return false;
}

safeGlobalProp('localStorage');
safeGlobalProp('sessionStorage');
